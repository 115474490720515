import React, {Dispatch, SetStateAction, useContext, useEffect, useMemo, useState} from "react";
import {AppContext} from "../../../App";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import ScreenContent from "../../components/screens/ScreenContent";
import {Box, CircularProgress, Divider, InputAdornment, Theme, Tooltip, Typography} from "@mui/material";
import EmptyListText from "../../components/textComponents/EmptyListText";
import {tt} from "../../../core/Localization";
import AppPaper from "../../components/paper/AppPaper";
import PaperAppbar from "../../components/paper/PaperAppbar";
import AppChip from "../../components/chips/AppChip";
import {AddOutlined} from "@mui/icons-material";
import SearchIcon from "../../../icons/SearchIcon";
import AppListItem from "../../components/listItems/AppListItem";
import {useNavigate, useSearchParams} from "react-router-dom";
import {workerDetailRoute} from "./WorkerDetailScreen";
import CreateWorkerModal from "../../components/workers/CreateWorkerModal";
import FormBuilder, {IInputsData, InputType} from "../../components/form/FormBuilder";
import ListShimmer from "../../components/shimmers/ListShimmer";
import {kAppColors, kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import {AppDataContext} from "../../../AppData";
import {
    EmployeeJoinedUserResponsePage,
    GetEmployeesJoinedUsersIncludingInactiveInput
} from "../../../generated/graphql/graphql";
import {UserFullName, UserPhotoUrl, UserRoleTitle} from "../../../service/UserService";
import {kMobileMenuRoute} from "../mobile/MobileMenuScreen";
import {SortEmployeesByName} from "../../../service/EmployeeService";
import Icons8AddShoppingCart1 from "../../../icons/Icons8AddShoppingCart1";
import Icons8AddShoppingCart2 from "../../../icons/Icons8AddShoppingCart2";
import {makeStyles} from "tss-react/mui";
import TwoLineChip from "../../components/chips/TwoLineChip";
import AppIconButton from "../../components/buttons/AppIconButton";
import {DisplaySubscriptionUpgradeModal} from "../../../service/CompanyService";
import {routeWithCurrentAsParam} from "../../../utils/Utils";
import {processQueryError} from "../../../service/ErrorService";
import {
    kActionCreate,
    kActionUpdate,
    kPermissionsCompanySubscription,
    kPermissionsWorkers,
    kTopicCompanyEmployees
} from "../../../core/constants";
import PermissionValid from "../../components/permissions/PermissionValid";
import Icons8UserWithLock from "../../../icons/Icons8UserWithLock";
import {RestApiClientContext} from "../../../core/RestApiProvider";


export const kWorkersRoute = '/workers';


export const useStyles = makeStyles()((theme: Theme) => ({
    chipsContainer: {
        display: 'flex',
        paddingBottom: 16,
        overflowX: "auto",
    },
    innerChip: {
        flexShrink: 0,
        paddingLeft: 4,
        fontSize: 12,
        color: "inherit",
    },
    noShrink: {
        flexShrink: 0,
    },
    spinnerContainer: {
        display: 'flex',
        alignItems: "center",
        marginLeft: 8,
        marginRight: -4,
    },
    listItemChipsContainer: {
        display: "flex",
    }
}));

export default function WorkersScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;

    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId, setStorage} = appDataContext;

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        setTitle(tt('workers.screen.title'));
    }, []);

    const [inputs, setInputs] = useState<IInputsData>({
        search: {
            type: InputType.Text,
            label: '',
            placeholder: tt('workers.screen.searchForWorker'),
            hideLabel: true,
            inputVariant: 'standard',
            extraStyle: 'thin',
            value: '',
            required: true,
            isClearable: true,
            innerPrefixJSX: (
                <InputAdornment position={"start"}>
                    <SearchIcon/>
                </InputAdornment>
            ),
        },
    });

    const [modal, setModal] = useState<boolean>(false);
    const [activeWorkers, setActiveWorkers] = useState<boolean>(
        searchParams.get('activeWorkers') === 'true' || searchParams.get('activeWorkers') === null
    );

    useEffect(() => {
        setSearchParams(prev => {
            prev.set('activeWorkers', activeWorkers.toString());
            return prev;
        });
    }, [activeWorkers]);

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<EmployeeJoinedUserResponsePage | NullOrUndefined>();
    useEffect(() => {
        if (companyId) {
            const subscription = subscribe(
                kTopicCompanyEmployees,
                {
                    uri: '/company/employee/search-joined-users-including-inactive',
                    params: {
                        companyId: companyId,
                        includeCheckPermissionsByRole: true,
                    } as GetEmployeesJoinedUsersIncludingInactiveInput,
                    setLoading: setLoading,
                    onData: setData,
                    onError: (error) => {
                        processQueryError(appDataContext, error);
                    },
                }, (notifications) => true,
            );

            return () => subscription.cancel();
        }
    }, [companyId]);

    useEffect(() => {
        if (data) {
            setStorage((prev) => {
                return {
                    filesToProcess: [
                        ...prev.filesToProcess,
                        ...(data.files || []),
                    ],
                };
            });
        }
    }, [data]);

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                loading={loading}
                data={data}
                search={inputs.search.value.toLowerCase()}
                inputs={inputs}
                setInputs={setInputs}
                setModal={setModal}
                isMobile={isMobile}
                activeWorkers={activeWorkers}
                setActiveWorkers={setActiveWorkers}
            />
        );
    }

    return (
        <>
            <ResponsiveContainer
                smallPhoneScreen={bodyJSX(true)}
                largePhoneScreen={bodyJSX(true)}
                tabletScreen={bodyJSX()}
                smallDesktopScreen={bodyJSX()}
                largeDesktopScreen={bodyJSX()}
                extraLargeDesktopScreen={bodyJSX()}
            />

            <CreateWorkerModal setOpen={setModal} open={modal}/>
        </>
    );
}

interface IBodyProps {
    loading: boolean;
    data: EmployeeJoinedUserResponsePage | NullOrUndefined;
    search: string;
    inputs: IInputsData;
    setInputs: Dispatch<SetStateAction<IInputsData>>;
    setModal: Dispatch<SetStateAction<boolean>>;
    isMobile?: boolean;
    activeWorkers: boolean;
    setActiveWorkers: Dispatch<SetStateAction<boolean>>;
}

function Body(props: IBodyProps) {
    const {
        loading,
        data,
        search,
        inputs,
        setInputs,
        setModal,
        isMobile,
        activeWorkers,
        setActiveWorkers,
    } = props;

    const appDataContext = useContext(AppDataContext);
    const {companySubscriptionStatus, storage} = appDataContext;

    const navigate = useNavigate();
    const {classes, cx} = useStyles();

    const content = useMemo(
        () => {
            const theData = data?.content
                .filter(worker => {
                    return (activeWorkers ? worker.active : !worker.active) && !worker.deleted;
                });

            return loading && !theData ?
                <ListShimmer items={2}/> : theData && theData.length > 0 ?
                    SortEmployeesByName(
                        theData
                            .filter(worker => {
                                const fullName = UserFullName(worker?.name || worker?.user?.name, worker?.surname || worker?.user?.surname);

                                return fullName.toLowerCase().includes(search);
                            })
                    )
                        .map(worker => {
                            const canInvite = worker.employeeInvitation && !worker.employeeInvitation.consumed;

                            const ownPermissionsJSX = data?.permissionsByRoleEmployeeIds?.includes(worker.id) ? null : (
                                <Box pr={0.5}>
                                    <Tooltip
                                        title={tt('employee.listItem.chipTooltip.customPermissions')}
                                        slotProps={{
                                            popper: {
                                                modifiers: [
                                                    {
                                                        name: 'offset',
                                                        options: {
                                                            offset: [0, -4],
                                                        },
                                                    },
                                                ],
                                            },
                                        }}
                                    >
                                        <Box>
                                            <AppChip
                                                noiconmargin={'true'}
                                                chipstyle={"outlined"}
                                                icon={<Icons8UserWithLock/>}
                                            />
                                        </Box>
                                    </Tooltip>
                                </Box>
                            );

                            return (
                                <AppListItem
                                    key={worker.id}
                                    variant={"smaller-title"}
                                    profileImage={
                                        UserPhotoUrl(worker.user, data?.files, storage.publicUrlsForFiles)
                                    }
                                    onClick={() => {
                                        navigate(routeWithCurrentAsParam(workerDetailRoute(worker.id)));
                                    }}
                                    title={UserFullName(worker?.name || worker?.user?.name, worker?.surname || worker?.user?.surname)}
                                    description={UserRoleTitle(worker.role)}
                                    customBottomWidget={<Box className={classes.listItemChipsContainer}>
                                        {ownPermissionsJSX}

                                        {canInvite ? <Box
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                minHeight: 30,
                                                borderRadius: 8,
                                                paddingTop: 4,
                                                paddingBottom: 4,
                                                paddingRight: 8,
                                                paddingLeft: 8,
                                                marginRight: 16,
                                                border: `1px solid ${kAppColors.primary.main}`,
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontSize: 12,
                                                    fontWeight: 700,
                                                    color: kAppColors.primary.main,
                                                }}>
                                                {tt('workerListItem.chip.invite')}
                                            </Typography>
                                        </Box> : null}
                                    </Box>}
                                />
                            );
                        })
                    : <EmptyListText text={tt('workers.screen.emptyListMessage')}/>;
        },
        [data, search, activeWorkers, storage.publicUrlsForFiles]
    );

    const currentUsers = useMemo(() => {
        return data?.content
            .filter(worker => {
                return worker.active;
            }).length || 0;
    }, [data]);

    const maxUsers = companySubscriptionStatus?.maxUsers || 0;
    const showUpgradeButton = currentUsers >= maxUsers;

    const activeWorkersFilterJSX = (
        <Box
            className={classes.chipsContainer}>
            <TwoLineChip
                className={classes.noShrink}
                suffix={
                    loading && !data?.content ?
                        <Box className={classes.spinnerContainer}>
                            <CircularProgress style={{height: 22, width: 22}}/>
                        </Box>
                        :
                        <Typography
                            className={cx(classes.innerChip, 'textSuffix')}>
                            {`${currentUsers} ${tt('common.outOf')} ${maxUsers}`}
                        </Typography>
                }
                variant={activeWorkers ? "primary" : "secondary"}
                text1={tt('workers.screen.button.active')}
                onClick={() => {
                    setActiveWorkers(true);
                }}
            />
            <Box pr={1}/>
            <TwoLineChip
                className={classes.noShrink}
                variant={!activeWorkers ? "primary" : "secondary"}
                text1={tt('workers.screen.button.deactivated')}
                onClick={() => {
                    setActiveWorkers(false);
                }}
            />
        </Box>
    );

    return (
        <ScreenContent
            appBar={!isMobile}
            noContentPadding={isMobile}
            navigationDrawer={!isMobile}
            bottomBar={isMobile}
            centerHorizontally={true}>
            <AppPaper
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}>
                <PaperAppbar
                    noMarginBottom={true}
                    isMobile={isMobile}
                    title={tt('common.workers')}
                    hideBackButton={!isMobile}
                    backRoute={isMobile ? kMobileMenuRoute : undefined}
                    children={
                        <>
                            {isMobile || !showUpgradeButton ?
                                <PermissionValid
                                    key={'shopButton'}
                                    permission={kPermissionsCompanySubscription}
                                    requiredPermissions={[kActionUpdate]}
                                >
                                    <Box pr={1}>
                                        <AppIconButton
                                            onClick={() => {
                                                DisplaySubscriptionUpgradeModal(
                                                    appDataContext,
                                                    currentUsers,
                                                    maxUsers,
                                                );
                                            }}
                                        ><Icons8AddShoppingCart1/>
                                        </AppIconButton>
                                    </Box>
                                </PermissionValid>
                                : undefined}

                            {isMobile ? undefined : (showUpgradeButton ? (
                                <PermissionValid
                                    permission={kPermissionsCompanySubscription}
                                    requiredPermissions={[kActionUpdate]}
                                >
                                    <AppChip
                                        onClick={() => {
                                            DisplaySubscriptionUpgradeModal(
                                                appDataContext,
                                                currentUsers,
                                                maxUsers,
                                            );
                                        }
                                        }
                                        key={'upgradeChip'}
                                        label={tt('workersScreen.button.upgrade')}
                                        icon={<Icons8AddShoppingCart2/>}
                                    />
                                </PermissionValid>
                            ) : (
                                <PermissionValid
                                    permission={kPermissionsWorkers}
                                    requiredPermissions={[kActionCreate]}
                                >
                                    <AppChip
                                        onClick={() => {
                                            setModal(true)
                                        }}
                                        key={'addWorkerChip'}
                                        label={tt('common.newWorker')}
                                        icon={<AddOutlined/>}
                                    />
                                </PermissionValid>
                            ))}
                        </>
                    }
                    bottomContent={(
                        <Box pt={1}>
                            <FormBuilder inputs={inputs} setInputs={setInputs}/>

                            {activeWorkersFilterJSX}

                            <Divider sx={{mr: -2, ml: -2}}/>
                        </Box>
                    )}
                />

                {content}

                <Box sx={{pb: 0.5}}/>
            </AppPaper>
        </ScreenContent>
    );
}
