import {makeStyles} from "tss-react/mui";
import {Box, Theme} from "@mui/material";
import React, {Dispatch, SetStateAction, useRef} from "react";
import Sheet, {SheetRef} from "react-modal-sheet";
import {kAppColors} from "../../styles/AppThemeProcessor";
import ResponsiveContainer from "./screens/ResponsiveContainer";

export const useStyles = makeStyles()((theme: Theme) => ({
    modal: {
        minWidth: '240px',
        maxWidth: '767px',
        width: '100%',
        margin: 'auto',
        zIndex: '1300 !important',
    },
    modalAboveModals: {
        zIndex: '1301 !important',
    },
    sheetContainer: {
        borderTopLeftRadius: 20 + 'px !important',
        borderTopRightRadius: 20 + 'px !important',
        overflow: "clip",
        backgroundColor: `${kAppColors.background.bottomSheet(theme.palette.mode === "dark")} !important`,
        boxShadow: "none !important",
    },
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
    blurBackdrop: {
        backdropFilter: `saturate(180%) blur(10px)`,
    },
    innerContainer: {
        overflowY: 'auto',
    },
    fullScreenOnMobile: {
        "@media (max-width: 767px)": {
            maxHeight: '100% !important',
            borderTopLeftRadius: 0 + 'px !important',
            borderTopRightRadius: 0 + 'px !important',
        }
    }
}));

export interface Props {
    open: boolean;
    hideHeader?: boolean;
    blurBackdrop?: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    children: React.ReactNode;
    backdropClose?: boolean;
    snapPoints?: number[];
    modalAboveModals?: boolean;
    customZIndex?: number;
    tallOnMobile?: boolean;
    fullScreenOnMobile?: boolean;
    className?: string,
}

function ModalBottomSheet(props: Props) {
    const ref = useRef<SheetRef>();

    const {
        open,
        children,
        setOpen,
        backdropClose,
        hideHeader,
        blurBackdrop,
        modalAboveModals,
        tallOnMobile,
        fullScreenOnMobile,
        className,
    }
        = props;

    const {classes, cx} = useStyles();

    function bodyJSX(isMobile?: boolean) {
        return (
            <Sheet
                ref={ref}
                className={cx(classes.modal, modalAboveModals ? classes.modalAboveModals : null, className)}
                onClose={() => setOpen(false)}
                isOpen={open}
                detent={tallOnMobile && isMobile ? undefined : "content-height"}
                disableScrollLocking={true}
            >
                <Sheet.Container
                    className={cx(classes.sheetContainer, fullScreenOnMobile ? classes.fullScreenOnMobile : null)}>
                    {hideHeader ? null : <Sheet.Header></Sheet.Header>}
                    <Sheet.Content disableDrag={true}>
                        <Box className={classes.innerContainer}>
                            {children}
                        </Box>
                    </Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop
                    // onTap={theBackdropClose ? () => setOpen(false) : undefined}
                    className={cx(classes.backdrop, blurBackdrop ? classes.blurBackdrop : null)}
                />
            </Sheet>
        );
    }

    return (
        <ResponsiveContainer
            smallPhoneScreen={bodyJSX(true)}
            largePhoneScreen={bodyJSX(true)}
            tabletScreen={bodyJSX()}
            smallDesktopScreen={bodyJSX()}
            largeDesktopScreen={bodyJSX()}
            extraLargeDesktopScreen={bodyJSX()}/>
    );
}

export default ModalBottomSheet;
