import React, {Dispatch, SetStateAction, useContext, useState} from "react";
import AppModal from "../AppModal";
import {tt} from "../../../../core/Localization";
import AppChip from "../../chips/AppChip";
import Icons8Eye from "../../../../icons/Icons8Eye";
import JobFormPreviewModal from "./JobFormPreviewModal";
import CreateAndEditJobForm from "../../jobForms/CreateAndEditJobForm";
import ResponsiveContainer from "../../screens/ResponsiveContainer";
import AppIconButton from "../../buttons/AppIconButton";
import {AppDataContext} from "../../../../AppData";
import {
    CreateJobFormTemplateDocument, CreateJobFormTemplateInput,
    CreateJobFormTemplateMutation, CreateJobFormTemplateMutationVariables,
    JobFormResponse
} from "../../../../generated/graphql/graphql";
import {useResettableMutation} from "tomaschyly-apollo-hooks-extended";
import {ErrorToast, SuccessToast} from "../../../../service/ToastService";
import {useNavigate} from "react-router-dom";
import {jobFormDetailRoute} from "../../../screens/jobForms/JobFormDetailScreen";

export interface INewJobFormModalProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    isMobile?: boolean;
    navigation?: boolean;
    onCreate?: (id: number) => void;
    onCreateNoBESave?: (input: CreateJobFormTemplateInput) => void;
    existingData?: JobFormResponse | NullOrUndefined;
    isDuplication?: boolean;
    modalAboveModals?: boolean;
}

export default function CreateJobFormModal(props: INewJobFormModalProps) {
    return (
        <ResponsiveContainer
            smallPhoneScreen={<Body {...props} isMobile={true}/>}
            largePhoneScreen={<Body  {...props} isMobile={true}/>}
            tabletScreen={<Body {...props} />}
            smallDesktopScreen={<Body {...props} />}
            largeDesktopScreen={<Body {...props} />}
            extraLargeDesktopScreen={<Body {...props} />}
        />
    );
}

function Body(props: INewJobFormModalProps) {
    const {
        open,
        setOpen,
        isMobile,
        navigation,
        onCreate,
        onCreateNoBESave,
        existingData,
        isDuplication,
        modalAboveModals,
    } = props;

    const appDataContext = useContext(AppDataContext);
    const {companyId} = appDataContext;

    const navigate = useNavigate();
    const theNavigation = navigation === undefined ? true : navigation;

    const [jobFormResponse, setJobFormResponse] = useState<JobFormResponse | null>(null);
    const [previewModal, setPreviewModal] = useState<boolean>(false);

    const [mutateCreate, {
        loading: loadingCreate,
    }] = useResettableMutation<CreateJobFormTemplateMutation, CreateJobFormTemplateMutationVariables>(CreateJobFormTemplateDocument);

    /**
     * Mutate create job form template to BE.
     */
    const createJobFormTemplate = async (jobForm: JobFormResponse) => {
        try {
            const variables: CreateJobFormTemplateMutationVariables = {
                input: {
                    companyId: companyId!,
                    name: jobForm.name,
                    sections: jobForm.sections,
                    elements: jobForm.elements,
                },
            };

            if (onCreateNoBESave) {
                setOpen(false);

                onCreateNoBESave(variables.input);

                SuccessToast(tt('createJobForm.modal.success'));
                return;
            }

            const result = await mutateCreate({variables});

            if (!result.errors) {
                setOpen(false);

                if (theNavigation) {
                    navigate(jobFormDetailRoute(result.data!.createJobFormTemplate.id));
                }

                SuccessToast(tt('createJobForm.modal.success'));

                if (onCreate) {
                    onCreate!(result.data!.createJobFormTemplate.id);
                }
            }
        } catch (e) {
            console.error(e);

            ErrorToast(tt('common.mutation.error'));
        }
    };

    return (
        <>
            <AppModal
                modalAboveModals={modalAboveModals}
                blurBackdrop={true}
                open={open}
                setOpen={setOpen}
                title={tt('common.newJobForm')}
                fullScreenOnMobile={true}
                headerActions={
                    isMobile ?
                        <AppIconButton
                            variant={"greyBg"}
                            onClick={() => setPreviewModal(true)}
                        >
                            <Icons8Eye/>
                        </AppIconButton>
                        :
                        <AppChip
                            chipstyle={"secondary"}
                            label={tt('common.preview')}
                            icon={<Icons8Eye/>}
                            onClick={() => setPreviewModal(true)}
                        />
                }
            >
                <CreateAndEditJobForm
                    companyId={companyId!}
                    isTemplate={true}
                    submitRight={true}
                    loadingUpdate={loadingCreate}
                    onChange={setJobFormResponse}
                    onSubmit={createJobFormTemplate}
                    existingData={existingData}
                    isDuplication={isDuplication}
                />
            </AppModal>

            <JobFormPreviewModal
                open={previewModal}
                setOpen={setPreviewModal}
                jobFormResponse={jobFormResponse}
                canEdit={true}
                modalAboveModals={modalAboveModals}
            />
        </>
    );
}
