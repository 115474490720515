import {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {AppContext} from "../../../App";
import {AppDataContext} from "../../../AppData";
import {useNavigate} from "react-router-dom";
import {
    CompanyProfileResponse,
    GetCompanyProfileInput,
    UpdateCompanyGeneralSettingsDocument,
    UpdateCompanyGeneralSettingsMutationVariables
} from "../../../generated/graphql/graphql";
import {useMutation} from "@apollo/client";
import {tt} from "../../../core/Localization";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import DetailScreenShimmer from "../../components/shimmers/DetailScreenShimmer";
import ScreenContent from "../../components/screens/ScreenContent";
import AppPaper from "../../components/paper/AppPaper";
import {kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import PaperAppbar from "../../components/paper/PaperAppbar";
import {kCompanyDetailRoute} from "./CompanyDetailScreen";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../../components/form/FormBuilder";
import {Box} from "@mui/material";
import AppButton from "../../components/buttons/AppButton";
import {getBackRoute} from "../../../utils/Utils";
import {processMutationError, processQueryError} from "../../../service/ErrorService";
import {SuccessToast} from "../../../service/ToastService";
import { FetchPolicy, RestApiClientContext } from "../../../core/RestApiProvider";

export const kCompanySettingsRoute = '/settings/company/general-settings';

export default function CompanySettingsScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet} = restApiClientContext;
    
    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId, setProcessing} = appDataContext;

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<CompanyProfileResponse | NullOrUndefined>();
    useEffect(() => {
        if (companyId) {
            restApiGet({
                uri: '/company/profile',
                params: {
                    companyId,
                } as GetCompanyProfileInput,
                fetchPolicy: FetchPolicy.NetworkOnly,
                setLoading,
                onData: setData,
                onError: (error: any) => processQueryError(appDataContext, error),
            });
        } else {
            setData(null);
        }
    }, [companyId]);

    const [mutateUpdateCompanyGeneralSettings, {
        loading: loadingUpdate,
    }] = useMutation(UpdateCompanyGeneralSettingsDocument);

    useEffect(() => {
        setTitle(tt('companySettings.screen.title'));
    }, []);

    const [inputs, setInputs] = useState<IInputsData>({
        gps: {
            testId: 'companySettingsUseGPSInputId',
            type: InputType.CheckBox,
            label: tt('companySettings.screen.checkbox.trackGPS'),
            value: false,
        },
    });

    useEffect(() => {
        if (data) {
            setInputs({
                ...inputs,
                gps: {
                    ...inputs.gps,
                    value: data.company.gpsLocationTracking || false,
                },
            });
        }
    }, [data]);

    /**
     * Mutate Company update general settings to BE.
     */
    const UpdateSettings = async () => {
        if (ValidateForm(inputs, setInputs)) {
            try {
                const variables: UpdateCompanyGeneralSettingsMutationVariables = {
                    input: {
                        companyId: companyId!,
                        gpsLocationTracking: inputs.gps.value,
                    },
                };

                const result = await mutateUpdateCompanyGeneralSettings({variables});

                if (!result.errors) {
                    navigate(getBackRoute(kCompanyDetailRoute));

                    SuccessToast(tt('companySettings.screen.toast.success'));
                }
            } catch (e) {
                processMutationError(e);
            }
        }
    };

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                loading={loading}
                loadingUpdate={loadingUpdate}
                isMobile={isMobile}
                inputs={inputs}
                setInputs={setInputs}
                update={UpdateSettings}
            />
        );
    }

    return (
        <>
            <ResponsiveContainer
                smallPhoneScreen={bodyJSX(true)}
                largePhoneScreen={bodyJSX(true)}
                tabletScreen={bodyJSX()}
                smallDesktopScreen={bodyJSX()}
                largeDesktopScreen={bodyJSX()}
                extraLargeDesktopScreen={bodyJSX()}
            />
        </>
    );
}

interface IBodyProps {
    loading: boolean;
    loadingUpdate: boolean;
    isMobile?: boolean;
    inputs: IInputsData;
    setInputs: Dispatch<SetStateAction<IInputsData>>;
    update: VoidFunction;
}

function Body(props: IBodyProps) {
    const {
        loading,
        loadingUpdate,
        isMobile,
        inputs,
        setInputs,
        update,
    } = props;

    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
        if (!loading) {
            setFirstLoad(false);
        }
    }, [loading]);

    const contentJSX = loading && firstLoad ? (
        <DetailScreenShimmer/>
    ) : (
        <Box pr={2} pl={2} pb={4}>
            <FormBuilder inputs={inputs} setInputs={setInputs}/>
        </Box>
    );

    return (
        <ScreenContent
            appBar={!isMobile}
            noContentPadding={isMobile}
            navigationDrawer={!isMobile}
            bottomBar={isMobile}
            centerHorizontally={true}>
            <AppPaper
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}
            >
                <PaperAppbar
                    backRoute={getBackRoute(kCompanyDetailRoute)}
                    cancelIconBackButton={true}
                    isMobile={isMobile}
                    title={tt('companySettings.screen.title')}
                />

                {contentJSX}

                <Box pr={2} pl={2} pb={2}>
                    <AppButton
                        variant={"contained"}
                        onClick={update}
                        fullWidth={true}
                        disabled={loading}
                        isLoading={loadingUpdate}
                    >
                        {tt('common.save')}
                    </AppButton>
                </Box>
            </AppPaper>
        </ScreenContent>
    );
}
