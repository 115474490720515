import {CurrencyOption, Option} from "../ui/components/form/InputProps";
import CZIcon from "../icons/languages/CZIcon";
import React from "react";
import Icons8Euro from "../icons/Icons8Euro";
import ENIcon from "../icons/languages/ENIcon";
import {tt} from "../core/Localization";
import SKIcon from "../icons/languages/SKIcon";
import DEIcon from "../icons/languages/DEIcon";
import {SetSubscriptionUpgradeModal} from "../ui/components/modals/AppModals";
import {IAppDataContext} from "../AppData";
import {ItemPaymentType, JobEmployeeDataResponse, JobEmployeeStatus, PaymentType} from "../generated/graphql/graphql";
import {DateTime} from "luxon";
import {Box} from "@mui/material";
import AppChip from "../ui/components/chips/AppChip";
import { jobEmployeeDataForVisitAndEmployee } from "./VisitService";

/**
 * Convert currency code into label.
 */
export function currencyCodeToLabel(currencyCode: string): string {
    const currency = kAvailableCurrencies.find((currency) => currency.value === currencyCode);

    return currency ? currency.label : '';
}

export const kAvailableCurrencies: CurrencyOption[] = [
    {
        value: 'CZK',
        label: 'Kč',
        labelFull: 'Česká koruna (Kč)',
        icon: <CZIcon/>,
    },
    {
        value: 'EUR',
        label: '€',
        labelFull: 'Euro (€)',
        icon: <Icons8Euro/>
    },
    {
        value: 'USD',
        label: '$',
        labelFull: 'United states dollar ($)',
        icon: <ENIcon/>
    },
];

export function kAvailableCountries(): Option[] {
    return [
        {icon: <ENIcon/>, label: tt('country.unitedStates'), value: 'us'},
        {icon: <CZIcon/>, label: tt('country.czechRepublic'), value: 'cz'},
        {icon: <SKIcon/>, label: tt('country.slovakia'), value: 'sk'},
        {icon: <DEIcon/>, label: tt('country.germany'), value: 'de'}
    ];
}

/**
 * Convert PaymentType to ItemPaymentType.
 */
export function paymentTypeToItemPaymentType(paymentType: PaymentType): ItemPaymentType {
    switch (paymentType) {
        case PaymentType.Fixed:
            return ItemPaymentType.Fixed;
    }

    return ItemPaymentType.Hourly;
}

export interface ICalculateTimesheetsProps {
    timesheets: {
        paymentType: ItemPaymentType;
        fixedPrice?: number | NullOrUndefined;
        hourRate?: number | NullOrUndefined;
        hours?: number | NullOrUndefined;
        minutes?: number | NullOrUndefined;
        approved: boolean;
        deleted?: boolean;
        distance?: number | NullOrUndefined;
        distanceRate?: number | NullOrUndefined;
        employeeId: number;
        repeatingDay?: number;
        uuid: string;
    }[];
    countNotApproved?: boolean;
    repeatingDay?: number;
    filterByEmployeeIds?: number[];
    filterByJobEmployeeData?: JobEmployeeDataResponse[];
}

export interface ICalculateTimesheetsResult {
    regular: {
        timesheets: number;
        hours: number;
        minutes: number;
        totalPrice: number;
    };
    travel: {
        timesheets: number;
        hours: number;
        minutes: number;
        totalPrice: number;
        distance: number;
    };
    timesheets: number;
    hours: number;
    minutes: number;
    totalPrice: number;
}

/**
 * One function to do all Timehseets calculations.
 * If filterByEmployeeIds is provided, count only those of employeeIds.
 * If filterByJobEmployeeData is provided, canceled are not counted.
 */
export function calculateTimesheets(props: ICalculateTimesheetsProps): ICalculateTimesheetsResult {
    const {timesheets, countNotApproved, repeatingDay, filterByEmployeeIds, filterByJobEmployeeData: filterByJobEmployeeData} = props;
    
    const result: ICalculateTimesheetsResult = {
        regular: {
            timesheets: 0,
            hours: 0,
            minutes: 0,
            totalPrice: 0,
        },
        travel: {
            timesheets: 0,
            hours: 0,
            minutes: 0,
            totalPrice: 0,
            distance: 0,
        },
        timesheets: 0,
        hours: 0,
        minutes: 0,
        totalPrice: 0,
    };

    const theFilterByJobEmployeeData = filterByJobEmployeeData && filterByJobEmployeeData.length > 0 ? jobEmployeeDataForVisitAndEmployee(
        filterByJobEmployeeData[0].visitId,
        repeatingDay,
        null,
        filterByJobEmployeeData,
    ) : undefined;

    const filtered = timesheets.filter((timesheet) => {
        if (repeatingDay && !timesheet.repeatingDay) {
            const thisForRepeatDay = timesheets.find((other) => {
                return other.repeatingDay == repeatingDay && timesheet.uuid == other.uuid && timesheet.employeeId == other.employeeId;
            });
            
            if (thisForRepeatDay) {
                return false;
            }
        } else if (repeatingDay && timesheet.repeatingDay !== repeatingDay) {
            return false;
        }

        if (filterByEmployeeIds && !filterByEmployeeIds.includes(timesheet.employeeId)) {
            return false;
        }

        if (theFilterByJobEmployeeData) {
            const theData = theFilterByJobEmployeeData.find(data => data.employeeId == timesheet.employeeId);

            if (!theData || theData.status === JobEmployeeStatus.CanceledByWorker || theData.status === JobEmployeeStatus.CanceledByManager) {
                return false;
            }
        }

        return (timesheet.approved || countNotApproved) && !timesheet.deleted;
    });

    filtered.forEach((timesheet) => {
        switch(timesheet.paymentType) {
            case ItemPaymentType.Fixed: 
                result.regular.timesheets++;

                result.regular.totalPrice += timesheet.fixedPrice || 0;
                break;
            case ItemPaymentType.TravelFixed: 
                result.travel.timesheets++;

                result.travel.totalPrice += timesheet.fixedPrice || 0;

                result.travel.distance += timesheet.distance || 0;
                break;
            case ItemPaymentType.Hourly:
            case ItemPaymentType.Extra:
            case ItemPaymentType.StartEnd: {
                result.regular.timesheets++;

                const hours = timesheet.hours || 0;
                const minutes = timesheet.minutes || 0;
                const hourRate = timesheet.hourRate || 0;

                result.regular.totalPrice += (hours * hourRate) + (minutes * (hourRate / 60));

                let theHours = hours;
                let theMinutes = minutes;

                if (theMinutes >= 60) {
                    theHours += Math.floor(theMinutes / 60);
                    theMinutes %= 60;
                }

                result.regular.hours += theHours;
                result.regular.minutes += theMinutes;
                break;
            }
            case ItemPaymentType.TravelHourly:
            case ItemPaymentType.TravelStartEnd: {
                result.travel.timesheets++;

                const hours = timesheet.hours || 0;
                const minutes = timesheet.minutes || 0;
                const hourRate = timesheet.hourRate || 0;

                result.travel.totalPrice += (hours * hourRate) + (minutes * (hourRate / 60));

                result.travel.distance += timesheet.distance || 0;

                let theHours = hours;
                let theMinutes = minutes;

                if (theMinutes >= 60) {
                    theHours += Math.floor(theMinutes / 60);
                    theMinutes %= 60;
                }

                result.travel.hours += theHours;
                result.travel.minutes += theMinutes;
                break;
            }
            case ItemPaymentType.TravelDistance: {
                result.travel.timesheets++;

                const distance = timesheet.distance || 0;
                const distanceRate = timesheet.distanceRate || 0;

                result.travel.totalPrice += distance * distanceRate;

                result.travel.distance += timesheet.distance || 0;
                break;
            }
        }
    });

    if (result.regular.minutes >= 60) {
        result.regular.hours += Math.floor(result.regular.minutes / 60);
        result.regular.minutes %= 60;
    }

    if (result.travel.minutes >= 60) {
        result.travel.hours += Math.floor(result.travel.minutes / 60);
        result.travel.minutes %= 60;
    }

    result.hours = result.regular.hours + result.travel.hours;
    result.minutes = result.regular.minutes + result.travel.minutes;

    if (result.minutes >= 60) {
        result.hours += Math.floor(result.minutes / 60);
        result.minutes %= 60;
    }

    result.timesheets = result.regular.timesheets + result.travel.timesheets;

    result.totalPrice = result.regular.totalPrice + result.travel.totalPrice;

    return result;
}

export interface ICalculatePriceProps {
    timesheets: {
        paymentType: ItemPaymentType;
        fixedPrice?: number | NullOrUndefined;
        hourRate?: number | NullOrUndefined;
        hours?: number | NullOrUndefined;
        minutes?: number | NullOrUndefined;
        approved: boolean;
        deleted?: boolean;
        distance?: number | NullOrUndefined;
        distanceRate?: number | NullOrUndefined;
    }[];
    currency: string;
    language: string;
    returnZeroInsteadOfUndefined?: boolean;
    countNotApproved?: boolean;
}

/**
 * Calculate total price.
 */
export function CalculateTotalPrice(props: ICalculatePriceProps) {
    const filtered = props.timesheets.filter((timesheet) => {
        return (timesheet.approved || props.countNotApproved) && !timesheet.deleted;
    });

    let total = 0;

    filtered.forEach((timesheet) => {
        if (timesheet.paymentType === ItemPaymentType.Fixed) {
            total += timesheet.fixedPrice || 0;
        } else {
            const hours = timesheet.hours || 0;
            const minutes = timesheet.minutes || 0;
            const hourRate = timesheet.hourRate || 0;

            total += (hours * hourRate) + (minutes * (hourRate / 60));
        }
    });

    return total;
}

/**
 * Add VAT to price.
 */
export function AddVatToPrice(price: number | NullOrUndefined, vatRate: number | NullOrUndefined) {
    price = price || 0;

    if (!vatRate) {
        return price;
    }

    return price + (price * (vatRate / 100));
}

/**
 * Get VAT amount from price and vatRate.
 */
export function GetVatAmount(price: number | NullOrUndefined, vatRate: number | NullOrUndefined) {
    price = price || 0;

    if (!vatRate) {
        return 0;
    }

    return price * (vatRate / 100);
}

/**
 * Display price with currency.
 */
export function TotalPriceDisplay(props: ICalculatePriceProps) {
    const price = CalculateTotalPrice(props);

    return PriceDisplay(price, props.currency, props.language, props.returnZeroInsteadOfUndefined);
}

/**
 * Display price with currency.
 */
export function PriceDisplay(price: number | NullOrUndefined, currency: string, language: string, returnZeroInsteadOfUndefined?: boolean) {
    price = price || 0;

    if (price === 0) {
        return returnZeroInsteadOfUndefined ? `0 ${currency}` : undefined;
    }

    return `${Intl.NumberFormat(language, {maximumFractionDigits: 2}).format(price)} ${currency}`;
}

/**
 * Display formatted number.
 */
export function numberDisplay(number: number | NullOrUndefined, language: string, returnZeroInsteadOfUndefined?: boolean) {
    number = number || 0;

    if (number === 0) {
        return returnZeroInsteadOfUndefined ? '0' : undefined;
    }

    return `${Intl.NumberFormat(language, {maximumFractionDigits: 2}).format(number)}`;
}

/**
 * Display formatted distance number.
 */
export function distanceDisplay(number: number | NullOrUndefined, language: string, returnZeroInsteadOfUndefined?: boolean) {
    number = number || 0;

    if (number === 0) {
        return returnZeroInsteadOfUndefined ?
            tt('distance.kilometersWithValue.short').replace('$value', '0') :
            undefined;
    }

    return tt('distance.kilometersWithValue.short').replace('$value', Intl.NumberFormat(language, { maximumFractionDigits: 2 }).format(number));
}

export interface ICalculateJobOfferPriceProps {
    jobOffer: {
        paymentType: PaymentType;
        fixedPrice?: number | NullOrUndefined;
        hourRate?: number | NullOrUndefined;
        hours?: number | NullOrUndefined;
        minutes?: number | NullOrUndefined;
    };
}

/**
 * Calculate JobOfferPrice.
 */
export function CalculateJobOfferPrice(props: ICalculateJobOfferPriceProps) {
    const {jobOffer} = props;

    switch (jobOffer.paymentType) {
        case PaymentType.Fixed:
            return jobOffer.fixedPrice || 0;
        default:
            const hours = jobOffer.hours || 0;
            const minutes = jobOffer.minutes || 0;
            const hourRate = jobOffer.hourRate || 0;

            return (hours * hourRate) + (minutes * (hourRate / 60));
    }
}

export interface ICalculateHoursMinutesProps {
    timesheets: {
        paymentType: ItemPaymentType;
        hours?: number | NullOrUndefined;
        minutes?: number | NullOrUndefined;
        approved: boolean;
        deleted?: boolean;
    }[];
    hideZeroMinutes?: boolean;
    returnZeroHoursInsteadOfUndefined?: boolean;
    countNotApproved?: boolean;
    language: string;
}

export interface ICalculateHoursMinutesResponse {
    hours: number;
    minutes: number;
    hideZeroMinutes?: boolean;
    returnZeroHoursInsteadOfUndefined?: boolean;
    language: string;
}

/**
 * Calculate hours and minutes.
 */
export function CalculateHoursMinutes(props: ICalculateHoursMinutesProps): ICalculateHoursMinutesResponse {
    const filtered = props.timesheets.filter((timesheet) => {
        if ((!timesheet.approved && !props.countNotApproved) || timesheet.deleted) {
            return false;
        }

        return timesheet.paymentType === ItemPaymentType.Hourly || timesheet.paymentType === ItemPaymentType.Extra || timesheet.paymentType === ItemPaymentType.StartEnd || timesheet.paymentType === ItemPaymentType.TravelHourly || timesheet.paymentType === ItemPaymentType.TravelStartEnd;
    });

    let theHours = 0;
    let theMinutes = 0;

    filtered.forEach((timesheet) => {
        theHours += timesheet.hours || 0;
        theMinutes += timesheet.minutes || 0;
    });

    if (theMinutes >= 60) {
        theHours += Math.floor(theMinutes / 60);
        theMinutes %= 60;
    }

    return {
        hours: theHours,
        minutes: theMinutes,
        language: props.language,
    };
}

/**
 * Display hours and minutes as time.
 */
export function HoursMinutesDisplay(props: ICalculateHoursMinutesProps) {
    const {returnZeroHoursInsteadOfUndefined} = props;
    const data = CalculateHoursMinutes(props);

    if (data.hours === 0 && data.minutes === 0) {
        return returnZeroHoursInsteadOfUndefined ? `0 ${tt('common.hoursAbbreviation')}` : undefined;
    }

    if (props.hideZeroMinutes && data.minutes === 0) {
        return `${data.hours}${tt('common.hoursAbbreviation')}`;
    }

    return `${data.hours}${tt('common.hoursAbbreviation')} ${data.minutes}${tt('common.minutesAbbreviation')}`;
}

/**
 * Display hours and minutes as time.
 */
export function HoursMinutesDisplayRaw(props: ICalculateHoursMinutesResponse) {
    if (props.minutes >= 60) {
        props.hours += Math.floor(props.minutes / 60);
        props.minutes %= 60;
    }

    if (props.hours === 0 && props.minutes === 0) {
        return props.returnZeroHoursInsteadOfUndefined ? `${props.hours} ${tt('common.hoursAbbreviation')}` : undefined;
    }

    if (props.hideZeroMinutes && props.minutes === 0) {
        return `${Intl.NumberFormat(props.language, {maximumFractionDigits: 2}).format(props.hours)} ${tt('common.hoursAbbreviation')}`;
    }

    return `${Intl.NumberFormat(props.language, {maximumFractionDigits: 2}).format(props.hours)} ${tt('common.hoursAbbreviation')} ${Intl.NumberFormat(props.language, {maximumFractionDigits: 2}).format(props.minutes)} ${tt('common.minutesAbbreviation')}`;
}

/**
 * Display hourRate with currency.
 */
export function HourRateDisplay(hourRate: number, currency: string, language: string) {
    return `${PriceDisplay(hourRate, currency, language, true)}/${tt('common.hoursAbbreviation')}`;
}

export interface ICalculateProductsPriceProps {
    products: {
        price?: number | NullOrUndefined;
        vatRate?: number | NullOrUndefined;
        unitCount?: number | NullOrUndefined;
    }[];
    currency: string;
    language: string;
    returnZeroInsteadOfUndefined?: boolean;
    multiplier?: number;
}

/**
 * Calculate and display price of Products without VAT.
 */
export function CalculateProductsPriceWithoutVat(props: ICalculateProductsPriceProps) {
    let total = 0;

    props.products.forEach((product) => {
        const price = product.price || 0;

        total += (price * (product.unitCount || 0));
    });

    total = props.multiplier ? props.multiplier * total : total;

    return PriceDisplay(total, props.currency, props.language, props.returnZeroInsteadOfUndefined);
}

/**
 * Calculate and display price of Products with VAT.
 */
export function CalculateProductsPriceWithVat(props: ICalculateProductsPriceProps) {
    let total = 0;

    props.products.forEach((product) => {
        const price = product.price || 0;

        total += AddVatToPrice(price * (product.unitCount || 0), product.vatRate);
    });

    total = props.multiplier ? props.multiplier * total : total;

    return PriceDisplay(total, props.currency, props.language, props.returnZeroInsteadOfUndefined);
}

/**
 * Calculate and display price of Products without VAT or with VAT.
 */
export function CalculateProductsPrice(props: ICalculateProductsPriceProps, withVat: boolean) {
    if (withVat) {
        return CalculateProductsPriceWithVat(props);
    }

    return CalculateProductsPriceWithoutVat(props);
}

/**
 * Deprecated - TODO remove this from the code
 */
export function GetTotalTimesheetsChipsWithHourRate(props: ICalculatePriceProps) {
    //TODO refactor offer item to use this
    const totalPriceRaw = CalculateTotalPrice(props);
    const totalHoursMinutes = CalculateHoursMinutes(props);

    let totalHourRateLabel = '';

    let timesheetHoursChip;
    let timesheetTotalPriceChip;

    if ((totalHoursMinutes.hours || totalHoursMinutes.minutes) && totalPriceRaw) {

        const hourRate = totalPriceRaw / (totalHoursMinutes.hours + (totalHoursMinutes.minutes / 60));
        if (hourRate) {
            totalHourRateLabel = HourRateDisplay(hourRate, props.currency, props.language);
        }
    }

    let hoursMinutesLabel = HoursMinutesDisplayRaw(totalHoursMinutes);

    if (hoursMinutesLabel) {
        timesheetHoursChip = (
            <Box pr={0.5} pb={0.5}>
                <AppChip
                    chipstyle={"outlined"}
                    label={hoursMinutesLabel}
                />
            </Box>
        );
    }

    let totalPriceLabel = TotalPriceDisplay(props);

    if (totalPriceLabel) {
        timesheetTotalPriceChip = (
            <Box pr={0.5} pb={0.5}>
                <AppChip
                    chipstyle={"outlined"}
                    label={`${totalPriceLabel}${totalHourRateLabel ? ` (${totalHourRateLabel})` : ''}`}
                />
            </Box>
        );
    }

    return <>
        {timesheetHoursChip ? timesheetHoursChip : null}
        {timesheetTotalPriceChip ? timesheetTotalPriceChip : null}
    </>;
}

/**
 * Display confirm modal for subscription upgrade.
 */
export function DisplaySubscriptionUpgradeModal(
    appDataContext: IAppDataContext,
    currentUsers: number,
    maxUsers: number,
) {
    SetSubscriptionUpgradeModal(
        appDataContext,
        {
            open: true,
            currentUsers: currentUsers,
            maxUsers: maxUsers,
        }
    );
}

export function StartEndHoursDisplay(startTime: number | NullOrUndefined | null, endTime: number | NullOrUndefined) {
    const thisStartTime = startTime ? DateTime.fromMillis(startTime).toFormat('H:mm') : tt('timesheet.startTime.undefined');
    const thisEndTime = endTime ? DateTime.fromMillis(endTime).toFormat('H:mm') : tt('timesheet.endTime.undefined');
    return `${thisStartTime} - ${thisEndTime}`;
}
