import {useContext, useEffect, useId, useState} from "react";
import {AppContext} from "../../../App";
import {useNavigate} from "react-router-dom";
import {AppBar, Box, Grid, Theme, Toolbar, Typography, useTheme} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import TimotyLogo from "../../../icons/TimotyLogo";
import {kSignOutRoute} from "../../screens/authorization/SignOutScreen";
import {tt} from "../../../core/Localization";
import AppIconButton from "../buttons/AppIconButton";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import UserIcon from "../../../icons/UserIcon";
import UserFilledIcon from "../../../icons/UserFilledIcon";
import SettingsIcon from "../../../icons/SettingsIcon";
import SignOutIcon from "../../../icons/SignOutIcon";
import {usePopupState} from "material-ui-popup-state/hooks";
import {kProfileRoute} from "../../screens/profile/ProfileScreen";
import {kSettingsRoute} from "../../screens/settings/SettingsScreen";
import {EmployeeRole, UserResponse} from "../../../generated/graphql/graphql";
import ChoseCompanyMenu from "../menus/ChoseCompanyMenu";
import {AppDataContext} from "../../../AppData";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import AppBreadCrumb from "../breadCrumb/AppBreadCrumb";
import NotificationsMenu from "../menus/NotificationsMenu";
import {openSpotlight} from "@mantine/spotlight";
import Icons8Search from "../../../icons/Icons8Search";
import {RestApiClientContext} from "../../../core/RestApiProvider";
import {kActionUpdate, kTopicUsers} from "../../../core/constants";
import {processQueryError} from "../../../service/ErrorService";
import IEventSystemNotification from "../../../model/firestore/EventSystemNotification";

export interface IDefaultAppbarProps {
    showBreadCrumb?: boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: theme.palette.background.paper,
        borderBottom: `1px solid ${kAppColors.border(theme.palette.mode === "dark")}`,
        color: kAppColors.text.primary(theme.palette.mode === 'dark'),
    },
    gridItem: {
        display: 'flex',
        alignItems: 'center',
    },
    logo: {
        width: 44,
        height: 44,
        color: kAppColors.text.primary(theme.palette.mode === 'dark'),
    },
    iconButton: {
        marginRight: 4,
    },
    popup: {
        '.MuiPaper-root': {
            top: '72px !important',
        },
    }
}));

/**
 * Component with default app bar for the App.
 */
export default function DefaultAppBar(props: IDefaultAppbarProps) {
    const {showBreadCrumb} = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;

    const appContext = useContext(AppContext);
    const {authUser} = appContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId, companyEmployeeRole} = appDataContext;

    const noCompaniesMode = !companyId && authUser && authUser.signInResponse && authUser.signInResponse.data && authUser.signInResponse.data.companyIds.length == 0;

    const navigate = useNavigate();
    const theme = useTheme();

    const {classes} = useStyles();

    const mainMenuPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    const [data, setData] = useState<UserResponse | NullOrUndefined>();
    useEffect(() => {
        const subscription = subscribe(
            kTopicUsers,
            {
                uri: '/user',
                params: {},
                onData: setData,
                onError: (error: any) => processQueryError(appDataContext, error),
            },
            (notifications: IEventSystemNotification[]) => {
                return notifications.some((notification) => {
                    return notification.action === kActionUpdate && notification.data['uid'] === authUser?.uid;
                });
            },
        );

        return () => subscription.cancel();
    }, [authUser?.uid]);

    let userName = authUser!!.email;
    if (data && data.name && data.surname) {
        userName = `${data.name} ${data.surname}`;
    }

    const companiesMenuJSX = !noCompaniesMode ? (
        <ChoseCompanyMenu/>
    ) : null;

    return (
        <AppBar className={classes.appBar} position="fixed" elevation={0}>
            <Toolbar>
                <Grid container={true} justifyContent="space-between">
                    <Grid item={true} className={classes.gridItem}>
                        <Box sx={{mr: 2}}>
                            <TimotyLogo className={classes.logo}/>
                        </Box>
                        <Typography sx={{color: theme.palette.text.primary, fontWeight: 700, fontSize: 20}}>
                            {tt('app.name')}
                        </Typography>
                    </Grid>

                    {showBreadCrumb ? <AppBreadCrumb/> : null}

                    <Grid item={true}>
                        {noCompaniesMode || companyEmployeeRole === EmployeeRole.Worker ? null : (
                            <AppIconButton
                                tooltip={tt('menu.spotlight')}
                                onClick={() => openSpotlight()}
                            >
                                <Icons8Search/>
                            </AppIconButton>
                        )}

                        {noCompaniesMode || companyEmployeeRole === EmployeeRole.Worker ? null : <NotificationsMenu/>}

                        <AppIconButton tooltip={userName}
                                       className={classes.iconButton} {...bindTrigger(mainMenuPopupState)}>
                            <UserFilledIcon/>
                        </AppIconButton>

                        <Menu className={classes.popup} {...bindMenu(mainMenuPopupState)}>
                            <MenuItem onClick={() => navigate(kProfileRoute)}>
                                <UserIcon/>
                                {userName}
                            </MenuItem>
                            <MenuItem onClick={() => navigate(kSettingsRoute)}>
                                <SettingsIcon/>
                                {tt('menu.settings')}
                            </MenuItem>
                            <MenuItem onClick={() => navigate(kSignOutRoute)}>
                                <Box sx={{
                                    color: kAppColors.red.confirmButton,
                                }}><SignOutIcon/></Box>
                                {tt('menu.signOut')}
                            </MenuItem>
                        </Menu>

                        {companiesMenuJSX}
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}
