import {
    EmployeeJoinedUserResponse,
    FileResponse,
    JobProtocolResponse
} from "../../../../../../generated/graphql/graphql";
import {bindMenu, usePopupState} from "material-ui-popup-state/hooks";
import React, {useContext, useId, useMemo, useState} from "react";
import AppListItem from "../../../../listItems/AppListItem";
import AppIconButton from "../../../../buttons/AppIconButton";
import {bindTrigger} from "material-ui-popup-state";
import {tt} from "../../../../../../core/Localization";
import MoreFilledIcon from "../../../../../../icons/MoreFilledIcon";
import {Box, Theme, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {DateTime} from "luxon";
import {UserFullName, UserPhotoUrl} from "../../../../../../service/UserService";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {kAppColors, kVisitStatusColors} from "../../../../../../styles/AppThemeProcessor";
import CloseIcon from "../../../../../../icons/CloseIcon";
import {HideConfirmModal, SetConfirmModal} from "../../../../modals/AppModals";
import {AppDataContext} from "../../../../../../AppData";
import DeleteIcon from "../../../../../../icons/DeleteIcon";
import PreviewFileModal from "../../../../modals/documents/PreviewFileModal";
import CustomStatusLikeChip from "../../../../chips/CustomStatusLikeChip";
import Icons8MediumRisk from "../../../../../../icons/Icons8MediunRisk";
import Icons8Download from "../../../../../../icons/Icons8Download";
import {RestApiClientContext} from "../../../../../../core/RestApiProvider";
import {isImageFile} from "../../../../../../service/StorageService";
import ProtocolOtherFileListItem from "../../../protocols/ProtocolOtherFileListItem";
import PermissionValid from "../../../../permissions/PermissionValid";
import {kActionDelete, kPermissionsVisitProtocols} from "../../../../../../core/constants";

export const useStyles = makeStyles()((theme: Theme) => ({
    galleryContainerOuter: {
        paddingTop: 8,
        marginLeft: 16,
        marginRight: 16,
    },
    galleryContainer: {
        display: "flex",
        marginLeft: -8,
        marginRight: -8,
        flexFlow: "wrap",
    },
    singleItem: {
        paddingRight: 8,
        paddingLeft: 8,
        marginBottom: 16,
        "@media (max-width: 767px)": {
            display: "flex",
            width: '25%',
        }
    },
    singleSignatureItem: {
        paddingRight: 8,
        paddingLeft: 8,
        marginBottom: 16,
        width: 96,
        "@media (max-width: 767px)": {
            display: "flex",
            flexDirection: "column",
            width: '25%',
        },
        p: {
            maxWidth: '100%',
            paddingTop: 4,
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            fontSize: 12,
            fontWeight: 600,
            color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        }
    },
    galleryImage: {
        cursor: "pointer",
        display: 'block',
        borderRadius: 8,
        height: 80,
        width: 80,
        backgroundSize: "cover",
        backgroundPosition: "center",
        flexShrink: 0,
        "@media (max-width: 767px)": {
            flexGrow: 1,
            height: "auto",
            width: "auto",
            paddingTop: '100%',
        }
    },
    defectDescription: {
        marginLeft: 16,
        marginRight: 16,
        marginBottom: 8,
    },
    chipsContainer: {
        display: "flex",
        paddingTop: 0,
        paddingRight: 16,
        paddingLeft: 16,
    }
}));

export interface IJobProtocolListItemProps {
    index: number;
    data: JobProtocolResponse;
    files: FileResponse[],
    employee?: EmployeeJoinedUserResponse;
    onDelete: (id: number) => void;
    canEdit: boolean;
    showTrashIcon?: boolean;
}

/**
 * Component used to display a single JobProtocol.
 */
export default function VisitProtocolListItem(props: IJobProtocolListItemProps) {
    const {
        index,
        data,
        files,
        employee,
        onDelete,
        canEdit,
        showTrashIcon,
    } = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {restUriBuilder} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {storage} = appDataContext;

    const {classes, cx} = useStyles();

    const createdAt = DateTime.fromMillis(data.createdAt);

    const [picturesModal, setPicturesModal] = useState<boolean>(false);
    const [picturesModalIndexOnOpen, setPicturesModalIndexOnOpen] = useState<number>(0);
    const [signaturesModal, setSignaturesModal] = useState<boolean>(false);
    const [signaturesModalIndexOnOpen, setSignaturesModalIndexOnOpen] = useState<number>(0);

    const morePopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    const imageFilesForThis = useMemo(() => {
        return files
            .filter(f => {
                return data.fileIds && data.fileIds.includes(f.id) && isImageFile(f.name);
            });
    }, [files, data]);

    const otherFilesForThis = useMemo(() => {
        return files
            .filter(f => {
                return data.fileIds && data.fileIds.includes(f.id) && !isImageFile(f.name);
            });
    }, [files, data]);

    const signaturesForThis = useMemo(() => {
        if (data.signatureFileIds) {
            return files
                .filter(f => {
                    return data.signatureFileIds && data.signatureFileIds.includes(f.id);
                });
        } else {
            return [];
        }
    }, [files, data]);

    const imagesPublicUrls = useMemo(() => {
        return Object.keys(storage.publicUrlsForFiles).filter(uuid => imageFilesForThis.find(f => f.uuid === uuid)).map(uuid => storage.publicUrlsForFiles[uuid]);
    }, [storage.publicUrlsForFiles, imageFilesForThis]);

    const otherFilesCombined = useMemo(() => {
        const combined = [];

        for (const file of otherFilesForThis) {
            combined.push({
                file,
                publicUrl: storage.publicUrlsForFiles[file.uuid],
            });
        }

        return combined;
    }, [storage.publicUrlsForFiles, otherFilesForThis]);

    const signaturesCombined = useMemo(() => {
        const combined = [];

        for (const file of signaturesForThis) {
            combined.push({
                file,
                publicUrl: storage.publicUrlsForFiles[file.uuid],
            });
        }

        return combined;
    }, [storage.publicUrlsForFiles, signaturesForThis]);

    const defectChipJSX = data.isDefect ? (
        <Box className={classes.chipsContainer}>
            <CustomStatusLikeChip
                textColor={kVisitStatusColors.scheduleLater.text}
                icon={<Icons8MediumRisk/>}
                variant={"outlined"}
                text={tt('common.defect')}
            />
        </Box>
    ) : null;

    return (
        <>
            <AppListItem
                profileImage={
                    UserPhotoUrl(employee?.user, files, storage.publicUrlsForFiles)
                }
                title={UserFullName(employee?.name || employee?.user?.name, employee?.surname || employee?.user?.surname)}
                description={createdAt.toFormat('H:mm · d.M.yyyy')}
                variant={"smaller-title"}
                actionWidget={
                    canEdit || imageFilesForThis.length > 0 || otherFilesForThis.length > 0 ?
                        <AppIconButton
                            {...bindTrigger(morePopupState)}
                            variant={"greyBg"}
                            placement={"right"}
                            tooltip={tt('common.more')}
                        >
                            <MoreFilledIcon/>
                        </AppIconButton> : <></>
                }
            />

            <Menu {...bindMenu(morePopupState)}>
                {imageFilesForThis.length > 0 || otherFilesForThis.length > 0 || signaturesCombined.length > 0 ? (
                    <MenuItem key={'downloadItem'}
                              onClick={() => {
                                  morePopupState.close();

                                  const input = {
                                      files: [
                                          ...imageFilesForThis.map(file => file.id),
                                          ...otherFilesForThis.map(file => file.id),
                                          ...signaturesCombined.map(file => file.file.id),
                                      ],
                                  };
                                  const downloadUrl = restUriBuilder('/firebase/download-files', input);

                                  window.open(downloadUrl, '_blank');
                              }}>
                        <Icons8Download/>
                        {tt('common.protocol.download.all')}
                    </MenuItem>
                ) : null}

                {canEdit ? (
                    <PermissionValid
                        permission={kPermissionsVisitProtocols}
                        requiredPermissions={[kActionDelete]}
                    >
                        <MenuItem key={'deleteItem'}
                                  onClick={() => {
                                      morePopupState.close();

                                      SetConfirmModal(appDataContext, {
                                          open: true,
                                          title: tt('jobProtocol.delete.confirmModal.title'),
                                          subtitle: tt('jobProtocol.delete.confirmModal.subtitle'),
                                          confirmationButtonText: tt('common.delete'),
                                          cancelButtonText: tt('common.close'),
                                          children: <></>,
                                          onConfirm: () => {
                                              HideConfirmModal(appDataContext);

                                              onDelete(data.id);
                                          },
                                      });
                                  }}>
                            <Box color={kAppColors.red.confirmButton}>{showTrashIcon ? <DeleteIcon/> :
                                <CloseIcon/>}</Box>
                            {tt('common.delete')}
                        </MenuItem>
                    </PermissionValid>
                ) : null}
            </Menu>

            <Box>
                <Typography className={classes.defectDescription}>
                    {data.description}
                </Typography>
            </Box>

            {imagesPublicUrls.length > 0 ? (
                <Box className={classes.galleryContainerOuter}>
                    <Box className={classes.galleryContainer}>
                        {imagesPublicUrls.map((img, index) =>
                            <Box
                                className={classes.singleItem}
                                key={`defectImage${index}`}
                            >
                                <Box
                                    onClick={() => {
                                        setPicturesModalIndexOnOpen(index!);

                                        setPicturesModal(true);
                                    }
                                    }
                                    className={classes.galleryImage}
                                    style={{
                                        backgroundImage: `url("${img}")`
                                    }}
                                >
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
            ) : null}

            {otherFilesCombined.length > 0 ? (
                otherFilesCombined.map((file, index) => {
                    return (
                        <ProtocolOtherFileListItem
                            key={index}
                            file={file.file}
                            publicUrl={file.publicUrl}
                            isDetail={true}
                        />
                    );
                })
            ) : null}

            {signaturesCombined.length > 0 ? (
                <Box className={classes.galleryContainerOuter}>
                    <Box className={classes.galleryContainer}>
                        {signaturesCombined.map((file, index) =>
                            <Box
                                className={classes.singleSignatureItem}
                                key={`signature${index}`}
                            >
                                <Box
                                    onClick={() => {
                                        setSignaturesModalIndexOnOpen(index!);

                                        setSignaturesModal(true);
                                    }
                                    }
                                    className={classes.galleryImage}
                                    style={{
                                        backgroundImage: `url("${file.publicUrl}")`
                                    }}
                                >
                                </Box>
                                <Typography>{file.file.label}</Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
            ) : null}

            {defectChipJSX}

            <Box sx={{pb: 2}}/>

            <PreviewFileModal
                onClose={() => setPicturesModal(false)}
                open={picturesModal}
                fileStates={[]}
                files={imageFilesForThis}
                pictures={imagesPublicUrls}
                indexOnOpen={picturesModalIndexOnOpen}
            />

            <PreviewFileModal
                onClose={() => setSignaturesModal(false)}
                open={signaturesModal}
                fileStates={[]}
                files={signaturesForThis}
                pictures={signaturesCombined.map((file) => file.publicUrl)}
                labels={signaturesCombined.map((file) => file.file.label || '')}
                indexOnOpen={signaturesModalIndexOnOpen}
            />
        </>
    );
}
