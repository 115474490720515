import React, {ReactNode, useContext, useEffect, useRef, useState} from "react";
import {SpotlightAction, SpotlightProvider} from "@mantine/spotlight";
import SearchIcon from "../icons/SearchIcon";
import {tt} from "./Localization";
import HammerIcon from "../icons/HammerIcon";
import {AppDataContext} from "../AppData";
import {
    kActionView,
    kPermissionsClients,
    kPermissionsJobs,
    kPermissionsWorkers,
    kThemeAnimationDuration
} from "./constants";
import UserIcon from "../icons/UserIcon";
import {
    ClientJoinedLocationsResponse,
    CompanySubscriptionStatusResponse,
    SpotlightSearchInput,
    SpotlightSearchResponse,
    VisitRepeatDayResponse,
    VisitRepeating,
    VisitResponse,
    VisitStatus
} from "../generated/graphql/graphql";
import {NavigateFunction, useNavigate} from "react-router-dom";
import {AppContext} from "../App";
import {kNotificationsRoute} from "../ui/screens/notifications/NotificationsScreen";
import {RestApiClientContext} from "./RestApiProvider";
import Debouncer from "../utils/Debouncer";
import {processQueryError} from "../service/ErrorService";
import {JobNameOrSequenceId} from "../service/JobService";
import {isJobDetailScreen, jobDetailRoute} from "../ui/screens/jobs/JobDetailScreen";
import {kAppColors} from "../styles/AppThemeProcessor";
import {Box, Theme, Typography} from "@mui/material";
import WorkerIcon from "../icons/WorkerIcon";
import Icons8BellLight from "../icons/Icons8BellLight";
import {UserAddressSingle, UserFullName} from "../service/UserService";
import {workerDetailRoute} from "../ui/screens/workers/WorkerDetailScreen";
import {LocationNameDisplay} from "../service/LocationService";
import {contactPersonDetailRoute} from "../ui/screens/contactPerson/ContactPersonDetailScreen";
import {PlaceDetailRoute} from "../ui/screens/places/PlaceDetailScreen";
import {locationDetailRoute} from "../ui/screens/locations/LocationDetailScreen";
import {clientDetailRoute} from "../ui/screens/clients/ClientDetailScreen";
import Icons8Company from "../icons/Icons8Company";
import {
    getVisitStatusTitle,
    visitDateTimes,
    VisitNameOrSequenceId,
    VisitRepeatingToString
} from "../service/VisitService";
import {newJobOrVisitRoute, NewJobOrVisitRouteKind} from "../ui/screens/jobs/NewJobOrVisitScreen";
import {SetVisitDetailModal, SetVisitsCalendarModal} from "../ui/components/modals/AppModals";
import ScheduleEvent from "../icons/ScheduleEvent";
import {Group} from "@mantine/core";
import {makeStyles} from "tss-react/mui";
import {hasPermission} from "../ui/components/permissions/PermissionValid";


const useStyles = makeStyles()((theme: Theme) => ({
    hintContainer: {
        width: '100%',
        paddingLeft: 8,
        paddingRight: 8,
        paddingBottom: 4,
        position: "absolute",
        zIndex: 1,
        display: 'block',
        backgroundColor: kAppColors.background.paper(theme.palette.mode === "dark"),
    },
    hint: {
        color: theme.palette.mode == 'dark' ? '#989898' : '#65676B',
        fontSize: '0.75rem',
        paddingTop: 8,
        paddingLeft: 9,
    }
}));

export interface ISpotlightProps {
    children: ReactNode;
}

/**
 * Single Spotlight component for whole app.
 */
export default function Spotlight(props: ISpotlightProps) {
    const {children} = props;

    const appDataContext = useContext(AppDataContext);
    const {theme, employeePermissionsMap, companySubscriptionStatus, companyId, language} = appDataContext;
    const darkMode = theme === 'dark';

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet} = restApiClientContext;

    const appContext = useContext(AppContext);
    const {authUser} = appContext;

    const noCompaniesMode: boolean = (!companyId && authUser && authUser.signInResponse && authUser.signInResponse.data && authUser.signInResponse.data.companyIds.length == 0) || false;

    const navigate = useNavigate();
    // const [createWorkerModal, setCreateWorkerModal] = useState<boolean>(false);
    // const [createClientModal, setCreateClientModal] = useState<boolean>(false);

    const [displayLimit, setDisplayLimit] = useState<number>();
    const [actions, setActions] = useState<SpotlightAction[]>(
        defaultActions(
            employeePermissionsMap,
            companySubscriptionStatus,
            navigate,
            // setCreateWorkerModal,
            // setCreateClientModal,
        ),
    );
    const [searching, setSearching] = useState<boolean>(false);
    const [data, setData] = useState<SpotlightSearchResponse | NullOrUndefined>(null);

    const searchTimeout = useRef(new Debouncer(kThemeAnimationDuration));

    useEffect(() => {
        return () => {
            searchTimeout.current?.dispose();
        }
    }, []);

    useEffect(() => {
        if (!searching) {
            setDisplayLimit(undefined);

            setActions(
                defaultActions(
                    employeePermissionsMap,
                    companySubscriptionStatus,
                    navigate,
                    // setCreateWorkerModal,
                    // setCreateClientModal,
                ),
            );
        } else if (data) {
            processData(data);
        } else {
            setActions([]);
        }
    }, [employeePermissionsMap, companySubscriptionStatus, searching, data, language]);

    const [loading, setLoading] = useState<boolean>(false);
    /**
     * Call BE for general Spotlight search and switch to search mode.
     */
    const search = async (query: string) => {
        const theQuery = query.trim();

        setSearching(theQuery.length > 0);

        if (theQuery.length === 0) {
            return;
        }

        searchTimeout.current.run(() => {
            restApiGet({
                uri: '/spotlight',
                params: {
                    query: theQuery,
                    companyId: companyId,
                } as SpotlightSearchInput,
                setLoading,
                onData: setData,
                onError: (error: any) => processQueryError(appDataContext, error),
            })
        });
    };

    /**
     * Action on trigger of Visit.
     * Go to Visit detail for non-repeating.
     * Display calendar with days for repeating.
     * If schedule later, go to new Visit screen.
     */
    const onVisitTrigger = (visit: VisitResponse, visitRepeatDay: VisitRepeatDayResponse | NullOrUndefined) => {
        if (visit.status === VisitStatus.ScheduleLater) {
            navigate(newJobOrVisitRoute({
                jobId: visit.jobId,
                visitId: visit.id,
                kind: NewJobOrVisitRouteKind.fromJobDetail,
            }));
        } else {
            if (visit.repeating === VisitRepeating.Never) {
                const canNavigateToJob = !isJobDetailScreen(visit.jobId);

                SetVisitDetailModal(appDataContext, {
                    open: true,
                    visitId: visit.id,
                    repeatingDay: undefined,
                    canNavigateToJob,
                });
            } else {
                SetVisitsCalendarModal(appDataContext, {
                    open: true,
                    visitIds: [visit.id],
                    centerOnVisitId: visit.id,
                    centerOnRepeatingDay: visitRepeatDay?.repeatingDay,
                });
            }
        }
    };

    /**
     * Process data response for search into actions.
     */
    const processData = (data: SpotlightSearchResponse) => {
        let newLimit = 0;
        let newActions: SpotlightAction[] = [];

        if (data.visits.length > 0 && hasPermission(kPermissionsJobs, [kActionView], employeePermissionsMap)) {
            const visitsLimit = data.visits.length > 5 ? 5 : data.visits.length;
            newLimit += visitsLimit;

            for (let i = 0; i < visitsLimit; i++) {
                const visit = data.visits[i];
                const visitRepeatDay = data.visitRepeatDays.find(
                    (visitRepeatDay) => visitRepeatDay.visitId === visit.id
                );
                const client = data.clientsForVisits.find((client) => client.id === visit.clientId);

                const dateTimes = visitDateTimes(visit, visitRepeatDay);
                const description: string[] = [];
                if (visit.status === VisitStatus.ScheduleLater) {
                    description.push(tt('common.visit.scheduleLater'));
                } else {
                    if (!dateTimes.isSingleDay && dateTimes.end) {
                        description.push(`${dateTimes.start.toFormat('d.M.yyyy')} - ${dateTimes.end.toFormat('d.M.yyyy')}`);
                    } else if (dateTimes.visitEnd) {
                        description.push(`${dateTimes.start.toFormat('d.M.yyyy')} - ${dateTimes.visitEnd.toFormat('d.M.yyyy')}`);
                    } else if (visit.repeating !== VisitRepeating.Never && !dateTimes.visitEnd) {
                        description.push(`${dateTimes.start.toFormat('d.M.yyyy')} - ${tt('common.visit.noEnd')}`);
                    } else {
                        description.push(dateTimes.start.toFormat('d.M.yyyy'));
                    }

                    if (visit.repeating === VisitRepeating.Never || visitRepeatDay) {
                        description.push(getVisitStatusTitle(visitRepeatDay?.status || visit.status));
                    } else {
                        description.push(VisitRepeatingToString(visit.repeating));
                    }
                }

                if (client) {
                    description.push(client.name);
                }

                newActions.push({
                    group: tt('spotlight.groupVisits'),
                    title: VisitNameOrSequenceId(visit, visitRepeatDay),
                    description: description.join(' · '),
                    closeOnTrigger: true,
                    onTrigger: () => {
                        onVisitTrigger(visit, visitRepeatDay);
                    },
                    icon: <ScheduleEvent/>,
                });
            }
        }

        if (data.jobs.length > 0 && hasPermission(kPermissionsJobs, [kActionView], employeePermissionsMap)) {
            const jobsLimit = data.jobs.length > 5 ? 5 : data.jobs.length;
            newLimit += jobsLimit;

            for (let i = 0; i < jobsLimit; i++) {
                newActions.push({
                    group: tt('spotlight.groupJobs'),
                    title: JobNameOrSequenceId(data.jobs[i]),
                    closeOnTrigger: true,
                    onTrigger: () => {
                        navigate(jobDetailRoute({jobId: data.jobs[i].id}));
                    },
                    icon: <HammerIcon/>,
                });
            }
        }

        if (data.clients.length > 0 && hasPermission(kPermissionsClients, [kActionView], employeePermissionsMap)) {
            const clientsLimit = data.clients.length > 5 ? 5 : data.clients.length;
            newLimit += clientsLimit;

            for (let i = 0; i < clientsLimit; i++) {
                newActions.push(
                    clientToSpotlightAction(
                        data.query,
                        data.clients[i],
                        navigate,
                    )
                );
            }
        }

        if (data.employees.length > 0 && hasPermission(kPermissionsWorkers, [kActionView], employeePermissionsMap)) {
            const employeesLimit = data.employees.length > 5 ? 5 : data.employees.length;
            newLimit += employeesLimit;

            for (let i = 0; i < employeesLimit; i++) {
                newActions.push({
                    group: tt('spotlight.groupEmployees'),
                    title: UserFullName(data.employees[i].name || data.employees[i].user?.name, data.employees[i].surname || data.employees[i].user?.surname),
                    closeOnTrigger: true,
                    onTrigger: () => {
                        navigate(workerDetailRoute(data.employees[i].id));
                    },
                    icon: <WorkerIcon/>,
                });
            }
        }

        setDisplayLimit(newLimit);
        setActions(newActions);
    };

    /**
     * Filter actions for spotlight by title and description.
     */
    const filter = (query: string, actions: SpotlightAction[]): SpotlightAction[] => {
        return actions
            .filter((action) => {
                const filterTitle = action.title.toLowerCase().includes(query.toLowerCase());
                const filterDescription = !action.description || action.description?.toLowerCase().includes(query.toLowerCase());

                let filterByPhone = false;
                if (!filterTitle && !filterDescription && query !== '+') {
                    const theQueryForPhone = query.toLowerCase().replace(/\s/g, '').trim();

                    filterByPhone = `+${action.title}`.replace(/\s/g, '').trim().includes(theQueryForPhone)
                        || `+${action.description}`.replace(/\s/g, '').trim().includes(theQueryForPhone);
                }

                return filterTitle || filterDescription || filterByPhone;
            });
    };

    return (
        <>
            <SpotlightProvider
                disabled={!authUser || noCompaniesMode}
                limit={displayLimit}
                actions={actions}
                actionsWrapperComponent={ActionsWrapper}
                searchIcon={<Box sx={{color: kAppColors.text.primary(darkMode)}}><SearchIcon/></Box>}
                searchPlaceholder={tt('spotlight.searchPlaceholder')}
                onQueryChange={search}
                cleanQueryOnClose={true}
                onSpotlightClose={() => setSearching(false)}
                filter={filter}
                highlightQuery={false}
                nothingFoundMessage={loading ? tt('spotlight.loading') : tt('spotlight.nothingFound')}
                styles={{
                    content: {
                        background: kAppColors.background.paper(darkMode),
                        borderRadius: 12,
                    },
                    searchInput: {
                        background: kAppColors.background.paper(darkMode),
                        color: kAppColors.text.primary(darkMode),
                    },
                    action: {
                        '&[data-hovered]': {
                            background: kAppColors.primary.mainLighter02,
                            color: kAppColors.text.primary(darkMode),
                            'svg': {
                                color: kAppColors.text.primary(darkMode),
                            },
                            '.mantine-Spotlight-actionDescription': {
                                color: kAppColors.text.secondary(darkMode)
                            }
                        },
                        '&[data-hovered]:hover': {background: kAppColors.primary.mainLighter02,},
                        color: kAppColors.text.primary(darkMode)
                    },
                    actionIcon: {color: kAppColors.text.primary(darkMode)},
                    actionDescription: {color: kAppColors.text.secondary(darkMode)},
                    actions: {paddingTop: 26}
                }}
            >
                {children}
            </SpotlightProvider>

            {/*<CreateWorkerModal setOpen={setCreateWorkerModal} open={createWorkerModal}/>

            <CreateClientModal setOpen={setCreateClientModal} open={createClientModal}/>*/}
        </>
    );
}

/**
 * Create set of default actions before searching.
 */
function defaultActions(
    employeePermissionsMap: Record<string, string[]> | undefined,
    companySubscriptionStatus: CompanySubscriptionStatusResponse | undefined,
    navigate: NavigateFunction,
    // setCreateWorkerModal: Dispatch<SetStateAction<boolean>>,
    // setCreateClientModal: Dispatch<SetStateAction<boolean>>,
): SpotlightAction[] {
    const actions: SpotlightAction[] = [];

    /*if (hasPermission(kPermissionsJobs, [kActionCreate], employeePermissionsMap)) {
        actions.push({
            title: tt('spotlight.create_job'),
            // description: tt('spotlight.create_job.description'),
            onTrigger: () => {
                navigate(newJobOrVisitRoute({}));
            },
            closeOnTrigger: true,
            icon: <HammerIcon/>,
        });
    }

    if (hasPermission(kPermissionsClients, [kActionCreate], employeePermissionsMap)) {
        actions.push({
            title: tt('spotlight.create_client'),
            // description: tt('spotlight.create_client.description'),
            onTrigger: () => {
                setCreateClientModal(true);
            },
            closeOnTrigger: true,
            icon: <UserIcon/>,
        });
    }

    const currentUsers = companySubscriptionStatus?.currentUsers || 0;
    const maxUsers = companySubscriptionStatus?.maxUsers || 0;
    const showUpgradeButton = currentUsers >= maxUsers;

    if (showUpgradeButton && hasPermission(kPermissionsCompanySubscription, [kActionUpdate], employeePermissionsMap)) {
        // for now we do not show subscription upgrade button
    } else if (hasPermission(kPermissionsWorkers, [kActionCreate], employeePermissionsMap)) {
        actions.push({
            title: tt('spotlight.create_worker'),
            // description: tt('spotlight.create_worker.description'),
            onTrigger: () => {
                setCreateWorkerModal(true);
            },
            closeOnTrigger: true,
            icon: <WorkerIcon/>,
        });
    }*/

    actions.push({
        title: tt('spotlight.notifications'),
        // description: tt('spotlight.notifications.description'),
        onTrigger: () => {
            navigate(kNotificationsRoute);
        },
        closeOnTrigger: true,
        icon: <Icons8BellLight/>,
    });

    return actions;
}

/**
 * Convert ClientJoinedLocationsResponse into spotlight action.
 */
function clientToSpotlightAction(
    query: string,
    client: ClientJoinedLocationsResponse,
    navigate: NavigateFunction,
): SpotlightAction {
    const theQuery = query.toLowerCase();

    let contactId: number | undefined;
    let locationId: number | undefined;
    let placeId: number | undefined;
    const description: string[] = [];

    if (client.contacts.length > 0) {
        for (const contactOf of client.contacts) {
            if (UserFullName(contactOf.name, contactOf.surname).toLowerCase().includes(theQuery)) {
                if (contactOf.locationPlaces.length > 0) {
                    let theLocation = undefined;
                    for (const locationOf of contactOf.locations) {
                        if (locationOf.id === contactOf.locationPlaces[0].locationId) {
                            theLocation = locationOf;
                            break;
                        }
                    }

                    if (theLocation) {
                        description.push(LocationNameDisplay(theLocation.name, theLocation.type));
                    }

                    description.push(contactOf.locationPlaces[0].name);
                } else if (contactOf.locations.length > 0) {
                    const value = contactOf.locations[0].name || UserAddressSingle(contactOf.locations[0].address);

                    if (value) {
                        description.push(value);
                    }
                }

                description.push(UserFullName(contactOf.name, contactOf.surname));

                contactId = contactOf.id;

                break;
            }
        }
    }

    if (description.length === 0 && client.locations.length > 0) {
        for (const locationOf of client.locations) {
            if (locationOf.name && locationOf.name.toLowerCase().includes(theQuery)) {
                description.push(locationOf.name);

                locationId = locationOf.id;

                break;
            } else if (locationOf.addressSingleLine.toLowerCase().includes(theQuery)) {
                description.push(locationOf.addressSingleLine);

                locationId = locationOf.id;

                break;
            }
        }
    }

    if (description.length === 0 && client.locationPlaces.length > 0) {
        for (const placeOf of client.locationPlaces) {
            if (placeOf.name.toLowerCase().includes(theQuery)) {
                let theLocation = undefined;
                for (const locationOf of client.locations) {
                    if (locationOf.id === placeOf.locationId) {
                        theLocation = locationOf;
                        break;
                    }
                }

                if (theLocation) {
                    description.push(LocationNameDisplay(theLocation.name, theLocation.type));

                    locationId = theLocation.id;
                }

                description.push(placeOf.name);

                placeId = placeOf.id;

                break;
            }
        }
    }

    const theQueryForPhone = theQuery.replace(/\s/g, '').trim();
    if (description.length === 0 && client.contacts.length > 0 && theQuery !== '+') {
        for (const contactOf of client.contacts) {
            if (`+${contactOf.phoneNumber}`.replace(/\s/g, '').trim().includes(theQueryForPhone)) {
                description.push(`+${contactOf.phoneNumber?.replace('+', '')}`);

                if (contactOf.locationPlaces.length > 0) {
                    let theLocation = undefined;
                    for (const locationOf of contactOf.locations) {
                        if (locationOf.id === contactOf.locationPlaces[0].locationId) {
                            theLocation = locationOf;
                            break;
                        }
                    }

                    if (theLocation) {
                        description.push(LocationNameDisplay(theLocation.name, theLocation.type));
                    }

                    description.push(contactOf.locationPlaces[0].name);
                } else if (contactOf.locations.length > 0) {
                    const value = contactOf.locations[0].name || UserAddressSingle(contactOf.locations[0].address);

                    if (value) {
                        description.push(value);
                    }
                }

                description.push(UserFullName(contactOf.name, contactOf.surname));

                contactId = contactOf.id;

                break;
            }
        }
    } else if (description.length === 0 && theQuery !== '+') {
        if (`+${client.phoneNumber}`.replace(/\s/g, '').trim().includes(theQueryForPhone)) {
            description.push(`+${client.phoneNumber?.replace('+', '')}`);
        }
    }

    if (description.length === 0 && theQuery !== '@') {
        if (`@${client.email}`.trim().includes(theQuery)) {
            description.push(`@${client.email}`);
        }
    }

    /**
     * Navigate to correct detail screen depending on which object was found.
     */
    const ToDetail = () => {
        if (contactId) {
            navigate(contactPersonDetailRoute(client.id, contactId));
        } else if (locationId && placeId) {
            navigate(PlaceDetailRoute(client.id, locationId, placeId));
        } else if (locationId) {
            navigate(locationDetailRoute(client.id, locationId));
        } else {
            navigate(clientDetailRoute(client.id));
        }
    };

    return {
        group: tt('spotlight.groupClients'),
        title: client.name,
        description: description.join(' | '),
        closeOnTrigger: true,
        onTrigger: ToDetail,
        icon: client.isCompany ? <Icons8Company/> : <UserIcon/>,
    };
}

interface IActionsWrapperProps {
    children: ReactNode;
}

/**
 * Spotlight actions wrapper component.
 */
function ActionsWrapper(props: IActionsWrapperProps) {
    const {children} = props;

    const {classes} = useStyles();

    return (
        <>
            <Box
                className={classes.hintContainer}
            >
                <Group
                    position="apart"
                >
                    <Typography className={classes.hint}>
                        {tt('spotlight.search.hint')}
                    </Typography>
                </Group>
            </Box>

            {children}
        </>
    );
}
