import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import FormBuilder, {IInputsData} from "../../form/FormBuilder";
import {tt} from "../../../../core/Localization";
import {Box, Divider, Theme} from "@mui/material";
import AppIconButton from "../../buttons/AppIconButton";
import Icons8Change from "../../../../icons/Icons8Change";
import {makeStyles} from "tss-react/mui";
import {ClientResponse, JobDetailResponse,} from "../../../../generated/graphql/graphql";
import SingleJobListItem from "../SingleJobListItem";
import ChooseJobModalBottomSheet from "../../modals/job/ChooseJobModalBottomSheet";
import {NewJobOrVisitRouteKind} from "../../../screens/jobs/NewJobOrVisitScreen";
import GreyLabel from "../../decorations/GreyLabel";
import {getPublicUrls} from "../../../../service/StorageService";
import {AppDataContext} from "../../../../AppData";

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        paddingLeft: 16,
        paddingRight: 8,
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        minHeight: 60,
        '.MuiGrid-container': {
            width: "auto",
            marginRight: "auto",
        },
        ".MuiGrid-root > div": {
            marginBottom: 0,
        }
    },
}));

export interface IAssignToExistingJobSectionProps {
    client: ClientResponse | NullOrUndefined;
    jobData: JobDetailResponse | undefined;
    jobId: string | number | NullOrUndefined;
    setJobId: Dispatch<SetStateAction<string | undefined>>;
    inputs: IInputsData;
    setInputs: Dispatch<SetStateAction<IInputsData>>;
    kindOfRoute: NewJobOrVisitRouteKind;
}

export default function AssignToExistingJobSection(props: IAssignToExistingJobSectionProps) {
    const {
        client,
        jobData,
        jobId,
        setJobId,
        inputs,
        setInputs,
        kindOfRoute,
    } = props;

    const appDataContext = useContext(AppDataContext);
    const {storage, setStorage} = appDataContext;

    const {classes} = useStyles();

    const [jobsModal, setJobsModal] = useState<boolean>(false);

    useEffect(() => {
        if (inputs.assignToJob.value && !jobData) {
            setJobsModal(true);
        }
    }, [inputs]);

    useEffect(() => {
        if (!jobsModal && !jobId) {
            setInputs(prevState => {
                    return {
                        ...prevState,
                        assignToJob: {
                            ...
                                prevState.assignToJob,
                            value: false,
                        }
                    }
                }
            );
        }
    }, [jobsModal]);

    useEffect(() => {
        if (!jobId) {
            setInputs(prevState => {
                    return {
                        ...prevState,
                        assignToJob: {
                            ...
                                prevState.assignToJob,
                            value: false,
                        }
                    }
                }
            );
        }
    }, [jobId]);

    const headerContentJSX = kindOfRoute === NewJobOrVisitRouteKind.fromJobDetail ? (
        <Box className={classes.container}>
            <GreyLabel
                text={tt('newVisit.screen.jobForVisit.label')}
            />
        </Box>
    ) : (
        <Box className={classes.container}>
            <FormBuilder inputs={inputs} setInputs={setInputs}/>
            {inputs.assignToJob.value ?
                <AppIconButton
                    tooltip={tt('common.change')}
                    color={"primary"}
                    onClick={() => {
                        setJobsModal(true);
                    }}
                >
                    <Icons8Change/>
                </AppIconButton>
                : null}
        </Box>
    );

    useEffect(() => {
        if (jobData) {
            setStorage((prev) => {
                return {
                    filesToProcess: [
                        ...prev.filesToProcess,
                        ...(jobData.files || []),
                    ],
                };
            });
        }
    }, [jobData]);

    return client ? (
        <>
            {headerContentJSX}

            {(inputs.assignToJob.value && jobData) || (jobData && kindOfRoute === NewJobOrVisitRouteKind.fromJobDetail) ? (
                <SingleJobListItem
                    data={jobData.job}
                    stats={jobData.stats}
                    client={client}
                    responsiblePerson={jobData.responsiblePerson}
                    customActionWidget={<></>}
                    files={jobData.files}
                />
            ) : null}

            <ChooseJobModalBottomSheet
                open={jobsModal}
                setOpen={setJobsModal}
                setJobId={setJobId}
                jobId={jobData?.job?.id}
                client={client}
            />

            <Divider/>
        </>
    ) : null;
}
