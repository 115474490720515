import {Box, Theme, Typography} from "@mui/material";
import AppChip from "../chips/AppChip";
import React from "react";
import {makeStyles} from "tss-react/mui";
import {kAppColors} from "../../../styles/AppThemeProcessor";


export const useStyles = makeStyles()((theme: Theme) => ({
    banner: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 16,
        marginRight: 16,
        marginLeft: 16,
        marginBottom: 8,
        borderRadius: 10,
        background: kAppColors.yellow.main,
        "@media (max-width: 767px)": {
            flexDirection: "column",
            justifyContent: "center",
        },
        'p': {
            fontWeight: 500,
            color: "black",
            marginRight: 16,
            "@media (max-width: 767px)": {
                marginRight: 0,
                marginBottom: 20,
                textAlign: "center",
            }
        }
    }
}));


export interface IBannerTextAndButtonProps {
    text: string;
    onTap?: Function;
    buttonText: string;
    loading?: boolean;
}

export default function BannerTextAndButton(props: IBannerTextAndButtonProps) {
    const {
        buttonText,
        text,
        onTap,
        loading
    } = props;

    const {classes} = useStyles();

    return (
        <Box className={classes.banner}>
            <Typography>{text}</Typography>

            {onTap  ? (
                <AppChip
                    isLoading={loading}
                    onClick={() => onTap()}
                         chipstyle={"tertiary"}
                         label={buttonText}
                />
            ) : null}
        </Box>
    );
}
