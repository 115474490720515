import React, {useContext} from "react";
import {AppDataContext, IAppDataContext} from "../../../AppData";
import ConfirmationModal, {IConfirmationModalProps} from "./ConfirmationModal";
import CreateCompanyModal, {ICreateCompanyModalProps} from "./CreateCompanyModal";
import JoinCompanyModal, {IJoinCompanyModalProps} from "./JoinCompanyModal";
import FullScreenLoaderModal, {IFullScreenLoaderModalProps} from "./FullScreenLoaderModal";
import {Link, Theme, Typography} from "@mui/material";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import {tt} from "../../../core/Localization";
import {makeStyles} from "tss-react/mui";
import AppVersionModal, {IAppVersionModalProps} from "./AppVersionModal";
import UpgradeSubscriptionModal, {IUpgradeSubscriptionModalProps} from "./UpgradeSubscriptionModal";
import VisitDetailModal, {IVisitDetailModalProps} from "./job/visits/VisitDetailModal";
import VisitPreviewModal, {IVisitPreviewModalProps} from "./job/visits/previewModal/VisitPreviewModal";
import EditPermissionsModal, {IEditPermissionsModalProps} from "../permissions/EditPermissionsModal";
import VisitsCalendarModal, {IVisitsCalendarModalProps} from "./job/visits/VisitsCalendarModal";
import LinkProvidersModal, { ILinkProvidersModalProps } from "./authorization/LinkProvidersModal";

export const appModalsStyles = makeStyles()((theme: Theme) => ({
    modalText: {
        whiteSpace: 'pre-line',
        fontSize: 14,
        fontWeight: 500,
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        paddingBottom: 16,
        textAlign: "center",
    },
    modalLInk: {
        color: kAppColors.primary.main,
        textDecorationColor: kAppColors.primary.main,
    },
}));

export interface IAppModalsProps {
    appVersion?: IAppVersionModalProps;
    confirm?: IConfirmationModalProps;
    createCompany?: ICreateCompanyModalProps;
    joinCompany?: IJoinCompanyModalProps;
    fullScreenLoader?: IFullScreenLoaderModalProps;
    upgradeSubscription?: IUpgradeSubscriptionModalProps;
    visitDetail?: IVisitDetailModalProps;
    visitPreview?: IVisitPreviewModalProps;
    editPermissions?: IEditPermissionsModalProps;
    visitsCalendarModal?: IVisitsCalendarModalProps;
    linkProvidersModal?: ILinkProvidersModalProps;
}

/**
 * Component for global app modals.
 */
export default function AppModals() {
    const appDataContext = useContext(AppDataContext);
    const {appModals} = appDataContext;

    const appVersionModalJSX = appModals?.appVersion ? (
        <AppVersionModal {...appModals!.appVersion!}/>
    ) : null;

    const confirmModalJSX = appModals?.confirm ? (
        <ConfirmationModal {...appModals!.confirm!}/>
    ) : null;

    const createCompanyJSX = appModals?.createCompany ? (
        <CreateCompanyModal {...appModals!.createCompany!}/>
    ) : null;

    const joinCompanyJSX = appModals?.joinCompany ? (
        <JoinCompanyModal {...appModals!.joinCompany!}/>
    ) : null;

    const fullScreenLoaderJSX = appModals?.fullScreenLoader ? (
        <FullScreenLoaderModal {...appModals!.fullScreenLoader!}/>
    ) : null;

    const upgradeSubscriptionModalJsx = appModals?.upgradeSubscription ? (
        <UpgradeSubscriptionModal {...appModals!.upgradeSubscription!}/>
    ) : null;

    const visitDetailModalJsx = appModals?.visitDetail ? (
        <VisitDetailModal {...appModals!.visitDetail!}/>
    ) : null;

    const visitPreviewlModalJsx = appModals?.visitPreview ? (
        <VisitPreviewModal {...appModals!.visitPreview!}/>
    ) : null;

    const editPermissionsModalJsx = appModals?.editPermissions ? (
        <EditPermissionsModal {...appModals!.editPermissions!}/>
    ) : null;

    const visitsCalendarModalJsx = appModals?.visitsCalendarModal ? (
        <VisitsCalendarModal {...appModals!.visitsCalendarModal!}/>
    ) : null;

    const linkProvidersModalJsx = appModals?.linkProvidersModal ? (
        <LinkProvidersModal {...appModals!.linkProvidersModal!}/>
    ) : null;

    return (
        <>
            {appVersionModalJSX}

            {confirmModalJSX}

            {createCompanyJSX}

            {joinCompanyJSX}

            {fullScreenLoaderJSX}

            {upgradeSubscriptionModalJsx}

            {visitDetailModalJsx}

            {visitPreviewlModalJsx}

            {editPermissionsModalJsx}

            {visitsCalendarModalJsx}

            {linkProvidersModalJsx}
        </>
    );
}

/**
 * Shorthand to display app version update modal.
 */
export function SetAppVersionModal(appDataContext: IAppDataContext, appVersion: Partial<IAppVersionModalProps>) {
    appDataContext.setAppModals(prev => {
        return {
            ...prev,
            appVersion: {
                open: prev?.appVersion?.open || false,
                version: '',
                ...prev?.appVersion,
                ...appVersion,
            },
        };
    });
}

/**
 * Shorthand to hide app version update modal.
 */
export function HideAppVersionModal(appDataContext: IAppDataContext) {
    appDataContext.setAppModals(prev => {
        return {
            ...prev,
            appVersion: {
                version: '',
                ...prev?.appVersion,
                open: false,
            },
        };
    });
}

/**
 * Shorthand to display Confirm modal.
 */
export function SetConfirmModal(appDataContext: IAppDataContext, confirm: Partial<IConfirmationModalProps>) {
    appDataContext.setAppModals(prev => {
        return {
            ...prev,
            confirm: {
                ...prev?.confirm,
                loading: false,
                open: prev?.confirm?.open || false,
                title: prev?.confirm?.title || '',
                subtitle: '',
                children: undefined,
                blurBackdrop: false,
                confirmationButtonText: undefined,
                cancelButtonText: undefined,
                onConfirm: undefined,
                onCancel: undefined,
                confirmButtonColor: undefined,
                ...confirm,
            },
        };
    });
}

/**
 * Shorthand to update loading state of confirm modal.
 */
export function SetConfirmModalLoading(appDataContext: IAppDataContext, loading: boolean) {
    appDataContext.setAppModals(prev => {
        if (prev?.confirm) {
            return {
                ...prev,
                confirm: {
                    ...prev?.confirm,
                    loading,
                },
            };
        } else {
            return {...prev};
        }
    });
}

/**
 * Shorthand to hide Confirm modal.
 */
export function HideConfirmModal(appDataContext: IAppDataContext) {
    appDataContext.setAppModals(prev => {
        return {
            ...prev,
            confirm: {
                ...prev!.confirm!,
                open: false,
            },
        };
    });
}

/**
 * Display modal for close account confirm.
 */
export function DisplayCloseAccountConfirm(appDataContext: IAppDataContext, classes: Record<string, string>) {
    SetConfirmModal(appDataContext, {
        open: true,
        title: tt('accountMarkedForClosure.popup.title'),
        cancelButtonText: tt('common.close'),
        children: (
            <Typography className={classes.modalText}>
                {tt('accountMarkedForClosure.popup.text1')}
                &nbsp;
                <Link className={classes.modalLInk}
                      href={tt('url.accountClosureArticle')}
                      target={'_blank'}>
                    {tt('accountMarkedForClosure.popup.link')}
                </Link>
                &nbsp;
                {tt('accountMarkedForClosure.popup.text2')}
            </Typography>
        ),
    });
}

/**
 * Shorthand to display Create Company modal.
 */
export function SetCreateCompanyModal(appDataContext: IAppDataContext, createCompany: ICreateCompanyModalProps) {
    appDataContext.setAppModals(prev => {
        return {
            ...prev,
            createCompany: {
                ...prev?.createCompany,
                ...createCompany,
            },
        };
    });
}

/**
 * Shorthand to display Join Company modal.
 */
export function SetJoinCompanyModal(appDataContext: IAppDataContext, joinCompany: IJoinCompanyModalProps) {
    appDataContext.setAppModals(prev => {
        return {
            ...prev,
            joinCompany: {
                ...prev?.joinCompany,
                ...joinCompany,
            },
        };
    });
}


/**
 * Shorthand to display Loader modal.
 */
export function SetLoaderModal(appDataContext: IAppDataContext, fullScreenLoader: Partial<IFullScreenLoaderModalProps>) {
    appDataContext.setAppModals(prev => {
        return {
            ...prev,
            fullScreenLoader: {
                open: prev?.fullScreenLoader?.open || false,
                text: prev?.fullScreenLoader?.text || '',
                ...prev?.fullScreenLoader,
                ...fullScreenLoader,
            },
        };
    });
}

/**
 * Shorthand to display Upgrade Subscription modal.
 */
export function SetSubscriptionUpgradeModal(appDataContext: IAppDataContext, upgradeSubscription: IUpgradeSubscriptionModalProps) {
    appDataContext.setAppModals(prev => {
        return {
            ...prev,
            upgradeSubscription: {
                ...prev?.upgradeSubscription,
                ...upgradeSubscription,
            },
        };
    });
}


/**
 * Shorthand to hide Upgrade Subscription modal.
 */
export function HideUpgradeSubscriptionModal(appDataContext: IAppDataContext) {
    appDataContext.setAppModals(prev => {
        return {
            ...prev,
            upgradeSubscription: {
                ...prev!.upgradeSubscription!,
                open: false,
            },
        };
    });
}

/**
 * Shorthand to display Visit Detail modal.
 */
export function SetVisitDetailModal(appDataContext: IAppDataContext, visitDetail: Partial<IVisitDetailModalProps>) {
    appDataContext.setAppModals(prev => {
        return {
            ...prev,
            visitDetail: {
                ...prev?.visitDetail,
                canNavigateToJob: false,
                preselectSelectedTab: null,
                ...visitDetail,
            } as any,
        };
    });
}

/**
 * Shorthand to hide Visit Detail modal.
 */
export function HideVisitDetailModal(appDataContext: IAppDataContext) {
    appDataContext.setAppModals(prev => {
        if (prev?.visitDetail) {
            return {
                ...prev,
                visitDetail: {
                    ...prev!.visitDetail!,
                    open: false,
                },
            };
        } else {
            return {...prev};
        }
    });
}

/**
 * Shorthand to display Visit preview modal.
 */
export function SetVisitDetailPreviewModal(appDataContext: IAppDataContext, visitPreview: Partial<IVisitPreviewModalProps>) {
    appDataContext.setAppModals(prev => {
        return {
            ...prev,
            visitPreview: {
                ...prev?.visitPreview,
                ...visitPreview,
            } as any,
        };
    });
}

export function HideVisitPreviewModal(appDataContext: IAppDataContext) {
    appDataContext.setAppModals(prev => {
        if (prev?.visitPreview) {
            return {
                ...prev,
                visitPreview: {
                    ...prev!.visitPreview!,
                    open: false,
                },
            };
        } else {
            return {...prev};
        }
    });
}

/**
 * Shorthand to display edit permissions modal.
 */
export function SetEditPermissionsModal(appDataContext: IAppDataContext, editPermissions: Partial<IEditPermissionsModalProps>) {
    appDataContext.setAppModals(prev => {
        return {
            ...prev,
            editPermissions: {
                ...prev?.editPermissions,
                applyForAllOfRole: undefined,
                updateLoading: false,
                ...editPermissions,
            } as any,
        };
    });
}

export function HideEditPermissionsModal(appDataContext: IAppDataContext) {
    appDataContext.setAppModals(prev => {
        if (prev?.editPermissions) {
            return {
                ...prev,
                editPermissions: {
                    ...prev!.editPermissions!,
                    open: false,
                },
            };
        } else {
            return {...prev};
        }
    });
}

/**
 * Shorthand to display VisitsCalendarModal.
 */
export function SetVisitsCalendarModal(appDataContext: IAppDataContext, visitsCalendarModal: Partial<IVisitsCalendarModalProps>) {
    appDataContext.setAppModals(prev => {
        return {
            ...prev,
            visitsCalendarModal: {
                ...prev?.visitsCalendarModal,
                centerOnVisitId: undefined,
                centerOnRepeatingDay: undefined,
                ...visitsCalendarModal,
            } as any,
        };
    });
}

/**
 * Hide VisitsCalendarModal.
 */

export function HideVisitsCalendarModal(appDataContext: IAppDataContext) {
    appDataContext.setAppModals(prev => {
        if (prev?.visitsCalendarModal) {
            return {
                ...prev,
                visitsCalendarModal: {
                    ...prev!.visitsCalendarModal!,
                    open: false,
                },
            };
        } else {
            return {...prev};
        }
    });
}

/**
 * Shorthand to display LinkProvidersModal.
 */
export function SetLinkProvidersModal(appDataContext: IAppDataContext, linkProvidersModal: Partial<ILinkProvidersModalProps>) {
    appDataContext.setAppModals(prev => {
        return {
            ...prev,
            linkProvidersModal: {
                ...prev?.linkProvidersModal,
                hideProvider: undefined,
                ...linkProvidersModal,
            } as any,
        };
    });
}

/**
 * Hide LinkProvidersModal.
 */
export function HideLinkProvidersModal(appDataContext: IAppDataContext) {
    appDataContext.setAppModals(prev => {
        if (prev?.linkProvidersModal) {
            return {
                ...prev,
                linkProvidersModal: {
                    ...prev!.linkProvidersModal!,
                    open: false,
                },
            };
        } else {
            return {...prev};
        }
    });
}
