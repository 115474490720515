import {makeStyles} from "tss-react/mui";
import {Box, Theme, Typography} from "@mui/material";
import {kAppColors} from "../../../../styles/AppThemeProcessor";
import React from "react";


export const useStyles = makeStyles()((theme: Theme) => ({
    text: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: 1.3,
        color: kAppColors.text.primary(theme.palette.mode === "dark"),
        display: "block",
    },
    container: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 13,
        paddingBottom: 13,
        display: "flex",
        alignItems: "center",
        'span, div': {
            marginRight: 16,
        }
    },
    description: {
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        fontWeight: 500,
        fontSize: 14,
        lineHeight: 18 / 14,
    }
}));


export interface IBasicRowProps {
    text: string;
}

export default function BasicRow(props: IBasicRowProps) {
    const {text} = props;
    const {classes, cx} = useStyles();

    return (
        <Box className={classes.container}>
            <Typography className={classes.text}>{text}</Typography>
        </Box>
    );
}