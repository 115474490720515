import {AutoCompleteOption, ITextAutocompleteFieldProps} from "./InputProps";
import React from "react";
import {Autocomplete, Box, createFilterOptions, Grid, TextField, Theme, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {tt} from "../../../core/Localization";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import {useStyles as formUseStyles} from "./FormBuilder";

export const useStyles = makeStyles()((theme: Theme) => ({
    paper: {
        backgroundColor: kAppColors.background.menuPaper(theme.palette.mode === "dark") + ' !important',
        borderRadius: 10,
        backgroundImage: "unset",
    },
    suffix: {
        marginLeft: theme.spacing(2),
    },
    overlayEndSuffix: {
        position: "absolute",
        right: 0,
        top: 0,
        height: 54,
    },
    container: {
        position: "relative",
        button: {
            width: 54,
            height: 54,
        }
    },
    newValue: {
        color: kAppColors.primary.main,
        fontWeight: 700,
    },
    cursor: {
        cursor: "pointer",
    },
    popper: {
        zIndex: 1320,
    }
}));

export default function TextAutocompleteField(props: ITextAutocompleteFieldProps) {
    const {
        index,
        className,
        label,
        value,
        updateValue,
        autocompleteOptions,
        hidden,
        error,
        errorMessage,
        helperText,
        placeholder,
        paperClass,
        suffixJSX,
        overlayEndSuffixJSX,
        innerSuffixJSX,
        onAutocompleteValueChanged,
        onNewValueAdded,
        freeSolo,
        disabled,
    } = props;

    const theFreeSolo = typeof freeSolo === "undefined" ? true : freeSolo;

    const {classes: formClasses} = formUseStyles();
    const {classes, cx} = useStyles();


    function OnChange(newValue: AutoCompleteOption) {
        updateValue(index, newValue);
    }

    const filter = createFilterOptions<AutoCompleteOption>();

    const suffixJSXContent = suffixJSX ? (
        <Grid item={true} alignItems={"start"} className={classes.suffix}>
            {suffixJSX()}
        </Grid>
    ) : null;

    const theOverlayEndSuffixJSX = overlayEndSuffixJSX ? (
        <Box className={classes.overlayEndSuffix}>
            {overlayEndSuffixJSX(props)}
        </Box>
    ) : null;

    return (
        <div className={cx(className, hidden ? formClasses.hidden : undefined)}>
            <Grid container={true} spacing={0} alignItems="start">
                <Grid item={true} xs={true} className={classes.container}>
                    <Autocomplete
                        disabled={disabled}
                        renderOption={(props, option) => {
                            return <li {...props}
                                       className={cx(option.newValue ? classes.newValue : null, classes.cursor)}>{option.label}</li>
                        }}
                        selectOnFocus={true}
                        clearOnBlur={true}
                        filterSelectedOptions={true}
                        handleHomeEndKeys={true}
                        onChange={(event, newValue, reason, details) => {
                            if (typeof newValue === 'string') {
                                OnChange({
                                    label: newValue,
                                    value: newValue,
                                });
                            } else if (newValue.newValue) {
                                OnChange({
                                    label: newValue.newValue,
                                    value: newValue.newValue,
                                });
                                if (onNewValueAdded) {
                                    onNewValueAdded(newValue.newValue);
                                }
                            } else {
                                OnChange(newValue);
                            }

                            if (onAutocompleteValueChanged) {
                                onAutocompleteValueChanged(
                                    event,
                                    newValue,
                                    reason,
                                    details,
                                );
                            }
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const {inputValue} = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.label);
                            if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                    newValue: inputValue,
                                    label: `${tt('autocomplete.field.add')} "${inputValue}"`,
                                });
                            }

                            return filtered;
                        }}

                        freeSolo={theFreeSolo}
                        componentsProps={{
                            popper: {
                                className: classes.popper,
                            },
                            paper: {
                                className: cx(paperClass, classes.paper),
                            },
                        }}

                        value={value}
                        disableClearable={true}
                        options={autocompleteOptions}
                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                                <Typography>{option.label}</Typography>
                            ))
                        }

                        renderInput={(params) => (
                            <TextField
                                className={className}
                                variant={"filled"}
                                error={error}
                                helperText={error ? errorMessage : undefined}
                                {...params}
                                label={label}
                                placeholder={placeholder}
                            />
                        )}
                    />

                    {theOverlayEndSuffixJSX}
                </Grid>

                {suffixJSXContent}
            </Grid>

            {helperText}
        </div>
    );
}
