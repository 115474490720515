import {Box, Theme, Typography} from "@mui/material";
import React from "react";
import ENIcon from "../../../../icons/languages/ENIcon";
import SKIcon from "../../../../icons/languages/SKIcon";
import CZIcon from "../../../../icons/languages/CZIcon";
import {tt} from "../../../../core/Localization";
import {kAppColors} from "../../../../styles/AppThemeProcessor";
import {makeStyles} from "tss-react/mui";


export const useStyles = makeStyles()((theme: Theme) => ({
    text: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: 1.3,
        color: kAppColors.text.primary(theme.palette.mode === "dark"),
        display: "block",
    },
    container: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 13,
        paddingBottom: 13,
        display: "flex",
        alignItems: "center",
        'span, div': {
            marginRight: 16,
        }
    },
    description: {
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        fontWeight: 500,
        fontSize: 14,
        lineHeight: 18 / 14,
    }
}));


export interface ICountryRowProps {
    country: 'us' | 'cz' | 'sk';
}

export default function CountryRow(props: ICountryRowProps) {
    const {country} = props;
    const {classes, cx} = useStyles();

    let title;
    let iconJSX;

    switch (country) {

        case "us":
            iconJSX = <ENIcon/>;
            title = tt('country.unitedStates');
            break;
        case "cz":
            iconJSX = <CZIcon/>;
            title = tt('country.czechRepublic');
            break;
        case "sk":
            iconJSX = <SKIcon/>;
            title = tt('country.slovakia');
            break;
    }

    return (
        <Box className={classes.container}>
            {iconJSX}
            <Typography className={classes.text}>{title}</Typography>
        </Box>
    );
}