declare global {
    interface Window {
        dataLayer?: any;
    }
}

/**
 * Use Google Analytics for DataLayer tracking events.
 */
export function trackDataLayerEvent(event: string) {
    if (window.dataLayer) {
        window.dataLayer.push({'event': event});
    }
}
