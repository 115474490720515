import {makeStyles} from "tss-react/mui";
import {Theme} from "@mui/material";
import React from "react";

export const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        display: 'flex',
        flex: '1 1 auto',
        justifyContent: 'center',
        alignItems: 'center',
    },
    topAlignedContent: {
        alignItems: "start",
    },
}));

export interface IFullSizedContainerProps {
    children: React.ReactNode;
    topAlignedContent?: boolean;
}

/**
 * Component container that stretches to full space avaiable.
 */
export default function FullSizedContainer(props: IFullSizedContainerProps) {
    const {children, topAlignedContent} = props;

    const {classes, cx} = useStyles();

    const theClassName = cx(
        classes.container,
        topAlignedContent ? classes.topAlignedContent : undefined,
    );

    return (
        <div className={theClassName}>
            {children}
        </div>
    );
}
