import {useEffect, useState} from "react";
import {processInfos, subscribeInfos} from "../../../service/InfoService";
import IInfo from "../../../model/firestore/Info";
import InfoNotice from "./InfoNotice";

/**
 * Component to listen for and display Info list.
 */
export default function InfoSection() {
    const [infos, setInfos] = useState<IInfo[]>([]);

    useEffect(() => {
        const unsubscribe = subscribeInfos((snapshot) => {
            const newInfos: IInfo[] = [];

            snapshot.forEach((doc) => {
                const data = doc.data();
                const info: IInfo = data as any;

                info.id = doc.id;

                newInfos.push(info);
            });

            setInfos(processInfos(newInfos));
        }, 1);

        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <>
            {infos.map((info) => {
                return (
                    <InfoNotice
                        key={info.id}
                        info={info}
                    />
                );
            })}
        </>
    );
}
