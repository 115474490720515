import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import AppModal from "../modals/AppModal";
import {tt} from "../../../core/Localization";
import {AppDataContext} from "../../../AppData";
import {useResettableMutation} from "tomaschyly-apollo-hooks-extended";
import {
    CreateClientDocument,
    CreateClientMutation,
    CreateClientMutationVariables
} from "../../../generated/graphql/graphql";
import {useNavigate} from "react-router-dom";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../form/FormBuilder";
import {Box, Divider, IconButton, InputAdornment, Theme, Typography} from "@mui/material";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import AppButton from "../buttons/AppButton";
import {makeStyles} from "tss-react/mui";
import {ErrorToast, SuccessToast} from "../../../service/ToastService";
import {clientDetailRoute} from "../../screens/clients/ClientDetailScreen";
import DeleteIcon from "../../../icons/DeleteIcon";
import HeadlineWithButton from "../../screenSections/detailListPreviewSection/HeadlineWithButton";

export const useStyles = makeStyles()((theme: Theme) => ({
    submitRightContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
    },
    button: {
        "@media (max-width: 767px)": {
            flexGrow: 1,
        }
    },
    deleteIcon: {
        color: kAppColors.red.confirmButton,
        'svg': {height: 21, width: 21}
    },
    switch: {
        marginBottom: 4,
    }
}));

export interface ICreateClientModalProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    navigation?: boolean;
    onCreate?: (id: number) => void;
}

/**
 * Modal component for new Client creation form.
 */
export default function CreateClientModal(props: ICreateClientModalProps) {
    const {open, setOpen, navigation, onCreate} = props;

    const appDataContext = useContext(AppDataContext);
    const {companyId} = appDataContext;

    const navigate = useNavigate();
    const theNavigation = navigation === undefined ? true : navigation;

    const {classes} = useStyles();

    const [mutateCreate, {
        loading,
    }] = useResettableMutation<CreateClientMutation, CreateClientMutationVariables>(CreateClientDocument);

    const [companyClient, setCompanyClient] = useState<boolean>(false);
    const [nameInputs, setNameInputs] = useState<boolean>(false);
    const [inputs, setInputs] = useState<IInputsData>({
        switch: {
            testId: 'createClientFormCompanySwitch',
            type: InputType.Switch,
            switchVariant: "Android12Switch",
            label: tt('createClientModal.switch'),
            value: companyClient,
            className: classes.switch,
        },
        companyName: {
            testId: 'createClientFormCompanyNameInput',
            type: InputType.Text,
            label: `${tt('clientForm.label.nameAndSurname')}*`,
            value: '',
            required: true,
            grid: {
                sm: 12,
                xs: 12
            },
        },
        companyPhone: {
            testId: 'createClientFormCompanyPhoneInput',
            type: InputType.Text,
            label: tt('common.phone'),
            toggleButtonText: tt('common.addPhone'),
            value: '',
            required: false,
            inputMode: 'tel',
            numbersOnly: true,
            grid: {
                sm: 6,
                xs: 12
            },
        },
        companyEmail: {
            testId: 'createClientFormCompanyEmailInput',
            type: InputType.ButtonTextField,
            label: tt('common.email'),
            toggleButtonText: tt('common.addEmail'),
            value: '',
            required: false,
            requireEmail: true,
            grid: {
                sm: 6,
                xs: 12
            },
        },
        divider: {
            type: InputType.CustomRenderer,
            value: '',
            label: '',
            render: () => {
                return (
                    <Divider/>
                );
            },
        },
        showContactPersonFormHeader: {
            type: InputType.CustomRenderer,
            value: '',
            label: '',
            render: () => {
                return (
                    <Box pt={1}>
                        <AppButton
                            testId={'createClientFormAddNameButton'}
                            sx={{
                                py: 0,
                                height: '36px !important',
                                mb: 2,
                                display: shouldShowContactPersonInputs ? 'none' : 'block'
                            }}
                            onClick={() => setNameInputs(true)}>{tt('common.addContactPerson')}
                        </AppButton>
                        <Box
                            sx={{display: shouldShowContactPersonInputs ? 'block' : 'none'}}
                        >
                            <HeadlineWithButton
                                title={tt('common.contactPerson')}
                                onClick={() => setNameInputs(false)}
                                iconJSX={<Box sx={{color: kAppColors.red.main}}><DeleteIcon/></Box>}
                            />
                        </Box>
                    </Box>
                );
            },
        },
        name: {
            testId: 'createClientFormFirstNameInput',
            type: InputType.Text,
            label: `${tt('common.firstName')}*`,
            value: '',
            required: false,
            hidden: true,
            grid: {
                sm: 6,
                xs: 12
            },
        },
        surname: {
            testId: 'createClientFormLastNameInput',
            type: InputType.Text,
            label: `${tt('common.lastName')}*`,
            value: '',
            required: false,
            hidden: true,
            grid: {
                sm: 6,
                xs: 12
            },
        },
        phoneNumber: {
            testId: 'createClientFormPhoneInput',
            type: InputType.Text,
            label: tt('common.phone'),
            value: '',
            required: false,
            inputMode: 'tel',
            numbersOnly: true,
            grid: {
                sm: 6,
                xs: 12
            },
        },
        role: {
            testId: 'createClientFormRoleInput',
            type: InputType.ButtonTextField,
            label: tt('common.role'),
            toggleButtonText: tt('common.addRole'),
            value: '',
            required: false,
            grid: {
                sm: 6,
                xs: 12
            },
        },
    });

    /**
     * show if nameInputs on
     * show if name | surname not empty
     * */
    const shouldShowContactPersonInputs = nameInputs || (inputs.name?.value != '' && inputs.name?.value != undefined) || (inputs.surname?.value != '' && inputs.surname?.value != undefined);

    useEffect(() => {
        setInputs(prevState => {
            return {
                ...prevState,
                companyName: {
                    ...prevState.companyName,
                    label: companyClient ? `${tt('clientForm.label.companyName')}*` : `${tt('clientForm.label.nameAndSurname')}*`,
                },
                showContactPersonFormHeader:
                    {
                        ...
                            prevState.showContactPersonFormHeader,
                        render: () => {
                            return (
                                <Box pt={1}>
                                    <AppButton
                                        testId={'createClientFormAddNameButton'}
                                        sx={{
                                            py: 0,
                                            height: '36px !important',
                                            mb: 2,
                                            display: shouldShowContactPersonInputs ? 'none' : 'block'
                                        }}
                                        onClick={() => setNameInputs(true)}>{tt('common.addContactPerson')}
                                    </AppButton>
                                    <Box
                                        sx={{
                                            marginLeft: -2,
                                            marginRight: -2,
                                            display: shouldShowContactPersonInputs ? 'block' : 'none'
                                        }}
                                    >
                                        <HeadlineWithButton
                                            title={tt('common.contactPerson')}
                                            onClick={() => setNameInputs(false)}
                                            iconJSX={<Box sx={{color: kAppColors.red.main}}><DeleteIcon/></Box>}
                                        />
                                    </Box>
                                </Box>
                            );
                        },
                    },
                name: {
                    ...
                        prevState.name,
                    required:
                    shouldShowContactPersonInputs,
                    hidden:
                        !shouldShowContactPersonInputs,
                }
                ,
                surname: {
                    ...
                        prevState.surname,
                    required:
                    shouldShowContactPersonInputs,
                    hidden:
                        !shouldShowContactPersonInputs,
                },
                phoneNumber: {
                    ...
                        prevState.phoneNumber,
                    hidden:
                        !shouldShowContactPersonInputs,
                },
                role: {
                    ...
                        prevState.role,
                    hidden:
                        !shouldShowContactPersonInputs,
                },
            };
        });
    }, [shouldShowContactPersonInputs, companyClient]);

    useEffect(() => {
        if (inputs.switch && inputs.switch.value !== companyClient) {
            setCompanyClient(inputs.switch.value);
        }
    }, [inputs.switch.value]);

    /**
     * Reset validation, values
     */
    useEffect(() => {
        setCompanyClient(false);
        setInputs(
            prev => {
                return {
                    ...prev,
                    switch: {
                        ...prev.switch,
                        value: false,
                    },
                    companyName: {
                        ...prev.companyName,
                        value: '',
                        error: false,
                    },
                    companyPhone: {
                        ...prev.companyPhone,
                        value: '',
                        error: false,
                    },
                    companyEmail: {
                        ...prev.companyEmail,
                        value: '',
                        error: false,
                    },
                    name: {
                        ...prev.name,
                        value: '',
                        error: false,
                    },
                    surname: {
                        ...prev.surname,
                        value: '',
                        error: false,
                    },
                    phoneNumber: {
                        ...prev.phoneNumber,
                        value: '',
                        error: false,
                    },
                    role: {
                        ...prev.role,
                        value: '',
                        error: false,
                    }
                }
            }
        );
    }, [open]);

    /**
     * Mutate Client to BE and close modal if success.
     */
    const CreateClient = async () => {
        if (ValidateForm(inputs, setInputs)) {
            try {
                const variables: CreateClientMutationVariables = {
                    input: {
                        companyId: companyId!,
                        isCompany: companyClient,
                        companyName: inputs.companyName.value,
                        companyPhone: inputs.companyPhone.value || undefined,
                        name: inputs.name.value || undefined,
                        surname: inputs.surname.value || undefined,
                        phoneNumber: inputs.phoneNumber.value || undefined,
                        role: inputs.role.value || undefined,
                        email: inputs.companyEmail.value || undefined,
                    },
                };

                const result = await mutateCreate({variables});

                if (!result.errors) {
                    setOpen(false);

                    if (theNavigation) {
                        navigate(clientDetailRoute(result.data!.createClient.id));
                    }

                    SuccessToast(tt('createClient.modal.success'));

                    if (onCreate) {
                        onCreate!(result.data!.createClient.id);
                    }
                }
            } catch (e) {
                console.error(e);

                ErrorToast(tt('common.mutation.error'));
            }
        }
    };

    return (
        <AppModal
            open={open}
            setOpen={setOpen}
            title={tt('common.newClient')}
            fullScreenOnMobile={true}
            blurBackdrop={true}>
            <FormBuilder inputs={inputs} setInputs={setInputs}/>

            <div className={classes.submitRightContainer}>
                <AppButton
                    className={classes.button}
                    variant={"contained"}
                    fullWidth={false}
                    onClick={CreateClient}
                    isLoading={loading}
                >
                    {tt('common.create')}
                </AppButton>
            </div>
        </AppModal>
    );
}
