import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {
    GetVisitInput,
    JobUpdateRepeats,
    UpdateVisitDateTimeDocument,
    UpdateVisitDateTimeMutation,
    UpdateVisitDateTimeMutationVariables,
    VisitRepeating,
    VisitRepeatModifier,
    VisitResponse
} from "../../../../../generated/graphql/graphql";
import {useMutation} from "@apollo/client";
import {ErrorToast, SuccessToast} from "../../../../../service/ToastService";
import {tt} from "../../../../../core/Localization";
import ModalBottomSheet from "../../../ModalBottomSheet";
import BottomSheetModalAppbar from "../../BottomSheetModalAppbar";
import {Box, Theme} from "@mui/material";
import AppButton from "../../../buttons/AppButton";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../../../form/FormBuilder";
import {DateTime} from "luxon";
import {makeStyles} from "tss-react/mui";
import {AppDataContext} from "../../../../../AppData";
import {visitDateTimes} from "../../../../../service/VisitService";
import {
    Create4WeeksOptions,
    CreateMonthDayPickerOptions,
    CreateWeekdayOptions,
} from "../../../../../utils/DateUtils";
import {processQueryError} from "../../../../../service/ErrorService";
import {IOnUpdateVisitIdParams} from "./VisitDetailModal";
import {RestApiClientContext} from "../../../../../core/RestApiProvider";

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        maxHeight: '80vh',
        height: 'auto',
        overflow: "auto",
    },
    tabContainer: {
        minHeight: 140,
        paddingLeft: 16,
        paddingRight: 16,
    },
    topEmptySpace: {
        paddingTop: 50,
    },
    topEmptySpaceRemoveOnMobile: {
        paddingTop: 50,
        "@media (max-width: 600px)": {
            paddingTop: 0,
        }
    },
    repeatsBy: {
        '.MuiFormHelperText-root': {
            marginBottom: -20,
        },
    },
    buttonContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: 16,
    },
    skipOtherDaysCalendar: {
        paddingTop: 12,
        paddingBottom: 12,
    }
}));

export interface IEditVisitDateTimeModalBottomSheetProps {
    visitId: number;
    repeatingDay?: number;
    repeats: JobUpdateRepeats;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    onUpdateJobId: (params: IOnUpdateVisitIdParams) => void;
    modalAboveModals?: boolean;
}

/**
 * Modal bottom sheet component for edit of date and time.
 */
export default function EditVisitDateTimeModalBottomSheet(props: IEditVisitDateTimeModalBottomSheetProps) {
    const {visitId, repeatingDay, repeats, open, setOpen, onUpdateJobId, modalAboveModals} = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {language} = appDataContext;

    const {classes} = useStyles();

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<VisitResponse | undefined>(undefined);
    useEffect(() => {
        if (open) {
            restApiGet({
                uri: '/job/visit',
                params: {
                    visitId,
                    repeatingDay,
                } as GetVisitInput,
                setLoading: setLoading,
                onData: setData,
                onError: (error) => {
                    processQueryError(appDataContext, error);
                },
            });
        }
    }, [open, visitId, repeatingDay]);

    const [mutateUpdate, {
        loading: updateLoading,
    }] = useMutation<UpdateVisitDateTimeMutation, UpdateVisitDateTimeMutationVariables>(UpdateVisitDateTimeDocument);

    const [inputsTab1, setInputsTab1] = useState<IInputsData>({
        startTime: {
            testId: 'createNewJobFormStartTime',
            type: InputType.FormTimePicker,
            label: `${tt('common.startTime')}*`,
            value: DateTime.now().toMillis(),
            required: true,
            grid: {
                sm: 3,
                xs: 6,
            }
        },
        endTime: {
            testId: 'createNewJobFormEndTime',
            type: InputType.FormTimePicker,
            label: `${tt('common.endTime')}*`,
            value: DateTime.now().plus({hours: 1}).toMillis(),
            required: true,
            grid: {
                sm: 3,
                xs: 6,
            }
        },
        startDate: {
            testId: 'createNewJobFormStartDate',
            type: InputType.DatePicker,
            label: `${tt('common.startDate')}*`,
            value: DateTime.now().toMillis(),
            dateFormat: 'dd.MM.yyyy',
            required: true,
            grid: {
                sm: 3,
                xs: 6,
            },
        },
        endDate: {
            testId: 'createNewJobFormSEndDate',
            type: InputType.DatePicker,
            label: `${tt('common.endDate')}*`,
            value: DateTime.now().plus({hours: 1}).toMillis(),
            dateFormat: 'dd.MM.yyyy',
            required: true,
            grid: {
                sm: 3,
                xs: 6,
            },
        },
        wholeDay: {
            testId: 'createNewJobFormWholeDay',
            type: InputType.CheckBox,
            label: tt('newVisitScreen.checkbox.wholeDay'),
            value: false,
            grid: {
                sm: 3,
                xs: 6,
            },
        },
        skipWeekends: {
            testId: 'createNewJobFormSkipWeekends',
            type: InputType.CheckBox,
            label: tt('newVisitScreen.checkbox.skipWeekends'),
            value: false,
            hidden: true,
            grid: {
                sm: 3,
                xs: 6,
            },
        },
        skipOtherDaysCheckbox: {
            testId: 'skipOtherDaysCheckboxTestId',
            type: InputType.CheckBox,
            label: tt('newVisitScreen.checkbox.skipOtherDays'),
            value: false,
            hidden: true,
            grid: {
                sm: 3,
                xs: 6,
            },
        },
        skipOtherDays: {
            className: classes.skipOtherDaysCalendar,
            testId: 'skipOtherDaysTestId',
            datesPickerVariant: 'excludeDays',
            type: InputType.DatesPicker,
            label: '',
            value: [],
            hidden: true,
            grid: {
                sm: 6,
                xs: 12,
            },
        },
        notifications: {
            testId: 'editJobDateAndTimeFormSendNotificationsInput',
            type: InputType.CheckBox,
            label: tt('common.visit.checkbox.sendNotificationsToWorkers'),
            value: true,
        },
    });

    const startDate = inputsTab1.startDate?.value ? DateTime.fromMillis(inputsTab1.startDate.value) : DateTime.now();
    const endDate = inputsTab1.endDate?.value ? DateTime.fromMillis(inputsTab1.endDate.value) : DateTime.now();
    const isSingleDay = startDate.hasSame(endDate, 'day');
    const skipWeekends = inputsTab1.skipWeekends.value;

    useEffect(() => {
        setInputsTab1(prev => {
            const isHidden = isSingleDay || !!repeatingDay;

            return {
                ...prev,
                skipWeekends: {
                    ...prev.skipWeekends,
                    value: isHidden ? false : prev.skipWeekends.value,
                    hidden: isHidden,
                },
                skipOtherDaysCheckbox: {
                    ...prev.skipOtherDaysCheckbox,
                    value: isHidden ? false : prev.skipOtherDaysCheckbox.value,
                    hidden: isHidden,
                },
            };
        });
    }, [isSingleDay, repeatingDay]);

    useEffect(() => {
        if (inputsTab1.startTime.value > inputsTab1.endTime.value) {
            const theStartDate = DateTime.fromMillis(inputsTab1.startDate.value).startOf('day');
            const theEndDate = DateTime.fromMillis(inputsTab1.endDate.value).startOf('day');

            if (theStartDate.toMillis() === theEndDate.toMillis()) {
                setInputsTab1(prev => {
                    return {
                        ...prev,
                        endTime: {
                            ...prev.endTime,
                            value: inputsTab1.startTime.value,
                        },
                    };
                });
            }
        }
    }, [inputsTab1.startTime.value, inputsTab1.startDate.value]);

    useEffect(() => {
        if (inputsTab1.startTime.value > inputsTab1.endTime.value) {
            const theStartDate = DateTime.fromMillis(inputsTab1.startDate.value).startOf('day');
            const theEndDate = DateTime.fromMillis(inputsTab1.endDate.value).startOf('day');

            if (theStartDate.toMillis() === theEndDate.toMillis()) {
                setInputsTab1(prev => {
                    return {
                        ...prev,
                        startTime: {
                            ...prev.startTime,
                            value: inputsTab1.endTime.value,
                        },
                    };
                });
            }
        }
    }, [inputsTab1.endTime.value, inputsTab1.endDate.value]);

    useEffect(() => {
        if (inputsTab1.startDate.value > inputsTab1.endDate.value) {
            setInputsTab1(prev => {
                return {
                    ...prev,
                    endDate: {
                        ...prev.endDate,
                        value: inputsTab1.startDate.value,
                    },
                };
            });
        }
    }, [inputsTab1.startDate.value]);

    useEffect(() => {
        if (inputsTab1.startDate.value > inputsTab1.endDate.value) {
            setInputsTab1(prev => {
                return {
                    ...prev,
                    startDate: {
                        ...prev.startDate,
                        value: inputsTab1.endDate.value,
                    },
                };
            });
        }
    }, [inputsTab1.endDate.value]);

    useEffect(() => {
        setInputsTab1(prev => {
            const isHidden = isSingleDay || !!repeatingDay;

            return {
                ...prev,
                skipOtherDays: {
                    ...prev.skipOtherDays,
                    hidden: isHidden || !inputsTab1.skipOtherDaysCheckbox.value,
                    startDate: startDate,
                    endDate: endDate,
                    skipWeekends: skipWeekends,
                },
            };
        });
    }, [inputsTab1.skipOtherDaysCheckbox.value, inputsTab1.startDate?.value, inputsTab1.endDate?.value, skipWeekends]);

    useEffect(() => {
        setInputsTab1(prev => {
            return {
                ...prev,
                startTime: {
                    ...prev.startTime,
                    hidden: inputsTab1.wholeDay.value,
                },
                endTime: {
                    ...prev.endTime,
                    hidden: inputsTab1.wholeDay.value,
                },
                startDate: {
                    ...prev.startDate,
                    grid: inputsTab1.wholeDay.value ? {
                        sm: 6,
                        xs: 12,
                    } : {
                        sm: 3,
                        xs: 6,
                    },
                },
                endDate: {
                    ...prev.endDate,
                    grid: inputsTab1.wholeDay.value ? {
                        sm: 6,
                        xs: 12,
                    } : {
                        sm: 3,
                        xs: 6,
                    },
                },
            };
        });
    }, [inputsTab1.wholeDay.value]);

    const [inputsTab2, setInputsTab2] = useState<IInputsData>({
        startTime: {
            testId: 'createNewJobFormStartTimeRecurring',
            type: InputType.FormTimePicker,
            label: `${tt('common.startTime')}*`,
            value: DateTime.now().toMillis(),
            required: true,
            grid: {
                sm: 3,
                xs: 6,
            }
        },
        endTime: {
            testId: 'createNewJobFormEndTimeRecurring',
            type: InputType.FormTimePicker,
            label: `${tt('common.endTime')}*`,
            value: DateTime.now().plus({hours: 1}).toMillis(),
            required: true,
            grid: {
                sm: 3,
                xs: 6,
            }
        },
        startDate: {
            testId: 'createNewJobFormStartDateRecurring',
            type: InputType.DatePicker,
            label: `${tt('common.startDate')}*`,
            value: DateTime.now().toMillis(),
            dateFormat: 'dd.MM.yyyy',
            required: true,
            grid: {
                sm: 3,
                xs: 6,
            },
        },
        endDate: {
            testId: 'createNewJobFormEndDateRecurring',
            type: InputType.DatePicker,
            label: `${tt('common.endDate')}*`,
            value: DateTime.now().plus({hours: 1}).toMillis(),
            dateFormat: 'dd.MM.yyyy',
            required: true,
            grid: {
                sm: 3,
                xs: 6,
            },
        },
        frequency: {
            testId: 'createNewJobFormFrequencySelectRecurring',
            type: InputType.Select,
            label: tt('common.frequency'),
            value: VisitRepeating.Daily,
            options: [
                {label: tt('common.daily'), value: VisitRepeating.Daily},
                {label: tt('common.weekly'), value: VisitRepeating.Weekly},
                {label: tt('common.monthly'), value: VisitRepeating.Monthly},
                {label: tt('common.yearly'), value: VisitRepeating.Yearly},
            ],
            required: true,
            grid: {
                sm: 6,
                xs: 12,
            }
        },
        every: {
            testId: 'createNewJobFormEveryRecurring',
            type: InputType.Text,
            numbersOnly: true,
            inputMode: "decimal",
            label: tt('common.every'),
            value: 1,
            hidden: false,
            required: true,
            grid: {
                sm: 6,
                xs: 12,
            },
        },
        weekdayPicker: {
            testId: 'createNewJobFormDaysToggleButtonRecurring',
            type: InputType.FormToggleButtons,
            label: '',
            value: [],
            options: CreateWeekdayOptions(language),
            hidden: true,
            grid: {
                sm: 6,
                xs: 12,
            },
        },
        monthDaysType: {
            testId: 'createNewVisitMonthDaysType',
            type: InputType.Select,
            label: tt('common.monthDaysType'),
            options: [
                {label: tt('common.dayOfMonth'), value: VisitRepeatModifier.DayOfMonth},
                {label: tt('common.dayOfWeek'), value: VisitRepeatModifier.DayOfWeek},
            ],
            value: VisitRepeatModifier.DayOfMonth,
            hidden: true,
            required: true,
            grid: {
                sm: 6,
                xs: 12,
            },
        },
        monthDayPicker: {
            testId: 'monthDayPickerInputTestId',
            type: InputType.FormToggleButtons,
            label: '',
            toggleButtonsVariant: 'calendar',
            value: [],
            options: CreateMonthDayPickerOptions(),
            hidden: true,
            grid: {
                sm: 6,
                xs: 12,
            },
        },
        monthWeekDayPicker: {
            testId: 'monthWeekDayPickerInputTestId',
            type: InputType.FormToggleButtons,
            label: '',
            toggleButtonsVariant: '4weeks',
            value: [],
            options: Create4WeeksOptions(language),
            hidden: true,
            grid: {
                sm: 6,
                xs: 12,
            },
        },
        wholeDay: {
            testId: 'wholeDayInputTestId',
            type: InputType.CheckBox,
            label: tt('newVisitScreen.checkbox.wholeDay'),
            value: false,
            grid: {
                sm: 3,
                xs: 6,
            },
        },
        skipWeekends: {
            testId: 'skipWeekendsInputTestId',
            type: InputType.CheckBox,
            label: tt('newVisitScreen.checkbox.skipWeekends'),
            value: false,
            hidden: true,
            grid: {
                sm: 3,
                xs: 6,
            },
        },
        neverEnding: {
            testId: 'neverEndingInputTestId',
            type: InputType.CheckBox,
            label: tt('newVisitScreen.checkbox.neverEnding'),
            value: false,
            hidden: false,
            grid: {
                sm: 3,
                xs: 6,
            },
        },
        notifications: {
            testId: 'editJobDateAndTimeFormSendNotificationsInput',
            type: InputType.CheckBox,
            label: tt('common.visit.checkbox.sendNotificationsToWorkers'),
            value: true,
        },
    });

    useEffect(() => {
        if (inputsTab2.startTime.value > inputsTab2.endTime.value) {
            const theStartDate = DateTime.fromMillis(inputsTab2.startDate.value).startOf('day');
            const theEndDate = DateTime.fromMillis(inputsTab2.endDate.value).startOf('day');

            if (theStartDate.toMillis() === theEndDate.toMillis()) {
                setInputsTab1(prev => {
                    return {
                        ...prev,
                        endTime: {
                            ...prev.endTime,
                            value: inputsTab2.startTime.value,
                        },
                    };
                });
            }
        }
    }, [inputsTab2.startTime.value, inputsTab2.startDate.value]);

    useEffect(() => {
        if (inputsTab2.startTime.value > inputsTab2.endTime.value) {
            const theStartDate = DateTime.fromMillis(inputsTab2.startDate.value).startOf('day');
            const theEndDate = DateTime.fromMillis(inputsTab2.endDate.value).startOf('day');

            if (theStartDate.toMillis() === theEndDate.toMillis()) {
                setInputsTab1(prev => {
                    return {
                        ...prev,
                        startTime: {
                            ...prev.startTime,
                            value: inputsTab2.endTime.value,
                        },
                    };
                });
            }
        }
    }, [inputsTab2.endTime.value, inputsTab2.endDate.value]);

    useEffect(() => {
        if (inputsTab2.startDate.value > inputsTab2.endDate.value) {
            setInputsTab1(prev => {
                return {
                    ...prev,
                    endDate: {
                        ...prev.endDate,
                        value: inputsTab2.startDate.value,
                    },
                };
            });
        }
    }, [inputsTab2.startDate.value]);

    useEffect(() => {
        if (inputsTab2.startDate.value > inputsTab2.endDate.value) {
            setInputsTab1(prev => {
                return {
                    ...prev,
                    startDate: {
                        ...prev.startDate,
                        value: inputsTab2.endDate.value,
                    },
                };
            });
        }
    }, [inputsTab2.endDate.value]);

    useEffect(() => {
        setInputsTab2(prev => {
            return {
                ...prev,
                startTime: {
                    ...prev.startTime,
                    hidden: inputsTab2.wholeDay.value,
                },
                endTime: {
                    ...prev.endTime,
                    hidden: inputsTab2.wholeDay.value,
                },
                startDate: {
                    ...prev.startDate,
                    grid: inputsTab2.wholeDay.value ? {
                        sm: 6,
                        xs: 12,
                    } : {
                        sm: 3,
                        xs: 6,
                    },
                },
                endDate: {
                    ...prev.endDate,
                    grid: inputsTab2.wholeDay.value ? {
                        sm: 6,
                        xs: 12,
                    } : {
                        sm: 3,
                        xs: 6,
                    },
                },
            };
        });
    }, [inputsTab2.wholeDay.value]);

    const frequency = inputsTab2.frequency.value;
    const selectedMonthDaysType = inputsTab2.monthDaysType.value;
    const theNeverEnding = inputsTab2.neverEnding.value;

    useEffect(() => {
        setInputsTab2(prev => {
            return {
                ...prev,
                endDate: {
                    ...prev.endDate,
                    hidden: theNeverEnding,
                },
                weekdayPicker: {
                    ...prev.weekdayPicker,
                    hidden: frequency !== VisitRepeating.Weekly,
                },
                monthDaysType: {
                    ...prev.monthDaysType,
                    hidden: frequency !== VisitRepeating.Monthly,
                },
                monthDayPicker: {
                    ...prev.monthDayPicker,
                    hidden: frequency !== VisitRepeating.Monthly || selectedMonthDaysType === VisitRepeatModifier.DayOfWeek,
                },
                monthWeekDayPicker: {
                    ...prev.monthWeekDayPicker,
                    hidden: frequency !== VisitRepeating.Monthly || selectedMonthDaysType === VisitRepeatModifier.DayOfMonth,
                },
                skipWeekends: {
                    ...prev.skipWeekends,
                    hidden: frequency !== VisitRepeating.Daily,
                },
            };
        });
    }, [frequency, selectedMonthDaysType, theNeverEnding]);

    const tab1ContentJSX = (
        <Box className={classes.tabContainer}>
            <FormBuilder inputs={inputsTab1} setInputs={setInputsTab1}/>
        </Box>
    );

    const tab2ContentJSX = (
        <Box className={classes.tabContainer}>
            <FormBuilder inputs={inputsTab2} setInputs={setInputsTab2}/>
        </Box>
    );

    useEffect(() => {
        if (data && open) {
            const repeatingDayData = data.repeatingDayData;

            const dateTimes = visitDateTimes(data, repeatingDayData, repeatingDay);

            setInputsTab1(prev => {
                return {
                    ...prev,
                    startDate: {
                        ...prev.startDate,
                        value: dateTimes.start.toMillis(),
                    },
                    endDate: {
                        ...prev.endDate,
                        value: dateTimes.end!.toMillis(),
                    },
                    startTime: {
                        ...prev.startTime,
                        value: dateTimes.startTime.toMillis(),
                    },
                    endTime: {
                        ...prev.endTime,
                        value: dateTimes.endTime.toMillis(),
                    },
                    wholeDay: {
                        ...prev.wholeDay,
                        value: data.wholeDay,
                    },
                    skipWeekends: {
                        ...prev.skipWeekends,
                        value: data.repeatModifier === VisitRepeatModifier.SkipWeekends || false,
                    },
                    skipOtherDaysCheckbox: {
                        ...prev.skipOtherDaysCheckbox,
                        value: (data.skipDays && data.skipDays.length > 0),
                    },
                    skipOtherDays: {
                        ...prev.skipOtherDays,
                        value: data.skipDays || [],
                        startDate: dateTimes.start,
                        endDate: dateTimes.end!,
                        skipWeekends: data.repeatModifier === VisitRepeatModifier.SkipWeekends || false,
                    },
                    notifications: {
                        ...prev.notifications,
                        value: true,
                    },
                };
            });

            let monthDaysTypeValue = VisitRepeatModifier.DayOfMonth;
            if (data && data.repeatModifier) {
                if (data.repeatModifier === VisitRepeatModifier.DayOfMonth || data.repeatModifier === VisitRepeatModifier.DayOfMonthOrLastDay) {
                    monthDaysTypeValue = VisitRepeatModifier.DayOfMonth;
                }
                if (data.repeatModifier === VisitRepeatModifier.DayOfWeek || data.repeatModifier === VisitRepeatModifier.DayOfWeekOrLastDay) {
                    monthDaysTypeValue = VisitRepeatModifier.DayOfWeek;
                }
            }

            setInputsTab2(prev => {
                return {
                    ...prev,
                    startTime: {
                        ...prev.startTime,
                        value: repeatingDayData?.startTime || dateTimes.startTime.toMillis() || DateTime.now().set({
                            hour: 8,
                            minute: 0,
                            second: 0,
                            millisecond: 0
                        }).toMillis(),
                    },
                    endTime: {
                        ...prev.endTime,
                        value: repeatingDayData?.endTime || data.endTime || DateTime.now().set({
                            hour: 9,
                            minute: 0,
                            second: 0,
                            millisecond: 0
                        }).toMillis(),
                    },
                    startDate: {
                        ...prev.startDate,
                        value: repeatingDayData?.startDate || dateTimes.start.toMillis() || DateTime.now().set({
                            hour: 8,
                            minute: 0,
                            second: 0,
                            millisecond: 0
                        }).toMillis(),
                    },
                    endDate: {
                        ...prev.endDate,
                        value: data.repeatEndDate || dateTimes.visitEnd?.toMillis() || repeatingDayData?.endDate || data.endDate || DateTime.now().set({
                            hour: 9,
                            minute: 0,
                            second: 0,
                            millisecond: 0
                        }).toMillis(),
                    },
                    frequency: {
                        ...prev.frequency,
                        value: data.repeating && data.repeating !== VisitRepeating.Never ? data.repeating : VisitRepeating.Daily,
                    },
                    every: {
                        ...prev.every,
                        value: data.every || 1,
                    },
                    weekdayPicker: {
                        ...prev.weekdayPicker,
                        value: data.repeatWeekDays.map(day => day.toString()) || [],
                    },
                    monthDaysType: {
                        ...prev.monthDaysType,
                        value: monthDaysTypeValue,
                    },
                    monthDayPicker: {
                        ...prev.monthDayPicker,
                        value: data.monthDays?.map(day => day.toString()) || [],
                    },
                    monthWeekDayPicker: {
                        ...prev.monthWeekDayPicker,
                        value: data.monthWeekDays || [],
                    },
                    wholeDay: {
                        ...prev.wholeDay,
                        value: repeatingDayData?.wholeDay || data.wholeDay || false,
                    },
                    skipWeekends: {
                        ...prev.skipWeekends,
                        value: data.repeatModifier === VisitRepeatModifier.SkipWeekends || false,
                    },
                    neverEnding: {
                        ...prev.neverEnding,
                        value: !data.repeatEndDate,
                    },
                    notifications: {
                        ...prev.notifications,
                        value: true,
                    },
                };
            });
        }
    }, [data, open]);

    /**
     * Mutate Job details update to BE.
     */
    const handleUpdate = async () => {
        if (((repeats === JobUpdateRepeats.Single && ValidateForm(inputsTab1, setInputsTab1)) || (repeats === JobUpdateRepeats.ThisAndAllFuture && ValidateForm(inputsTab2, setInputsTab2)))) {
            try {
                let startDate: DateTime | undefined;
                let endDate: DateTime | undefined;
                let startTime: DateTime | undefined;
                let endTime: DateTime | undefined;
                let repeating = VisitRepeating.Never;
                let repeatWeekDays: number[] = [];
                let repeatEndDate: number | undefined;
                let repeatModifier: VisitRepeatModifier | undefined;
                let monthDays: number[] | undefined;
                let monthWeekDays: string[] | undefined;
                let wholeDay = false;
                let skipDays: number[] = [];

                let skipWeekends = repeats === JobUpdateRepeats.Single ? inputsTab1.skipWeekends.value : false;
                skipWeekends = repeats === JobUpdateRepeats.ThisAndAllFuture ? inputsTab2.skipWeekends.value : skipWeekends;
                let every = 1;
                every = repeats === JobUpdateRepeats.ThisAndAllFuture ? inputsTab2.every.value : every;
                every = parseInt(`${every}`);

                let notifications = false;

                if (repeats === JobUpdateRepeats.Single) {
                    let start = DateTime.fromMillis(inputsTab1.startDate.value);
                    startTime = DateTime.fromMillis(inputsTab1.startTime.value);
                    start = start.set({hour: startTime.hour, minute: startTime.minute});

                    startDate = start;

                    let end = DateTime.fromMillis(inputsTab1.endDate.value);
                    endTime = DateTime.fromMillis(inputsTab1.endTime.value);
                    end = end.set({hour: endTime.hour, minute: endTime.minute});

                    endDate = end;

                    wholeDay = inputsTab1.wholeDay.value;

                    const startOfDayOfStart = start.startOf('day').toMillis();
                    const endOfDayOfEnd = end.endOf('day').toMillis();
                    skipDays = inputsTab1.skipOtherDaysCheckbox.value ? inputsTab1.skipOtherDays.value
                        .filter((timestamp: number) => timestamp >= startOfDayOfStart && timestamp <= endOfDayOfEnd) : [];

                    if (skipWeekends) {
                        repeatModifier = VisitRepeatModifier.SkipWeekends;
                    }

                    notifications = inputsTab1.notifications.value;

                    if (start > end) {
                        ErrorToast(tt('createJob.dates.error'));
                        return;
                    }
                }
                if (repeats === JobUpdateRepeats.ThisAndAllFuture) {
                    let start = DateTime.fromMillis(inputsTab2.startDate.value);
                    startTime = DateTime.fromMillis(inputsTab2.startTime.value);
                    start = start.set({hour: startTime.hour, minute: startTime.minute});

                    startDate = start;

                    endTime = DateTime.fromMillis(inputsTab2.endTime.value);

                    if (!inputsTab2.neverEnding.value) {
                        let end = DateTime.fromMillis(inputsTab2.endDate.value);
                        endTime = DateTime.fromMillis(inputsTab2.endTime.value);
                        end = end.set({hour: endTime.hour, minute: endTime.minute});

                        repeatEndDate = end.toMillis();
                    }

                    wholeDay = inputsTab2.wholeDay.value;

                    repeating = inputsTab2.frequency.value;

                    repeatWeekDays = inputsTab2.weekdayPicker.value.map((day: string) => parseInt(day));

                    const monthDaysType = inputsTab2.monthDaysType.value;
                    monthDays = inputsTab2.monthDayPicker.value.map((day: string) => parseInt(day));
                    monthWeekDays = inputsTab2.monthWeekDayPicker.value;

                    if (skipWeekends && repeating === VisitRepeating.Daily) {
                        repeatModifier = VisitRepeatModifier.SkipWeekends;
                    }

                    if (repeating === VisitRepeating.Monthly) {
                        if (monthDays && monthDaysType === VisitRepeatModifier.DayOfMonth) {
                            if (monthDays.length > 1) {
                                repeatModifier = monthDays.includes(-1) ? VisitRepeatModifier.DayOfMonthOrLastDay : VisitRepeatModifier.DayOfMonth;
                            } else {
                                repeatModifier = monthDays.includes(-1) ? VisitRepeatModifier.LastDayOfMonth : VisitRepeatModifier.DayOfMonth;
                            }
                        }

                        if (monthWeekDays && monthDaysType === VisitRepeatModifier.DayOfWeek) {
                            repeatModifier = VisitRepeatModifier.DayOfWeek;
                        }
                    }

                    notifications = inputsTab2.notifications.value;

                    if (startTime > endTime) {
                        ErrorToast(tt('createJob.dates.repeating.error'));
                        return;
                    }

                    if (repeating == VisitRepeating.Weekly && repeatWeekDays.length < 1) {
                        ErrorToast(tt('createJob.days.error'));
                        return;
                    }

                    if (repeating == VisitRepeating.Monthly && monthDaysType === VisitRepeatModifier.DayOfMonth && (!monthDays || monthDays.length < 1)) {
                        ErrorToast(tt('createJob.monthDays.error'));
                        return;
                    } else if (repeating == VisitRepeating.Monthly && monthDaysType === VisitRepeatModifier.DayOfWeek && (!monthWeekDays || monthWeekDays.length < 1)) {
                        ErrorToast(tt('createJob.monthWeekDays.error'));
                        return;
                    }
                }

                if (wholeDay) {
                    startDate = startDate?.startOf('day');
                    endDate = endDate?.endOf('day');
                    startTime = startTime?.startOf('day');
                    endTime = endTime?.endOf('day');
                    repeatEndDate = repeatEndDate ? DateTime.fromMillis(repeatEndDate).endOf('day').toMillis() : undefined;
                }

                const updateVariables: UpdateVisitDateTimeMutationVariables = {
                    input: {
                        visitId: visitId,
                        repeating,
                        startDate: startDate ? startDate.toMillis() : undefined,
                        endDate: endDate ? endDate.toMillis() : undefined,
                        startTime: startTime ? startTime.toMillis() : undefined,
                        endTime: endTime ? endTime.toMillis() : undefined,
                        repeatWeekDays,
                        repeatingDay,
                        repeats,
                        repeatEndDate,
                        repeatModifier,
                        every,
                        monthDays: monthDays || [],
                        monthWeekDays: monthWeekDays || [],
                        wholeDay,
                        sendNotifications: notifications,
                        skipDays,
                    },
                };

                const result = await mutateUpdate({variables: updateVariables});

                if (!result.errors) {
                    SuccessToast(tt('jobDateTime.modal.success'));

                    setTimeout(() => {
                        setOpen(false);

                        if (result.data!.updateVisitDateTime.id) {
                            onUpdateJobId({
                                visitId: result.data!.updateVisitDateTime.id,
                                repeatingDay: repeatingDay!,
                            });
                        }
                    }, 1);
                }
            } catch (e) {
                console.error(e);

                ErrorToast(tt('common.mutation.error'));
            }
        }
    };

    const formJSX = repeats === JobUpdateRepeats.Single ? tab1ContentJSX : tab2ContentJSX;

    return (
        <ModalBottomSheet
            blurBackdrop={true}
            modalAboveModals={modalAboveModals}
            open={open}
            setOpen={setOpen}
            hideHeader={true}>
            <Box className={classes.container}>
                <BottomSheetModalAppbar
                    noBorderBottom={true}
                    onClose={() => setOpen(false)}
                    title={tt('common.datesAndTimes')}>
                </BottomSheetModalAppbar>

                {formJSX}

                <Box sx={{pb: 10}}/>

                <Box className={classes.buttonContainer}>
                    <AppButton
                        variant={"contained"}
                        fullWidth={true}
                        onClick={handleUpdate}
                        disabled={loading}
                        isLoading={updateLoading}
                    >
                        {tt('common.save')}
                    </AppButton>
                </Box>
            </Box>
        </ModalBottomSheet>
    );
}
