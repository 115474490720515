import {IInputsData} from "../../form/FormBuilder";
import {makeStyles} from "tss-react/mui";
import {Box, Theme, Typography} from "@mui/material";
import {kAppColors} from "../../../../styles/AppThemeProcessor";
import {tt} from "../../../../core/Localization";
import React, {useMemo} from "react";
import {NewVisitEvents} from "../../../screens/jobs/NewJobOrVisitScreen";
import {DateTime} from "luxon";

const useStyles = makeStyles()((theme: Theme) => ({
    dashedDivider: {
        borderBottom: `1px dashed ${kAppColors.border(theme.palette.mode === "dark")}`,
        marginLeft: -16,
        marginRight: -16,
    },
    container: {
        display: "flex",
        overflow: "auto",
        maxWidth: '100%',
    },
    columnItem: {
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingRight: 26,
        paddingLeft: 26,
        paddingTop: 8,
        paddingBottom: 8,
    },
    title: {
        fontSize: 12,
        fontWeight: 700,
        textTransform: "uppercase",
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        marginBottom: 8,
        textAlign: "center",
    },
    description: {
        fontSize: 16,
        fontWeight: 500,
        textAlign: "center",
    },
}));

export interface ITotalVisitsSectionProps {
    selectedTab: string;
    inputs: IInputsData;
}

export default function VisitsSummarySection(props: ITotalVisitsSectionProps) {
    const {selectedTab, inputs} = props;

    const {cx, classes} = useStyles();

    const frequency = inputs.frequency?.value;
    let isSingle = true;
    let neverEnding = false;
    if (frequency) {
        isSingle = !(selectedTab !== '0' || DateTime.fromMillis(inputs.endDate.value).startOf('day') > DateTime.fromMillis(inputs.startDate.value).startOf('day'));
        neverEnding = inputs.neverEnding.value;
    }

    const events = useMemo(() => {
        if (frequency) {
            if (neverEnding && !isSingle) {
                return [];
            } else {
                return NewVisitEvents(
                    selectedTab,
                    frequency,
                    inputs,
                    undefined,
                );
            }
        }

        return [];
    }, [frequency, inputs]);

    const startDate = inputs.startDate?.value ? DateTime.fromMillis(inputs.startDate.value) : undefined;
    const startTime = inputs.startTime?.value ? DateTime.fromMillis(inputs.startTime.value) : undefined;

    const startJSX = inputs.scheduleLater?.value ? (
        <Box className={classes.columnItem}>
            <Typography className={classes.title}>{tt('visits.summarySection.label.firstVisit')}</Typography>
            <Typography className={classes.description}>{tt('visits.value.notAvailable')}</Typography>
        </Box>
    ) : events.length > 0 && events[0]?.startDateOfDynamic ? (
        <Box className={classes.columnItem}>
            <Typography className={classes.title}>{tt('visits.summarySection.label.firstVisit')}</Typography>

            <Typography className={classes.description}>
                {events[0]!.startDateOfDynamic?.toFormat('d.M.yyyy') || ''}
            </Typography>
        </Box>
    ) : (
        <Box className={classes.columnItem}>
            <Typography className={classes.title}>{tt('visits.summarySection.label.firstVisit')}</Typography>

            <Typography className={classes.description}>
                {startDate && startTime ? startDate.set({
                    hour: startTime.hour,
                    minute: startTime.minute,
                }).toFormat('d.M.yyyy') : ''}
            </Typography>
        </Box>
    );

    const endDate = inputs.endDate?.value ? DateTime.fromMillis(inputs.endDate.value) : undefined;
    const endTime = inputs.endTime?.value ? DateTime.fromMillis(inputs.endTime.value) : undefined;

    const lastJSX = isSingle ? (
        <Box className={classes.columnItem}>
            <Typography className={classes.title}>{tt('visits.summarySection.label.lastVisit')}</Typography>
            <Typography className={classes.description}>
                {endDate && endTime ? endDate.set({
                    hour: endTime.hour,
                    minute: endTime.minute,
                }).toFormat('d.M.yyyy') : ''}
            </Typography>
        </Box>
    ) : !neverEnding && events.length > 0 ? (
        <Box className={classes.columnItem}>
            <Typography className={classes.title}>{tt('visits.summarySection.label.lastVisit')}</Typography>
            <Typography className={classes.description}>
                {events[events.length - 1]!.startDateOfDynamic?.toFormat('d.M.yyyy') || ''}
            </Typography>
        </Box>
    ) : (
        <Box className={classes.columnItem}>
            <Typography className={classes.title}>{tt('visits.summarySection.label.lastVisit')}</Typography>
            <Typography className={classes.description}>{tt('visits.value.notAvailable')}</Typography>
        </Box>
    );

    const countJSX = isSingle ? (
        <Box className={classes.columnItem}>
            <Typography className={classes.title}>{tt('visits.summarySection.label.totalVisits')}</Typography>
            <Typography className={classes.description}>1</Typography>
        </Box>
    ) : !neverEnding && events.length > 0 ? (
        <Box className={classes.columnItem}>
            <Typography className={classes.title}>{tt('visits.summarySection.label.totalVisits')}</Typography>
            <Typography className={classes.description}>
                {events.length}
            </Typography>
        </Box>
    ) : (
        <Box className={classes.columnItem}>
            <Typography className={classes.title}>{tt('visits.summarySection.label.totalVisits')}</Typography>
            <Typography className={classes.description}>{tt('visits.value.notAvailable')}</Typography>
        </Box>
    );

    return (
        <>
            <Box className={classes.dashedDivider}/>
            <Box pb={1}/>
            <Box className={cx('styledScrollbar', classes.container)}>
                {startJSX}

                {lastJSX}

                {countJSX}
            </Box>
            <Box pb={1}/>
        </>
    );
}
