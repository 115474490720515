import React, {Dispatch, SetStateAction, useContext, useEffect, useMemo, useState} from "react";
import {AppContext} from "../../../App";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import ScreenContent from "../../components/screens/ScreenContent";
import AppPaper from "../../components/paper/AppPaper";
import PaperAppbar from "../../components/paper/PaperAppbar";
import {tt} from "../../../core/Localization";
import AppChip from "../../components/chips/AppChip";
import {AddOutlined} from "@mui/icons-material";
import {Box, InputAdornment} from "@mui/material";
import SearchIcon from "../../../icons/SearchIcon";
import {kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import CreateClientModal from "../../components/clients/CreateClientModal";
import FormBuilder, {IInputsData, InputType} from "../../components/form/FormBuilder";
import {
    ClientJoinedLocationsResponse,
    ClientJoinedLocationsResponsePage,
    GetClientsInput
} from "../../../generated/graphql/graphql";
import {AppDataContext, SetAppBreadcrumbs} from "../../../AppData";
import ListShimmer from "../../components/shimmers/ListShimmer";
import EmptyListText from "../../components/textComponents/EmptyListText";
import AppListItem from "../../components/listItems/AppListItem";
import {useNavigate} from "react-router-dom";
import {clientDetailRoute} from "./ClientDetailScreen";
import Icons8Company from "../../../icons/Icons8Company";
import UserIcon from "../../../icons/UserIcon";
import {UserAddressSingle, UserFullName} from "../../../service/UserService";
import PeopleIcon from "../../../icons/PeopleIcon";
import IBreadcrumb from "../../../model/Breadcrumb";
import {contactPersonDetailRoute} from "../contactPerson/ContactPersonDetailScreen";
import {locationDetailRoute} from "../locations/LocationDetailScreen";
import {LocationNameDisplay} from "../../../service/LocationService";
import {PlaceDetailRoute} from "../places/PlaceDetailScreen";
import {processQueryError} from "../../../service/ErrorService";
import {kActionCreate, kPermissionsClients, kTopicClients} from "../../../core/constants";
import PermissionValid from "../../components/permissions/PermissionValid";
import {RestApiClientContext} from "../../../core/RestApiProvider";

export const kClientsRoute = '/clients';
export const kClientsBreadcrumb: IBreadcrumb = {
    key: 'clients',
    icon: <PeopleIcon/>,
    tooltip: tt('common.clients'),
    route: kClientsRoute,
};

export default function ClientsScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;

    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId, clientsFullTextSearch, setClientsFullTextSearch} = appDataContext;

    const [createModal, setCreateModal] = useState<boolean>(false);

    useEffect(() => {
        setTitle(tt('clients.screen.title'));

        SetAppBreadcrumbs(appDataContext, [kClientsBreadcrumb]);

        setInputs(prev => {
            return {
                ...prev,
                search: {
                    ...prev.search,
                    value: '',
                },
            };
        })
    }, []);

    const [inputs, setInputs] = useState<IInputsData>({
        search: {
            type: InputType.Text,
            label: tt('clients.screen.searchFor'),
            placeholder: tt('clients.screen.search.placeholder'),
            hideLabel: true,
            inputVariant: 'standard',
            extraStyle: 'thin',
            value: clientsFullTextSearch || '',
            required: true,
            isClearable: true,
            innerPrefixJSX: (
                <InputAdornment position={"start"}>
                    <SearchIcon/>
                </InputAdornment>
            ),
        },
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<ClientJoinedLocationsResponsePage | NullOrUndefined>();
    useEffect(() => {
        if (companyId) {
            const subscription = subscribe(
                kTopicClients,
                {
                    uri: '/client/search-joined-locations',
                    params: {
                        companyId,
                        search: inputs.search.value.trim(),
                    } as GetClientsInput,
                    setLoading,
                    onData: setData,
                    onError: (error) => processQueryError(appDataContext, error),
                },
                (notifications) => true,
            );

            return () => subscription.cancel();
        } else {
            setData(null);
        }
    }, [companyId, inputs.search.value]);

    useEffect(() => {
        if (inputs.search.value !== clientsFullTextSearch) {
            setClientsFullTextSearch(inputs.search.value);
        }
    }, [inputs]);

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                loading={loading}
                data={data}
                setCreateModal={setCreateModal}
                isMobile={isMobile}
                search={inputs.search.value.trim()}
                inputs={inputs}
                setInputs={setInputs}
            />
        );
    }

    return (
        <>
            <ResponsiveContainer
                smallPhoneScreen={bodyJSX(true)}
                largePhoneScreen={bodyJSX(true)}
                tabletScreen={bodyJSX()}
                smallDesktopScreen={bodyJSX()}
                largeDesktopScreen={bodyJSX()}
                extraLargeDesktopScreen={bodyJSX()}/>

            <CreateClientModal open={createModal} setOpen={setCreateModal}/>
        </>
    );
}

interface IBodyProps {
    isMobile?: boolean;
    setCreateModal: Dispatch<SetStateAction<boolean>>;
    loading: boolean;
    data: ClientJoinedLocationsResponsePage | NullOrUndefined;
    search: string;
    inputs: IInputsData;
    setInputs: Dispatch<SetStateAction<IInputsData>>;
}

function Body(props: IBodyProps) {
    const {isMobile, loading, data, setCreateModal, search, inputs, setInputs} = props;

    const content = useMemo(() => {
        return loading && !data ?
            <ListShimmer items={2}/> : data && data.content.length > 0 ?
                [...data.content]
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(client => (
                        <ClientListItem key={client.id} data={client} search={search}/>
                    ))
                : <EmptyListText text={tt('clients.screen.emptyListMessage')}/>;
    }, [search, data, loading]);

    return (
        <ScreenContent
            showBreadCrumb={false}
            appBar={!isMobile}
            noContentPadding={isMobile}
            navigationDrawer={!isMobile}
            bottomBar={isMobile}
            centerHorizontally={true}>
            <AppPaper sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}>
                <PaperAppbar
                    noMarginBottom={true}
                    isMobile={isMobile}
                    title={tt('common.clients')}
                    hideBackButton={true}
                    bottomContent={
                        <Box pt={1}><FormBuilder inputs={inputs} setInputs={setInputs}/></Box>
                    }
                >
                    {isMobile ? <></> : (
                        <PermissionValid
                            permission={kPermissionsClients}
                            requiredPermissions={[kActionCreate]}
                        >
                            <AppChip
                                onClick={() => {
                                    setCreateModal(true);
                                }}
                                key={'addClientChip'} label={tt('common.newClient')}
                                icon={<AddOutlined/>}/>
                        </PermissionValid>
                    )}
                </PaperAppbar>
                {content}
            </AppPaper>
        </ScreenContent>
    );
}

interface IClientListItemsProps {
    data: ClientJoinedLocationsResponse;
    search: string;
}

/**
 * Component for Client list item.
 */
function ClientListItem(props: IClientListItemsProps) {
    const {data, search} = props;
    const theSearch = search.toLowerCase();

    const navigate = useNavigate();

    let contactId: number | undefined;
    let locationId: number | undefined;
    let placeId: number | undefined;
    const breadcrumbs: React.ReactNode[] = [];

    if (search && data.contacts.length > 0) {
        for (const contactOf of data.contacts) {
            if (UserFullName(contactOf.name, contactOf.surname).toLowerCase().includes(theSearch)) {
                if (contactOf.locationPlaces.length > 0) {
                    let theLocation = undefined;
                    for (const locationOf of contactOf.locations) {
                        if (locationOf.id === contactOf.locationPlaces[0].locationId) {
                            theLocation = locationOf;
                            break;
                        }
                    }

                    if (theLocation) {
                        breadcrumbs.push(<span
                            key="contact-location">{LocationNameDisplay(theLocation.name, theLocation.type)}</span>);

                        breadcrumbs.push(' | ');
                    }

                    breadcrumbs.push(<span key="contact-place">{contactOf.locationPlaces[0].name}</span>);

                    breadcrumbs.push(' | ');
                } else if (contactOf.locations.length > 0) {
                    breadcrumbs.push(<span
                        key="location">{contactOf.locations[0].name || UserAddressSingle(contactOf.locations[0].address)}</span>);

                    breadcrumbs.push(' | ');
                }

                breadcrumbs.push(<span key="contact">{UserFullName(contactOf.name, contactOf.surname)}</span>);

                contactId = contactOf.id;

                break;
            }
        }
    }

    if (search && breadcrumbs.length === 0 && data.locations.length > 0) {
        for (const locationOf of data.locations) {
            if (locationOf.name && locationOf.name.toLowerCase().includes(theSearch)) {
                breadcrumbs.push(<span key="location">{locationOf.name}</span>);

                locationId = locationOf.id;

                break;
            } else if (locationOf.addressSingleLine.toLowerCase().includes(theSearch)) {
                breadcrumbs.push(<span key="location">{locationOf.addressSingleLine}</span>);

                locationId = locationOf.id;

                break;
            }
        }
    }

    if (search && breadcrumbs.length === 0 && data.locationPlaces.length > 0) {
        for (const placeOf of data.locationPlaces) {
            if (placeOf.name.toLowerCase().includes(theSearch)) {
                let theLocation = undefined;
                for (const locationOf of data.locations) {
                    if (locationOf.id === placeOf.locationId) {
                        theLocation = locationOf;
                        break;
                    }
                }

                if (theLocation) {
                    breadcrumbs.push(<span
                        key="place-location">{LocationNameDisplay(theLocation.name, theLocation.type)}</span>);

                    breadcrumbs.push(' | ');

                    locationId = theLocation.id;
                }

                breadcrumbs.push(<span key="place">{placeOf.name}</span>);

                placeId = placeOf.id;

                break;
            }
        }
    }

    /**
     * Navigate to correct detail screen depending on which object was found.
     */
    const ToDetail = () => {
        if (contactId) {
            navigate(contactPersonDetailRoute(data.id, contactId));
        } else if (locationId && placeId) {
            navigate(PlaceDetailRoute(data.id, locationId, placeId));
        } else if (locationId) {
            navigate(locationDetailRoute(data.id, locationId));
        } else {
            navigate(clientDetailRoute(data.id));
        }
    };

    return (
        <AppListItem
            customAvatarInCircle={data.isCompany ? <Icons8Company/> : <UserIcon/>}
            description={data.isCompany ? tt('common.companyClient') : undefined}
            title={data.name}
            secondLine={
                breadcrumbs.length ? (
                    <>
                        {breadcrumbs}
                    </>
                ) : null}
            variant={"smaller-title"}
            onClick={ToDetail}
        />
    );
}
