import {useContext, useEffect} from "react";
import {AppContext} from "../../../App";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import ScreenContent from "../../components/screens/ScreenContent";
import {tt} from "../../../core/Localization";
import AppPaper from "../../components/paper/AppPaper";
import PaperAppbar from "../../components/paper/PaperAppbar";
import {Box, Grid, Link, Theme, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import DarkModeMenu from "../../components/menus/DarkModeMenu";
import packageJson from '../../../../package.json';
import LanguagesMenu from "../../components/menus/LanguagesMenu";
import {kAppColors, kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import {kMobileMenuRoute} from "../mobile/MobileMenuScreen";
import {FlavorByEnvironment} from "../../../flavor-config";
import AppListItem from "../../components/listItems/AppListItem";
import CalendarIcon from "../../../icons/CalendarIcon";
import {useNavigate} from "react-router-dom";
import {kCalendarSettingsRoute} from "./CalendarSettingsScreen";
import ClockIcon from "../../../icons/ClockIcon";
import {kTimesheetsSettingsRoute} from "./TimesheetsSettingsScreen";
import {kNotificationsSettingsRoute} from "./NotificationsSettingsScreen";
import Icons8BellLight from "../../../icons/Icons8BellLight";
import PeopleIcon from "../../../icons/PeopleIcon";
import {kClientsSettingsRoute} from "./ClientsSettingsScreen";

export const kSettingsRoute = '/settings';

const useStyles = makeStyles()((theme: Theme) => ({
    icon: {
        color: kAppColors.background.default(theme.palette.mode === "dark"),
    },
    textStyle: {
        color: kAppColors.grey.link,
        marginBottom: 12,
        fontWeight: 500,
        lineHeight: 18 / 14,
    },
    dotStyle: {
        color: kAppColors.grey.link,
        fontWeight: 500,
        marginLeft: 14,
        marginRight: 14,
    }
}));

export default function SettingsScreen() {
    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    useEffect(() => {
        setTitle(tt('settings.screen.title'));
    }, []);

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                isMobile={isMobile}
            />
        );
    }

    return (
        <ResponsiveContainer
            smallPhoneScreen={bodyJSX(true)}
            largePhoneScreen={bodyJSX(true)}
            tabletScreen={bodyJSX()}
            smallDesktopScreen={bodyJSX()}
            largeDesktopScreen={bodyJSX()}
            extraLargeDesktopScreen={bodyJSX()}/>
    );
}

interface IBodyProps {
    isMobile?: boolean;
}

function Body(props: IBodyProps) {
    const {isMobile} = props;

    const {classes, cx} = useStyles();
    const navigate = useNavigate();

    const appVersionAndTermsJSX = isMobile ? (
        <>
            <Box pb={6}></Box>
            <Typography align={"center"}
                        className={classes.textStyle}
            >
                {tt('settings.screen.versionOfApplication')} {packageJson.version}
            </Typography>

            <Grid justifyContent={"center"}>
                <Box sx={{textAlign: 'center', mb: 1}}>
                    <Link
                        href={FlavorByEnvironment()!.termsAndConditions}
                        target={'_blank'}
                    >{tt('common.termsAndConditions')}</Link></Box>
                <Box sx={{textAlign: 'center', mb: 2}}>
                    <Link
                        target={'_blank'}
                        href={FlavorByEnvironment()!.privacyPolicy}
                    >{tt('common.privacyPolicy')}</Link></Box>
            </Grid>
        </>
    ) : (
        <>
            <Typography align={"center"}
                        className={classes.textStyle}
            >
                {tt('settings.screen.versionOfApplication')} {packageJson.version}
            </Typography>

            <Grid container justifyContent={"center"}>
                <Box sx={{textAlign: 'center', mb: 1}}>
                    <Link
                        href={FlavorByEnvironment()!.termsAndConditions}
                        target={'_blank'}
                    >{tt('common.termsAndConditions')}</Link></Box>
                <Typography className={classes.dotStyle}>·</Typography>
                <Box sx={{textAlign: 'center', mb: 2}}>
                    <Link
                        target={'_blank'}
                        href={FlavorByEnvironment()!.privacyPolicy}
                    >{tt('common.privacyPolicy')}</Link></Box>
            </Grid>
        </>
    );

    return (
        <ScreenContent appBar={!isMobile} noContentPadding={isMobile} navigationDrawer={!isMobile} bottomBar={isMobile}
                       centerHorizontally={true}>
            <AppPaper
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}>
                <PaperAppbar isMobile={isMobile} title={tt('settings.screen.title')} hideBackButton={!isMobile}
                             backRoute={isMobile ? kMobileMenuRoute : undefined}
                             children={[<LanguagesMenu key={'settingsLanguageMenu'}/>,
                                 <DarkModeMenu key={'settingsDarkmodeToggle'}/>]}/>
                <AppListItem
                    title={tt('common.calendar')}
                    variant={"smaller-title"}
                    customAvatar={<CalendarIcon/>}
                    onClick={() => navigate(kCalendarSettingsRoute)}
                />
                <AppListItem
                    title={tt('common.clients')}
                    variant={"smaller-title"}
                    customAvatar={<PeopleIcon/>}
                    onClick={() => navigate(kClientsSettingsRoute)}
                />
                <AppListItem
                    title={tt('common.timesheets')}
                    variant={"smaller-title"}
                    customAvatar={<ClockIcon/>}
                    onClick={() => navigate(kTimesheetsSettingsRoute)}
                />
                <AppListItem
                    title={tt('common.notifications')}
                    variant={"smaller-title"}
                    customAvatar={<Icons8BellLight/>}
                    onClick={() => navigate(kNotificationsSettingsRoute)}
                />
                <Box pb={2}/>
                {appVersionAndTermsJSX}
            </AppPaper>
        </ScreenContent>
    );
}
