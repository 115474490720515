import {makeStyles} from "tss-react/mui";
import {Box, Theme, Typography} from "@mui/material";
import {kAppColors} from "../../../../styles/AppThemeProcessor";
import React from "react";
import {kAvailableCurrencies} from "../../../../service/CompanyService";

export const useStyles = makeStyles()((theme: Theme) => ({
    text: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: 1.3,
        color: kAppColors.text.primary(theme.palette.mode === "dark"),
        display: "block",
    },
    container: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 13,
        paddingBottom: 13,
        display: "flex",
        alignItems: "center",
        'span, div': {
            marginRight: 16,
        }
    },
    description: {
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        fontWeight: 500,
        fontSize: 14,
        lineHeight: 18 / 14,
    }
}));


export interface ICurrencyRowProps {
    currency: 'CZK' | 'EUR' | 'USD';
}

export default function CurrencyRow(props: ICurrencyRowProps) {
    const {currency} = props;
    const {classes, cx} = useStyles();

    let title;
    let iconJSX;

    switch (currency) {
        case kAvailableCurrencies[0].value:
            iconJSX = kAvailableCurrencies[0].icon;
            title = kAvailableCurrencies[0].labelFull;
            break;
        case kAvailableCurrencies[1].value:
            iconJSX = kAvailableCurrencies[1].icon;
            title = kAvailableCurrencies[1].labelFull;
            break;
        case kAvailableCurrencies[2].value:
            iconJSX = kAvailableCurrencies[2].icon;
            title = kAvailableCurrencies[2].labelFull;
            break;
    }

    return (
        <Box className={classes.container}>
            {iconJSX}
            <Typography className={classes.text}>{title}</Typography>
        </Box>
    );
}