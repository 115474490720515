import {Box, Divider, Theme, Typography, useTheme} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../../form/FormBuilder";
import {EmployeeJoinedUserResponse, ItemPaymentType, VisitStatus,} from "../../../../generated/graphql/graphql";
import {kAppColors} from "../../../../styles/AppThemeProcessor";
import {DateTime} from "luxon";
import {getTimesheetIcon, getTimesheetTypeTitle} from "../../../../service/TimesheetService";
import HeadlineWithButton from "../../../screenSections/detailListPreviewSection/HeadlineWithButton";
import DeleteIcon from "../../../../icons/DeleteIcon";
import AppIconButton from "../../buttons/AppIconButton";
import {makeStyles} from "tss-react/mui";
import {UserFullName} from "../../../../service/UserService";
import {tt} from "../../../../core/Localization";
import {AppDataContext} from "../../../../AppData";
import VisitStatusChip from "../../chips/VisitStatusChip";
import {ITimesheetData} from "./WorkerTimesheetModalBottomSheet";
import PencilIcon from "../../../../icons/PencilIcon";
import AppChip from "../../chips/AppChip";
import {
    calculateTimesheets,
    distanceDisplay,
    HoursMinutesDisplay,
    HoursMinutesDisplayRaw,
    ICalculateTimesheetsResult,
    PriceDisplay,
    StartEndHoursDisplay
} from "../../../../service/CompanyService";
import CloseIcon from "../../../../icons/CloseIcon";
import TimesheetsTotalSection from "../../timesheets/TimesheetsTotalSection";
import CustomStatusLikeChip from "../../chips/CustomStatusLikeChip";
import {getVisitStatusBackgroundColor, getVisitStatusTextColor} from "../../../../service/VisitService";
import PermissionValid from "../../permissions/PermissionValid";
import {kActionDelete, kActionUpdate, kPermissionsTimesheets} from "../../../../core/constants";
import {userCanApproveByRoleCascade} from "../../../../service/PrivilegesService";

export const useStyles = makeStyles()((theme: Theme) => ({
    deleteIcon: {
        color: kAppColors.red.confirmButton,
    },
    inputAlignEnd: {
        'input': {
            textAlign: "end",
        }
    },
    switchSectionContainer: {
        width: '100%',
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 8,
        '.MuiGrid-root': {
            padding: 0,
            flexBasis: 0,
            width: "auto",
            marginLeft: "auto",
        }
    },
    switchInputContainer: {
        marginLeft: "auto",
    },
    headerIconsContainer: {
        display: "flex",
        alignItems: "center",
    },
    saveChip: {
        height: 30,
    },
    chipsContainer: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "start",
        marginBottom: -8,
        '.MuiChip-root, statusChip': {
            marginBottom: 8,
        }
    },
    footerText: {
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        fontSize: 12,
        fontWeight: 600
    },
    totalAmount: {
        textAlign: "end",
        fontSize: 18,
        paddingTop: 16,
        paddingBottom: 16,
        marginRight: 16,
        fontWeight: 700,
    },
    dottedBorder: {
        marginRight: -16,
        marginLeft: -16,
        height: 1,
        background: `linear-gradient(to right, transparent 33%, ${kAppColors.border(theme.palette.mode === "dark")} 0%) bottom/12px 1px repeat-x`,
    },
    headlineTitleContainer: {
        display: "flex",
        alignItems: "center",
        marginRight: 'auto',
        p: {
            fontSize: 12,
            fontWeight: 600,
            color: kAppColors.text.secondary(theme.palette.mode === "dark"),
            textTransform: "uppercase",
        },
        svg: {
            color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        }
    }
}));

export interface ITimesheetModalSectionProps {
    isNewJobCreation?: boolean;
    variant: 'new' | 'edit';
    startEditMode?: boolean;
    data: ITimesheetData;
    employee?: EmployeeJoinedUserResponse;
    createdByEmployee?: EmployeeJoinedUserResponse;
    onDelete: (uuid: string) => void;
    onUpdate: (uuid: string, data: Partial<ITimesheetData>) => void;
    canOnlyApprove?: boolean;
}

export default function TimesheetModalSection(props: ITimesheetModalSectionProps) {
    const {
        isNewJobCreation,
        variant,
        startEditMode,
        data,
        employee,
        createdByEmployee,
        onDelete,
        onUpdate,
        canOnlyApprove,
    } = props;

    const {classes} = useStyles();

    const appDataContext = useContext(AppDataContext);
    const {currency, language, darkMode, employeePermissionsMap, companyEmployeeRole} = appDataContext;
    const theme = useTheme();

    const [isNew, setIsNew] = useState<boolean>(true);
    const [editMode, setEditMode] = useState<boolean>(startEditMode || false);

    const theEditMode = editMode;
    const [editModeStats, setEditModeStats] = useState<ICalculateTimesheetsResult | null>(null);

    const [switchInput, setSwitchInput] = useState<IInputsData>({
        approveTimesheet: {
            testId: 'timesheetApprovalSwitch',
            type: InputType.Switch,
            label: tt('timesheetModal.approvalSwitch.approved'),
            value: false,
            switchVariant: "Android12Switch",
            onSwitch: (value) => {
                if (canOnlyApprove || !editMode) {
                    onUpdate(data.uuid, {
                        approved: value,
                    });
                }
            },
        },
    });
    const [inputs, setInputs] = useState<IInputsData>({});

    const hourRateHelperText = employee?.hourRate ? tt('timesheetModal.hourRate.helperText')
        .replace('{hourRate}', PriceDisplay(employee!.hourRate!, currency, language)) : undefined;
    const distanceRateHelperText = employee?.distanceRate ? tt('timesheetModal.distanceRate.helperText')
        .replace('{distanceRate}', PriceDisplay(employee!.distanceRate!, currency, language)) : undefined;

    useEffect(() => {
        setSwitchInput(prev => ({
            ...prev,
            approveTimesheet: {
                ...prev.approveTimesheet,
                value: data.approved,
            },
        }));
    }, [data]);

    useEffect(() => {
        setSwitchInput(prev => ({
            ...prev,
            approveTimesheet: {
                ...prev.approveTimesheet,
                onSwitch: (value) => {
                    if (canOnlyApprove || !editMode) {
                        onUpdate(data.uuid, {
                            approved: value,
                        });
                    }
                },
            },
        }));
    }, [variant, canOnlyApprove, editMode]);

    function setForms(updateSwitch?: boolean) {
        if (updateSwitch) {
            setSwitchInput(prev => ({
                ...prev,
                approveTimesheet: {
                    ...prev.approveTimesheet,
                    value: data.approved,
                },
            }));
        }

        const theHourRate = data.hourRate || 0;
        const theDistanceRate = data.distanceRate || 0;

        switch (data.paymentType) {
            case ItemPaymentType.Hourly:
            case ItemPaymentType.Extra:
            case ItemPaymentType.TravelHourly:
                setInputs(prev => {
                    return {
                        ...prev,
                        hours: {
                            testId: 'workerTimesheetModalHoursInput',
                            type: InputType.Select,
                            label: `${tt('workerTimesheetForm.hoursSelect.label')}`,
                            options: [
                                {label: `0 ${tt('common.hoursAbbreviation')}`, value: '0'},
                                {label: `1 ${tt('common.hoursAbbreviation')}`, value: '1'},
                                {label: `2 ${tt('common.hoursAbbreviation')}`, value: '2'},
                                {label: `3 ${tt('common.hoursAbbreviation')}`, value: '3'},
                                {label: `4 ${tt('common.hoursAbbreviation')}`, value: '4'},
                                {label: `5 ${tt('common.hoursAbbreviation')}`, value: '5'},
                                {label: `6 ${tt('common.hoursAbbreviation')}`, value: '6'},
                                {label: `7 ${tt('common.hoursAbbreviation')}`, value: '7'},
                                {label: `8 ${tt('common.hoursAbbreviation')}`, value: '8'},
                                {label: `9 ${tt('common.hoursAbbreviation')}`, value: '9'},
                                {label: `10 ${tt('common.hoursAbbreviation')}`, value: '10'},
                                {label: `11 ${tt('common.hoursAbbreviation')}`, value: '11'},
                                {label: `12 ${tt('common.hoursAbbreviation')}`, value: '12'},
                                {label: `13 ${tt('common.hoursAbbreviation')}`, value: '13'},
                                {label: `14 ${tt('common.hoursAbbreviation')}`, value: '14'},
                            ],
                            value: data.hours?.toString() || '0',
                            grid: {
                                sm: 4,
                                xs: 6,
                            },
                        },
                        minutes: {
                            testId: 'workerTimesheetModalMinutesInput',
                            type: InputType.Select,
                            label: `${tt('workerTimesheetForm.minutesSelect.label')}`,
                            options: [
                                {label: `0 ${tt('common.minutesAbbreviation')}`, value: '0'},
                                {label: `5 ${tt('common.minutesAbbreviation')}`, value: '5'},
                                {label: `10 ${tt('common.minutesAbbreviation')}`, value: '10'},
                                {label: `15 ${tt('common.minutesAbbreviation')}`, value: '15'},
                                {label: `20 ${tt('common.minutesAbbreviation')}`, value: '20'},
                                {label: `25 ${tt('common.minutesAbbreviation')}`, value: '25'},
                                {label: `30 ${tt('common.minutesAbbreviation')}`, value: '30'},
                                {label: `35 ${tt('common.minutesAbbreviation')}`, value: '35'},
                                {label: `40 ${tt('common.minutesAbbreviation')}`, value: '40'},
                                {label: `45 ${tt('common.minutesAbbreviation')}`, value: '45'},
                                {label: `50 ${tt('common.minutesAbbreviation')}`, value: '50'},
                                {label: `55 ${tt('common.minutesAbbreviation')}`, value: '55'},
                            ],
                            value: data.minutes?.toString() || '0',
                            grid: {
                                sm: 4,
                                xs: 6,
                            },
                        },
                        hourRate: {
                            testId: 'workerTimesheetModalHourRateInput',
                            className: classes.inputAlignEnd,
                            type: InputType.ButtonTextField,
                            toggleButtonText: tt('common.hourRate'),
                            numbersOnly: true,
                            label: tt('common.hourRate'),
                            helperText: hourRateHelperText,
                            value: theHourRate > 0 ? theHourRate.toString() : '',
                            required: false,
                            inputMode: "decimal",
                            suffixJSX: () => <Typography
                                sx={{
                                    mt: 2.5,
                                    fontSize: 14,
                                    color: kAppColors.text.primary(theme.palette.mode === 'dark'),
                                }}>{currency}</Typography>,
                            grid: {
                                sm: 4,
                                xs: 12,
                            },
                        },
                        distance: {
                            testId: 'timesheetModalDistance',
                            className: classes.inputAlignEnd,
                            type: InputType.ButtonTextField,
                            changeThisToCloseInput: data.distance?.toString() == '0' ? 1 : 0,
                            toggleButtonText: tt('timesheetModal.distance.label'),
                            inputMode: "decimal",
                            numbersOnly: true,
                            label: `${tt('timesheetModal.distance.label')}`,
                            value: data.distance?.toString() || '',
                            suffixJSX: () => <Typography
                                sx={{
                                    mt: 2.5,
                                    fontSize: 14,
                                    color: kAppColors.text.primary(theme.palette.mode === 'dark'),
                                }}>{tt("distance.kilometers.short")}</Typography>,
                            required: false,
                            hidden: data.paymentType !== ItemPaymentType.TravelHourly,
                            grid: {
                                sm: 4,
                                xs: 6,
                            },
                        },
                    }
                });
                break;
            case ItemPaymentType.Fixed:
            case ItemPaymentType.TravelFixed:
                setInputs(prev => {
                    return {
                        ...prev,
                        fixedPrice: {
                            testId: 'workerTimesheetModalFixedPriceInput',
                            className: classes.inputAlignEnd,
                            type: InputType.Text,
                            numbersOnly: true,
                            label: tt('workerTimesheetForm.input.label.fixedPrice'),
                            value: data.fixedPrice?.toString() || '',
                            required: false,
                            inputMode: "decimal",
                            removeZeroOnFocus: true,
                            innerSuffixJSX: <Typography
                                sx={{
                                    pl: 0.8,
                                    mt: 2.5,
                                    fontSize: 14,
                                    color: kAppColors.text.primary(theme.palette.mode === 'dark'),
                                }}>&nbsp;{currency}</Typography>,
                            grid: {
                                sm: 4,
                            },
                        },
                        distance: {
                            testId: 'timesheetModalDistance',
                            className: classes.inputAlignEnd,
                            inputMode: "decimal",
                            numbersOnly: true,
                            type: InputType.ButtonTextField,
                            changeThisToCloseInput: 0,
                            toggleButtonText: tt('timesheetModal.distance.label'),
                            label: `${tt('timesheetModal.distance.label')}`,
                            value: data.distance?.toString() || '',
                            suffixJSX: () => <Typography
                                sx={{
                                    mt: 2.5,
                                    fontSize: 14,
                                    color: kAppColors.text.primary(theme.palette.mode === 'dark'),
                                }}>{tt("distance.kilometers.short")}</Typography>,
                            required: false,
                            hidden: data.paymentType !== ItemPaymentType.TravelFixed,
                            grid: {
                                sm: 4,
                                xs: 6,
                            },
                        },
                    };
                });
                break;
            case ItemPaymentType.StartEnd:
            case ItemPaymentType.TravelStartEnd:
                setInputs(prev => {
                    return {
                        ...prev,
                        startTime: {
                            testId: 'timesheetModalStartTime',
                            type: InputType.FormTimePicker,
                            label: `${tt('timesheetModal.startTime.label')}*`,
                            value: data.startTime || DateTime.now().set({second: 0, millisecond: 0}).toMillis(),
                            required: true,
                            grid: {
                                sm: 4,
                                xs: 6,
                            }
                        },
                        endTime: {
                            testId: 'timesheetModalEndTime',
                            type: InputType.FormTimePicker,
                            label: `${tt('timesheetModal.endTime.label')}*`,
                            value: data.endTime || undefined,
                            required: true,
                            grid: {
                                sm: 4,
                                xs: 6,
                            }
                        },
                        hourRate: {
                            testId: 'workerTimesheetModalHourRateInput',
                            className: classes.inputAlignEnd,
                            type: InputType.ButtonTextField,
                            toggleButtonText: tt('common.hourRate'),
                            numbersOnly: true,
                            removeZeroOnFocus: true,
                            label: tt('common.hourRate'),
                            helperText: hourRateHelperText,
                            value: theHourRate > 0 ? theHourRate.toString() : '',
                            required: false,
                            inputMode: "decimal",
                            suffixJSX: () => <Typography
                                sx={{
                                    mt: 2.5,
                                    fontSize: 14,
                                    color: kAppColors.text.primary(theme.palette.mode === 'dark'),
                                }}>{currency}</Typography>,
                            grid: {
                                sm: 4,
                                xs: 12,
                            },
                        },
                        distance: {
                            testId: 'timesheetModalDistance',
                            className: classes.inputAlignEnd,
                            inputMode: "decimal",
                            numbersOnly: true,
                            type: InputType.ButtonTextField,
                            changeThisToCloseInput: data.distance?.toString() == '0' ? 1 : 0,
                            toggleButtonText: tt('timesheetModal.distance.label'),
                            label: `${tt('timesheetModal.distance.label')}`,
                            value: data.distance?.toString() || '',
                            suffixJSX: () => <Typography
                                sx={{
                                    mt: 2.5,
                                    fontSize: 14,
                                    color: kAppColors.text.primary(theme.palette.mode === 'dark'),
                                }}>{tt("distance.kilometers.short")}</Typography>,
                            required: false,
                            hidden: data.paymentType !== ItemPaymentType.TravelStartEnd,
                            grid: {
                                sm: 4,
                                xs: 6,
                            },
                        },
                    };
                });
                break;
            case ItemPaymentType.TravelDistance:
                setInputs(prev => {
                    return {
                        ...prev,
                        distance: {
                            testId: 'timesheetModalDistance',
                            className: classes.inputAlignEnd,
                            type: InputType.Text,
                            inputMode: "decimal",
                            numbersOnly: true,
                            label: `${tt('timesheetModal.distance.label')}*`,
                            value: data.distance?.toString() || '',
                            innerSuffixJSX: <Typography
                                sx={{
                                    pl: 1,
                                    mt: 2.25,
                                    fontSize: 14,
                                    color: kAppColors.text.primary(theme.palette.mode === 'dark'),
                                }}>{tt("distance.kilometers.short")}</Typography>,
                            required: true,
                            grid: {
                                sm: 4,
                                xs: 6,
                            }
                        },
                        distanceRate: {
                            testId: 'workerTimesheetModalHourRateInput',
                            className: classes.inputAlignEnd,
                            type: InputType.ButtonTextField,
                            changeThisToCloseInput: 0,
                            toggleButtonText: tt('common.distanceRate'),
                            numbersOnly: true,
                            removeZeroOnFocus: true,
                            label: tt('common.distanceRate'),
                            helperText: distanceRateHelperText,
                            value: theDistanceRate > 0 ? theDistanceRate.toString() : '',
                            required: false,
                            inputMode: "decimal",
                            suffixJSX: () => <Typography
                                sx={{
                                    mt: 2.5,
                                    fontSize: 14,
                                    color: kAppColors.text.primary(theme.palette.mode === 'dark'),
                                }}>{currency}</Typography>,
                            grid: {
                                sm: 4,
                                xs: 12,
                            },
                        },
                    };
                });
                break;
        }

        setInputs(prev => {
            return {
                ...prev,
                note: {
                    type: InputType.ButtonTextField,
                    value: data.note || '',
                    label: tt('timesheetModal.noteInput.placeholder'),
                    minRows: 4,
                    toggleButtonText: tt('timesheetModal.noteButton.toggleText.addNote'),
                },
            };
        });
    }

    useEffect(() => {
        if (!editMode || isNew) {
            setIsNew(false);

            setForms();
        }
    }, [data, isNew]);

    useEffect(() => {
        if (theEditMode) {
            if ([ItemPaymentType.Hourly, ItemPaymentType.Extra, ItemPaymentType.TravelHourly].includes(data.paymentType)) {
                setEditModeStats(calculateTimesheets({
                    timesheets: [{
                        paymentType: data.paymentType,
                        hours: inputs.hours ? parseFloat(inputs.hours.value) : 0,
                        minutes: inputs.minutes ? parseFloat(inputs.minutes.value) : 0,
                        hourRate: inputs.hourRate ? parseFloat(inputs.hourRate.value) : 0,
                        distance: inputs.distance ? parseFloat(inputs.distance.value) : 0,
                        approved: true,
                        employeeId: employee?.id || 0,
                        uuid: '',
                    }],
                    countNotApproved: true,
                }));
            } else if ([ItemPaymentType.StartEnd, ItemPaymentType.TravelStartEnd].includes(data.paymentType)) {
                if (inputs.startTime && inputs.endTime) {
                    const startTime = inputs.startTime.value ? inputs.startTime.value : DateTime.now().toMillis();
                    const endTime = inputs.endTime.value ? inputs.endTime.value : null;

                    if (endTime) {
                        const thisStartDate = DateTime.fromMillis(startTime);
                        const thisEndDate = DateTime.fromMillis(endTime);
                        let diffInMIns = (thisEndDate.hour * 60 + thisEndDate.minute) - (thisStartDate.hour * 60 + thisStartDate.minute);

                        if (diffInMIns < 0) {
                            diffInMIns = 0;
                        }

                        const hours = Math.floor(diffInMIns / 60);
                        const minutes = diffInMIns % 60;
                        const hourRate = inputs.hourRate.value ? parseFloat(inputs.hourRate.value) : 0;
                        const distance = inputs.distance.value ? parseFloat(inputs.distance.value) : 0;

                        setEditModeStats(calculateTimesheets({
                            timesheets: [{
                                paymentType: data.paymentType,
                                hours: hours,
                                minutes: minutes,
                                hourRate: hourRate,
                                distance: distance,
                                approved: true,
                                employeeId: employee?.id || 0,
                                uuid: '',
                            }],
                            countNotApproved: true,
                        }));
                    } else {
                        setEditModeStats(calculateTimesheets({
                            timesheets: [{
                                paymentType: data.paymentType,
                                hours: 0,
                                minutes: 0,
                                hourRate: inputs.hourRate ? parseFloat(inputs.hourRate.value) : 0,
                                distance: inputs.distance ? parseFloat(inputs.distance.value) : 0,
                                approved: true,
                                employeeId: employee?.id || 0,
                                uuid: '',
                            }],
                            countNotApproved: true,
                        }));
                    }
                }
            } else if (data.paymentType === ItemPaymentType.TravelDistance) {
                setEditModeStats(calculateTimesheets({
                    timesheets: [{
                        paymentType: data.paymentType,
                        distance: inputs.distance ? parseFloat(inputs.distance.value) : 0,
                        distanceRate: inputs.distanceRate ? parseFloat(inputs.distanceRate.value) : 0,
                        approved: true,
                        employeeId: employee?.id || 0,
                        uuid: '',
                    }],
                    countNotApproved: true,
                }));
            }
        } else {
            setEditModeStats(null);
        }
    }, [inputs, theEditMode]);

    /**
     * Update data on save.
     */
    const updateData = (canValidate?: boolean) => {
        if (!canValidate || ValidateForm(inputs, setInputs)) {
            const note = inputs.note?.value || '';

            switch (data.paymentType) {
                case ItemPaymentType.Hourly:
                case ItemPaymentType.Extra:
                case ItemPaymentType.TravelHourly:
                    if (inputs.hours && inputs.minutes && inputs.hourRate && inputs.distance) {
                        const hours = inputs.hours.value ? parseInt(inputs.hours.value) : 0;
                        const minutes = inputs.minutes.value ? parseInt(inputs.minutes.value) : 0;
                        const hourRate = inputs.hourRate.value ? parseFloat(inputs.hourRate.value) : 0;

                        const distance = inputs.distance.hidden ? undefined : (inputs.distance.value ? parseFloat(inputs.distance.value) : 0);

                        onUpdate(data.uuid, {
                            hours,
                            minutes,
                            hourRate,
                            note,
                            approved: switchInput.approveTimesheet && switchInput.approveTimesheet.value,
                            distance,
                        });
                    }

                    break;
                case ItemPaymentType.Fixed:
                case ItemPaymentType.TravelFixed:
                    if (inputs.fixedPrice && inputs.distance) {
                        const fixedPrice = inputs.fixedPrice.value ? parseFloat(inputs.fixedPrice.value) : 0;

                        const distance = inputs.distance.hidden ? undefined : (inputs.distance.value ? parseFloat(inputs.distance.value) : 0);

                        onUpdate(data.uuid, {
                            fixedPrice,
                            note,
                            approved: switchInput.approveTimesheet && switchInput.approveTimesheet.value,
                            distance,
                        });
                    }

                    break;
                case ItemPaymentType.StartEnd:
                case ItemPaymentType.TravelStartEnd:
                    if (inputs.startTime && inputs.endTime && inputs.distance) {
                        const startTime = inputs.startTime.value ? inputs.startTime.value : DateTime.now().toMillis();
                        const endTime = inputs.endTime.value ? inputs.endTime.value : null;
                        const thisStartDate = DateTime.fromMillis(startTime);

                        const hourRate = inputs.hourRate.value ? parseFloat(inputs.hourRate.value) : 0;
                        const distance = inputs.distance.hidden ? undefined : (inputs.distance.value ? parseFloat(inputs.distance.value) : 0);

                        if (endTime) {
                            const thisEndDate = DateTime.fromMillis(endTime);
                            const diffInMIns = (thisEndDate.hour * 60 + thisEndDate.minute) - (thisStartDate.hour * 60 + thisStartDate.minute);

                            const hours = Math.floor(diffInMIns / 60);
                            const minutes = diffInMIns % 60;

                            onUpdate(data.uuid, {
                                startTime,
                                endTime,
                                hours,
                                minutes,
                                hourRate,
                                note,
                                approved: switchInput.approveTimesheet && switchInput.approveTimesheet.value,
                                distance,
                            });
                        } else {
                            onUpdate(data.uuid, {
                                startTime,
                                endTime,
                                minutes: undefined,
                                hours: undefined,
                                hourRate,
                                note,
                                approved: switchInput.approveTimesheet && switchInput.approveTimesheet.value,
                                distance,
                            });
                        }

                    }
                    break;
                case ItemPaymentType.TravelDistance:
                    if (inputs.distance && inputs.distanceRate) {
                        const distance = inputs.distance.value ? parseFloat(inputs.distance.value) : 0;
                        const distanceRate = inputs.distanceRate.value ? parseFloat(inputs.distanceRate.value) : 0;

                        onUpdate(data.uuid, {
                            distance,
                            distanceRate,
                            note,
                            approved: switchInput.approveTimesheet && switchInput.approveTimesheet.value,
                        });
                    }
                    break;
            }
        }
    };

    function canSave(): boolean {
        if (data.paymentType === ItemPaymentType.StartEnd || data.paymentType === ItemPaymentType.TravelStartEnd) {
            if (inputs.startTime && inputs.endTime) {
                const startTime = inputs.startTime.value ? inputs.startTime.value : DateTime.now().toMillis();
                const endTime = inputs.endTime.value ? inputs.endTime.value : undefined;

                if (!endTime) {
                    return true;
                } else {
                    /** check if start < end */
                    const thisStartDate = DateTime.fromMillis(startTime);
                    const thisEndDate = DateTime.fromMillis(endTime);
                    const diffInMIns = (thisEndDate.hour * 60 + thisEndDate.minute) - (thisStartDate.hour * 60 + thisStartDate.minute);

                    if (diffInMIns < 0) {
                        return false;
                    }
                    return true;
                }
            } else {
                return true;
            }
        }
        if (data.paymentType === ItemPaymentType.Fixed || data.paymentType === ItemPaymentType.TravelFixed) {
            if (!inputs.fixedPrice) {
                return false;
            } else if (inputs.fixedPrice.value === '' || inputs.fixedPrice.value == 0) {
                return false;
            }
        }
        return true;
    }

    let previewTotalChipsJSX;

    const hoursMinutes = data.paymentType == ItemPaymentType.StartEnd || data.paymentType == ItemPaymentType.TravelStartEnd ?
        StartEndHoursDisplay(data.startTime, data.endTime)
        :
        HoursMinutesDisplay({
            timesheets: [{
                hours: data.hours,
                minutes: data.minutes,
                paymentType: data.paymentType,
                approved: data.approved,
            }],
            returnZeroHoursInsteadOfUndefined: true,
            countNotApproved: true,
            hideZeroMinutes: true,
            language,
        });
    const stats = calculateTimesheets({
            timesheets: [{
                hours: data.hours,
                minutes: data.minutes,
                hourRate: data.hourRate,
                fixedPrice: data.fixedPrice,
                paymentType: data.paymentType,
                approved: data.approved,
                distance: data.distance,
                distanceRate: data.distanceRate,
                employeeId: data.employeeId,
                uuid: data.uuid,
            }],
            countNotApproved: true,
        }
    );
    const totalPrice = PriceDisplay(
        stats.totalPrice,
        currency,
        language,
        false,
    );

    switch (data.paymentType) {
        case ItemPaymentType.Extra:
            previewTotalChipsJSX = (
                <>
                    <AppChip
                        chipstyle={"outlined"}
                        label={hoursMinutes}
                    />
                    {
                        totalPrice ?
                            <>
                                <Box pr={1}/>

                                <AppChip
                                    chipstyle={"outlined"}
                                    label={totalPrice}
                                />
                            </> : null
                    }
                </>
            );
            break;

        case ItemPaymentType.TravelDistance:
        case ItemPaymentType.TravelFixed:
            previewTotalChipsJSX = (
                <>
                    {
                        totalPrice ?
                            <>
                                <AppChip
                                    chipstyle={"outlined"}
                                    label={totalPrice}
                                />

                                <Box pr={1}/>
                            </> : null
                    }

                    <AppChip
                        chipstyle={"outlined"}
                        label={distanceDisplay(stats.travel.distance, language, true)}
                    />
                </>
            );
            break;
        case ItemPaymentType.Fixed:
            previewTotalChipsJSX = (
                <AppChip
                    chipstyle={"outlined"}
                    label={totalPrice}
                />
            );
            break;
        case ItemPaymentType.Hourly:
            previewTotalChipsJSX = (
                <>
                    <AppChip
                        chipstyle={"outlined"}
                        label={hoursMinutes}
                    />

                    {
                        totalPrice ?
                            <>
                                <Box pr={1}/>

                                <AppChip
                                    chipstyle={"outlined"}
                                    label={totalPrice}
                                />
                            </> : null
                    }
                </>
            );
            break;
        case ItemPaymentType.TravelHourly:
            previewTotalChipsJSX = (
                <>
                    <AppChip
                        chipstyle={"outlined"}
                        label={hoursMinutes}
                    />

                    {
                        totalPrice ?
                            <>
                                <Box pr={1}/>

                                <AppChip
                                    chipstyle={"outlined"}
                                    label={totalPrice}
                                />
                            </> : null
                    }

                    <Box pr={1}/>

                    <AppChip
                        chipstyle={"outlined"}
                        label={distanceDisplay(stats.travel.distance, language, true)}
                    />
                </>
            );
            break;
        case ItemPaymentType.StartEnd:
            previewTotalChipsJSX = (
                <>
                    {!data.endTime ?
                        <Box pr={1}>
                            <CustomStatusLikeChip
                                text={tt('startEndTimesheet.chip.undefinedEnd')}
                                textColor={getVisitStatusTextColor(VisitStatus.Unfinished, darkMode)}
                                backgroundColor={getVisitStatusBackgroundColor(VisitStatus.Unfinished, theme.palette.mode === "dark")}
                            />
                        </Box>
                        : null}
                    <AppChip
                        chipstyle={"outlined"}
                        label={hoursMinutes}
                    />

                    {
                        totalPrice ?
                            <>
                                <Box pr={1}/>

                                <AppChip
                                    chipstyle={"outlined"}
                                    label={totalPrice}
                                />
                            </> : null
                    }
                </>
            );
            break;
        case ItemPaymentType.TravelStartEnd:
            previewTotalChipsJSX = (
                <>
                    {!data.endTime ?
                        <Box pr={1}>
                            <CustomStatusLikeChip
                                text={tt('startEndTimesheet.chip.undefinedEnd')}
                                textColor={getVisitStatusTextColor(VisitStatus.Unfinished, darkMode)}
                                backgroundColor={getVisitStatusBackgroundColor(VisitStatus.Unfinished, theme.palette.mode === "dark")}
                            />
                        </Box>
                        : null}

                    <AppChip
                        chipstyle={"outlined"}
                        label={hoursMinutes}
                    />

                    {
                        totalPrice ?
                            <>
                                <Box pr={1}/>

                                <AppChip
                                    chipstyle={"outlined"}
                                    label={totalPrice}
                                />
                            </> : null
                    }

                    <Box pr={1}/>

                    <AppChip
                        chipstyle={"outlined"}
                        label={distanceDisplay(stats.travel.distance, language, true)}
                    />
                </>
            );
            break;
        default:
            previewTotalChipsJSX = <></>;
    }

    let previewJSX = (
        <>
            <Box className={classes.chipsContainer}>
                {previewTotalChipsJSX}
            </Box>

            {data.note ?
                <Box pt={2}>
                    <Typography>{data.note}</Typography>
                </Box>
                : null}
        </>
    );

    const isTodayCreated = data.createdAt && DateTime.fromMillis(data.createdAt).startOf('day').toMillis() === DateTime.now().startOf('day').toMillis();
    const createdAtText = data.createdAt ? (
        isTodayCreated ? `${tt('common.today')}, ${DateTime.fromMillis(data.createdAt!).toFormat('H:mm')}` : DateTime.fromMillis(data.createdAt!).toFormat('H:mm · d.M.yyyy')
    ) : tt('common.date.now');

    const authoredJSX = createdByEmployee ? (
        <Box p={2}>
            <Typography className={classes.footerText}>
                {tt('jobDetail.screen.footerNote.created')}
                &nbsp;
                {createdAtText}
                &nbsp;
                ·
                &nbsp;
                {UserFullName(createdByEmployee.name || createdByEmployee.user?.name, createdByEmployee.surname || createdByEmployee.user?.surname)}
            </Typography>
        </Box>
    ) : undefined;

    const editJSX = theEditMode ? (
        <>
            <AppChip
                className={classes.saveChip}
                disabled={!canSave()}
                onClick={() => {
                    updateData(true);

                    setEditMode(!editMode);
                }}
                chipstyle={"primary"}
                label={tt('common.save')}
            />
            {variant === 'edit' ? (
                <>
                    <Box pl={1}/>
                    
                    <AppIconButton
                        tooltip={tt('common.close')}
                        onClick={() => {
                            setEditMode(!editMode);
                            setForms(true);
                        }}
                    >
                        <CloseIcon/>
                    </AppIconButton>
                </>
            ) : null}
        </>
    ) : (
        <PermissionValid
            permission={kPermissionsTimesheets}
            requiredPermissions={[kActionUpdate]}
            forceValid={isNewJobCreation}
        >
            <AppIconButton
                tooltip={tt('common.edit')}
                onClick={() => {
                    setEditMode(!editMode);
                }}
            >
                <PencilIcon/>
            </AppIconButton>
        </PermissionValid>
    );

    const canApprove = userCanApproveByRoleCascade(
        companyEmployeeRole,
        createdByEmployee?.role,
        employeePermissionsMap,
    );

    return (
        <>
            <Divider/>

            <HeadlineWithButton
                titleJSX={<Box className={classes.headlineTitleContainer}>
                    {getTimesheetIcon(data.paymentType)}
                    <Box pr={1}/>
                    <Typography>{getTimesheetTypeTitle(data.paymentType)}</Typography>
                </Box>}
                customAction={
                    <Box className={classes.headerIconsContainer}>
                        {editJSX}
                        <>
                            <Box pl={1}/>

                            <PermissionValid
                                permission={kPermissionsTimesheets}
                                requiredPermissions={[kActionDelete]}
                                forceValid={isNewJobCreation}
                            >
                                <AppIconButton
                                    tooltip={tt('common.delete')}
                                    onClick={() => onDelete(data.uuid)}
                                >
                                    <DeleteIcon className={classes.deleteIcon}/>
                                </AppIconButton>
                            </PermissionValid>
                        </>
                    </Box>
                }
            />

            <Box sx={{pr: 2, pl: 2}}>
                <>
                    <Box className={classes.switchSectionContainer}>
                        {switchInput.approveTimesheet && switchInput.approveTimesheet.value ? null : (
                            <Box mr={4}>
                                <VisitStatusChip className={'statusChip'} timeSheetStatusAllApproved={false}/>
                            </Box>
                        )}

                        {canApprove ? (
                            <Box className={classes.switchInputContainer}>
                                <FormBuilder inputs={switchInput} setInputs={setSwitchInput}/>
                            </Box>
                        ) : null}
                    </Box>

                    {theEditMode ? (
                        <Box pt={1}>
                            <FormBuilder inputs={inputs} setInputs={setInputs}/>

                            {editModeStats ? (
                                <>
                                    <TimesheetsTotalSection
                                        moneyTotal={PriceDisplay(editModeStats.totalPrice, currency, language, true)}
                                        timeTotal={data.paymentType !== ItemPaymentType.TravelDistance ? HoursMinutesDisplayRaw({
                                            hours: editModeStats.hours,
                                            minutes: editModeStats.minutes,
                                            language,
                                            returnZeroHoursInsteadOfUndefined: true
                                        }) : undefined}
                                        distanceTotal={[ItemPaymentType.TravelDistance, ItemPaymentType.TravelHourly, ItemPaymentType.TravelStartEnd].includes(data.paymentType) ?
                                            distanceDisplay(editModeStats.travel.distance, language, true) :
                                            undefined
                                        }
                                    />
                                </>
                            ) : undefined}
                        </Box>
                    ) : (
                        <>
                            {previewJSX}

                            <Box mr={-2} ml={-2} pb={1}>{authoredJSX}</Box>
                        </>
                    )}
                </>
            </Box>
        </>
    );
}
