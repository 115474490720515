import {makeStyles} from "tss-react/mui";
import {Autocomplete, TextField, Theme} from "@mui/material";
import {IChipsAutocompleteProps, Option} from "./InputProps";
import React, {useEffect, useState} from "react";
import AppChip from "../chips/AppChip";
import {kAppColors} from "../../../styles/AppThemeProcessor";

export const useStyles = makeStyles()((theme: Theme) => ({
    inputNotEmpty: {
        '.MuiInputBase-root': {
            paddingBottom: 12,
        },
        '.MuiInputBase-input': {
            display: 'block',
            width: 'inherit !important',
        }
    },
    chip: {
        marginRight: 8,
        marginTop: 6,
        marginBottom: 2,
        borderRadius: 40,
        paddingLeft: 4,
        paddingRight: 4,
        fontSize: 14,
        height: 32,
        fontWeight: 600,
        background: kAppColors.background.autocompleteChip(theme.palette.mode === "dark"),
        color: kAppColors.text.primary(theme.palette.mode === 'dark'),
        ':hover': {
            background: kAppColors.background.autocompleteChip(theme.palette.mode === "dark"),
        },
        '.MuiChip-deleteIcon': {
            color: theme.palette.mode === 'dark' ? kAppColors.text.white : kAppColors.text.secondaryLightModeGrey,
        }
    }
}));

export default function ChipsAutocomplete(props: IChipsAutocompleteProps) {
    const {
        index,
        className,
        label,
        value,
        updateValue,
        options,
        hidden,
        error,
        errorMessage,
        helperText,
        placeholder,
        paperClass,
        freeSolo,
        disabled,
        uniqueValues,
    } = props;

    const [currentOptions, setCurrentOptions] = useState<Array<Option>>(options);

    useEffect(() => {
        let thisCurrentOptions: Array<Option> = [];
        options.forEach((item) => {
            const included = value.find((valueItem) => valueItem.value == item.value);

            if (!included) {
                thisCurrentOptions.push(item);
            }
        });
        setCurrentOptions(thisCurrentOptions);
    }, [options]);

    function OnChange(newValue: Option[]) {
        let theValue = [...newValue];
        updateValue(index, theValue);

        if (currentOptions.length != options.length - theValue.length) {
            let thisCurrentOptions: Array<Option> = [];
            options.forEach((item) => {
                const included = theValue.find((valueItem) => valueItem.value == item.value);

                if (!included) {
                    thisCurrentOptions.push(item);
                }
            });
            setCurrentOptions(thisCurrentOptions);
        }
    }

    const {classes, cx} = useStyles();
    const theInputClass = cx(
        value.length === 0 ? undefined : classes.inputNotEmpty,
    );

    return (
        <Autocomplete
            componentsProps={{
                paper: {
                    className: paperClass,
                }
            }}
            open={freeSolo && options.length == 0 ? false : undefined}
            multiple
            freeSolo={freeSolo}
            value={value}
            disabled={disabled}
            disableClearable={true}
            onChange={(event, newValue) => {
                let processed: Option[] = [];

                for (const item of newValue) {
                    const newItem = typeof item === 'string' ? {label: item, value: item} : item;

                    if (uniqueValues && processed.find((value) => value.value == newItem.value)) {
                        continue;
                    }

                    processed.push(newItem);
                }

                OnChange(processed);
            }}
            options={currentOptions}
            getOptionLabel={(option) => typeof option === 'string' ? option : option.label}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <AppChip
                        label={option.label}
                        {...getTagProps({index})}
                        className={classes.chip}
                    />
                ))
            }

            renderInput={(params) => (
                <TextField
                    className={theInputClass}
                    variant={"filled"}
                    error={error}
                    helperText={error ? errorMessage : undefined}
                    {...params}
                    label={label}
                    placeholder={placeholder}
                />
            )}
        />
    );

}

