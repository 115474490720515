import React, {useContext} from "react";
import {createTheme, ThemeProvider} from "@mui/material";
import {AppDataContext} from "../AppData";
import {MantineProvider} from "@mantine/core";
import {localizationForTheme} from "../core/Localization";

export const kAppColors = {
    primary: {
        main: '#ff5722',
        light: '#FFEEE9',
        mainLighter02: 'rgba(255,87,34, 0.2)',
    },
    hoverColor: {
        main: '#E24C1D',
        grey: (darkMode: boolean) => darkMode ? 'rgba(111,111,111, 0.25)' : '#f0f2f5',
        mainLighter: 'rgba(255,87,34, 0.097)',
        contactIconButton: (darkMode: boolean) => darkMode ? '#5e5e5e' : '#DFE1E5',
    },
    selectedItemColor: {
        main: 'rgba(255,87,34, 0.097)',
    },
    green: {
        main: '#00CB1F',
        secondary: '#5AC700',
    },
    yellow: {
        main: '#FFDF07',
    },
    background: {
        paper: (darkMode: boolean) => darkMode ? '#202020' : "white",
        bottomSheet: (darkMode: boolean) => darkMode ? '#000000' : '#ffffff',
        default: (darkMode: boolean) => darkMode ? '#000000' : '#ffffff',
        secondary: (darkMode: boolean) => darkMode ? '#ffffff' : '#000000',
        mobileAppbar: (darkMode: boolean) => darkMode ? 'transparent' : 'rgba(255, 255, 255, 0.65)',
        disabledInput: '#4d4d4d',
        autocompleteChip: (darkMode: boolean) => darkMode ? '#6F6F6F' : '#DCDFE3',
        contactIconButton: (darkMode: boolean) => darkMode ? '#2f2f2f' : '#EEF0F3',
        menuPaper: (darkMode: boolean) => darkMode ? '#2E2E2E' : '#fff',
        bodyBackground: (darkMode: boolean) => darkMode ? '#121212' : '#e4e4e4',
        blue: '#1b97f0'
    },
    text: {
        primary: (darkMode: boolean) => darkMode ? '#ffffff' : '#000000',
        secondary: (darkMode: boolean) => darkMode ? '#989898' : '#65676B',
        secondaryLightModeGrey: '#65676B',
        secondaryLighterForDarkMode: (darkMode: boolean) => darkMode ? '#bababa' : '#6F6F6F',
        tertiary: (darkMode: boolean) => darkMode ? '#000000' : '#ffffff',
        white: '#ffffff',
        black: '#000000',
        inputLabel: (darkMode: boolean) => darkMode ? '#A8A8A8' : '#6F6F6F'
    },
    border: (darkMode: boolean) => darkMode ? 'rgba(111,111,111, 0.35)' : 'rgba(0,0,0, 0.13)',
    red: {
        main: '#FF1C1C',
        confirmButton: '#FF1C1C'
    },
    grey: {
        greyF1: '#f1f1f1',
        inputBackground: (darkMode: boolean) => darkMode ? 'rgba(238, 240, 243, 0.2)' : '#EEF0F3',
        hexa70: '#707070',
        secondaryChipBg: '#EEF0F3',
        hexaDFE1E5: '#DFE1E5',
        background2: '#DCDFE3',
        background3: (darkMode: boolean) => darkMode ? 'rgba(238, 240, 243, 0.2)' : '#ffffff',
        link: '#6f6f6f',
        disabledInput: (darkMode: boolean) => darkMode ? '#6f6f6f' : 'rgba(0, 0, 0, 0.26)',
    },
};

export const kVisitStatusModifiersColors = {
    invoiced: '#FF9922',
    paid: '#5AC727',
    notPaid: '#FF0000',
};

export const kVisitStatusColors = {
    offer: {
        text: (darkMode: boolean) => kAppColors.text.primary(darkMode),
        background: (darkMode: boolean) => darkMode ? '#545454' : '#F5F5F5',
    },
    scheduleLater: {
        text: '#FF9922',
        background: (darkMode: boolean) => darkMode ? '#3F2B12' : '#FFF5E8',
    },
    scheduled: {
        text: '#1B97F0',
        background: (darkMode: boolean) => darkMode ? '#112A3C' : '#E7F4FE',
    },
    travelling: {
        text: '#D8B601',
        background: (darkMode: boolean) => darkMode ? '#37300C' : '#FBF8E5',
    },
    inProgress: {
        text: '#04C6BF',
        background: (darkMode: boolean) => darkMode ? '#0C3432' : '#E5F9F9',
    },
    done: {
        text: kAppColors.green.secondary,
        background: (darkMode: boolean) => darkMode ? '#1E340C' : '#EEF9E5',
    },
    closed: {
        text: (darkMode: boolean) => kAppColors.text.primary(darkMode),
        background: (darkMode: boolean) => darkMode ? '#202020' : '#FFFFFF',
    },
    canceled: {
        text: (darkMode: boolean) => darkMode ? '#7f7f7f' : '#767676',
        background: (darkMode: boolean) => darkMode ? '#202020' : '#FFFFFF',
    },
    unfinished: {
        text: '#FF2222',
        background: (darkMode: boolean) => darkMode ? '#3F1212' : '#FFE8E8',
    }
};


export const kContentWidthNarrow = 430;
export const kContentWidthMedium = 770;
export const kPhoneStopBreakpoint = 576;
export const kLargePhoneBreakpoint = 768;


export interface IAppThemeProcessorProps {
    children: React.ReactNode;
}

/**
 * Component for generation of correct App Theme.
 */
export default function AppThemeProcessor(props: IAppThemeProcessorProps) {
    const {children} = props;

    const appDataContext = useContext(AppDataContext);
    const {darkMode, language} = appDataContext;
    const isMobile = window!.visualViewport!.width <= kPhoneStopBreakpoint;

    // @ts-ignore
    const localization = localizationForTheme[language];

    const appTheme = createTheme({
            typography: {
                fontFamily: 'Plus Jakarta Sans',
                h1: {
                    fontSize: 45,
                    fontWeight: 700,
                },
                h2: {
                    fontSize: 20,
                    fontWeight: 500,
                },
                body1: {
                    fontSize: 14,
                },
                button: {
                    textTransform: "none",
                    fontWeight: 600,
                }
            },
            palette: {
                mode: darkMode ? "dark" : "light",
                primary: kAppColors.primary,
                secondary: kAppColors.green,
                text: {
                    primary: kAppColors.text.primary(darkMode),
                    secondary: '#6F6F6F',
                },
                background: {
                    default: isMobile ? (darkMode ? '#202020' : 'white') : kAppColors.background.bodyBackground(darkMode),
                }
            },
            components: {
                MuiLink: {
                    styleOverrides: {
                        root: {
                            cursor: "pointer",
                            ':hover': {
                                textDecoration: 'none'
                            }
                        }
                    },
                    defaultProps: {
                        fontWeight: 500,
                        color: kAppColors.grey.link,
                        lineHeight: 18 / 14,
                    },
                },
                MuiButton: {
                    styleOverrides: {
                        containedError: {
                            '&.Mui-disabled': {
                                backgroundColor: `${darkMode ? '#6f6f6f' : 'rgba(0, 0, 0, 0.12)'} !important`,
                            },
                            backgroundColor: kAppColors.red.confirmButton + ' !important',
                        },
                        contained: {
                            paddingRight: 16,
                            paddingLeft: 16,
                            paddingTop: 10,
                            paddingBottom: 10,
                            borderRadius: 8,
                            '&:disabled': {
                                backgroundColor: darkMode ? '#6f6f6f' : '#EEF0F3',
                                color: darkMode ? '#cecfd0' : '#6f6f6f'
                            },
                        },
                        text: {
                            paddingRight: 16,
                            paddingLeft: 16,
                            paddingTop: 10,
                            paddingBottom: 10,
                            borderRadius: 8,
                            ':hover': {
                                backgroundColor: kAppColors.hoverColor.mainLighter,
                            }
                        },
                    }
                },
                MuiIconButton: {
                    styleOverrides: {
                        root: {
                            ':hover': {
                                background: kAppColors.hoverColor.grey(darkMode),
                            }
                        }
                    }

                },
                MuiButtonGroup: {
                    styleOverrides: {
                        groupedContained: {
                            borderColor: 'transparent !important',
                            marginRight: 1,
                        },
                    }
                },
                MuiToolbar: {
                    styleOverrides: {
                        root: {
                            '&.MuiToolbar-regular': {
                                paddingRight: 16,
                                paddingLeft: 16,
                            }
                        }
                    }
                },
                MuiMenu: {
                    styleOverrides: {
                        paper: {
                            padding: 8,
                            borderRadius: 10,
                            backgroundImage: "unset",
                            backgroundColor: kAppColors.background.menuPaper(darkMode),
                        },
                        list: {
                            padding: 0,
                            'li:last-child': {
                                marginBottom: 0,
                            },
                            li: {
                                paddingRight: 8,
                                paddingLeft: 8,
                                paddingTop: 10,
                                paddingBottom: 10,
                                marginBottom: 4,
                                borderRadius: 10,
                            }
                        },
                    },
                },
                MuiMenuItem: {
                    styleOverrides: {
                        root: {
                            fontWeight: 500,
                            "&.Mui-selected": {
                                backgroundColor: kAppColors.selectedItemColor,
                            },
                            '> span, > div': {
                                marginRight: 8,
                            }
                        },
                    }
                },
                MuiAutocomplete: {
                    styleOverrides: {
                        paper: {
                            padding: 8,
                            borderRadius: 10,
                            boxShadow: '0px 3px 6px #00000021 !important',
                            border: '1px solid #00000021 !important',
                            'ul': {
                                'li': {
                                    paddingRight: '8px !important',
                                    paddingLeft: '8px !important',
                                    paddingTop: '10px !important',
                                    paddingBottom: '10px !important',
                                    marginBottom: '4px',
                                    borderRadius: 10,
                                },
                                'li:last-child': {
                                    marginBottom: '0 !important',
                                },
                            }
                        },
                        listbox: {
                            margin: 0,
                            padding: 0,
                        },
                    }
                },
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            marginBottom: 20
                        }
                    }
                },
                MuiChip: {
                    styleOverrides: {
                        root: {
                            borderRadius: 40,
                            paddingLeft: 4,
                            paddingRight: 4,
                            fontSize: 14,
                            height: 36,
                            color: darkMode ? kAppColors.text.primary(darkMode) : kAppColors.text.tertiary(darkMode),
                            background: kAppColors.primary.main,
                            ":hover": {background: kAppColors.hoverColor.main},
                            fontWeight: 600,
                        },
                        colorSuccess: {
                            backgroundColor: kAppColors.green.main,
                            ':hover': {
                                backgroundColor: kAppColors.green.main,
                            }
                        },
                        icon: {
                            marginRight: -4,
                            color: kAppColors.primary.light,
                        },
                    }
                },
                MuiDrawer: {
                    styleOverrides: {
                        paper: {
                            borderRight: `1px solid ${kAppColors.border(darkMode)}`,
                        },
                    }
                },
                MuiListItem: {
                    styleOverrides: {
                        root: {
                            minHeight: 56,
                        }
                    }
                },
                MuiListItemAvatar: {
                    styleOverrides: {
                        root: {
                            marginRight: 16,
                            minWidth: "auto",
                        }
                    }
                },
                MuiListItemSecondaryAction: {
                    styleOverrides: {
                        root: {
                            position: "relative",
                            top: 0,
                            right: 0,
                            transform: "none",
                            display: 'flex',
                            alignItems: "center",
                        }

                    }
                },
                MuiListItemText: {
                    styleOverrides: {
                        primary: {
                            fontSize: 20,
                            fontWeight: 500,
                            textOverflow: 'ellipsis',
                        },
                        secondary: {
                            fontSize: 14,
                            lineHeight: 1.28,
                            fontWeight: 500,
                            textOverflow: 'ellipsis',
                        },
                    }
                },
                MuiDivider: {
                    styleOverrides: {
                        root: {
                            borderColor: kAppColors.border(darkMode)
                        }
                    }
                },
                MuiSelect: {
                    styleOverrides: {
                        select: {
                            display: "flex",
                            alignItems: "center",
                            'span, div': {
                                marginRight: 8,
                            }
                        },
                        iconOpen: {
                            color: kAppColors.primary.main,
                        }
                    }
                },
                MuiFormHelperText: {
                    styleOverrides: {
                        root: {
                            color: kAppColors.text.secondary(darkMode),
                            marginTop: 0,
                            marginLeft: 16,
                            minHeight: 20,
                        }
                    }
                },
                MuiFormControl: {
                    styleOverrides: {
                        root: {
                            marginBottom: '20px !important',
                            '.MuiFormHelperText-root.Mui-error': {
                                position: 'absolute',
                                bottom: -20,
                            },
                        }
                    }
                },
                MuiInputLabel: {
                    styleOverrides: {
                        root: {
                            color: kAppColors.text.inputLabel(darkMode),
                        }
                    }
                },
                MuiInputBase: {
                    styleOverrides: {
                        root: {
                            '& .MuiInputBase-inputSizeSmall': {
                                paddingTop: 10,
                                paddingBottom: 10,
                            },
                            '& input[type=number]': {
                                MozAppearance: 'textfield'
                            },
                            '& input[type=number]::-webkit-outer-spin-button': {
                                WebkitAppearance: 'none',
                                margin: 0
                            },
                            '& input[type=number]::-webkit-inner-spin-button': {
                                WebkitAppearance: 'none',
                                margin: 0
                            },
                            caretColor: kAppColors.primary.main,
                            background: kAppColors.grey.inputBackground(darkMode) + ' !important',
                            borderRadius: '8px !important',
                            ':before': {
                                border: 'none !important',
                            },
                            ':after': {
                                border: 'none !important',
                            },
                        },
                    }
                },
                MuiTooltip: {
                    styleOverrides: {
                        tooltipPlacementRight: {
                            marginLeft: 16,
                        },
                        tooltip: {
                            fontSize: 14,
                            color: kAppColors.text.tertiary(darkMode),
                            background: kAppColors.background.secondary(darkMode),
                        },
                        arrow: {
                            color: kAppColors.background.secondary(darkMode),
                        }
                    }
                },
                MuiTab: {
                    styleOverrides: {
                        root: {
                            fontSize: 14,
                            fontWeight: 600,
                            paddingTop: 16,
                            paddingBottom: 16,
                            paddingLeft: 35,
                            paddingRight: 35,
                        },
                    }
                },
                MuiTabs: {
                    styleOverrides: {
                        root: {
                            '.MuiTabScrollButton-root .MuiSvgIcon-root': {
                                width: 24,
                                height: 24,
                            },
                            maxWidth: "100vw",
                        }
                    }
                },
                MuiBottomNavigationAction: {
                    styleOverrides: {
                        root: {
                            minWidth: 60,
                            color: kAppColors.text.primary(darkMode),
                            fontSize: 9,
                            fontWeight: 500,
                        }
                    }
                },
                MuiSkeleton: {
                    styleOverrides: {
                        rectangular: {
                            borderRadius: 4,
                        }
                    }
                },
                MuiToggleButtonGroup: {
                    styleOverrides: {
                        root: {
                            padding: 4,
                            backgroundColor: kAppColors.grey.inputBackground(darkMode),
                            borderRadius: 8,
                            height: 53,
                        }
                    }
                },
                MuiToggleButton: {
                    styleOverrides: {
                        root: {
                            margin: 0 + 'px !important',
                            borderRadius: 8 + 'px !important',
                            overflow: "clip",
                            border: "none !important",
                            fontSize: 14,
                            fontWeight: 400,
                            '&.Mui-selected': {
                                color: 'white',
                                backgroundColor: kAppColors.primary.main,
                            },
                            '&.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
                                marginRight: 2 + 'px !important',
                            },
                            '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
                                marginLeft: 2 + 'px !important',
                            }
                        },

                    }
                },
                MuiPopover: {
                    styleOverrides: {
                        root: {
                            zIndex: 1400
                        }
                    }
                },
                MuiAccordion: {
                    styleOverrides: {
                        root: {
                            background: "transparent",
                            '.MuiAccordionSummary-root': {
                                minHeight: 'unset !important',
                            },
                            '.MuiAccordionSummary-content': {
                                margin: 0 + ' !important',
                            },
                            '.MuiAccordionSummary-expandIconWrapper': {
                                color: kAppColors.text.primary(darkMode),
                            },
                            '&:before': {
                                display: 'none',
                            },
                            margin: 0 + ' !important',
                            boxShadow: "none",
                        }
                    }
                },
            }
        },
        localization,
    );

    return (
        <MantineProvider
            theme={{
                colorScheme: darkMode ? 'dark' : 'light',
                colors: {
                    'primary-orange': [
                        "#E5CAC2",
                        "#E0B0A0",
                        "#E2947C",
                        "#EC7752",
                        "#FF5722",
                        "#E64E1E",
                        "#C04E2A",
                        "#9F4D33",
                        "#864B38",
                        "#714739",
                    ],
                },
                primaryColor: 'primary-orange',
                primaryShade: 4,
                loader: 'bars',
            }}
            withGlobalStyles={true}
            withNormalizeCSS={true}
        >
            <ThemeProvider theme={appTheme}>
                {children}
            </ThemeProvider>
        </MantineProvider>
    );
}
