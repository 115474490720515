export const kIsLocal = process.env.REACT_APP_ENVIRONMENT === 'local';

/**
 * Get Flavor for current environment if possible.
 */
export function FlavorByEnvironment() {
    switch (process.env.REACT_APP_ENVIRONMENT) {
        case 'local':
        case 'dev':
            const localGraphqlEndpoints = process.env.REACT_APP_ENVIRONMENT === 'local' ? 'http://127.0.0.1:8080/graphql' : 'https://api-dev.timoty.io/graphql';
            const localRestEndpoints = process.env.REACT_APP_ENVIRONMENT === 'local' ? 'http://127.0.0.1:8080' : 'https://api-dev.timoty.io';
            
            return { //dev or local
                firebase: {
                    apiKey: "AIzaSyDKkMXR64fOta1JmLilP3Sz8rynw-6djw4",
                    authDomain: "timoty-io-dev.firebaseapp.com",
                    projectId: "timoty-app-dev",
                    storageBucket: "timoty-app-dev.appspot.com",
                    messagingSenderId: "1037573654035",
                    appId: "1:1037573654035:web:4873e7e21405d04892e1e6",
                    measurementId: "G-BXHEL7RM20"
                },
                graphqlEndpoint: localGraphqlEndpoints,
                restEndpoint: localRestEndpoints,
                domains: [
                    "localhost:3000",
                    "timoty-io-dev.web.app",
                    "timoty-io-dev.firebaseapp.com",
                ],
                schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
                appstoreLink: 'https://apps.apple.com/us/app/timoty/id1633143369',
                googlePlayLink: 'https://play.google.com/store/apps/details?id=eu.appliable.timoty',
                webUrl: 'https://timoty-io-dev.firebaseapp.com',
                privacyPolicy: 'https://www.timoty.io/legal',
                termsAndConditions: 'https://www.timoty.io/legal',
                appIssuesUrl: 'https://trello.com/b/ryNRXjPz/timoty-chyby-a-jejich-stav',
                roadmapUrl: 'https://trello.com/b/D5y0dYNl/timoty-roadmapa-funkc%C3%AD',
                gleap: undefined,
                contactEmail: 'timotyapp@gmail.com',
                mapyCZApiKey: 'FYe_x-6h4izmX8xALOhUmFebaC6BgTCWpOw6_Hwpv6s',
            };
        case 'stage':
            return { //stage
                firebase: {
                    apiKey: "AIzaSyB8kPspfY_V9YyiOCrauxImDVJXKOdAihQ",
                    authDomain: "timoty-io-stage.firebaseapp.com",
                    projectId: "timoty-app-stage",
                    storageBucket: "timoty-app-stage.appspot.com",
                    messagingSenderId: "603497216105",
                    appId: "1:603497216105:web:23c56a96db744f58134af8"
                },
                graphqlEndpoint: '', //TODO also codegen
                restEndpoint: '', //TODO
                domains: [
                    "timoty-io-stage.web.app",
                    "timoty-io-stage.firebaseapp.com",
                ],
                schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
                appstoreLink: 'https://apps.apple.com/us/app/timoty/id1633143369',
                googlePlayLink: 'https://play.google.com/store/apps/details?id=eu.appliable.timoty',
                webUrl: 'https://timoty-io-stage.firebaseapp.com',
                privacyPolicy: 'https://www.timoty.io/legal',
                termsAndConditions: 'https://www.timoty.io/legal',
                appIssuesUrl: 'https://trello.com/b/ryNRXjPz/timoty-chyby-a-jejich-stav',
                roadmapUrl: 'https://trello.com/b/D5y0dYNl/timoty-roadmapa-funkc%C3%AD',
                gleap: undefined,
                contactEmail: 'timotyapp@gmail.com',
                mapyCZApiKey: 'FYe_x-6h4izmX8xALOhUmFebaC6BgTCWpOw6_Hwpv6s',
            };
        case 'prod':
            return { //prod
                firebase: {
                    apiKey: "AIzaSyArCtrWngFof3tCd0ZD_C7KYjCnlDSzrJU",
                    authDomain: "go.timoty.io",
                    projectId: "timoty-app",
                    storageBucket: "timoty-app.appspot.com",
                    messagingSenderId: "679550955894",
                    appId: "1:679550955894:web:82a2f7483f5bd09a6e0a57",
                    measurementId: "G-J99Z8S5FTC"
                },
                graphqlEndpoint: 'https://api-prod.timoty.io/graphql',
                restEndpoint: 'https://api-prod.timoty.io',
                domains: [
                    "timoty-io.web.app",
                    "timoty-io.firebaseapp.com",
                ],
                schedulerLicenseKey: '0319583680-fcs-1700554427',
                appstoreLink: 'https://apps.apple.com/us/app/timoty/id1633143369',
                googlePlayLink: 'https://play.google.com/store/apps/details?id=eu.appliable.timoty',
                webUrl: 'https://go.timoty.io',
                privacyPolicy: 'https://www.timoty.io/legal',
                termsAndConditions: 'https://www.timoty.io/legal',
                appIssuesUrl: 'https://trello.com/b/ryNRXjPz/timoty-chyby-a-jejich-stav',
                roadmapUrl: 'https://trello.com/b/D5y0dYNl/timoty-roadmapa-funkc%C3%AD',
                gleap: 'LM3D5MkMdcIsEh6RNMkCKEMlYCAyFSJl',
                contactEmail: 'timotyapp@gmail.com',
                mapyCZApiKey: 'FYe_x-6h4izmX8xALOhUmFebaC6BgTCWpOw6_Hwpv6s',
            };
    }

    return null;
}
