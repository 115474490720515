import AppVersion, {AppType} from "../model/firestore/AppVersion";
import {getFirestore, collection, query, where, orderBy, limit, getDocs} from "firebase/firestore";
import {DateTime} from "luxon";
import packageJson from "../../package.json";
import {SetAppVersionModal} from "../ui/components/modals/AppModals";
import {IAppDataContext} from "../AppData";

const kAppVersionsCollection = 'app_versions';

/**
 * Query the latest version of the app from Firestore.
 */
export async function queryLatestAppVersion(): Promise<AppVersion | null> {
    const firestore = getFirestore();
    const theCollection = collection(firestore, kAppVersionsCollection);

    const now = DateTime.now().toMillis();

    const theQuery = query(
        theCollection,
        where('app', '==', AppType.Web),
        where('createdAt', '<=', now),
        orderBy('createdAt', 'desc'),
        limit(1),
    )

    const snapshot = await getDocs(theQuery);

    if (snapshot.empty) {
        return null;
    }

    const doc = snapshot.docs[0];

    return {
        ...doc.data(),
        id: doc.id,
    } as AppVersion;
}

let lastCheckedAt: number = 0;
const kTenMinutes = 1000 * 60 * 10;

/**
 * Compare latest app version with latest version on Firestore.
 * If there is newer version, display update dialog.
 */
export async function checkAppVersion(appDataContext: IAppDataContext): Promise<void> {
    const now = DateTime.now().toMillis();

    if (now - lastCheckedAt < kTenMinutes) {
        return;
    }
    lastCheckedAt = now;

    try {
        const latestAppVersion = await queryLatestAppVersion();

        if (latestAppVersion) {
            const latestVersionNumber: number = parseInt(latestAppVersion.version.replace(/\./g, ''));
            const currentAppVersion: number = parseInt(packageJson.version.replace(/\./g, ''));

            if (latestVersionNumber > currentAppVersion) {
                SetAppVersionModal(appDataContext, {
                    open: true,
                    version: latestAppVersion.version,
                });
            }
        }
    } catch (e) {
        console.error(e);
    }
}
