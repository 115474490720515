import {FlavorByEnvironment} from "../flavor-config";
import Gleap from "gleap";
import {IAppDataContext} from "../AppData";
import IAuthUser from "../model/AuthUser";

/**
 * Initialize Gleap SDK with your API key and correct language.
 */
export function initGleap(appDataContext: IAppDataContext) {
    const config = FlavorByEnvironment();

    if (config?.gleap) {
        changeGleapLanguage(appDataContext.language);

        Gleap.initialize(config.gleap);
    }
}

/**
 * Change Gleap language.
 */
export function changeGleapLanguage(language: string) {
    const config = FlavorByEnvironment();

    if (config?.gleap) {
        Gleap.setLanguage(language);
    }
}

/**
 * Set user identity in Gleap for IAuthUser.
 */
export function setGleapIdentity(user: IAuthUser) {
    const config = FlavorByEnvironment();

    if (config?.gleap) {
        Gleap.identify(user.uid, {
            email: user.email,
        });
    }
}

/**
 * Clear user identity in Gleap.
 */
export function clearGleapIdentity() {
    const config = FlavorByEnvironment();

    if (config?.gleap) {
        Gleap.clearIdentity();
    }
}
