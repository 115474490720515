import {LocationPureResponse} from "../../../../generated/graphql/graphql";
import Icons8Location from "../../../../icons/Icons8Location";
import {LocationNameDisplay, LocationTypeDisplay} from "../../../../service/LocationService";
import {UserAddressSingle} from "../../../../service/UserService";
import ShowOnMapMenu from "../../menus/ShowOnMapMenu";
import AppListItem from "../../listItems/AppListItem";
import React, {useId} from "react";
import {usePopupState} from "material-ui-popup-state/hooks";
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import MoreFilledIcon from "../../../../icons/MoreFilledIcon";
import AppIconButton from "../../buttons/AppIconButton";
import {Box} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {kAppColors} from "../../../../styles/AppThemeProcessor";
import CloseIcon from "../../../../icons/CloseIcon";
import {tt} from "../../../../core/Localization";

export interface INewJobLocationItemProps {
    locationData: LocationPureResponse;
    onDelete: (id: number) => void;
}

/**
 * Component for selection Location on New Job page.
 */
export default function NewJobLocationItem(props: INewJobLocationItemProps) {
    const {locationData, onDelete} = props;

    const morePopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    return (
        <>
            <AppListItem
                customAvatar={<Icons8Location/>}
                title={LocationNameDisplay(locationData?.name, locationData?.type || '')}
                description={`${locationData?.name ? LocationTypeDisplay(locationData?.type || '') : ''}\n${UserAddressSingle(locationData?.address)}`.trim()}
                variant={"smaller-title"}
                actionWidget={
                    <>
                        {locationData?.address.longitude ? (
                            <Box mr={1}>
                                <ShowOnMapMenu address={locationData.address}/>
                            </Box>
                        ) : null}

                        <AppIconButton
                            variant={"greyBg"}
                            placement={"right"}
                            {...bindTrigger(morePopupState)}
                        >
                            <MoreFilledIcon/>
                        </AppIconButton>
                    </>
                }
            />

            <Menu {...bindMenu(morePopupState)}>
                <MenuItem onClick={() => {
                    morePopupState.close();
                    onDelete(locationData.id);
                }}>
                    <Box sx={{color: kAppColors.red.confirmButton}}><CloseIcon/></Box>
                    {tt('common.removeFromList')}
                </MenuItem>
            </Menu>
        </>
    );
}
