import React, {useContext, useEffect, useState} from "react";
import {getAuth} from "firebase/auth";
import {AppContext} from "../App";
import IAuthUser from "../model/AuthUser";
import {AppDataContext} from "../AppData";
import {useResettableMutation} from "tomaschyly-apollo-hooks-extended";
import {UserSignInDocument, UserSignInMutation, UserSignInMutationVariables} from "../generated/graphql/graphql";
import {convertAvatarUrlToLarge, UserCompany, UserStartSwitchCompany} from "../service/UserService";
import {AuthUserCanRefreshToken, AuthUserRefreshedToken, FirebaseProviderToEnum, LinkAuthProvidersFinish} from "../service/AuthUserService";
import {checkAppVersion} from "../service/AppVersionService";
import {clearGleapIdentity, setGleapIdentity} from "../service/GleapService";
import { HideLinkProvidersModal } from "../ui/components/modals/AppModals";

export interface IAutoSignInProcessorProps {
    children: React.ReactNode;
}

/**
 * Component for processing Firebase Auth events and auto signIn.
 */
export default function AutoSignInProcessor(props: IAutoSignInProcessorProps) {
    const {children} = props;

    const appContext = useContext(AppContext);
    const {authUser, setAuthUser, setAuthUserVerified} = appContext;
    const firebaseUid = authUser?.uid;

    const appDataContext = useContext(AppDataContext);
    const {language} = appDataContext;

    const [executeCheckAppVersion, setExecuteCheckAppVersion] = useState<boolean>(false);

    const [mutateSignIn, {
        loading,
        error,
        data,
        reset,
    }] = useResettableMutation<UserSignInMutation, UserSignInMutationVariables>(UserSignInDocument);

    useEffect(() => {
        if (authUser && (loading !== authUser.signInResponse?.loading || error !== authUser.signInResponse?.error)) {
            setAuthUser((prev: IAuthUser | undefined) => {
                if (prev) {
                    return {
                        ...prev,
                        signInResponse: {
                            loading,
                            error,
                            data: data?.userSignIn,
                        },
                    };
                } else {
                    return undefined;
                }
            });
        }
    }, [authUser, loading, error, data]);

    useEffect(() => {
        if (authUser) {
            checkAppVersion(appDataContext);
        }
    }, [executeCheckAppVersion, authUser]);

    useEffect(() => {
        getAuth().onAuthStateChanged(async (user) => {
            if (user) {
                const idToken = await user.getIdToken(true);

                const authUser: IAuthUser = {
                    email: user.email || '',
                    emailVerified: user.emailVerified,
                    photoUrl: convertAvatarUrlToLarge(user.photoURL),
                    uid: user.uid,
                    loggedInWith: user.providerData.map(({providerId}) => FirebaseProviderToEnum(providerId)),
                    idToken,
                };

                setAuthUser(prev => {
                    setTimeout(() => {
                        setExecuteCheckAppVersion(!executeCheckAppVersion);
                    }, 1);

                    return authUser;
                });

                setGleapIdentity(authUser);

                HideLinkProvidersModal(appDataContext);

                LinkAuthProvidersFinish(user);
            } else {
                setAuthUser(undefined);

                clearGleapIdentity();
            }

            setAuthUserVerified(true);
        });
    }, []);

    useEffect(() => {
        if (authUser) {
            const refreshToken = async () => {
                try {
                    const user = getAuth().currentUser;

                    if (user && AuthUserCanRefreshToken()) {
                        setAuthUser((prev: IAuthUser | undefined) => {
                            if (prev) {
                                return {
                                    ...prev,
                                    idToken: undefined,
                                };
                            } else {
                                return undefined;
                            }
                        });

                        const idToken = await user.getIdToken(true);

                        setAuthUser((prev: IAuthUser | undefined) => {
                            if (prev) {
                                setTimeout(() => {
                                    setExecuteCheckAppVersion(!executeCheckAppVersion);
                                }, 1);

                                return {
                                    ...prev,
                                    email: user.email!,
                                    emailVerified: user.emailVerified,
                                    uid: user.uid,
                                    idToken,
                                };
                            } else {
                                return undefined;
                            }
                        });

                        AuthUserRefreshedToken();
                    }
                } catch (e) {
                    console.error(e);
                }
            };

            window.addEventListener('focus', refreshToken);

            return () => {
                window.removeEventListener('focus', refreshToken);
            };
        }
    }, [authUser]);

    useEffect(() => {
        if (firebaseUid) {
            SignIn();
        } else {
            reset();
        }
    }, [firebaseUid]);

    /**
     * When User signIn with Firebase, load our data from BE.
     */
    const SignIn = async () => {
        try {
            const variables: UserSignInMutationVariables = {
                input: {
                    email: authUser!.email,
                    photoUrl: authUser!.photoUrl,
                    loginProviders: authUser!.loggedInWith,
                    language,
                },
            };

            const response = await mutateSignIn({variables});

            if (!response.errors) {
                const theData = response.data!.userSignIn;

                UserStartSwitchCompany(appDataContext, UserCompany(theData.companyIds));
            }
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <>
            {children}
        </>
    );
}
