import {ErrorToast} from "./ToastService";
import {tt} from "../core/Localization";
import {IAppDataContext} from "../AppData";
import {DateTime} from "luxon";

const kZeroIdCode = 'Code-IDZERO';

const kInvalidUserCode = 'IU:';

const kCompanyMaxEmployeesReachedException = 'CMER:';

export const kQueryErrorToastThrottle = 200;
export const kMutationErrorToastThrottle = 200;

let lastQueryErrorTime = DateTime.now();
let lastMutationErrorTime = DateTime.now();

/**
 * Display error message for code or in general.
 */
export function processQueryError(appDataContext: IAppDataContext, error: any) {
    console.error(error);

    const now = DateTime.now();

    if (now.diff(lastQueryErrorTime).as('milliseconds') < kQueryErrorToastThrottle) {
        return;
    }

    lastQueryErrorTime = now;

    const {processing} = appDataContext;
    const {deleteCompany} = processing;

    if (deleteCompany) {
        return;
    }

    if (error.message.includes(kInvalidUserCode)) {
        ErrorToast(tt('common.query.error.IU'));
        return;
    }

    if (!error.message.includes(kZeroIdCode)) {
        ErrorToast(tt('common.query.error'));
    }
}

/**
 * Display error message for code or in general.
 */
export function processMutationError(error: any) {
    console.error(error);

    const now = DateTime.now();

    if (now.diff(lastMutationErrorTime).as('milliseconds') < kMutationErrorToastThrottle) {
        return;
    }

    lastMutationErrorTime = now;

    if (error.message.includes(kInvalidUserCode)) {
        ErrorToast(tt('common.mutation.error.IU'));
        return;
    }

    if (error.message.includes(kCompanyMaxEmployeesReachedException)) {
        ErrorToast(tt('common.mutation.error.CMER'));
        return;
    }

    if (!error.message.includes(kZeroIdCode)) {
        ErrorToast(tt('common.mutation.error'));
    }
}
