import {makeStyles} from "tss-react/mui";
import {Theme} from "@mui/material";
import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import AppChip from "../chips/AppChip";
import {UserFullName, UserPhotoUrl} from "../../../service/UserService";
import {tt} from "../../../core/Localization";
import AppAvatar from "../AppAvatar";
import ChoseWorkerModalBottomSheet from "../modals/job/editJob/ChoseWorkerModalBottomSheet";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import {processQueryError} from "../../../service/ErrorService";
import {AppDataContext} from "../../../AppData";
import {RestApiClientContext} from "../../../core/RestApiProvider";
import {EmployeeJoinedUserOthersResponse, GetEmployeeJoinedUserInput} from "../../../generated/graphql/graphql";
import {kActionDelete, kActionUpdate, kTopicCompanyEmployees} from "../../../core/constants";
import IEventSystemNotification from "../../../model/firestore/EventSystemNotification";

const useStyles = makeStyles()((theme: Theme) => ({
    chip: {
        paddingLeft: 4,
        'p': {
            color: kAppColors.text.black + ' !important',
        }
    }
}));

export interface IChooseWorkerMenuProps {
    employeeId: number | null;
    setEmployeeId: Dispatch<SetStateAction<number | null>>;
}

export default function ChooseWorkerMenu(props: IChooseWorkerMenuProps) {
    const {employeeId, setEmployeeId} = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {storage, setStorage} = appDataContext;

    const {classes} = useStyles();

    const [bottomSheetWorkers, setBottomSheetWorkers] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<EmployeeJoinedUserOthersResponse | NullOrUndefined>();
    useEffect(() => {
        if (employeeId) {
            const subscription = subscribe(
                kTopicCompanyEmployees,
                {
                    uri: '/company/employee/joined-user-others',
                    params: {
                        employeeId,
                    } as GetEmployeeJoinedUserInput,
                    setLoading,
                    onData: setData,
                    onError: (error) => processQueryError(appDataContext, error),
                },
                (notification: IEventSystemNotification[]) => {
                    const isDeleted = notification.some(n => n.action === kActionDelete && n.data.id === employeeId);

                    if (isDeleted) {
                        setEmployeeId(null);

                        return false;
                    }

                    return notification.some(n => n.action === kActionUpdate && n.data.id === employeeId);
                },
            );

            return () => {
                subscription.cancel();
            };
        } else {
            setData(null);
        }
    }, [employeeId]);

    useEffect(() => {
        if (data) {
            setStorage((prev) => {
                return {
                    filesToProcess: [
                        ...prev.filesToProcess,
                        ...(data.files || []),
                    ],
                };
            });
        }
    }, [data]);

    return (
        <>
            <AppChip
                className={classes.chip}
                chipstyle={'secondary'}
                icon={
                    data ? (
                        <AppAvatar
                            variant={"tiny"}
                            title={UserFullName(data?.employee.name || data?.employee.user?.name, data?.employee.surname || data?.employee.user?.surname)}
                            img={
                                UserPhotoUrl(data?.employee.user, data?.files, storage.publicUrlsForFiles)
                            }
                        />
                    ) : undefined
                }
                label={
                    data ? UserFullName(data?.employee.name || data?.employee.user?.name, data?.employee.surname || data?.employee.user?.surname) : tt('common.allWorkers')
                }
                onClick={() => setBottomSheetWorkers(true)}
            />

            <ChoseWorkerModalBottomSheet
                employeeId={employeeId}
                setOpen={setBottomSheetWorkers}
                open={bottomSheetWorkers}
                onSave={setEmployeeId}
            />
        </>
    );
}
