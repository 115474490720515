import {Box, Divider, Theme, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {tt} from "../../../../../core/Localization";
import {
    GetVisitsInput,
    VisitJoinedOthersResponsePage,
    VisitStatus,
    VisitStatusModifier
} from "../../../../../generated/graphql/graphql";
import {useSearchParams} from "react-router-dom";
import React, {useContext, useEffect, useId, useMemo, useRef, useState} from "react";
import {DateTime} from "luxon";
import {AppDataContext} from "../../../../../AppData";
import AppButton from "../../../buttons/AppButton";
import {boxContentStyles} from "../../../../../styles/UtilStyles";
import IVisitEvent from "../../../../../model/VisitEvent";
import {
    FilterJobEmployeeTimesheetItems,
    filterJobOfferSeatsForVisit,
    IVisitsDay,
    kVisitsListDisplayLimitPage,
    ProcessVisitsEventsToDays,
    VisitsProcess,
    VisitsProcessScheduleLater
} from "../../../../../service/VisitService";
import {kAppColors, kContentWidthMedium} from "../../../../../styles/AppThemeProcessor";
import VisitItemWithBackgroundShimmer from "../../../shimmers/VisitItemWithBackgroundShimmer";
import JobsDateHeadlineShimmer from "../../../shimmers/JobsDateHeadlineShimmer";
import {DatesRangeValue} from "@mantine/dates";
import DateRangeModal from "../../../modals/DateRangeModal";
import {usePopupState} from "material-ui-popup-state/hooks";
import Menu from "@mui/material/Menu";
import {bindMenu} from "material-ui-popup-state";
import VisitDateFilterMenuItem from "./VisitDateFilterMenuItem";
import {MillisToDate} from "../../../../../utils/DateUtils";
import FilterButton from "../../../buttons/FilterButton";
import VisitsByWorkerFilter from "./VisitsByWorkerFilter";
import VisitsByLocationFilter from "./VisitsByLocationFilter";
import {processQueryError} from "../../../../../service/ErrorService";
import FilterByClientFilter from "../../FilterByClientFilter";
import VisitsByNameFilter from "./VisitsByNameFilter";
import {DateRangeType, getDateRangeIntervalData, isJobOfferSeatFull} from "../../../../../service/JobService";
import VisitsByStatusFilter from "./VisitsByStatusFilter";
import VisitListItem from "../../jobDetail/VisitListItem";
import {filterJobFormsPureByVisit} from "../../../../../service/JobFormService";
import {filterProductsForVisit} from "../../../../../service/ProductService";
import {filterMaterialsForVisit} from "../../../../../service/MaterialService";
import {kTopicVisits} from "../../../../../core/constants";
import {RestApiClientContext} from "../../../../../core/RestApiProvider";
import {getPublicUrls} from "../../../../../service/StorageService";

export const useStyles = makeStyles()((theme: Theme) => ({
    paginationContainer: {
        display: "flex",
        justifyContent: "center",
        paddingTop: 8,
        paddingBottom: 8,
    },
    chipsContainerOuter: {
        maxWidth: '100vw',
        marginRight: -24,
        marginLeft: -24,
        overflowX: "auto",
        paddingBottom: 8,
        "@media (max-width: 768px)": {
            marginRight: 0,
            marginLeft: 0,
        }
    },
    chipsContainer: {
        maxWidth: '100vw',
        paddingLeft: 16,
        paddingRight: 16,
        display: "flex",
        justifyContent: "center",
        'button': {
            flexShrink: 0,
            marginRight: 8,
        },
        "@media (max-width: 991px)": {
            justifyContent: "start",
            paddingRight: 20,
        },
    },
    cardsContainer: {
        marginRight: "auto",
        marginLeft: "auto",
        border: "none",
    },
    dateHeader: {
        zIndex: 1,
        position: "sticky",
        top: -16,
        fontSize: 18,
        textAlign: "center",
        fontWeight: 600,
        margin: 0,
        paddingTop: 16,
        paddingBottom: 16,
        marginLeft: -16,
        marginRight: -16,
        backgroundColor: kAppColors.background.bodyBackground(theme.palette.mode === "dark"),
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        "@media (max-width: 768px)": {
            top: 0,
            marginRight: 0,
            marginLeft: 0,
        }
    },
    chevronDown: {
        marginRight: -8,
        paddingLeft: 4,
    },
    checkIcon: {
        marginLeft: -8,
        paddingLeft: 8,
    },
    menuOffset: {
        '.MuiPaper-root': {
            minWidth: 200,
        },
        transform: 'translateY(8px)',
    },
    singleItemContainer: {
        marginBottom: 16,
        overflow: "clip",
        cursor: "pointer",
        transition: 'background-color 0.3s linear',
        background: theme.palette.mode === "dark" ? '#202020' : "white",
        borderRadius: 8,
        "@media (max-width: 767px)": {
            border: "none",
            borderRadius: 0,
        }
    },
    noMarginBottom: {
        marginBottom: 0,
    }
}));


interface IJobsMonth {
    startDate: DateTime;
    endDate: DateTime;
    days: IVisitsDay[];
}

export interface IJobsCardsTabProps {
    isMobile: boolean | undefined;
}

export default function VisitsCardsTab(props: IJobsCardsTabProps) {
    const {isMobile} = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId, language, storage, setStorage} = appDataContext;

    const {classes, cx} = useStyles();
    const {classes: boxContentClasses} = boxContentStyles();

    const [searchParams, setSearchParams] = useSearchParams();

    const [dateRange, setDateRange] = useState<DateRangeType>(searchParams.get('dateRange') as any || DateRangeType.today);
    const [fromDate, setFromDate] = useState(searchParams.get('fromDate') ? DateTime.fromMillis(parseInt(searchParams.get('fromDate')!)) : DateTime.now().startOf("day"));
    const [toDate, setToDate] = useState(searchParams.get('toDate') ? DateTime.fromMillis(parseInt(searchParams.get('toDate')!)) : DateTime.now().endOf("day"));
    const [unfilteredVisits, setUnfilteredVisits] = useState<IVisitEvent[]>([]);
    const [monthsData, setMonthsData] = useState<IJobsMonth[]>([]);
    const [jobsCount, setJobsCount] = useState<number>(0);
    const [jobsDisplayLimit, setJobsDisplayLimit] = useState<number>(searchParams.get('jobsDisplayLimit') ? parseInt(searchParams.get('jobsDisplayLimit')!) : kVisitsListDisplayLimitPage);
    const [datePickerRangeValue, setDatePickerRangeValue] = useState<DatesRangeValue | undefined>();
    const [dateRangeModal, setDateRangeModal] = useState<boolean>(false);
    const [statusFilter, setStatusFilter] = useState<VisitStatus | undefined>(searchParams.get('statusFilter') as any);
    const [statusModifierFilter, setStatusModifierFilter] = useState<VisitStatusModifier | undefined>(searchParams.get('statusModifierFilter') as any);
    const [employeesSelected, setEmployeesSelected] = useState<number[]>(searchParams.get('employeesSelected') ? searchParams.get('employeesSelected')!.split(',').map(id => parseInt(id)) : []);
    const [locationSelected, setLocationSelected] = useState<number | undefined>(searchParams.get('locationSelected') ? parseInt(searchParams.get('locationSelected')!) : undefined);
    const [clientSelected, setClientSelected] = useState<number | undefined>(searchParams.get('clientSelected') ? parseInt(searchParams.get('clientSelected')!) : undefined);
    const [visitSelected, setVisitSelected] = useState<string | undefined>(searchParams.get('visitSelected') ? searchParams.get('visitSelected')! : undefined);
    const [hasDefectFilter, setHasDefectFilter] = useState<boolean>(!!searchParams.get('hasDefectFilter'));

    const datepickerButtonRef = useRef<HTMLDivElement>(null);
    const navigateToItemRef = useRef<HTMLDivElement | null>(null);

    const dateFilterMenu = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<VisitJoinedOthersResponsePage | NullOrUndefined>();
    useEffect(() => {
        if (companyId) {
            const subscription = subscribe(
                kTopicVisits,
                {
                    uri: '/job/visit/search-joined-others',
                    params: {
                        companyId: companyId!,
                        fromDate: fromDate.toMillis(),
                        toDate: toDate.toMillis(),
                        includeScheduleLater: true,
                    } as GetVisitsInput,
                    setLoading,
                    onData: setData,
                    onError: (error) => {
                        processQueryError(appDataContext, error);
                    },
                },
                (notifications) => true,
            );

            return () => subscription.cancel();
        }
    }, [companyId, fromDate, toDate]);

    useEffect(() => {
        if (data) {
            setStorage((prev) => {
                return {
                    filesToProcess: [
                        ...prev.filesToProcess,
                        ...(data.files || []),
                    ],
                };
            });
        }
    }, [data]);

    useEffect(() => {
        if (!searchParams.get('dateRange')) {
            setSearchParams(prev => {
                prev.set('dateRange', dateRange);
                prev.set('fromDate', `${fromDate!.toMillis()}`);
                prev.set('toDate', `${toDate!.toMillis()}`);
                return prev;
            });
        }
    }, []);

    useEffect(() => {
        setSearchParams(prev => {
            prev.set('jobsDisplayLimit', jobsDisplayLimit.toString());
            return prev;
        });
    }, [jobsDisplayLimit]);

    useEffect(() => {
        if (data) {
            let visitsProcessed = VisitsProcess({
                nonRepeating: data.nonRepeating,
                repeating: data.repeating,
                visitRepeatDays: data.repeatingDayData,
                from: fromDate,
                to: toDate,
                multipleEventsForMultiDay: true,
            });
            const theUnfilteredVisits = visitsProcessed;
            setTimeout(() => {
                setUnfilteredVisits(theUnfilteredVisits);
            }, 1);

            const theJobOfferSeats = data.jobOfferSeats;

            visitsProcessed = statusFilter !== VisitStatus.ScheduleLater ? visitsProcessed.filter(visit => {
                const jobOfferSeats = filterJobOfferSeatsForVisit(visit, theJobOfferSeats);

                const status = visit.visitRepeatDay?.status || visit.status;
                const statusModifier = visit.visitRepeatDay?.statusModifier || visit.statusModifier;

                let theStatusFilter = !statusFilter || status === statusFilter;

                if (!theStatusFilter && jobOfferSeats) {
                    const notFull = jobOfferSeats.find(seat => !isJobOfferSeatFull(seat));

                    if (notFull && statusFilter == VisitStatus.JobOffer) {
                        theStatusFilter = true;
                    }
                }

                if (statusFilter === VisitStatus.Closed && !statusModifierFilter && statusModifier && statusModifier !== VisitStatusModifier.None) {
                    theStatusFilter = false;
                }
                if (statusFilter === VisitStatus.Closed && statusModifierFilter && statusModifierFilter !== statusModifier) {
                    theStatusFilter = false;
                }

                const employeeIds = visit.visitRepeatDay?.employeeIds || visit.employeeIds;

                let employeeFilter = employeesSelected.length === 0;

                if (employeesSelected.length > 0) {
                    if (employeeIds.some(employeeId => employeesSelected.includes(employeeId))) {
                        employeeFilter = true;
                    }

                    if (jobOfferSeats) {
                        const jobOfferSeatForEmployee = jobOfferSeats.find(jobOfferSeat => jobOfferSeat.employeeIds.some(employeeId => employeesSelected.includes(employeeId)));

                        if (jobOfferSeatForEmployee) {
                            employeeFilter = true;
                        }
                    }
                }

                if (visitSelected && visit.dynamicId) {
                    return visit.dynamicId === visitSelected;
                }
                if (visitSelected) {
                    return visit.id === parseInt(visitSelected);
                }

                const visitDefectsCount = visit.visitRepeatDay?.visitDefectsCount ?? visit.visitDefectsCount;

                const defectFilter = !hasDefectFilter || visitDefectsCount > 0;

                const locationFilter = !locationSelected || visit.locationId === locationSelected;

                const clientFilter = !clientSelected || visit.clientId === clientSelected;

                return theStatusFilter && employeeFilter && defectFilter && locationFilter && clientFilter;
            }) : VisitsProcessScheduleLater(data.scheduleLater || []);

            setMonthsData([
                {
                    startDate: fromDate,
                    endDate: toDate,
                    days: ProcessVisitsEventsToDays(
                        visitsProcessed,
                    ),
                },
            ]);

            setJobsCount(visitsProcessed.length);
        }
    }, [data, statusFilter, statusModifierFilter, employeesSelected, hasDefectFilter, locationSelected, clientSelected, visitSelected]);

    useEffect(() => {
        if (datePickerRangeValue && datePickerRangeValue[0] && datePickerRangeValue[1]) {
            const fromDate = DateTime.fromMillis(datePickerRangeValue[0].valueOf()).startOf("day");
            const toDate = DateTime.fromMillis(datePickerRangeValue[1].valueOf()).endOf("day");

            setDateRange(DateRangeType.custom);
            setFromDate(fromDate);
            setToDate(toDate);

            setSearchParams(prev => {
                prev.set('dateRange', DateRangeType.custom);
                prev.set('fromDate', `${fromDate.toMillis()}`);
                prev.set('toDate', `${toDate.toMillis()}`);
                return prev;
            });
        }
    }, [datePickerRangeValue]);

    /**
     * Set VisitStatus filter and JobStatusModifier filter with URL params.
     */
    const SetVisitStatusFilter = (status?: VisitStatus, statusModifier?: VisitStatusModifier) => {
        setStatusFilter(status);
        setStatusModifierFilter(statusModifier);

        setSearchParams(prev => {
            prev.set('statusFilter', status || '');
            prev.set('statusModifierFilter', statusModifier || '');

            return prev;
        });
    };

    useEffect(() => {
        setSearchParams(prev => {
            prev.set('employeesSelected', employeesSelected.join(','));

            return prev;
        });
    }, [employeesSelected]);

    useEffect(() => {
        setSearchParams(prev => {
            prev.set('hasDefectFilter', hasDefectFilter ? 'true' : '');

            return prev;
        });
    }, [hasDefectFilter]);

    useEffect(() => {
        setSearchParams(prev => {
            prev.set('locationSelected', locationSelected?.toString() || '');

            return prev;
        });
    }, [locationSelected]);

    useEffect(() => {
        setSearchParams(prev => {
            prev.set('clientSelected', clientSelected?.toString() || '');

            return prev;
        });
    }, [clientSelected]);

    useEffect(() => {
        setSearchParams(prev => {
            prev.set('visitSelected', visitSelected || '');

            return prev;
        });
    }, [visitSelected]);

    /**
     * Scroll to job and clear.
     */
    const ScrollToJob = () => {
        setTimeout(() => {
            navigateToItemRef.current?.scrollIntoView({behavior: 'smooth'});

            setSearchParams(prev => {
                prev.set('id', '');
                return prev;
            });
        }, 1);
    };

    const cards = useMemo(() => {
        let jobsDisplaying = 0;

        return (
            <>
                {monthsData.map((month, index) => {
                    return (
                        <div key={`month-${month.startDate.toMillis()}`}>

                            {month.days.map(day => {
                                if (jobsDisplayLimit < jobsDisplaying && jobsDisplaying !== 0) {
                                    return null;
                                }

                                jobsDisplaying += day.visits.length;

                                return (
                                    <div key={day.date.toMillis()}>
                                        {statusFilter !== VisitStatus.ScheduleLater ? (
                                            <Typography
                                                paragraph={true}
                                                className={classes.dateHeader}
                                            >
                                                {day.date.toFormat('EEEE d MMMM yyyy', {locale: language})}
                                            </Typography>
                                        ) : undefined}

                                        {day.visits.map((visit, index) => {
                                            const navigateToId = searchParams.get('id');

                                            if (navigateToId && navigateToId == (visit.dynamicId || visit.id)) {
                                                ScrollToJob();
                                            }

                                            const employees = data?.employees || [];

                                            const job = data?.jobs.find(job => job.id === visit.jobId);
                                            const client = data?.clients.find(client => client.id === visit.clientId);
                                            const locationId = visit.visitRepeatDay?.locationId || visit.locationId;
                                            const location = locationId ? data?.locations.find(location => location.id === locationId) : undefined;
                                            const jobForms = filterJobFormsPureByVisit(visit.id, visit.repeatingDay, data?.jobForms || []);
                                            const timesheets = FilterJobEmployeeTimesheetItems({
                                                jobEmployeeTimesheetItems: data?.timesheets,
                                                repeatingDay: visit.repeatingDay,
                                                filterByVisitId: visit.id,
                                            });
                                            const products = filterProductsForVisit({
                                                products: data?.products,
                                                visitId: visit.id,
                                                repeatingDay: visit.repeatingDay,
                                            });
                                            const materials = filterMaterialsForVisit({
                                                materials: data?.materials,
                                                visitId: visit.id,
                                                repeatingDay: visit.repeatingDay,
                                            });
                                            const jobOfferSeats = filterJobOfferSeatsForVisit(visit, data?.jobOfferSeats);

                                            const theEmployeeIds = visit.visitRepeatDay?.employeeIds || visit.employeeIds;
                                            const theEmployees = employees.filter(employee => {
                                                const jobOfferSeatForEmployee = jobOfferSeats?.find(jobOfferSeat => jobOfferSeat.acceptedIds.includes(employee.id));

                                                return theEmployeeIds.includes(employee.id) || jobOfferSeatForEmployee;
                                            });

                                            return (
                                                <div key={visit.dynamicId || visit.id}
                                                     ref={(navigateToId && navigateToId == (visit.dynamicId || visit.id)) ? navigateToItemRef : undefined}>
                                                    <Box
                                                        className={cx(classes.singleItemContainer, index === day.visits.length - 1 && visit.status != VisitStatus.ScheduleLater ? classes.noMarginBottom : null)}>
                                                        <VisitListItem
                                                            key={visit.dynamicId || visit.id}
                                                            data={visit}
                                                            job={job}
                                                            client={client}
                                                            location={location}
                                                            employees={theEmployees}
                                                            jobForms={jobForms}
                                                            timesheets={timesheets}
                                                            products={products}
                                                            materials={materials}
                                                            jobOfferSeats={jobOfferSeats}
                                                            visitDetailCanNavigateToJob={true}
                                                            files={data?.files}
                                                        />
                                                    </Box>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}

                            {month.days.length === 0 ? (
                                <Typography sx={{textAlign: 'center', paddingTop: 4}} paragraph={true}>
                                    {tt('visits.screen.empty')}
                                </Typography>
                            ) : undefined}
                        </div>
                    );
                })}

                {jobsDisplayLimit < jobsCount ? (
                    <>
                        <Box sx={{pb: 2}}/>

                        <Box className={boxContentClasses.centered}>
                            <AppButton variant="outlined" color="primary" disabled={loading}
                                       onClick={() => setJobsDisplayLimit(jobsDisplayLimit + kVisitsListDisplayLimitPage)}>
                                {tt('common.loadMore')}
                            </AppButton>
                        </Box>

                        <Box sx={{pb: 2}}/>
                    </>
                ) : undefined}
            </>
        );
    }, [data, fromDate, toDate, monthsData, jobsDisplayLimit, storage.publicUrlsForFiles]);

    const regularDateFilters = [
        DateRangeType.yesterday,
        DateRangeType.today,
        DateRangeType.tomorrow,
        DateRangeType.thisWeek,
        DateRangeType.lastWeek,
        DateRangeType.thisMonth,
        DateRangeType.lastMonth,
        DateRangeType.nextMonth,
        DateRangeType.thisYear,
    ];

    const clientsOnUnfilteredJobs = useMemo(() => {
        const clients: number[] = [];

        unfilteredVisits.forEach(job => {
            const client = job.clientId;

            if (!clients.includes(client)) {
                clients.push(client);
            }
        });

        return clients;
    }, [unfilteredVisits]);

    const locationsOnUnfilteredJobs = useMemo(() => {
        const locations: number[] = [];

        unfilteredVisits.forEach(job => {
            const location = job.locationId;

            if (location && !locations.includes(location)) {
                locations.push(location);
            }
        });

        return locations;
    }, [unfilteredVisits]);

    const employeesOnUnfilteredJobs = useMemo(() => {
        const employees: number[] = [];

        unfilteredVisits.forEach(job => {
            const employeeIds = job.visitRepeatDay?.employeeIds || job.employeeIds;

            employeeIds.forEach(employeeId => {
                if (!employees.includes(employeeId)) {
                    employees.push(employeeId);
                }
            });
        });

        return employees;
    }, [unfilteredVisits]);

    return (
        <>
            <Box className={cx('styledScrollbar', classes.chipsContainerOuter)}>
                <Box className={classes.chipsContainer}>
                    <Box ref={datepickerButtonRef}>
                        <FilterButton
                            isSelected={dateRange !== DateRangeType.none}
                            title={dateRange === DateRangeType.custom ? `${MillisToDate(fromDate?.valueOf())} - ${MillisToDate(toDate?.valueOf())}`
                                : getDateRangeIntervalData(dateRange).title}
                            onClick={(e) => {
                                dateFilterMenu.setOpen(true, e);
                            }}
                        />
                    </Box>

                    <Menu className={classes.menuOffset}  {...bindMenu(dateFilterMenu)}>
                        {regularDateFilters.map(filterType => <VisitDateFilterMenuItem
                            key={filterType}
                            closeMenu={() => dateFilterMenu.setOpen(false)}
                            type={filterType}
                            setDateRange={setDateRange}
                            setFromDate={setFromDate}
                            setToDate={setToDate}
                        />)}
                        <Divider sx={{
                            mr: -2, ml: -2,
                        }}/>
                        <VisitDateFilterMenuItem
                            key={DateRangeType.custom}
                            closeMenu={() => dateFilterMenu.setOpen(false)}
                            setDateRangeModal={() => {
                                setDatePickerRangeValue(undefined);
                                setDateRangeModal(true);
                            }}
                            type={DateRangeType.custom}
                            setDateRange={setDateRange}
                            setFromDate={setFromDate}
                            setToDate={setToDate}
                        />
                    </Menu>

                    <VisitsByNameFilter
                        selected={visitSelected}
                        setSelected={setVisitSelected}
                        visits={unfilteredVisits}
                    />

                    <VisitsByStatusFilter
                        statusModifierFilter={statusModifierFilter}
                        statusFilter={statusFilter}
                        isDisabled={!!visitSelected}
                        SetFilter={SetVisitStatusFilter}
                        visitsData={data}
                        unfilteredVisits={unfilteredVisits}
                        notScheduledCount={(data?.scheduleLater || []).length}
                    />

                    <FilterByClientFilter
                        selected={clientSelected}
                        setSelected={setClientSelected}
                        validClients={clientsOnUnfilteredJobs}
                        isDisabled={!!visitSelected}
                    />

                    <VisitsByLocationFilter
                        selected={locationSelected}
                        setSelected={setLocationSelected}
                        validLocations={locationsOnUnfilteredJobs}
                        isDisabled={!!visitSelected}
                    />

                    <VisitsByWorkerFilter
                        selected={employeesSelected}
                        setSelected={setEmployeesSelected}
                        validEmployees={employeesOnUnfilteredJobs}
                        isDisabled={!!visitSelected}
                    />

                    <FilterButton
                        isSelected={hasDefectFilter}
                        hideChevronDown={true}
                        title={tt('jobs.screen.filterByDefect.button')}
                        onClick={() => {
                            setHasDefectFilter(!hasDefectFilter)
                        }}
                        isDisabled={!!visitSelected}
                    />

                    {isMobile ? <Box pr={1}/> : null}
                </Box>
            </Box>

            <Box
                className={classes.cardsContainer}
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium + 'px !important'}}>

                {statusFilter === VisitStatus.ScheduleLater ? <Box sx={{pb: 2}}/> : null}

                {loading ? <>
                    <JobsDateHeadlineShimmer/>
                    <VisitItemWithBackgroundShimmer/>
                    <VisitItemWithBackgroundShimmer/>
                </> : cards}

                <Box sx={{pb: 0.5}}/>
            </Box>

            <DateRangeModal
                anchorRef={datepickerButtonRef}
                setOpen={setDateRangeModal}
                open={dateRangeModal}
                setRangeVal={setDatePickerRangeValue}
                value={datePickerRangeValue}
            />
        </>
    );
}
