import {useContext, useEffect} from "react";
import {AppContext} from "../../../App";
import {useNavigate} from "react-router-dom";
import {kSignInRoute} from "./SignInScreen";
import {tt} from "../../../core/Localization";
import {AuthUserSignOut} from "../../../service/AuthUserService";
import {SuccessToast} from "../../../service/ToastService";

export const kSignOutRoute = '/sign-out';

/**
 * Screen component which signsOut current authUser.
 */
export default function SignOutScreen() {
    const appContext = useContext(AppContext);
    const {setTitle, authUser, setAuthUser} = appContext;

    const navigate = useNavigate();

    useEffect(() => {
        setTitle(tt('signOut.screen.title'));

        AuthUserSignOut(appContext)
            .then(() => SuccessToast(tt('common.userWasSignedOut')))
            .catch(e => console.error(e));
    }, []);

    useEffect(() => {
        if (!authUser) {
            navigate(kSignInRoute);
        }
    }, [authUser]);

    return null;
}
