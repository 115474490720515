import ResponsiveContainer from "../screens/ResponsiveContainer";
import { useContext, useEffect, useState } from "react";
import ScreenContent from "../screens/ScreenContent";
import { kAppColors, kContentWidthNarrow } from "../../../styles/AppThemeProcessor";
import AppPaper from "../paper/AppPaper";
import { Box, Theme, Typography } from "@mui/material";
import { tt } from "../../../core/Localization";
import AppChip from "../chips/AppChip";
import { AddOutlined } from "@mui/icons-material";
import TextDivider from "../decorations/TextDivider";
import KeyIcon from "../../../icons/KeyIcon";
import { makeStyles } from "tss-react/mui";
import { AppDataContext } from "../../../AppData";
import { SetCreateCompanyModal, SetJoinCompanyModal } from "../modals/AppModals";
import { trackDataLayerEvent } from "../../../service/AnalyticsService";
import { UserResponse } from "../../../generated/graphql/graphql";
import { processQueryError } from "../../../service/ErrorService";
import { RestApiClientContext } from "../../../core/RestApiProvider";
import { AppContext } from "../../../App";
import IEventSystemNotification from "../../../model/firestore/EventSystemNotification";
import { kActionUpdate, kTopicUsers } from "../../../core/constants";

export const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    titleText: {
        fontSize: 32,
        fontWeight: 600,
        marginBottom: 40,
        textAlign: "center",
    },
    paper1Text: {
        color: kAppColors.text.white,
        fontSize: 20,
        fontWeight: 500,
        textAlign: "center",
        marginBottom: 32,
    },
    paper2Text: {
        color: kAppColors.text.primary(theme.palette.mode === "dark"),
        fontSize: 20,
        fontWeight: 500,
        textAlign: "center",
        marginBottom: 32,
    },
    paper1: {
        paddingTop: 32,
        paddingBottom: 32,
        paddingLeft: 16,
        paddingRight: 16,
        backgroundColor: kAppColors.primary.main,
        textAlign: "center",
        border: "none",
    },
    paper2: {
        paddingTop: 32,
        paddingBottom: 32,
        paddingLeft: 16,
        paddingRight: 16,
        backgroundColor: kAppColors.grey.background3(theme.palette.mode === "dark"),
        textAlign: "center",
        border: "none",
    },
    chip2: {
        background: theme.palette.mode === "dark" ? '#ffffff' : '#EEF0F3',
    },
})
);

/**
 * Component for display of Onboarding elements.
 */
export default function OnboardingScreenWidget() {
    return (
        <ResponsiveContainer
            smallPhoneScreen={<Body isMobile={true} />}
            largePhoneScreen={<Body isMobile={true} />}
            tabletScreen={<Body />}
            smallDesktopScreen={<Body />}
            largeDesktopScreen={<Body />}
            extraLargeDesktopScreen={<Body />} />
    );
}

interface IBodyProps {
    isMobile?: boolean;
}

/**
 * Body of the Onboarding.
 */
function Body(props: IBodyProps) {
    const { isMobile } = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;

    const appContext = useContext(AppContext);
    const {authUser} = appContext;

    const appDataContext = useContext(AppDataContext);

    const { classes } = useStyles();

    const [data, setData] = useState<UserResponse | NullOrUndefined>();
    useEffect(() => {
        const subscription = subscribe(
            kTopicUsers,
            {
                uri: '/user',
                params: {},
                onData: setData,
                onError: (error: any) => processQueryError(appDataContext, error),
            },
            (notifications: IEventSystemNotification[]) => {
                return notifications.some((notification) => {
                    return notification.action === kActionUpdate && notification.data['uid'] === authUser?.uid;
                });
            },
        );

        return () => subscription.cancel();
    }, [authUser?.uid]);

    const alreadyCreatedCompany = !!(data && data?.historicCompanyIds.length > 0);
    
    return (
        <ScreenContent appBar={!isMobile} centerContent={!isMobile} navigationDrawer={!isMobile} bottomBar={isMobile}
            mobileChoseCompanyWidget={isMobile}>
            <Box className={classes.container} maxWidth={kContentWidthNarrow}>
                <AppPaper className={classes.paper1}>
                    <Typography className={classes.paper1Text}>
                        {tt('onboarding.screen.createCompanyText')}
                    </Typography>
                    <AppChip
                        chipstyle={"tertiary"}
                        onClick={() => {
                            if (alreadyCreatedCompany) {
                                trackDataLayerEvent('next_company_form_modal_start');
                            } else {
                                trackDataLayerEvent('new_company_form_modal_start');
                            }

                            SetCreateCompanyModal(appDataContext, {
                                newCompanyOpen: true,
                                singleStep: alreadyCreatedCompany,
                                cleanFormOnOpen: true,
                            });
                        }
                        }
                        icon={<AddOutlined />}
                        label={tt('common.createCompany')} />
                </AppPaper>

                <Box pb={2.5} />

                <TextDivider text={tt('common.dividerText.or')} />

                <Box pb={2.5} />

                <AppPaper className={classes.paper2}>
                    <Typography className={classes.paper2Text}>
                        {tt('onboarding.screen.joinCompanyText')}
                    </Typography>
                    <AppChip
                        className={classes.chip2}
                        chipstyle={"tertiary"}
                        onClick={() => {
                            SetJoinCompanyModal(appDataContext, {
                                open: true,
                            });
                        }
                        }
                        icon={<KeyIcon />}
                        label={tt('common.joinCompany')} />
                </AppPaper>
            </Box>
        </ScreenContent>
    );
}
