import {Accordion, AccordionDetails, AccordionSummary, Box, Theme} from "@mui/material";
import ChevronDownIcon from "../../../icons/ChevronDownIcon";
import GreyLabel from "../decorations/GreyLabel";
import React from "react";
import {makeStyles} from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
    boxWrapper: {
        position: 'relative',
    },
    root: {
        background: 'transparent !important',
        '.Mui-disabled': {
            opacity: '1 !important',
        },
        '.Mui-expanded': {
            '.chevronContainer span': {
                transform: 'rotate(180deg)',
            }
        }
    },
    summary: {
        ':hover': {
            backgroundColor: theme.palette.mode === "dark" ? 'rgba(111,111,111, 0.25)' : '#f0f2f5',
        },
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        flexGrow: 1,
        minHeight: 54,
    },
    actionsRight: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
        flexGrow: 1,
    },
    actionsRightDisabled: {
        opacity: 0,
    },
    actionsRightOverlay: {
        position: 'absolute',
        top: 3,
        right: 16,
    },
    details: {
        padding: 0,
    },
    chevronContainer: {
        display: "flex",
        alignItems: "center",
        marginLeft: 6,
        position: "relative",
        marginTop: -10,
        top: 6,
        span: {
            transition: 'transform 0.3s linear',
        }
    },
}));

export interface IAppAccordionProps {
    summaryText?: string;
    summaryWidget?: React.ReactNode;
    actionsJSX?: React.ReactNode;
    children?: React.ReactNode;
    disabled?: boolean;
}

/**
 * Custom designed accordion component.
 */
export default function AppAccordion(props: IAppAccordionProps) {
    const {
        summaryText,
        actionsJSX,
        children,
        disabled,
        summaryWidget
    } = props;

    const {classes, cx} = useStyles();

    return (
        <Box className={classes.boxWrapper}>
            <Accordion
                className={classes.root}
                disabled={disabled}
            >
                <AccordionSummary
                    className={classes.summary}
                >
                    <Box className={classes.actions}>
                        <Box sx={{pt: 1, pb: 1, display: 'flex'}}>
                            {summaryWidget}
                            {summaryText ? <GreyLabel text={summaryText}/> : null}

                            {disabled ? null : <Box
                                className={cx(classes.chevronContainer, 'chevronContainer')}><ChevronDownIcon/></Box>}
                        </Box>

                        {actionsJSX ? (
                            <Box className={cx(
                                classes.actionsRight,
                                disabled ? classes.actionsRightDisabled : null,
                            )}>
                                {actionsJSX}
                            </Box>
                        ) : null}
                    </Box>
                </AccordionSummary>

                <AccordionDetails className={classes.details}>
                    {children}
                </AccordionDetails>
            </Accordion>

            {actionsJSX && disabled ? (
                <Box className={cx(classes.actionsRight, classes.actionsRightOverlay)}>
                    {actionsJSX}
                </Box>
            ) : null}
        </Box>
    );
}
