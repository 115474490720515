import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import FormBuilder, {IInputsData, InputType} from "../form/FormBuilder";
import {tt} from "../../../core/Localization";
import {Box, Divider, InputAdornment, Theme, Typography} from "@mui/material";
import {
    GetJobsInput,
    JobJoinedOthersResponseResponsePage,
    JobResponse,
    VisitStatus,
    VisitStatusModifier,
} from "../../../generated/graphql/graphql";
import {AppDataContext} from "../../../AppData";
import {boxContentStyles} from "../../../styles/UtilStyles";
import {makeStyles} from "tss-react/mui";
import {SetURLSearchParams, useNavigate} from "react-router-dom";
import {
    FilterCheckJobIsValidForVisitStatusAndVisitStatusModifier,
    JobNameOrSequenceId,
    kJobsListDisplayLimitPage
} from "../../../service/JobService";
import {processQueryError} from "../../../service/ErrorService";
import {jobDetailRoute} from "../../screens/jobs/JobDetailScreen";
import {routeWithCurrentAsParam} from "../../../utils/Utils";
import AppButton from "../buttons/AppButton";
import SearchIcon from "../../../icons/SearchIcon";
import AppIconButton from "../buttons/AppIconButton";
import Icons8Plus from "../../../icons/Icons8Plus";
import AppListItemShimmer from "../shimmers/AppListItemShimmer";
import SingleJobListItem from "../jobs/SingleJobListItem";
import {newJobOrVisitRoute, NewJobOrVisitRouteKind} from "../../screens/jobs/NewJobOrVisitScreen";
import JobsByStatusFilter from "../jobs/JobsByStatusFilter";
import {kTopicJobs} from "../../../core/constants";
import {RestApiClientContext} from "../../../core/RestApiProvider";
import {getPublicUrls} from "../../../service/StorageService";

export const useStyles = makeStyles()((theme: Theme) => ({
    chipsContainerOuter: {
        flexShrink: 0,
        overflowX: "auto",
        paddingBottom: 8,
        maxWidth: '100%',
    },
    chipsContainer: {
        paddingLeft: 16,
        paddingRight: 16,
        display: "flex",
        'button': {
            flexShrink: 0,
            marginRight: 8,
        },
    },
    menuOffset: {
        '.MuiPaper-root': {
            minWidth: 200,
        },
        transform: 'translateY(8px)',
    },
    searchContainer: {
        display: "flex",
        alignItems: "center",
        paddingTop: 8,
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 16,
        '.MuiFormControl-root': {
            margin: 0 + ' !important',
        }
    }
}));

export interface IClientJobsTabProps {
    clientId: number;
    searchParams: URLSearchParams;
    setSearchParams: SetURLSearchParams;
}

export default function ClientJobsTab(props: IClientJobsTabProps) {
    const {clientId, searchParams, setSearchParams} = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId, storage, setStorage} = appDataContext;

    const {classes, cx} = useStyles();
    const {classes: boxContentClasses} = boxContentStyles();

    const navigate = useNavigate();

    const statusFilterFromParams = searchParams.get('jobsStatusFilter') as VisitStatus | undefined;

    const [unfilteredJobs, setUnfilteredJobs] = useState<JobResponse[]>([]);
    const [jobsCount, setJobsCount] = useState<number>(0);
    const [jobsDisplayLimit, setJobsDisplayLimit] = useState<number>(searchParams.get('jobsDisplayLimit') ? parseInt(searchParams.get('jobsDisplayLimit')!) : kJobsListDisplayLimitPage);
    const [statusFilter, setStatusFilter] = useState<VisitStatus | undefined>(statusFilterFromParams);
    const [statusModifierFilter, setStatusModifierFilter] = useState<VisitStatusModifier | undefined>(searchParams.get('jobsStatusModifierFilter') as any);

    const [inputs, setInputs] = useState<IInputsData>({
        search: {
            type: InputType.Text,
            label: '',
            inputVariant: 'standard',
            extraStyle: 'thin',
            hideLabel: true,
            placeholder: tt('jobs.screen.search.placeholder'),
            value: searchParams.get('jobsQuery') || '',
            required: true,
            isClearable: true,
            innerPrefixJSX: (
                <InputAdornment position={"start"}>
                    <SearchIcon/>
                </InputAdornment>
            ),
        },
    });
    const theQuery = inputs.search.value;

    const navigateToItemRef = useRef<HTMLDivElement | null>(null);

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<JobJoinedOthersResponseResponsePage | NullOrUndefined>();
    useEffect(() => {
        if (companyId) {
            const subscription = subscribe(
                kTopicJobs,
                {
                    uri: '/job/search-joined-others',
                    params: {
                        companyId: companyId!,
                        clientId: clientId,
                        query: theQuery || undefined,
                        page: theQuery && theQuery.length > 0 ? undefined : 0,
                        pageSize: theQuery && theQuery.length > 0 ? undefined : (kJobsListDisplayLimitPage * 4),
                    } as GetJobsInput,
                    setLoading,
                    onData: (value: JobJoinedOthersResponseResponsePage) => {
                        if (value) {
                            setData({
                                ...value,
                                jobs: value.jobs.map(job => ({
                                    ...job,
                                    stats: value.stats.find(stats => stats.jobId === job.id),
                                })),
                            });
                        } else {
                            setData(null);
                        }
                    },
                    onError: (error: any) => processQueryError(appDataContext, error),
                },
                () => true,
            );

            return () => {
                subscription.cancel();
            };
        }
    }, [companyId, clientId, theQuery]);

    useEffect(() => {
        if (data) {
            setStorage((prev) => {
                return {
                    filesToProcess: [
                        ...prev.filesToProcess,
                        ...(data.files || []),
                    ],
                };
            });
        }
    }, [data]);

    useEffect(() => {
        setSearchParams(prev => {
            prev.set('jobsDisplayLimit', jobsDisplayLimit.toString());
            return prev;
        });
    }, [jobsDisplayLimit]);

    /**
     * Set JobStatus filter with URL params.
     */
    const SetJobStatusFilter = (status?: VisitStatus, statusModifier?: VisitStatusModifier) => {
        setStatusFilter(status);
        setStatusModifierFilter(statusModifier);

        setSearchParams(prev => {
            prev.set('jobsStatusFilter', status || '');
            prev.set('jobsStatusModifierFilter', statusModifier || '');

            return prev;
        });
    };

    useEffect(() => {
        setSearchParams(prev => {
            prev.set('jobsQuery', theQuery || '');

            return prev;
        });
    }, [theQuery]);

    /**
     * Navigate to detail with parameters for return back.
     */
    const ToDetail = (id: number) => {
        setSearchParams(prev => {
            prev.set('id', id.toString());
            return prev;
        });

        let urlParams: string[] = [];

        searchParams.forEach((value: string, key: string) => {
            if (key !== 'id') {
                urlParams.push(`${key}=${value}`);
            }
        });

        urlParams.push(`id=${id}`);

        navigate(routeWithCurrentAsParam(
            jobDetailRoute({
                jobId: id,
                urlParams,
            })
        ));
    };

    /**
     * Scroll to job and clear.
     */
    const ScrollToJob = () => {
        setTimeout(() => {
            navigateToItemRef.current?.scrollIntoView({behavior: 'smooth'});

            setSearchParams(prev => {
                prev.set('id', '');
                return prev;
            });
        }, 1);
    };

    useEffect(() => {
        if (data) {
            setUnfilteredJobs([...data.jobs]);
        }
    }, [data]);

    const cards = useMemo(() => {
        let jobsDisplaying = 0;

        let jobs = data?.jobs;
        if (jobs) {
            const theQueryValue = theQuery?.toLowerCase();

            jobs = jobs.filter(job => {
                const theName = JobNameOrSequenceId(job).toLowerCase();
                const theQueryFilter = !theQueryValue || theName.includes(theQueryValue);

                const theStatusFilter = FilterCheckJobIsValidForVisitStatusAndVisitStatusModifier(job, statusFilter, statusModifierFilter);

                return job.clientId === clientId && theQueryFilter && theStatusFilter;
            })
                .sort((a, b) => b.createdAt - a.createdAt);
        }

        setJobsCount(jobs?.length || 0);

        return (
            <>
                {jobs && jobs.length > 0 ? (
                    jobs.map((job, index) => {
                        if (jobsDisplayLimit <= jobsDisplaying && jobsDisplaying !== 0) {
                            return null;
                        }

                        const navigateToId = searchParams.get('id');

                        if (navigateToId && navigateToId == job.id) {
                            ScrollToJob();
                        }

                        jobsDisplaying++;

                        return (
                            <div key={job.id}
                                 ref={(navigateToId && navigateToId == job.id) ? navigateToItemRef : undefined}>
                                <SingleJobListItem
                                    data={job}
                                    stats={data!.stats.find(stats => stats.jobId === job.id)}
                                    client={data!.clients.find(client => client.id === job.clientId)}
                                    responsiblePerson={data!.employees.find(employee => employee.id === job.responsiblePersonId)}
                                    onClick={ToDetail}
                                    files={data?.files}
                                />
                            </div>
                        );
                    })
                ) : (
                    <Typography sx={{textAlign: 'center', paddingTop: 4}} paragraph={true}>
                        {tt('jobs.screen.empty')}
                    </Typography>
                )}
            </>
        );
    }, [data, jobsDisplayLimit, theQuery, clientId, statusFilter, statusModifierFilter, storage.publicUrlsForFiles]);

    useEffect(() => {
        setJobsDisplayLimit(kJobsListDisplayLimitPage);
    }, [theQuery, statusFilter, statusModifierFilter]);

    const filtersJSX = (
        <Box className={cx('styledScrollbar', classes.chipsContainerOuter)}>
            <Box className={classes.chipsContainer}>
                <JobsByStatusFilter
                    statusModifierFilter={statusModifierFilter}
                    statusFilter={statusFilter}
                    SetFilter={SetJobStatusFilter}
                    unfilteredJobs={unfilteredJobs}
                    notScheduledCount={data?.notScheduledCount}
                />
            </Box>
        </Box>
    );

    return (
        <>
            <Box className={classes.searchContainer}>
                <FormBuilder inputs={inputs} setInputs={setInputs}/>
                <Box pr={2}/>
                <AppIconButton
                    color={"primary"}
                    tooltip={tt('common.newJob')}
                    onClick={
                        () => navigate(routeWithCurrentAsParam(
                            newJobOrVisitRoute({
                                kind: NewJobOrVisitRouteKind.fromClientDetail,
                                clientId,
                            })
                        ))
                    }><Icons8Plus/></AppIconButton>
            </Box>

            {filtersJSX}
            <Box pt={1}>
                <Divider/>
            </Box>

            {loading && !data ? <>
                <AppListItemShimmer/>
                <AppListItemShimmer/>
            </> : cards}

            {jobsDisplayLimit < jobsCount ? (
                <>
                    <Box sx={{pb: 2}}/>

                    <Box className={boxContentClasses.centered}>
                        <AppButton variant="outlined" color="primary" disabled={loading}
                                   onClick={() => setJobsDisplayLimit(jobsDisplayLimit + kJobsListDisplayLimitPage)}>
                            {tt('common.loadMore')}
                        </AppButton>
                    </Box>

                    <Box sx={{pb: 2}}/>
                </>
            ) : undefined}

            <Box sx={{pb: 0.5}}/>
        </>
    );
}
