import React, {useContext, useEffect, useMemo, useState} from "react";
import AppModal from "../AppModal";
import {Box, Theme, Tooltip, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {rem} from "@mantine/core";
import {Carousel, Embla} from "@mantine/carousel";
import {ChevronLeft, ChevronRight} from "@mui/icons-material";
import {kAppColors} from "../../../../styles/AppThemeProcessor";
import IFileState from "../../../../model/FileState";
import AppButton from "../../buttons/AppButton";
import {tt} from "../../../../core/Localization";
import Icons8Download from "../../../../icons/Icons8Download";
import {FileResponse} from "../../../../generated/graphql/graphql";
import {RestApiClientContext} from "../../../../core/RestApiProvider";
import {AppDataContext} from "../../../../AppData";

export const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        display: "flex",
        height: 'calc(100vh - 120px)',
        '.mantine-Carousel-control': {
            'svg': {
                width: 34,
                height: 34,
                color: kAppColors.text.primary(theme.palette.mode === 'dark'),
            },
            width: 44,
            height: 44,
            border: "none",
            background: kAppColors.background.contactIconButton(theme.palette.mode === "dark"),
            color: kAppColors.text.black,
            ':hover': {
                backgroundColor: kAppColors.hoverColor.contactIconButton(theme.palette.mode === "dark"),
            },
        }
    },
    hideButtons: {
        '.mantine-Carousel-control': {
            display: "none",
        }
    },
    modalPaper: {
        background: 'unset',
    },
    chevronLeftContainer: {
        'svg': {
            transform: 'rotate(180deg)'
        }
    },
    viewPDFContainer: {
        height: '100%',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    },
    fileName: {
        color: "white",
        textAlign: "center",
        paddingLeft: 16,
        paddingRight: 16,
        lineBreak: "anywhere",
        maxWidth: '100%',
    },
    downloadbutton: {
        color: "white",
        padding: 8,
        borderRadius: '50%',
        background: kAppColors.primary.main,
        display: "block",
    },
    headerClipText: {
        '.headerTitleContainer': {
            overflowX: "hidden",
            paddingRight: 8,
        },
        'p': {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
        }
    }
}));

export interface IPreviewFileModalProps {
    open: boolean;
    onClose: Function;
    fileStates: IFileState[];
    files?: FileResponse[];
    pictures?: string[] | NullOrUndefined;
    labels?: string[] | NullOrUndefined;
    indexOnOpen?: number;
}

export default function PreviewFileModal(props: IPreviewFileModalProps) {
    const {
        open,
        onClose,
        fileStates,
        files,
        indexOnOpen,
        pictures,
        labels,
    } = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {restUriBuilder} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {storage, setStorage} = appDataContext;

    const {classes, cx} = useStyles();

    const [embla, setEmbla] = useState<Embla | null>(null);
    const [initialized, setInitialized] = useState(false);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (open && !initialized) {
            setInitialized(true);
        }
    }, [open]);

    useEffect(() => {
        if (embla) {
            const listener = () => {
                setIndex(embla.selectedScrollSnap());
            };

            embla.on('select', listener);

            return () => {
                embla.off('select', listener);
            };
        }
    }, [embla]);

    useEffect(() => {
        if (open && embla && initialized) {
            embla.reInit();

            embla.scrollTo(indexOnOpen || 0);
        }
    }, [open, embla, initialized]);

    useEffect(() => {
        if (open) {
            if (!pictures) {
                setStorage((prev) => {
                    return {
                        filesToProcess: [
                            ...prev.filesToProcess,
                            ...(fileStates.filter(file => !!file.data).map(file => file.data!) || []),
                        ],
                    };
                });
            }
        }
    }, [open]);

    const slidesJSX = initialized ? (
            pictures ?
                pictures.map((picture, index) => {
                    return (
                        <Carousel.Slide key={index}>
                            <Box
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                                sx={{
                                    width: '100vw',
                                    height: '100%',
                                    objectFit: "cover",
                                    backgroundImage: `url('${picture}')`,
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center'
                                }}>
                            </Box>
                        </Carousel.Slide>
                    );
                })
                :
                fileStates.map((file, index) => {
                    const isPDF = file.data?.name.toLowerCase().endsWith('.pdf');
                    return (
                        <Carousel.Slide key={index}>
                            <Box
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                                sx={{
                                    width: '100vw',
                                    height: '100%',
                                    objectFit: "cover",
                                    backgroundImage: isPDF ? 'unset' : `url('${storage.publicUrlsForFiles[file.uuid]}')`,
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center'
                                }}>
                                {isPDF ? <Box className={classes.viewPDFContainer}>
                                    <Typography className={classes.fileName}>{file.data?.name}</Typography>
                                    <Box pb={2}/>
                                    <AppButton
                                        onClick={() => {
                                            window.open(storage.publicUrlsForFiles[file.uuid], '_blank', 'fullscreen=yes')
                                        }}
                                        variant={"contained"}
                                    >{tt('previewFiles.carousel.openFile.button')}</AppButton>
                                </Box> : null}
                            </Box>
                        </Carousel.Slide>
                    );
                })
        ) :
        [];

    const theFile = useMemo(() => {
        if (fileStates.length > 0) {
            return fileStates[index].data;
        }

        if (files && files.length > 0) {
            return files[index];
        }

        return null;
    }, [fileStates, files, index]);

    let title = fileStates.length > 0 ? (fileStates[index]?.data?.name || '') : '';
    if (!title && labels) {
        title = labels[index];
    }

    const action = theFile ? (
        <Tooltip title={tt('common.download')}>
            <a className={classes.downloadbutton}
               download={theFile.name || ''}
               target={"_blank"}
               href={restUriBuilder('/firebase/download-file', {fileId: theFile.id})}>
                <Icons8Download/>
            </a>
        </Tooltip>
    ) : (pictures ? (
        <Tooltip title={tt('common.download')}>
            <a className={classes.downloadbutton}
               download={pictures[index]}
               target={"_blank"}
               href={pictures[index]}><Icons8Download/></a>
        </Tooltip>
    ) : (
        <Tooltip title={tt('common.download')}>
            <a className={classes.downloadbutton}
               download={fileStates[index]?.data?.name || ''}
               target={"_blank"}
               href={storage.publicUrlsForFiles[fileStates[index]?.uuid]}><Icons8Download/></a>
        </Tooltip>
    ));

    const hasMultipleSlides = fileStates.length > 1 || (pictures && pictures.length > 1);

    return (
        <AppModal
            headerClass={classes.headerClipText}
            whiteTextAppBarVariant={true}
            paperClassName={classes.modalPaper}
            fullScreen={true}
            title={title}
            headerActions={action}
            modalAboveModals={true}
            blurBackdrop={true}
            open={open}
            onClose={onClose}
        >
            <Box className={cx(hasMultipleSlides ? null : classes.hideButtons, classes.container)}>
                <Carousel
                    getEmblaApi={setEmbla}
                    withIndicators={!!hasMultipleSlides}
                    height={'100%'}
                    maw={'100%'}
                    slideSize={'100%'}
                    sx={{flex: 1}}
                    slideGap="md"
                    align="start"
                    nextControlIcon={<ChevronRight/>}
                    previousControlIcon={<ChevronLeft/>}
                    styles={{
                        indicator: {
                            width: rem(12),
                            height: rem(4),
                            transition: 'width 250ms ease',
                            '&[data-active]': {
                                width: rem(40),
                            },
                        },
                    }}
                >
                    {slidesJSX}
                </Carousel>
            </Box>

        </AppModal>
    );
}
