import {Dispatch, SetStateAction, useContext, useEffect, useId, useMemo, useState} from "react";
import {AppContext} from "../../../App";
import {tt} from "../../../core/Localization";
import {kAppColors, kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import {Box, CircularProgress, Menu, MenuItem, Theme, Typography, useTheme} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import ScreenContent from "../../components/screens/ScreenContent";
import {AppDataContext} from "../../../AppData";
import {useNavigate, useSearchParams} from "react-router-dom";
import {DateTime} from "luxon";
import {
    GetVisitJobOfferSeatsInput,
    GetVisitsInput,
    JobOfferSeatResponsePage,
    VisitResponsePage,
    VisitStatus,
    VisitStatusModifier
} from "../../../generated/graphql/graphql";
import {processQueryError} from "../../../service/ErrorService";
import {
    filterJobOfferSeatsForVisit,
    getVisitStatusIcon,
    getVisitStatusModifierColor,
    getVisitStatusModifierIcon,
    getVisitStatusModifierTitle,
    getVisitStatusTextColor,
    getVisitStatusTitle,
    VisitsProcess
} from "../../../service/VisitService";
import {isJobOfferSeatFull} from "../../../service/JobService";
import {arraysEqual, capitalizeFirst, uniqueArray} from "../../../utils/Utils";
import {visitsForStatusRoute} from "../jobs/visits/VisitsForStatusScreen";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import IEventSystemListener from "../../../model/EventSystemListener";
import {
    listenToEventSystem,
    unListenToEventSystem
} from "../../../service/EventSystemService";
import IEventSystemNotification from "../../../model/firestore/EventSystemNotification";
import AppListItem from "../../components/listItems/AppListItem";
import {usePopupState} from "material-ui-popup-state/hooks";
import ColoredValueChip from "../../components/chips/ColoredValueChip";
import TwoLineChip from "../../components/chips/TwoLineChip";
import AppTabsComponent from "../../components/AppTabsComponent";
import RadialBarChartIconLabel from "../../components/screens/dashboard/RadialBarChartIconLabel";
import RadialBarChartLabel from "../../components/screens/dashboard/RadialBarChartLabel";
import AppPaper from "../../components/paper/AppPaper";
import MobileChoseCompanyAppBar from "../../components/MobileChoseCompanyAppBar";
import PaperAppbar from "../../components/paper/PaperAppbar";
import ChooseWorkerMenu from "../../components/menus/ChooseWorkerMenu";
import AppChip from "../../components/chips/AppChip";
import {bindMenu} from "material-ui-popup-state";
import Chart from 'react-apexcharts'
import {kPermissionsWorkers, kTopicJobs, kTopicVisits} from "../../../core/constants";
import PermissionValid from "../../components/permissions/PermissionValid";
import { RestApiClientContext } from "../../../core/RestApiProvider";

export const useStyles = makeStyles()((theme: Theme) => ({
    paper: {
        position: "relative",
    },
    date: {
        textAlign: "center",
        fontSize: 18,
        fontWeight: 600,
        color: kAppColors.text.primary(theme.palette.mode === "dark"),
        "@media (max-width: 767px)": {
            fontSize: 16,
            paddingTop: 12,
        }
    },
    chartContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    chartContainerInner: {
        position: "relative",
    },
    labelWithValueContainer: {
        margin: "auto",
    },
    value: {
        fontSize: 14,
        fontWeight: 700,
        lineHeight: 1.2,
        textAlign: "center",
    },
    label: {
        fontSize: 14,
        textAlign: "center",
    },
    mobileChipsContainer: {
        display: "none",
        overflowX: "auto",
        paddingLeft: 16,
        marginRight: 16,
        paddingTop: 16,
        'button': {
            flexShrink: 0,
            marginRight: 8,
            marginBottom: 8,
        },
        "@media (max-width: 576px)": {
            display: "flex",
        },
    },
    spaceAfterChartSection: {
        paddingBottom: 20,
    },
    listItem: {
        minHeight: 48,
        marginBottom: 0,
        paddingTop: 0,
        paddingBottom: 0,
    },
    statusIconsContainer: {
        "@media (max-width: 576px)": {
            display: "none",
        },
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        '> span, > div': {
            flexShrink: 0,
            marginTop: 8,
            marginLeft: 12,
            marginRight: 12,
        }
    },
    doneLabel: {
        position: "absolute",
        left: '50%',
        transform: `translate(-50%, 0px)`,
        top: 86,
        fontWeight: 700,
        color: '#5AC700',
        cursor: "pointer",
        ':hover': {
            borderBottom: `1px solid #5AC700`,
        }
    },
    chartValue: {
        position: "absolute",
        left: '50%',
        transform: `translate(-50%, 0px)`,
        top: 64,
        fontWeight: 700,
        color: kAppColors.text.primary(theme.palette.mode === 'dark'),
    },
    loadingOverlay: {
        borderRadius: 10,
        display: "none",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backdropFilter: `saturate(180%) blur(3px)`,
        zIndex: 101,
        opacity: 0,
        transition: 'opacity 0.4s ease-out',
        "@media (max-width: 767px)": {
            borderRadius: 0,
            position: "fixed",
        }
    },
    showLoadingOverlay: {
        display: "flex",
        opacity: 1,
    },
    mobileWorkersMenuContainer: {
        paddingRight: 16,
        paddingTop: 8,
        paddingBottom: 8,
        display: "flex",
        justifyContent: "end",
    }
}));

export const kDashboardRoute = '/';

/**
 * Screen component for Dashboard.
 */
export default function DashboardScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;

    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId} = appDataContext;

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const now = DateTime.now();

    const todayDates = useMemo(() => {
        const today = DateTime.now();
        const fromDate = today.startOf("day");
        const toDate = today.endOf("day");

        return {today, fromDate, toDate};
    }, [now.daysInMonth, now.month, now.year]);

    const [selectedEmployeeId, setSelectedEmployeeId] = useState<number | null>(searchParams.get('employeeId') ? parseInt(searchParams.get('employeeId')!) : null);
    const [year, setYear] = useState<string>(searchParams.get('year') || todayDates.today.year.toString());
    const [yearValues, setYearValues] = useState<Array<number>>([]);
    const [selectedTab, setSelectedTab] = useState<string>(searchParams.get('selectedTab') ? searchParams.get('selectedTab')! : '0');

    const [refetch, setRefetch] = useState(0);
    const [todayLoading, setTodayLoading] = useState(false);
    const [todayData, setTodayData] = useState<VisitResponsePage | NullOrUndefined>();
    useEffect(() => {
        if (companyId) {
            const subscription = subscribe(
                kTopicVisits,
                {
                    uri: '/job/visit/search',
                    params: {
                        companyId: companyId!,
                        fromDate: todayDates.fromDate.toMillis(),
                        toDate: todayDates.toDate.toMillis(),
                        includeJobOfferSeats: true,
                    } as GetVisitsInput,
                    setLoading: setTodayLoading,
                    onData: setTodayData,
                    onError: (error: any) => processQueryError(appDataContext, error),
                },
                (notifications) => true,
            );

            return () => subscription.cancel();
        } else {
            setTodayData(null);
        }
    }, [companyId, todayDates, refetch]);

    const yearDate = useMemo(() => {
        return DateTime.fromObject({year: parseInt(year)});
    }, [year]);

    const [yearLoading, setYearLoading] = useState(false);
    const [yearData, setYearData] = useState<VisitResponsePage | NullOrUndefined>();
    useEffect(() => {
        if (companyId) {
            const subscription = subscribe(
                kTopicVisits,
                {
                    uri: '/job/visit/search',
                    params: {
                        companyId: companyId!,
                        fromDate: yearDate.startOf("year").toMillis(),
                        toDate: yearDate.endOf("year").toMillis(),
                        includeScheduleLater: true,
                        includeJobOfferSeats: true,
                    } as GetVisitsInput,
                    setLoading: setYearLoading,
                    onData: setYearData,
                    onError: (error: any) => processQueryError(appDataContext, error),
                },
                (notifications) => true,
            );

            return () => subscription.cancel();
        } else {
            setYearData(null);
        }
    }, [companyId, yearDate, refetch]);

    useEffect(() => {
        setTitle(tt('dashboard.screen.title'));
    }, []);

    useEffect(() => {
        let tempArray = [];
        const currentYear = todayDates.today.year;

        for (let i = currentYear - 5; i <= currentYear + 1; i++) {
            tempArray.push(i);
        }

        setYearValues(tempArray);
    }, []);

    useEffect(() => {
        setSearchParams(prev => {
            prev.set('employeeId', selectedEmployeeId?.toString() || '');
            return prev;
        });
    }, [selectedEmployeeId]);

    useEffect(() => {
        setSearchParams(prev => {
            prev.set('year', year);
            return prev;
        });
    }, [year]);

    useEffect(() => {
        setSearchParams(prev => {
            prev.set('selectedTab', selectedTab);
            return prev;
        });
    }, [selectedTab]);

    const todayProcessedData = useMemo(() => {
        if (todayData) {
            const processed: ITodayData = {
                visitsTotal: 0,
                visitsScheduled: 0,
                visitsInProgress: 0,
                visitsTravelling: 0,
                visitsUnfinished: 0,
                visitsCanceled: 0,
                visitsDone: 0,
                visitsClosed: 0,
                visitsOffers: 0,
            };

            const visits = VisitsProcess({
                nonRepeating:todayData.nonRepeating,
                repeating: todayData.repeating,
                visitRepeatDays:todayData.repeatingDayData,
                from:todayDates.fromDate,
                to:todayDates.toDate,
            }).filter(visit => {
                if (!selectedEmployeeId) {
                    return true;
                }

                const employeeIds = visit.visitRepeatDay?.employeeIds || visit.employeeIds;
                const jobOfferSeats = filterJobOfferSeatsForVisit(visit, todayData.jobOfferSeats);

                if (jobOfferSeats) {
                    const jobOfferSeatForEmployee = jobOfferSeats.find(jobOfferSeat => jobOfferSeat.employeeIds.includes(selectedEmployeeId));

                    if (jobOfferSeatForEmployee) {
                        return true;
                    }
                }

                return employeeIds.includes(selectedEmployeeId);
            });

            for (const visitOf of visits) {
                const jobOfStatus = visitOf.visitRepeatDay?.status || visitOf.status;

                processed.visitsTotal += 1;

                const status = visitOf.visitRepeatDay?.status || visitOf.status;

                let canCheckOffers = true;

                switch (status) {
                    case VisitStatus.Scheduled:
                        processed.visitsScheduled += 1;
                        break;
                    case VisitStatus.InProgress:
                        processed.visitsInProgress += 1;
                        break;
                    case VisitStatus.Travelling:
                        processed.visitsTravelling += 1;
                        break;
                    case VisitStatus.Unfinished:
                        processed.visitsUnfinished += 1;
                        break;
                    case VisitStatus.Canceled:
                        processed.visitsCanceled += 1;
                        break;
                    case VisitStatus.Done:
                        processed.visitsDone += 1;
                        break;
                    case VisitStatus.Closed:
                        processed.visitsClosed += 1;
                        break;
                    case VisitStatus.JobOffer:
                        if (jobOfStatus !== VisitStatus.Canceled && jobOfStatus !== VisitStatus.Done && jobOfStatus !== VisitStatus.Closed) {
                            processed.visitsOffers += 1;
                            canCheckOffers = false;
                        }
                        break;
                }

                if (canCheckOffers) {
                    const jobOfferSeats = filterJobOfferSeatsForVisit(visitOf, todayData.jobOfferSeats);

                    if (jobOfferSeats) {
                        const notFull = jobOfferSeats.find(seat => !isJobOfferSeatFull(seat));

                        if (notFull && jobOfStatus !== VisitStatus.Canceled && jobOfStatus !== VisitStatus.Done && jobOfStatus !== VisitStatus.Closed) {
                            processed.visitsOffers += 1;
                        }
                    }
                }
            }

            return processed;
        }

        return undefined;
    }, [todayData, todayDates.fromDate, todayDates.toDate, selectedEmployeeId]);

    const yearProcessedData = useMemo(() => {
        if (yearData) {
            const processed: IYearData = {
                visitsScheduled: 0,
                visitsInProgress: 0,
                visitsTravelling: 0,
                visitsUnfinished: 0,
                visitsCanceled: 0,
                visitsDone: 0,
                visitsClosed: 0,
                visitsScheduleLater: 0,
                visitsOffers: 0,
                visitStatusModifierInvoiced: 0,
                visitStatusModifierPaid: 0,
                visitStatusModifierNotPaid: 0,
            };

            let visits = VisitsProcess({
                nonRepeating: yearData.nonRepeating,
                repeating: yearData.repeating,
                visitRepeatDays: yearData.repeatingDayData,
                from:yearDate.startOf("year"),
                to:yearDate.endOf("year"),
            });

            visits = visits.filter(visit => {
                if (!selectedEmployeeId) {
                    return true;
                }

                const employeeIds = visit.visitRepeatDay?.employeeIds || visit.employeeIds;
                const jobOfferSeats = filterJobOfferSeatsForVisit(visit, yearData.jobOfferSeats);

                if (jobOfferSeats) {
                    const jobOfferSeatForEmployee = jobOfferSeats.find(jobOfferSeat => jobOfferSeat.employeeIds.includes(selectedEmployeeId));

                    if (jobOfferSeatForEmployee) {
                        return true;
                    }
                }

                return employeeIds.includes(selectedEmployeeId);
            });

            for (const visitOf of visits) {
                const status = visitOf.visitRepeatDay?.status || visitOf.status;
                const statusModifier = visitOf.visitRepeatDay?.statusModifier || visitOf.statusModifier;

                let canCheckOffers = true;

                switch (status) {
                    case VisitStatus.Scheduled:
                        processed.visitsScheduled += 1;
                        break;
                    case VisitStatus.InProgress:
                        processed.visitsInProgress += 1;
                        break;
                    case VisitStatus.Travelling:
                        processed.visitsTravelling += 1;
                        break;
                    case VisitStatus.Unfinished:
                        processed.visitsUnfinished += 1;
                        break;
                    case VisitStatus.Canceled:
                        processed.visitsCanceled += 1;
                        break;
                    case VisitStatus.Done:
                        processed.visitsDone += 1;
                        break;
                    case VisitStatus.Closed:
                        switch (statusModifier) {
                            case VisitStatusModifier.Invoiced:
                                processed.visitStatusModifierInvoiced += 1;
                                break;
                            case VisitStatusModifier.Paid:
                                processed.visitStatusModifierPaid += 1;
                                break;
                            case VisitStatusModifier.NotPaid:
                                processed.visitStatusModifierNotPaid += 1;
                                break;
                            default:
                                processed.visitsClosed += 1;
                                break;
                        }
                        break;
                    case VisitStatus.ScheduleLater:
                        processed.visitsScheduleLater += 1;
                        break;
                    case VisitStatus.JobOffer:
                        processed.visitsOffers += 1;
                        canCheckOffers = false;
                        break;
                }

                if (canCheckOffers) {
                    const visitOfferSeats = filterJobOfferSeatsForVisit(visitOf, yearData.jobOfferSeats);

                    if (visitOfferSeats) {
                        const notFull = visitOfferSeats.find(seat => !isJobOfferSeatFull(seat));

                        if (notFull && status !== VisitStatus.Canceled && status !== VisitStatus.Done && status !== VisitStatus.Closed) {
                            processed.visitsOffers += 1;
                        }
                    }
                }
            }

            processed.visitsScheduleLater += (yearData.scheduleLater || []).length;

            return processed;
        }

        return undefined;
    }, [yearData, year, selectedEmployeeId]);

    /**
     * Goto jobs list for status and today or year.
     */
    const gotoVisitsList = (status: VisitStatus, today?: boolean, statusModifier?: VisitStatusModifier, includeAllStatusModifiers?: boolean) => {
        let params: string[] = [];

        if (selectedEmployeeId) {
            params.push(`employeeId=${selectedEmployeeId}`);
        }

        params.push(`year=${year}`);

        if (today) {
            params.push(`fromDate=${todayDates.fromDate.toMillis()}`);
            params.push(`toDate=${todayDates.toDate.toMillis()}`);
        } else {
            params.push(`fromDate=${yearDate.startOf("year").toMillis()}`);
            params.push(`toDate=${yearDate.endOf("year").toMillis()}`);
        }

        if (statusModifier) {
            params.push(`statusModifier=${statusModifier}`);
        }

        if (includeAllStatusModifiers) {
            params.push(`includeAllStatusModifiers=${includeAllStatusModifiers}`);
        }

        params.push(`selectedTab=${selectedTab}`);

        navigate(`${visitsForStatusRoute(status)}?${params.join('&')}`);
    };

    function bodyJSX(isMobile?: boolean, isSmallPhoneScreen?: boolean) {
        return (
            <Body
                isMobile={isMobile}
                isSmallPhoneScreen={isSmallPhoneScreen}
                todayProcessedData={todayProcessedData}
                selectedEmployeeId={selectedEmployeeId}
                setSelectedEmployeeId={setSelectedEmployeeId}
                year={year}
                setYear={setYear}
                yearValues={yearValues}
                yearProcessedData={yearProcessedData}
                gotoVisitsList={gotoVisitsList}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
            />
        );
    }

    return (
        <>
            <EventSystemListeners
                refetch={() => setRefetch(refetch => refetch + 1)}
            />

            <ResponsiveContainer
                smallPhoneScreen={bodyJSX(true, true)}
                largePhoneScreen={bodyJSX(true)}
                tabletScreen={bodyJSX()}
                smallDesktopScreen={bodyJSX()}
                largeDesktopScreen={bodyJSX()}
                extraLargeDesktopScreen={bodyJSX()}/>
        </>
    );
}

interface IEventSystemListenersProps {
    refetch: VoidFunction;
}

/**
 * Component for EventSystem listeners.
 */
function EventSystemListeners(props: IEventSystemListenersProps) {
    const {refetch} = props;

    useEffect(() => {
        const eventSystemListeners: IEventSystemListener[] = [
            {
                topic: kTopicJobs,
                callback: (notifications: IEventSystemNotification[]) => {
                    refetch();
                },
            },
        ];

        eventSystemListeners.forEach(listener => {
            listenToEventSystem(listener);
        });

        return () => {
            eventSystemListeners.forEach(listener => {
                unListenToEventSystem(listener);
            });
        };
    }, [refetch]);

    return null;
}

interface ITodayData {
    visitsTotal: number;
    visitsScheduled: number;
    visitsInProgress: number;
    visitsTravelling: number;
    visitsUnfinished: number;
    visitsCanceled: number;
    visitsDone: number;
    visitsClosed: number;
    visitsOffers: number;
}

interface IYearData {
    visitsScheduleLater: number;
    visitsScheduled: number;
    visitsTravelling: number;
    visitsInProgress: number;
    visitsUnfinished: number;
    visitsCanceled: number;
    visitsDone: number;
    visitsClosed: number;
    visitsOffers: number;
    visitStatusModifierInvoiced: number;
    visitStatusModifierPaid: number;
    visitStatusModifierNotPaid: number;
}

interface IBodyProps {
    isMobile?: boolean;
    isSmallPhoneScreen?: boolean;
    todayProcessedData?: ITodayData;
    selectedEmployeeId: number | null;
    setSelectedEmployeeId: Dispatch<SetStateAction<number | null>>;
    year: string;
    setYear: Dispatch<SetStateAction<string>>;
    yearValues: number[];
    yearProcessedData?: IYearData;
    gotoVisitsList: (status: VisitStatus, today?: boolean, statusModifier?: VisitStatusModifier, includeAllStatusModifiers?: boolean) => void;
    selectedTab: string;
    setSelectedTab: Dispatch<SetStateAction<string>>;
}

/**
 * Dashboard screen body contents.
 */
function Body(props: IBodyProps) {
    const {
        isMobile,
        isSmallPhoneScreen,
        todayProcessedData,
        selectedEmployeeId,
        setSelectedEmployeeId,
        year,
        setYear,
        yearValues,
        yearProcessedData,
        gotoVisitsList,
        selectedTab,
        setSelectedTab,
    } = props;

    const {classes, cx} = useStyles();

    const theme = useTheme();

    const [showOverlay, setShowOverlay] = useState<Boolean>(false);

    const appDataContext = useContext(AppDataContext);
    const {language} = appDataContext;

    const overlayClass = cx(
        classes.loadingOverlay,
        showOverlay ? classes.showLoadingOverlay : undefined
    );

    useEffect(() => {
        if (yearValues && yearProcessedData) {
            setShowOverlay(true);
            setTimeout(function () {
                setShowOverlay(false);
            }, 800);
        }
    }, [selectedEmployeeId]);

    const yearSelectPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    const isStatusButtonShown =
        todayProcessedData?.visitsUnfinished || todayProcessedData?.visitsOffers || todayProcessedData?.visitsCanceled || todayProcessedData?.visitsDone || todayProcessedData?.visitsInProgress || todayProcessedData?.visitsCanceled
        || (isSmallPhoneScreen && (todayProcessedData?.visitsDone || todayProcessedData?.visitsScheduled));

    const ongoingTabJSX = (
        <>
            <Box pb={1}/>
            <AppListItem
                onClick={() => gotoVisitsList(VisitStatus.Unfinished)}
                className={classes.listItem}
                variant={"smaller-title"}
                customAvatar={
                    <Box sx={{
                        color: getVisitStatusTextColor(VisitStatus.Unfinished, theme.palette.mode === "dark"),
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <ColoredValueChip
                            value={(yearProcessedData?.visitsUnfinished || 0)}
                            status={VisitStatus.Unfinished}
                            isLoading={yearProcessedData === undefined}
                        />
                        <Box pr={2}/>
                        {getVisitStatusIcon(VisitStatus.Unfinished)}
                    </Box>
                }
                title={getVisitStatusTitle(VisitStatus.Unfinished)}
            />

            <AppListItem
                onClick={() => gotoVisitsList(VisitStatus.ScheduleLater)}
                className={classes.listItem}
                variant={"smaller-title"}
                customAvatar={
                    <Box sx={{
                        color: getVisitStatusTextColor(VisitStatus.ScheduleLater, theme.palette.mode === "dark"),
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <ColoredValueChip
                            value={(yearProcessedData?.visitsScheduleLater || 0)}
                            status={VisitStatus.ScheduleLater}
                            isLoading={yearProcessedData === undefined}
                        />
                        <Box pr={2}/>
                        {getVisitStatusIcon(VisitStatus.ScheduleLater)}
                    </Box>
                }
                title={getVisitStatusTitle(VisitStatus.ScheduleLater)}
            />

            <AppListItem
                onClick={() => gotoVisitsList(VisitStatus.JobOffer)}
                className={classes.listItem}
                variant={"smaller-title"}
                customAvatar={
                    <Box sx={{
                        color: getVisitStatusTextColor(VisitStatus.JobOffer, theme.palette.mode === "dark"),
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <ColoredValueChip
                            value={(yearProcessedData?.visitsOffers || 0)}
                            status={VisitStatus.JobOffer}
                            isLoading={yearProcessedData === undefined}
                        />
                        <Box pr={2}/>
                        {getVisitStatusIcon(VisitStatus.JobOffer)}
                    </Box>
                }
                title={getVisitStatusTitle(VisitStatus.JobOffer)}
            />

            <AppListItem
                onClick={() => gotoVisitsList(VisitStatus.Scheduled)}
                className={classes.listItem}
                variant={"smaller-title"}
                customAvatar={
                    <Box sx={{
                        color: getVisitStatusTextColor(VisitStatus.Scheduled, theme.palette.mode === "dark"),
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <ColoredValueChip
                            value={(yearProcessedData?.visitsScheduled || 0)}
                            status={VisitStatus.Scheduled}
                            isLoading={yearProcessedData === undefined}
                        />
                        <Box pr={2}/>
                        {getVisitStatusIcon(VisitStatus.Scheduled)}
                    </Box>
                }
                title={getVisitStatusTitle(VisitStatus.Scheduled)}
            />

            <AppListItem
                onClick={() => gotoVisitsList(VisitStatus.Travelling)}
                className={classes.listItem}
                variant={"smaller-title"}
                customAvatar={
                    <Box sx={{
                        color: getVisitStatusTextColor(VisitStatus.Travelling, theme.palette.mode === "dark"),
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <ColoredValueChip
                            value={(yearProcessedData?.visitsTravelling || 0)}
                            status={VisitStatus.Travelling}
                            isLoading={yearProcessedData === undefined}
                        />
                        <Box pr={2}/>
                        {getVisitStatusIcon(VisitStatus.Travelling)}
                    </Box>
                }
                title={getVisitStatusTitle(VisitStatus.Travelling)}
            />

            <AppListItem
                onClick={() => gotoVisitsList(VisitStatus.InProgress)}
                className={classes.listItem}
                variant={"smaller-title"}
                customAvatar={
                    <Box sx={{
                        color: getVisitStatusTextColor(VisitStatus.InProgress, theme.palette.mode === "dark"),
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <ColoredValueChip
                            value={(yearProcessedData?.visitsInProgress || 0)}
                            status={VisitStatus.InProgress}
                            isLoading={yearProcessedData === undefined}
                        />
                        <Box pr={2}/>
                        {getVisitStatusIcon(VisitStatus.InProgress)}
                    </Box>
                }
                title={getVisitStatusTitle(VisitStatus.InProgress)}
            />

            <AppListItem
                onClick={() => gotoVisitsList(VisitStatus.Done)}
                className={classes.listItem}
                variant={"smaller-title"}
                customAvatar={
                    <Box sx={{
                        color: getVisitStatusTextColor(VisitStatus.Done, theme.palette.mode === "dark"),
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <ColoredValueChip
                            value={(yearProcessedData?.visitsDone || 0)}
                            status={VisitStatus.Done}
                            isLoading={yearProcessedData === undefined}
                        />
                        <Box pr={2}/>
                        {getVisitStatusIcon(VisitStatus.Done)}
                    </Box>
                }
                title={getVisitStatusTitle(VisitStatus.Done)}
            />
        </>
    );

    const finishedTabJSX = (
        <>
            <Box pb={1}/>
            <AppListItem
                onClick={() => gotoVisitsList(VisitStatus.Closed)}
                className={classes.listItem}
                variant={"smaller-title"}
                customAvatar={
                    <Box sx={{
                        color: getVisitStatusTextColor(VisitStatus.Closed, theme.palette.mode === "dark"),
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <ColoredValueChip
                            value={(yearProcessedData?.visitsClosed || 0)}
                            status={VisitStatus.Closed}
                            isLoading={yearProcessedData === undefined}
                        />
                        <Box pr={2}/>
                        {getVisitStatusIcon(VisitStatus.Closed)}
                    </Box>
                }
                title={getVisitStatusTitle(VisitStatus.Closed)}
            />

            <AppListItem
                onClick={() => gotoVisitsList(VisitStatus.Closed, undefined, VisitStatusModifier.Invoiced)}
                className={classes.listItem}
                variant={"smaller-title"}
                customAvatar={
                    <Box sx={{
                        color: getVisitStatusModifierColor(VisitStatusModifier.Invoiced),
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <ColoredValueChip
                            value={(yearProcessedData?.visitStatusModifierInvoiced || 0)}
                            statusModifier={VisitStatusModifier.Invoiced}
                            isLoading={yearProcessedData === undefined}
                        />
                        <Box pr={2}/>
                        {getVisitStatusModifierIcon(VisitStatusModifier.Invoiced)}
                    </Box>
                }
                title={getVisitStatusModifierTitle(VisitStatusModifier.Invoiced)}
            />

            <AppListItem
                onClick={() => gotoVisitsList(VisitStatus.Closed, undefined, VisitStatusModifier.Paid)}
                className={classes.listItem}
                variant={"smaller-title"}
                customAvatar={
                    <Box sx={{
                        color: getVisitStatusModifierColor(VisitStatusModifier.Paid),
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <ColoredValueChip
                            value={(yearProcessedData?.visitStatusModifierPaid || 0)}
                            statusModifier={VisitStatusModifier.Paid}
                            isLoading={yearProcessedData === undefined}
                        />
                        <Box pr={2}/>
                        {getVisitStatusModifierIcon(VisitStatusModifier.Paid)}
                    </Box>
                }
                title={getVisitStatusModifierTitle(VisitStatusModifier.Paid)}
            />
            <AppListItem
                onClick={() => gotoVisitsList(VisitStatus.Closed, undefined, VisitStatusModifier.NotPaid)}
                className={classes.listItem}
                variant={"smaller-title"}
                customAvatar={
                    <Box sx={{
                        color: getVisitStatusModifierColor(VisitStatusModifier.NotPaid),
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <ColoredValueChip
                            value={(yearProcessedData?.visitStatusModifierNotPaid || 0)}
                            statusModifier={VisitStatusModifier.NotPaid}
                            isLoading={yearProcessedData === undefined}
                        />
                        <Box pr={2}/>
                        {getVisitStatusModifierIcon(VisitStatusModifier.NotPaid)}
                    </Box>
                }
                title={getVisitStatusModifierTitle(VisitStatusModifier.NotPaid)}
            />

            <AppListItem
                onClick={() => gotoVisitsList(VisitStatus.Canceled)}
                className={classes.listItem}
                variant={"smaller-title"}
                customAvatar={
                    <Box sx={{
                        color: getVisitStatusTextColor(VisitStatus.Canceled, theme.palette.mode === "dark"),
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <ColoredValueChip
                            value={(yearProcessedData?.visitsCanceled || 0)}
                            status={VisitStatus.Canceled}
                            isLoading={yearProcessedData === undefined}
                        />
                        <Box pr={2}/>
                        {getVisitStatusIcon(VisitStatus.Canceled)}
                    </Box>
                }
                title={getVisitStatusTitle(VisitStatus.Canceled)}
            />
        </>
    );

    return (
        <ScreenContent
            appBar={!isMobile}
            noContentPadding={isMobile}
            navigationDrawer={!isMobile}
            bottomBar={isMobile}
            centerHorizontally={true}>
            <AppPaper className={classes.paper}
                      sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}
            >
                {isMobile ? <MobileChoseCompanyAppBar
                    employeeId={selectedEmployeeId}
                    setEmployeeId={setSelectedEmployeeId}/> : (
                    <PaperAppbar
                        title={''}
                        hideBackButton={true}
                        children={
                            isMobile ? null :
                                <>
                                    <PermissionValid
                                        permission={kPermissionsWorkers}
                                    >
                                        <ChooseWorkerMenu
                                            employeeId={selectedEmployeeId}
                                            setEmployeeId={setSelectedEmployeeId}
                                        />
                                    </PermissionValid>
                                    <Box pl={1}></Box>
                                    <AppChip
                                        chipstyle={'secondary'}
                                        label={year}
                                        onClick={(e) => yearSelectPopupState.open(e)}
                                    />
                                </>
                        }
                    />
                )}
                <Box className={overlayClass}>
                    <CircularProgress
                        size={60}
                        color={"primary"}
                    />
                </Box>
                {isMobile ? <Box className={classes.mobileWorkersMenuContainer}>
                    <PermissionValid
                        permission={kPermissionsWorkers}
                    >
                        <ChooseWorkerMenu
                            employeeId={selectedEmployeeId}
                            setEmployeeId={setSelectedEmployeeId}/>
                    </PermissionValid>
                    <Box pl={1}></Box>
                    <AppChip
                        chipstyle={'secondary'}
                        label={year}
                        onClick={(e) => yearSelectPopupState.open(e)}
                    />
                </Box> : null}

                <Box>
                    <Typography
                        className={classes.date}>
                        {capitalizeFirst(DateTime.now().toFormat('EEEE d MMMM', {locale: language}))}
                    </Typography>
                </Box>

                <Box className={classes.chartContainer}>
                    <RadialBarChartLabel
                        onClick={() => gotoVisitsList(VisitStatus.Scheduled, true)}
                        label={tt('dashboard.screen.chart.label.scheduled')}
                        value={todayProcessedData?.visitsScheduled || 0}/>

                    <Box className={classes.chartContainerInner}>
                        <Chart
                            height={200}
                            type={"radialBar"}
                            series={
                                [todayProcessedData && todayProcessedData.visitsTotal !== 0 ? (todayProcessedData.visitsClosed / todayProcessedData.visitsTotal * 100) : 0]
                            }
                            options={{
                                chart: {
                                    id: 'dashboardChart'
                                },
                                plotOptions: {
                                    radialBar: {
                                        hollow: {
                                            size: "70%",
                                        },
                                        track: {
                                            background: [theme.palette.mode === 'dark' ? 'rgba(238, 240, 243, 0.2)' : 'rgba(242,242,242,0.85)'],
                                        },
                                        dataLabels: {
                                            name: {
                                                color: kAppColors.green.secondary,
                                                fontSize: "14px",
                                                offsetY: 18,
                                            },
                                            value: {
                                                show: false,
                                            }
                                        }
                                    },
                                },
                                fill: {
                                    type: 'solid',
                                    colors: [kAppColors.green.secondary],
                                },
                                stroke: {
                                    lineCap: "round",
                                },
                                labels: ['']
                            }}
                        />

                        <Typography
                            className={classes.chartValue}>
                            {`${todayProcessedData?.visitsClosed || 0} ${tt('dashboard.screen.chart.outOf')} ${todayProcessedData?.visitsTotal || 0}`}
                        </Typography>

                        <Typography
                            onClick={() => gotoVisitsList(VisitStatus.Closed, true, undefined, true)}
                            className={classes.doneLabel}>
                            {tt('dashboardScreen.tab.finished')}
                        </Typography>
                    </Box>

                    <RadialBarChartLabel
                        onClick={() => gotoVisitsList(VisitStatus.Done, true)}
                        label={tt('dashboard.screen.chart.label.done')}
                        value={todayProcessedData?.visitsDone || 0}/>

                </Box>

                <Box className={classes.statusIconsContainer}>
                    <RadialBarChartIconLabel
                        status={VisitStatus.Unfinished}
                        value={todayProcessedData?.visitsUnfinished || 0}
                        onClick={() => gotoVisitsList(VisitStatus.Unfinished, true)}
                    />

                    <RadialBarChartIconLabel
                        status={VisitStatus.JobOffer}
                        value={todayProcessedData?.visitsOffers || 0}
                        onClick={() => gotoVisitsList(VisitStatus.JobOffer, true)}
                    />

                    <RadialBarChartIconLabel
                        status={VisitStatus.Travelling}
                        value={todayProcessedData?.visitsTravelling || 0}
                        onClick={() => gotoVisitsList(VisitStatus.Travelling, true)}
                    />

                    <RadialBarChartIconLabel
                        status={VisitStatus.InProgress}
                        value={todayProcessedData?.visitsInProgress || 0}
                        onClick={() => gotoVisitsList(VisitStatus.InProgress, true)}
                    />

                    <RadialBarChartIconLabel
                        status={VisitStatus.Canceled}
                        value={todayProcessedData?.visitsCanceled || 0}
                        onClick={() => gotoVisitsList(VisitStatus.Canceled, true)}
                    />
                </Box>

                <Box className={cx('styledScrollbar', classes.mobileChipsContainer)}>
                    {
                        todayProcessedData?.visitsUnfinished ? <TwoLineChip
                            variant={"secondary"}
                            text1={capitalizeFirst(tt('radialChart.label.toSolve'))}
                            text2={todayProcessedData?.visitsUnfinished.toString()}
                            onClick={() => gotoVisitsList(VisitStatus.Unfinished, true)}
                        ></TwoLineChip> : null
                    }

                    {
                        todayProcessedData?.visitsOffers ? <TwoLineChip
                            variant={"secondary"}
                            text1={capitalizeFirst(tt('radialChart.label.offer'))}
                            text2={todayProcessedData?.visitsOffers.toString()}
                            onClick={() => gotoVisitsList(VisitStatus.JobOffer, true)}
                        ></TwoLineChip> : null
                    }

                    {
                        todayProcessedData?.visitsDone ? <TwoLineChip
                            variant={"secondary"}
                            text1={capitalizeFirst(tt('radialChart.label.done'))}
                            text2={todayProcessedData?.visitsDone.toString()}
                            onClick={() => gotoVisitsList(VisitStatus.Done, true)}
                        ></TwoLineChip> : null
                    }


                    {
                        todayProcessedData?.visitsInProgress ?
                            <TwoLineChip
                                variant={"secondary"}
                                text1={tt('dashboard.screen.chart.label.ongoing')}
                                text2={todayProcessedData?.visitsInProgress.toString() || '0'}
                                onClick={() => gotoVisitsList(VisitStatus.InProgress, true)}
                            ></TwoLineChip> : null
                    }

                    {
                        todayProcessedData?.visitsTravelling ?
                            <TwoLineChip
                                variant={"secondary"}
                                text1={capitalizeFirst(tt('radialChart.label.onWay'))}
                                text2={todayProcessedData?.visitsTravelling.toString()}
                                onClick={() => gotoVisitsList(VisitStatus.Travelling, true)}
                            ></TwoLineChip> : null
                    }

                    {
                        todayProcessedData?.visitsScheduled ?
                            <TwoLineChip
                                variant={"secondary"}
                                text1={tt('dashboard.screen.chart.label.scheduled')}
                                text2={todayProcessedData?.visitsScheduled.toString() || '0'}
                                onClick={() => gotoVisitsList(VisitStatus.Scheduled, true)}
                            ></TwoLineChip> : null
                    }

                    {
                        todayProcessedData?.visitsCanceled ? <TwoLineChip
                            variant={"secondary"}
                            text1={capitalizeFirst(tt('radialChart.label.canceled'))}
                            text2={todayProcessedData?.visitsCanceled.toString()}
                            onClick={() => gotoVisitsList(VisitStatus.Canceled, true)}
                        ></TwoLineChip> : null
                    }
                </Box>

                {isStatusButtonShown ? <Box className={classes.spaceAfterChartSection}/> : null}

                <AppTabsComponent
                    controlledValue={selectedTab}
                    onTabChange={setSelectedTab}
                    variant={"fullWidth"}
                    data={
                        [
                            {
                                label: tt('dashboardScreen.tab.ongoing'),
                                content: ongoingTabJSX
                            },
                            {
                                label: tt('dashboardScreen.tab.finished'),
                                content: finishedTabJSX
                            }
                        ]
                    }
                />

                <Box sx={{pb: 2}}/>
            </AppPaper>

            <Menu {...bindMenu(yearSelectPopupState)}>
                {yearValues.map(value => <MenuItem
                    key={value}
                    onClick={() => {
                        yearSelectPopupState.close();
                        setYear(value.toString())
                    }}
                >
                    {value}
                </MenuItem>)}
            </Menu>
        </ScreenContent>
    );
}
