import {Box, Menu, MenuItem} from "@mui/material";
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import {HideConfirmModal, HideVisitDetailModal, SetConfirmModal} from "../../../modals/AppModals";
import {kAppColors} from "../../../../../styles/AppThemeProcessor";
import DeleteIcon from "../../../../../icons/DeleteIcon";
import {tt} from "../../../../../core/Localization";
import React, {useContext, useEffect, useId, useState} from "react";
import Icons8Cancel from "../../../../../icons/Icons8Cancel";
import AppIconButton from "../../../buttons/AppIconButton";
import PencilIcon from "../../../../../icons/PencilIcon";
import MoreFilledIcon from "../../../../../icons/MoreFilledIcon";
import {usePopupState} from "material-ui-popup-state/hooks";
import {AppDataContext} from "../../../../../AppData";
import ClockIcon from "../../../../../icons/ClockIcon";
import EditVisitDetailNameAndDescriptionModalBottomSheet
    from "../../../modals/job/visits/EditVisitDetailNameAndDescriptionModalBottomSheet";
import {
    DeleteVisitDocument,
    DeleteVisitMutation,
    DeleteVisitMutationVariables,
    JobUpdateRepeats,
    VisitDetailResponse,
    VisitRepeating,
    VisitStatus
} from "../../../../../generated/graphql/graphql";
import EditVisitDateTimeModalBottomSheet from "../../../modals/job/visits/EditVisitDateTimeModalBottomSheet";
import EditRecurringVisitModal from "../../../modals/EditRecurringVisitModal";
import {useMutation} from "@apollo/client";
import {ErrorToast, SuccessToast} from "../../../../../service/ToastService";
import Icons8PowerOffButton from "../../../../../icons/Icons8PowerOffButton";
import Icons8Envelope from "../../../../../icons/Icons8Envelope";
import Icons8Alarm from "../../../../../icons/Icons8Alarm";
import EditVisitNotificationsModalBottomSheet from "../../../modals/job/visits/EditVisitNotificationsModalBottomSheet";
import Icons8TimeMachine from "../../../../../icons/Icons8TimeMachine";
import JobHistoryModalBottomSheet from "../../../modals/job/visits/JobHistoryModalBottomSheet";
import IEventSystemListener from "../../../../../model/EventSystemListener";
import {
    listenToEventSystem,
    unListenToEventSystem
} from "../../../../../service/EventSystemService";
import IEventSystemNotification from "../../../../../model/firestore/EventSystemNotification";
import Icons8MultilineText from "../../../../../icons/Icons8MultilineText";
import {IOnUpdateVisitIdParams} from "../../../modals/job/visits/VisitDetailModal";
import Icons8Copy from "../../../../../icons/Icons8Copy";
import {useNavigate} from "react-router-dom";
import {newJobOrVisitRoute, NewJobOrVisitRouteKind} from "../../../../screens/jobs/NewJobOrVisitScreen";
import CancelActivateVisitModal from "../../../modals/job/visits/CancelActivateVisitModal";
import DuplicateVisitOptionsModal, {
    IDuplicateOptionsProps
} from "../../../modals/job/visits/DuplicateVisitOptionsModal";
import {kActionDelete, kActionUpdate, kPermissionsJobs, kTopicVisits} from "../../../../../core/constants";
import PermissionValid, {hasPermission} from "../../../permissions/PermissionValid";

export interface IJobDetailMenuProps {
    visitId: number;
    data?: VisitDetailResponse | NullOrUndefined;
    repeatingDay?: number;
    onDelete: VoidFunction;
    onUpdateVisitId: (params: IOnUpdateVisitIdParams) => void;
}

export default function VisitDetailMenu(props: IJobDetailMenuProps) {
    const {data, repeatingDay, onDelete, onUpdateVisitId, visitId} = props;

    const theJobStatus = data?.visit.repeatingDayData?.status || data?.visit?.status;
    const canEdit = theJobStatus !== VisitStatus.Canceled && theJobStatus !== VisitStatus.Closed;

    const isRepeating = data?.visit.repeating !== VisitRepeating.Never;

    const appDataContext = useContext(AppDataContext);
    const {employeePermissionsMap} = appDataContext;

    const navigate = useNavigate();

    const [editNotifications, setEditNotifications] = useState<boolean>(false);
    const [editNameAndDescription, setEditNameAndDescription] = useState<boolean>(false);
    const [editDateAndTimes, setEditDateAndTimes] = useState<boolean>(false);
    const [recurringEditModal, setRecurringEditModal] = useState(false);
    const [recurringConfirmAction, setRecurringConfirmAction] = useState<number>();
    const [repeats, setRepeats] = useState<JobUpdateRepeats>(JobUpdateRepeats.Single);
    const [historyBottomSheet, setHistoryBottomSheet] = useState(false);
    const [cancelActivateVisitModal, setCancelActivateVisitModal] = useState(false);
    const [reActivate, setReActivate] = useState(false);
    const [duplicateOptionsModal, setDuplicateOptionsModal] = useState(false);

    useEffect(() => {
        const eventSystemListener: IEventSystemListener = {
            topic: kTopicVisits,
            callback: (notifications: IEventSystemNotification[]) => {
                const doDelete = notifications.some(notification => {
                    if (notification.action !== kActionDelete) {
                        return false;
                    }

                    return notification.data.id === visitId &&
                        (notification.data.repeatingDay === repeatingDay || (!notification.data.repeatingDay && !repeatingDay)) || notification.data.repeats === JobUpdateRepeats.ThisAndAllFuture;
                });

                if (doDelete) {
                    onDelete();
                }
            },
        };

        listenToEventSystem(eventSystemListener);

        return () => {
            unListenToEventSystem(eventSystemListener);
        };
    }, [onDelete, visitId, repeatingDay]);

    const [mutateDelete, {
        loading: deleteLoading,
        error: deleteError,
        data: deleteData,
    }] = useMutation<DeleteVisitMutation, DeleteVisitMutationVariables>(DeleteVisitDocument);

    useEffect(() => {
        SetConfirmModal(appDataContext, {
            loading: deleteLoading,
        });
    }, [deleteLoading]);

    /**
     * On confirm recurring modal call correct action by recurringConfirmAction.
     */
    const onRecurringConfirm = (repeats: JobUpdateRepeats) => {
        setRepeats(repeats);

        if (recurringConfirmAction === 1) {
            setEditNameAndDescription(true);
        } else if (recurringConfirmAction === 2) {
            setEditDateAndTimes(true);
        } else if (recurringConfirmAction === 3) {
            setReActivate(false);
            setCancelActivateVisitModal(true);
        } else if (recurringConfirmAction === 4) {
            SetConfirmModal(appDataContext, {
                open: true,
                title: tt('visitDetail.delete.confirmModal.title'),
                subtitle: tt('visitDetail.delete.confirmModal.subtitle'),
                confirmationButtonText: tt('common.delete'),
                cancelButtonText: tt('common.close'),
                children: <></>,
                onConfirm: () => deleteVisit(repeats),
            });
        } else if (recurringConfirmAction === 5) {
            setReActivate(true);
            setCancelActivateVisitModal(true);
        }
    };

    /**
     * Mutate delete visit to BE.
     */
    const deleteVisit = async (repeats: JobUpdateRepeats) => {
        try {
            const deleteVariables: DeleteVisitMutationVariables = {
                input: {
                    visitId: data!.visit.id,
                    repeats,
                    repeatingDay,
                },
            };

            const result = await mutateDelete({variables: deleteVariables});

            if (!result.errors) {
                HideConfirmModal(appDataContext);

                SuccessToast(tt('visitDetailMenu.deleteVisit.success'));
            }
        } catch (e) {
            console.error(e);

            ErrorToast(tt('common.mutation.error'));
        }
    };

    /**
     * Check if should duplicate visit or show modal
     */
    const showDuplicateModalOrDuplicate = () => {
        const forms = !!data?.jobForms.length;
        const attachments = !!data?.visit.attachmentFileIds?.length;
        const products = !!data?.products.length;
        const workers = !!data?.employees.length || !!data?.jobOfferSeat.length;

        const showModal = forms || attachments || products || workers;

        if (showModal) {
            setDuplicateOptionsModal(true);
        } else {
            duplicateVisit();
        }

    };
    /**
     * Duplicate Visit by navigate to new visit.
     */
    const duplicateVisit = (props?: IDuplicateOptionsProps) => {
        HideVisitDetailModal(appDataContext);

        navigate(newJobOrVisitRoute({
            kind: NewJobOrVisitRouteKind.fromJobDetail,
            jobId: data!.visit.jobId,
            duplicateVisitId: visitId,
            duplicateRepeatingDay: repeatingDay,
            duplicateOptions: props,
        }));
    };

    const notificationsPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    const editOptionsPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    const settingsPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    const editMenuJSX = canEdit ? (
        <PermissionValid
            permission={kPermissionsJobs}
            requiredPermissions={[kActionUpdate]}
        >
            <AppIconButton
                tooltip={tt('visitDetail.menu.tooltip.edit')}
                key={'jobDetailEditBtn'}
                onClick={(e) => {
                    editOptionsPopupState.open(e);
                }}
            >
                <PencilIcon/>
            </AppIconButton>
        </PermissionValid>
    ) : undefined;

    const notificationsJSX = canEdit ? (
        <>
            <AppIconButton
                tooltip={tt('visitDetail.menu.tooltip.notifications')}
                key={'jobDetailNotificationsBtn'}
                {...bindTrigger(notificationsPopupState)}
            >
                <Icons8Envelope/>
            </AppIconButton>

            <Menu {...bindMenu(notificationsPopupState)}>
                <MenuItem
                    onClick={() => {
                        notificationsPopupState.close();
                        setEditNotifications(true);
                    }}
                >
                    <Icons8Alarm/>
                    {tt('visitDetailMenu.button.sendNotificationToWorkers')}
                </MenuItem>
            </Menu>
        </>
    ) : undefined;

    return (
        <>
            <AppIconButton
                tooltip={tt('visitDetail.menu.tooltip.history')}
                placement={"bottom"}
                onClick={() => setHistoryBottomSheet(true)}
            >
                <Icons8TimeMachine/>
            </AppIconButton>

            {notificationsJSX}

            {editMenuJSX}

            {hasPermission(kPermissionsJobs, [kActionUpdate], employeePermissionsMap) || hasPermission(kPermissionsJobs, [kActionDelete], employeePermissionsMap) ? (
                <AppIconButton
                    tooltip={tt('visitDetail.menu.tooltip.more')}
                    key={'jobDetailMoreBtn'}
                    {...bindTrigger(settingsPopupState)}
                >
                    <MoreFilledIcon/>
                </AppIconButton>
            ) : null}

            {/*edit*/}
            <Menu {...bindMenu(editOptionsPopupState)}>
                <MenuItem
                    onClick={() => {
                        setRecurringConfirmAction(1);
                        if (isRepeating) {
                            setRecurringEditModal(true);
                        } else {
                            setEditNameAndDescription(true);
                        }
                        editOptionsPopupState.close();
                    }
                    }
                >
                    <Icons8MultilineText/>
                    {tt('visitDetailMenu.button.editNameAndDescription')}
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        setRecurringConfirmAction(2);
                        if (isRepeating) {
                            setRecurringEditModal(true);
                        } else {
                            setEditDateAndTimes(true);
                        }
                        editOptionsPopupState.close();
                    }
                    }
                >
                    <ClockIcon/>
                    {tt('visitDetailMenu.button.editDateAndTime')}
                </MenuItem>
            </Menu>

            {/*settings*/}
            <Menu {...bindMenu(settingsPopupState)}>
                <PermissionValid
                    permission={kPermissionsJobs}
                    requiredPermissions={[kActionUpdate]}
                >
                    <>
                        <MenuItem
                            onClick={() => {
                                settingsPopupState.close();
                                showDuplicateModalOrDuplicate();
                            }}
                        >
                            <Icons8Copy/>
                            {tt('visitDetailMenu.button.duplicateVisit')}
                        </MenuItem>

                        {theJobStatus !== VisitStatus.Canceled && theJobStatus !== VisitStatus.Closed ? (
                            <MenuItem
                                onClick={() => {
                                    settingsPopupState.close();

                                    setRecurringConfirmAction(3);

                                    if (isRepeating) {
                                        setRecurringEditModal(true);
                                    } else {
                                        setReActivate(false);
                                        setCancelActivateVisitModal(true);
                                    }
                                }
                                }
                            >
                                <Icons8Cancel/>
                                {tt('visitDetailMenu.button.cancelJob')}
                            </MenuItem>
                        ) : theJobStatus !== VisitStatus.Closed ? (
                            <MenuItem
                                onClick={() => {
                                    settingsPopupState.close();

                                    setRecurringConfirmAction(5);

                                    if (isRepeating) {
                                        setRecurringEditModal(true);
                                    } else {
                                        setReActivate(true);
                                        setCancelActivateVisitModal(true);
                                    }
                                }
                                }
                            >
                                <Box color={kAppColors.green.secondary}><Icons8PowerOffButton/></Box>
                                {tt('visitDetailMenu.button.activateJob')}
                            </MenuItem>
                        ) : undefined}
                    </>
                </PermissionValid>

                <PermissionValid
                    permission={kPermissionsJobs}
                    requiredPermissions={[kActionDelete]}
                >
                    <MenuItem key={'jobDetailDeleteKey'} onClick={() => {
                        settingsPopupState.close();

                        setRecurringConfirmAction(4);

                        if (isRepeating) {
                            setRecurringEditModal(true);
                        } else {
                            SetConfirmModal(appDataContext, {
                                open: true,
                                title: tt('visitDetail.delete.confirmModal.title'),
                                subtitle: tt('visitDetail.delete.confirmModal.subtitle'),
                                confirmationButtonText: tt('common.delete'),
                                cancelButtonText: tt('common.close'),
                                children: <></>,
                                onConfirm: () => deleteVisit(JobUpdateRepeats.Single),
                            });
                        }

                    }}>
                        <Box sx={{color: kAppColors.red.confirmButton}}><DeleteIcon/></Box>
                        {tt('common.delete')}
                    </MenuItem>
                </PermissionValid>
            </Menu>

            <EditRecurringVisitModal
                open={recurringEditModal}
                setOpen={setRecurringEditModal}
                onConfirm={onRecurringConfirm}
                modalAboveModals={true}
            />

            <EditVisitDetailNameAndDescriptionModalBottomSheet
                visitId={data?.visit.id || 0}
                repeatingDay={repeatingDay}
                repeats={repeats}
                open={editNameAndDescription}
                setOpen={setEditNameAndDescription}
                onUpdateVisitId={onUpdateVisitId}
                modalAboveModals={true}
            />

            <EditVisitDateTimeModalBottomSheet
                visitId={data?.visit.id || 0}
                repeatingDay={repeatingDay}
                repeats={repeats}
                open={editDateAndTimes}
                setOpen={setEditDateAndTimes}
                onUpdateJobId={onUpdateVisitId}
                modalAboveModals={true}
            />

            <EditVisitNotificationsModalBottomSheet
                visitId={data?.visit.id || 0}
                repeatingDay={repeatingDay}
                data={data}
                open={editNotifications}
                setOpen={setEditNotifications}
                modalAboveModals={true}
            />

            <JobHistoryModalBottomSheet
                history={data?.history}
                employees={data?.employees}
                files={data?.files}
                open={historyBottomSheet}
                setOpen={setHistoryBottomSheet}
                modalAboveModals={true}
            />

            <CancelActivateVisitModal
                open={cancelActivateVisitModal}
                setOpen={setCancelActivateVisitModal}
                visitId={visitId}
                repeatingDay={repeatingDay}
                repeats={repeats}
                reActivate={reActivate}
                onUpdateVisitId={onUpdateVisitId}
                modalAboveModals={true}
            />

            <DuplicateVisitOptionsModal
                open={duplicateOptionsModal}
                setOpen={setDuplicateOptionsModal}
                showFormsOption={!!data?.jobForms.length}
                showAttachmentsOption={!!data?.visit.attachmentFileIds?.length}
                showProductsOption={!!data?.products.length}
                showWorkersOption={!!data?.employees.length || !!data?.jobOfferSeat.length}
                showMaterialsOption={!!data?.materials.length}
                onSave={(props: IDuplicateOptionsProps) => {
                    duplicateVisit(props);
                }}
            />
        </>
    );
}
