import IVisitEvent from "../../../../model/VisitEvent";
import {
    ClientPureResponse,
    EmployeeJoinedUserResponse, FileResponse,
    JobEmployeeTimesheetItemResponse,
    JobFormPureResponse,
    JobOfferSeatResponse,
    JobPureResponse,
    LocationPureResponse,
    MaterialResponse,
    ProductResponse,
    VisitRepeating,
    VisitStatus,
    VisitStatusModifier
} from "../../../../generated/graphql/graphql";
import React, {ReactNode, useContext, useMemo} from "react";
import {VisitNameOrSequenceId} from "../../../../service/VisitService";
import {useNavigate} from "react-router-dom";
import {routeWithCurrentAsParam} from "../../../../utils/Utils";
import {UserAddressSingle, UserFullName, UserPhotoUrl} from "../../../../service/UserService";
import {LocationTypeDisplay} from "../../../../service/LocationService";
import {Box, Theme, Tooltip, Typography, useTheme} from "@mui/material";
import VisitStatusChip from "../../chips/VisitStatusChip";
import {kAppColors, kVisitStatusColors} from "../../../../styles/AppThemeProcessor";
import {makeStyles} from "tss-react/mui";
import AppListItemV2 from "../../listItems/ApplistItemV2";
import {DateTime} from "luxon";
import {AppDataContext} from "../../../../AppData";
import {newJobOrVisitRoute, NewJobOrVisitRouteKind} from "../../../screens/jobs/NewJobOrVisitScreen";
import {SetVisitDetailModal} from "../../modals/AppModals";
import {tt} from "../../../../core/Localization";
import AppChip from "../../chips/AppChip";
import AppAvatar from "../../AppAvatar";
import Icons8Attachment from "../../../../icons/Icons8Attachment";
import Icons8ClipBoardList from "../../../../icons/Icons8ClipBoardList";
import {JobNameOrSequenceId} from "../../../../service/JobService";
import Icons8Invoice from "../../../../icons/Icons8Invoice";
import {CalculateProductsPrice, PriceDisplay} from "../../../../service/CompanyService";
import WorkersIcon from "../../../../icons/WorkersIcon";
import Icons8Reset from "../../../../icons/Icons8Reset";
import CustomStatusLikeChip from "../../chips/CustomStatusLikeChip";
import Icons8Document from "../../../../icons/Icons8Document";
import Icons8MediumRisk from "../../../../icons/Icons8MediunRisk";
import Icons8Layers from "../../../../icons/Icons8Layers";
import {combineMaterialsByTemplate} from "../../../../service/MaterialService";
import PermissionValid, {hasSomePermissions} from "../../permissions/PermissionValid";
import {
    kActionView,
    kPermissionsClients,
    kPermissionsForms,
    kPermissionsMaterials,
    kPermissionsProducts,
    kPermissionsTimesheets,
    kPermissionsVisitAttachments, kPermissionsVisitClientDetails,
    kPermissionsVisitProtocols,
    kPermissionsWorkers
} from "../../../../core/constants";
import MediaIcon from "../../../../icons/MediaIcon";

const useStyles = makeStyles()((theme: Theme) => ({
    chipsContainer: {
        display: "flex",
        flexWrap: "wrap",
        marginBottom: 10,
    },
    singleChipContainer: {
        marginBottom: 4,
        marginRight: 4,
    },
    bottomContentContainer: {
        paddingLeft: 44,
    },
    dashedDivider: {
        borderBottom: `1px dashed ${kAppColors.border(theme.palette.mode === "dark")}`
    },
    listItemBeforeContent: {
        flexShrink: 0,
        paddingRight: 6,
        paddingLeft: 14,
        marginTop: 14,
        marginBottom: 22,
        width: 80,
        textAlign: "center",
        borderRight: `1px solid ${kAppColors.border(theme.palette.mode === "dark")}`,
        textOverflow: "ellipsis",
        whiteSpace: "pre-wrap",
        overflow: "hidden",
        'p': {
            margin: 0,
            textOverflow: "ellipsis",
            whiteSpace: "pre-wrap",
            overflow: "hidden",
        }
    },
    firstLine: {
        color: '#FF5555',
        fontWeight: 600,
        lineHeight: 1.3,
    },
    secondLine: {
        color: '#6F6F6F',
        fontWeight: 600,
        lineHeight: 1.3,
    },
    thirdLine: {
        color: kAppColors.text.primary(theme.palette.mode === "dark"),
        fontWeight: 600,
        lineHeight: 1.3,
    },
    noTitleText: {
        color: kAppColors.text.secondary(theme.palette.mode === "dark")
    },
    verticalDivider: {
        width: 2,
        height: 20,
        background: kAppColors.border(theme.palette.mode === "dark"),
        marginRight: 8,
        marginLeft: 8,
    },
    avatarsChip: {
        '.MuiBox-root:last-of-type': {
            paddingRight: 0,
        }
    }
}));

export interface IDetailVisitListItemProps {
    data: IVisitEvent;
    showBorderBottom?: boolean;
    job?: JobPureResponse;
    client?: ClientPureResponse;
    location?: LocationPureResponse;
    employees: EmployeeJoinedUserResponse[];
    jobForms: JobFormPureResponse[];
    timesheets: JobEmployeeTimesheetItemResponse[];
    products: ProductResponse[];
    materials: MaterialResponse[];
    jobOfferSeats?: JobOfferSeatResponse[];
    visitDetailCanNavigateToJob?: boolean;
    files: FileResponse[] | NullOrUndefined;
}

/**
 * List item for a VisitEvent, used in the JobDetailScreen.
 */
export default function VisitListItem(props: IDetailVisitListItemProps) {
    const {
        data,
        showBorderBottom,
        job,
        client,
        location,
        employees,
        jobForms,
        timesheets,
        products,
        materials,
        jobOfferSeats,
        visitDetailCanNavigateToJob,
        files,
    } = props;
    const repeatDay = data.visitRepeatDay;

    const appDataContext = useContext(AppDataContext);
    const {storage, language, company, currency, employeePermissionsMap} = appDataContext;

    const companyHasVat = company?.hasVat || false;

    const navigate = useNavigate();

    const theme = useTheme();
    const {cx, classes} = useStyles();

    const isRepeating = data?.repeating !== VisitRepeating.Never;

    const title = useMemo(() => {
        const tempTitle = VisitNameOrSequenceId(data, repeatDay);
        return <>{!tempTitle ?
            <span className={classes.noTitleText}>{tt('visitItem.withoutTitle')}</span> : tempTitle}</>;
    }, [data]);

    const jobName = job ? JobNameOrSequenceId(job) : undefined;

    let description = location ? `${UserAddressSingle(location.address)} · ${LocationTypeDisplay(location.type)}` : '';

    if (jobName) {
        description = description ? `${description}\n${jobName}` : jobName;
    }

    const theStatus = repeatDay?.status || data?.status;
    const theStatusModifier = repeatDay?.statusModifier || data?.statusModifier;
    const isWholeDay = data.visitRepeatDay ? data.visitRepeatDay.wholeDay : data.wholeDay;

    const isRepeatingJSX = isRepeating ? (<Box
            className={classes.singleChipContainer}
        >
            <Tooltip
                title={tt('visitItem.chipTooltip.isRepeating')}
                slotProps={{
                    popper: {
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, -4],
                                },
                            },
                        ],
                    },
                }}
            >
                <Box>
                    <AppChip
                        chipstyle={"outlined"}
                        icon={<Icons8Reset/>}
                        noiconmargin={"true"}
                    />
                </Box>
            </Tooltip>
        </Box>
    ) : null;

    const statusChipJSX = (
        <Box className={classes.singleChipContainer}>
            <VisitStatusChip
                status={theStatus}
                statusModifier={theStatus === VisitStatus.Closed && theStatusModifier && theStatusModifier !== VisitStatusModifier.None ? theStatusModifier : undefined}
            />
        </Box>
    );

    const startDate = DateTime.fromMillis(repeatDay?.startDate || data.startDateOfDynamic?.toMillis() || data.startDate);

    const dayMonthText = startDate.toFormat('d.MMM', {locale: language});
    const dayOFWeekText = startDate.toFormat('EEEE', {locale: language});
    const hoursMinutes = startDate.toFormat('H:mm', {locale: language});

    const beforeContentJSX = data.status === VisitStatus.ScheduleLater ? null : (
        <Box className={classes.listItemBeforeContent}>
            <Typography className={classes.firstLine}>{dayMonthText}</Typography>
            <Typography className={classes.secondLine}>{dayOFWeekText}</Typography>
            <Typography
                className={classes.thirdLine}>{isWholeDay ? tt('visitItem.label.wholeDay') : hoursMinutes}</Typography>
        </Box>
    );

    /**
     * On click go to detail of Visit or if is not scheduled yet go to NewVisitScreen.
     */
    const onClick = () => {
        if (data.status === VisitStatus.ScheduleLater) {
            navigate(routeWithCurrentAsParam(
                newJobOrVisitRoute({
                    jobId: data.jobId,
                    visitId: data.id,
                    kind: NewJobOrVisitRouteKind.fromJobDetail,
                })
            ));
        } else {
            SetVisitDetailModal(appDataContext, {
                open: true,
                visitId: data.id,
                repeatingDay: data.repeatingDay,
                canNavigateToJob: visitDetailCanNavigateToJob || false,
            });
        }
    };

    const firstFourEmployees = employees.slice(0, 4);
    const avatarsJSX = employees.length > 0 ? (
        <>
            {firstFourEmployees.map((employee, index) => {
                return (
                    <Box
                        key={employee.id}
                        pr={0.5}
                    >
                        <AppAvatar
                            title={UserFullName(employee.name || employee.user?.name, employee.surname || employee.user?.surname)}
                            variant={"tiny"}
                            tooltip={UserFullName(employee.name || employee.user?.name, employee.surname || employee.user?.surname)}
                            img={
                                UserPhotoUrl(employee.user, files, storage.publicUrlsForFiles)
                            }
                        />
                    </Box>
                );
            })}

            {employees.length > 4 ? (
                <>
                    <Typography>+{employees.length - 4}</Typography>
                </>
            ) : undefined}
        </>
    ) : undefined;

    const avatarsAndJobOfferSeatsJSX = useMemo(() => {
        let requiredAvatarsJSX: ReactNode[] = [];
        let substituteAcceptedAvatarsJSX: ReactNode[] = [];
        let substituteAvatarsJSX: ReactNode[] = [];

        if (jobOfferSeats && jobOfferSeats.length > 0) {
            for (const jobOfferSeatOf of jobOfferSeats) {
                let requiredEmployee = false;
                let substituteCount = jobOfferSeatOf.substituteCount;

                for (const acceptedOf of jobOfferSeatOf.acceptedIds) {
                    if (employees && employees.find((employee) => employee.id === acceptedOf)) {
                        if (!requiredEmployee) {
                            requiredEmployee = true;
                        } else {
                            substituteCount--;
                        }

                        continue;
                    }

                    const employee = employees.find((employee) => employee.id === acceptedOf);

                    if (employee) {
                        const avatarJSX = (
                            <Box
                                key={employee.id}
                                pr={0.5}
                            >
                                <AppAvatar
                                    title={UserFullName(employee.name || employee.user?.name, employee.surname || employee.user?.surname)}
                                    variant={"tiny"}
                                    tooltip={UserFullName(employee.name || employee.user?.name, employee.surname || employee.user?.surname)}
                                    img={
                                        UserPhotoUrl(employee.user, files, storage.publicUrlsForFiles)
                                    }
                                />
                            </Box>
                        );

                        if (!requiredEmployee) {
                            requiredEmployee = true;
                            requiredAvatarsJSX.push(avatarJSX);
                        } else {
                            substituteCount--;
                            substituteAvatarsJSX.push(avatarJSX);
                        }
                    }
                }

                if (!requiredEmployee) {
                    substituteAcceptedAvatarsJSX.push(
                        <Box
                            key={`backup-required-${jobOfferSeatOf.uuid}`}
                            pr={0.5}
                        >
                            <AppAvatar
                                title={'? ?'}
                                variant={"tiny"}
                                tooltip={tt('singeJob.card.tooltip.lookingForWorker')}
                                img={null}
                            />
                        </Box>
                    );
                }

                for (let i = 0; i < substituteCount; i++) {
                    substituteAvatarsJSX.push(
                        <Box
                            key={`backup-${i}-${jobOfferSeatOf.uuid}`}
                            pr={0.5}
                        >
                            <AppAvatar
                                variant={"tiny"}
                                img={null}
                                tooltip={tt('singeJob.card.tooltip.lookingForBackup')}
                                title={'? ?'}
                            />
                        </Box>
                    );
                }
            }
        }

        if (employees.length || requiredAvatarsJSX.length || substituteAcceptedAvatarsJSX.length || substituteAvatarsJSX.length) {
            return (
                <PermissionValid
                    permission={kPermissionsWorkers}
                >
                    <Box className={cx(classes.singleChipContainer, classes.avatarsChip)}>
                        <AppChip
                            chipstyle={"outlined"}
                            icon={(
                                <>
                                    {avatarsJSX}
                                    {requiredAvatarsJSX}
                                    {substituteAcceptedAvatarsJSX}
                                    {(avatarsJSX || requiredAvatarsJSX.length || substituteAcceptedAvatarsJSX) && substituteAvatarsJSX.length ?
                                        <Box key={'divider'} className={classes.verticalDivider}></Box> : null}
                                    {substituteAvatarsJSX}
                                </>
                            )}
                        />
                    </Box>
                </PermissionValid>
            );
        }

        return undefined;
    }, [jobOfferSeats, employees]);

    const jobFormsJSX = jobForms.length > 0 ? (
        <PermissionValid
            permission={kPermissionsForms}
        >
            <Box
                className={classes.singleChipContainer}
            >
                <Tooltip
                    title={tt('visit.listItem.chipTooltip.forms')}
                    slotProps={{
                        popper: {
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, -4],
                                    },
                                },
                            ],
                        },
                    }}
                >
                    <Box>
                        <AppChip chipstyle={"outlined"}
                                 icon={<Icons8ClipBoardList/>}
                                 label={jobForms.length}
                        />
                    </Box>
                </Tooltip>
            </Box>
        </PermissionValid>
    ) : null;

    const timesheetsJSX = useMemo(() => {
        const total = PriceDisplay(
            repeatDay?.employeeTotalPrice || data?.employeeTotalPrice,
            currency,
            language,
        );

        return total ? (
            <PermissionValid
                permission={kPermissionsTimesheets}
            >
                <Box className={classes.singleChipContainer}>
                    <Tooltip
                        title={tt('visit.listItem.chipTooltip.workersCost')}
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -4],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <Box>
                            <AppChip
                                chipstyle={"outlined"}
                                icon={<WorkersIcon/>}
                                label={total}
                            />
                        </Box>
                    </Tooltip>
                </Box>
            </PermissionValid>
        ) : undefined;
    }, [data, repeatDay, currency, language]);

    const productsJSX = useMemo(() => {
        return products.length > 0 ? (
            <PermissionValid
                permission={kPermissionsProducts}
            >
                <Box className={classes.singleChipContainer}>
                    <Tooltip
                        title={tt('visit.listItem.chipTooltip.productsCost')}
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -4],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <Box>
                            <AppChip
                                chipstyle={"outlined"}
                                icon={<Icons8Invoice/>}
                                label={
                                    CalculateProductsPrice({
                                        products: products,
                                        currency,
                                        language,
                                        returnZeroInsteadOfUndefined: true,
                                    }, companyHasVat)
                                }
                            />
                        </Box>
                    </Tooltip>
                </Box>
            </PermissionValid>
        ) : undefined;
    }, [products, currency, language]);

    const materialsChipJSX = useMemo(() => {
        const materialsByTemplate = combineMaterialsByTemplate({
            materials,
            employees: null,
        });

        return materialsByTemplate.length > 0 ? (
            <PermissionValid
                permission={kPermissionsMaterials}
            >
                <Box className={classes.singleChipContainer}>
                    <Tooltip
                        title={tt('visit.listItem.chipTooltip.materials')}
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -4],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <Box>
                            <AppChip
                                chipstyle={"outlined"}
                                icon={<Icons8Layers/>}
                                label={materialsByTemplate.length}
                            />
                        </Box>
                    </Tooltip>
                </Box>
            </PermissionValid>
        ) : undefined;
    }, [materials]);

    const attachments = data.visitRepeatDay?.attachmentFileIds || data.attachmentFileIds || [];

    const theProtocolCount = isRepeating ? data.visitRepeatDay?.visitProtocolsCount : data.visitProtocolsCount;
    const theDefectCount = isRepeating ? data.visitRepeatDay?.visitDefectsCount : data.visitDefectsCount;

    const protocolsChipJSX = theProtocolCount ? (
        <PermissionValid
            permission={kPermissionsVisitProtocols}
        >
            <Box className={classes.singleChipContainer}>
                <Tooltip
                    title={tt('visit.listItem.chipTooltip.protocols')}
                    slotProps={{
                        popper: {
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, -4],
                                    },
                                },
                            ],
                        },
                    }}
                >
                    <Box>
                        <AppChip
                            chipstyle={"outlined"}
                            icon={<MediaIcon/>}
                            label={theProtocolCount}
                        />
                    </Box>
                </Tooltip>
            </Box>
        </PermissionValid>
    ) : null;

    const defectsChipJSX = theDefectCount ? (
        <PermissionValid
            permission={kPermissionsVisitProtocols}
        >
            <Box className={classes.singleChipContainer}>
                <Tooltip
                    title={tt('visit.listItem.chipTooltip.defect')}
                    slotProps={{
                        popper: {
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, -4],
                                    },
                                },
                            ],
                        },
                    }}
                >
                    <Box>
                        <CustomStatusLikeChip
                            textColor={kVisitStatusColors.scheduleLater.text}
                            backgroundColor={theme.palette.mode === "dark" ? '#202020' : "white"}
                            icon={<Icons8MediumRisk/>}
                            variant={"outlined"}
                        />
                    </Box>
                </Tooltip>
            </Box>
        </PermissionValid>
    ) : null;

    return (
        <>
            <AppListItemV2
                beforeContent={beforeContentJSX}
                variant={"smaller-title"}
                noAvatar={true}
                title={
                    <>
                        {title}
                        {client && hasSomePermissions([{
                            permission: kPermissionsClients,
                            requiredPermissions: [kActionView]
                        }, {
                            permission: kPermissionsVisitClientDetails,
                            requiredPermissions: [kActionView]
                        }], employeePermissionsMap) ? ` · ${client.name}` : undefined}
                    </>
                }
                description={description}
                belowListItemWidget={
                    <Box
                        className={beforeContentJSX || theStatus === VisitStatus.ScheduleLater ? '' : classes.bottomContentContainer}>
                        <Box className={classes.chipsContainer}>
                            {statusChipJSX}
                            {isRepeatingJSX}
                            {avatarsAndJobOfferSeatsJSX}
                            {productsJSX}
                            {timesheetsJSX}
                            {materialsChipJSX}
                            {jobFormsJSX}
                            {attachments.length > 0 ? (
                                <PermissionValid
                                    permission={kPermissionsVisitAttachments}
                                >
                                    <Box className={classes.singleChipContainer}>
                                        <Tooltip
                                            title={tt('visit.listItem.chipTooltip.attachment')}
                                            slotProps={{
                                                popper: {
                                                    modifiers: [
                                                        {
                                                            name: 'offset',
                                                            options: {
                                                                offset: [0, -4],
                                                            },
                                                        },
                                                    ],
                                                },
                                            }}
                                        >
                                            <Box>
                                                <AppChip
                                                    chipstyle={"outlined"}
                                                    icon={<Icons8Attachment/>}
                                                    label={attachments.length}
                                                />
                                            </Box>
                                        </Tooltip>
                                    </Box>
                                </PermissionValid>
                            ) : undefined}
                            {protocolsChipJSX}
                            {defectsChipJSX}
                        </Box>
                    </Box>
                }
                actionWidget={<></>}
                onClick={onClick}
            />

            {showBorderBottom ? <Box className={classes.dashedDivider}/> : null}
        </>
    );
}
