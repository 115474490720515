import React from "react";
import {tt} from "../../../core/Localization";
import ResponsiveContainer from "../screens/ResponsiveContainer";
import AppPaper from "../paper/AppPaper";
import ContentPadding from "../paper/ContentPadding";
import {makeStyles} from "tss-react/mui";
import {Box, Grid, Theme, Typography} from "@mui/material";
import SocialButton from "../buttons/SocialButton";
import Icons8AppleLogo from "../../../icons/Icons8AppleLogo";
import Icons8GooglePlay from "../../../icons/Icons8GooglePlay";
import Icons8Smartphone from "../../../icons/Icons8Smartphone";
import ScreenContent from "../screens/ScreenContent";
import {kAppColors, kContentWidthNarrow} from "../../../styles/AppThemeProcessor";
import {FlavorByEnvironment} from "../../../flavor-config";

export const useStyles = makeStyles()((theme: Theme) => ({
    modalPaper: {
        maxWidth: kContentWidthNarrow,
    },
    socialButton: {
        marginRight: 4,
        marginLeft: 4,
        marginBottom: 16
    },
    termsTextStyle: {
        fontWeight: 500,
        color: kAppColors.grey.link,
        lineHeight: 18 / 14,
    },
    text: {
        textAlign: "center",
        fontSize: 20,
        fontWeight: 500,
        marginBottom: 38,
    },
    smartphoneIcon: {
        width: 23,
        height: 40,
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: 20,
        'svg': {
            width: 23,
            height: 40,
        }
    },
    actionsContainer: {
        flexGrow: 1,
    }
}));

export default function DownloadAppScreenWidget() {
    return (<ResponsiveContainer
            smallPhoneScreen={<Body isMobile={true}/>}
            largePhoneScreen={<Body isMobile={true}/>}
            tabletScreen={<Body/>}
            smallDesktopScreen={<Body/>} largeDesktopScreen={<Body/>}
            extraLargeDesktopScreen={<Body/>}/>
    );
}

interface IBodyProps {
    isMobile?: boolean;
}

function Body(props: IBodyProps) {
    const {isMobile} = props;
    const {classes} = useStyles();

    return (
        <ScreenContent appBar={!isMobile} centerContent={true} navigationDrawer={!isMobile} bottomBar={isMobile}>
            <AppPaper sx={{maxWidth: kContentWidthNarrow}}>
                <ContentPadding>
                    <Box pb={5}/>
                    <Icons8Smartphone className={classes.smartphoneIcon}/>
                    <Typography className={classes.text}>{tt('downloadApp.modal.text')}</Typography>

                    <Grid className={classes.actionsContainer} container justifyContent={"center"}>
                        <SocialButton
                            onClick={() => {
                                window.open(FlavorByEnvironment()!.appstoreLink, '_blank', 'noreferrer');
                            }
                            }
                            className={classes.socialButton} text={tt('downloadApp.modal.appstore')}
                            icon={<Icons8AppleLogo/>}/>
                        <SocialButton
                            onClick={() => {
                                window.open(FlavorByEnvironment()!.googlePlayLink, '_blank', 'noreferrer');
                            }
                            }
                            className={classes.socialButton} text={tt('downloadApp.modal.googlePlay')}
                            icon={<Icons8GooglePlay/>}/>
                    </Grid>
                    <Box pb={3}/>
                </ContentPadding>
            </AppPaper>
        </ScreenContent>
    );
}
