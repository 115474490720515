import {makeStyles} from "tss-react/mui";
import {Box, InputAdornment, Theme} from "@mui/material";
import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {tt} from "../../../../../core/Localization";
import AppListItem from "../../../listItems/AppListItem";
import {AddOutlined, CheckCircle} from "@mui/icons-material";
import ModalBottomSheet from "../../../ModalBottomSheet";
import BottomSheetModalAppbar from "../../BottomSheetModalAppbar";
import AppButton from "../../../buttons/AppButton";
import ListShimmer from "../../../shimmers/ListShimmer";
import EmptyListText from "../../../textComponents/EmptyListText";
import CreateJobFormModal from "../../jobForms/CreateJobFormModal";
import Icons8ClipBoardList from "../../../../../icons/Icons8ClipBoardList";
import {GetJobFormsInput, JobFormResponsePage} from "../../../../../generated/graphql/graphql";
import {AppDataContext} from "../../../../../AppData";
import {processQueryError} from "../../../../../service/ErrorService";
import FormBuilder, {IInputsData, InputType} from "../../../form/FormBuilder";
import SearchIcon from "../../../../../icons/SearchIcon";
import {RestApiClientContext} from "../../../../../core/RestApiProvider";
import PermissionValid from "../../../permissions/PermissionValid";
import {kActionCreate, kPermissionsForms} from "../../../../../core/constants";

export const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        maxHeight: '80vh',
        height: '100vh',
        overflow: "auto",
    },
    buttonContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: 16,
    },
}));

export interface IChooseJobFormModalBottomSheetProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    existingFormIds?: number[];
    onSave: (ids: number[]) => void;
    singleSelection?: boolean;
    modalAboveModals?: boolean;
}

export default function ChooseJobFormModalBottomSheet(props: IChooseJobFormModalBottomSheetProps) {
    const {
        open,
        setOpen,
        existingFormIds,
        onSave,
        singleSelection,
        modalAboveModals,
    } = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId} = appDataContext;

    const {classes} = useStyles();

    const [chosenFormIds, setChosenFormIds] = useState<number[]>([]);
    const [createModal, setCreateModal] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<JobFormResponsePage | undefined>(undefined);
    useEffect(() => {
        if (open && !createModal) {
            restApiGet({
                uri: '/job-form/search-by-company',
                params: {
                    companyId: companyId!,
                    isTemplate: true,
                } as GetJobFormsInput,
                setLoading: setLoading,
                onData: setData,
                onError: (error) => {
                    processQueryError(appDataContext, error);
                },
            });
        }
    }, [open, companyId, createModal]);

    const [inputs, setInputs] = useState<IInputsData>({
        search: {
            type: InputType.Text,
            label: '',
            placeholder: tt('jobForms.screen.search.placeholder'),
            hideLabel: true,
            inputVariant: 'standard',
            extraStyle: 'thin',
            value: '',
            required: true,
            isClearable: true,
            innerPrefixJSX: (
                <InputAdornment position={"start"}>
                    <SearchIcon/>
                </InputAdornment>
            ),
        },
    });

    useEffect(() => {
        if (open) {
            setChosenFormIds(existingFormIds || []);
        }
    }, [open]);

    const theSearch = inputs.search.value.toLowerCase();

    const formsJSX = data?.content
        .filter(form => form.name.toLowerCase().includes(theSearch))
        .sort((a, b) => {
            if (a.position === b.position) {
                return 0;
            }

            return a.position > b.position ? 1 : -1;
        })
        .map(form =>
            <AppListItem
                key={form.id}
                customAvatar={<Icons8ClipBoardList/>}
                title={form.name}
                variant={"smaller-title"}
                onClick={() => {
                    if (singleSelection) {
                        setChosenFormIds([form.id]);
                    } else {
                        if (chosenFormIds.includes(form.id)) {
                            setChosenFormIds(chosenFormIds.filter(id => id !== form.id));
                        } else {
                            setChosenFormIds([...chosenFormIds, form.id]);
                        }
                    }
                }}
                actionWidget={
                    chosenFormIds.includes(form.id) ? <CheckCircle color={"primary"}/> : <></>
                }
            />
        );

    return (
        <ModalBottomSheet
            tallOnMobile={true}
            blurBackdrop={true}
            open={open}
            setOpen={setOpen}
            hideHeader={true}
            modalAboveModals={modalAboveModals}
        >
            <Box className={classes.container}>
                <BottomSheetModalAppbar
                    onClose={() => setOpen(false)}
                    bottomContent={
                        <>
                            <FormBuilder inputs={inputs} setInputs={setInputs}/>
                        </>
                    }
                >
                    <PermissionValid
                        permission={kPermissionsForms}
                        requiredPermissions={[kActionCreate]}
                    >
                        <AppButton
                            onClick={() => {
                                setCreateModal(true);
                            }}
                            variant={"textThin"}

                        >
                            <AddOutlined sx={{mr: 0.25}}/>
                            {tt('common.newJobForm')}
                        </AppButton>
                    </PermissionValid>
                </BottomSheetModalAppbar>

                {loading ?
                    <ListShimmer items={3} showIcon={false}/>
                    : (!formsJSX || formsJSX.length === 0 ?
                        <EmptyListText text={tt('jobFormsList.emptyListMessage')}/> : formsJSX)}

                <Box sx={{pb: 10}}/>

                <Box className={classes.buttonContainer}>
                    <AppButton
                        variant={"contained"}
                        disabled={chosenFormIds.length === 0}
                        fullWidth={true}
                        onClick={() => {
                            onSave(chosenFormIds);

                            setOpen(false);
                        }}>
                        {tt('common.save')}
                    </AppButton>
                </Box>

                <CreateJobFormModal
                    open={createModal}
                    setOpen={setCreateModal}
                    modalAboveModals={true}
                    navigation={false}
                    onCreate={(id: number) => {
                        setTimeout(() => {
                            onSave([id]);

                            setOpen(false);
                        }, 400);
                    }}
                />
            </Box>
        </ModalBottomSheet>
    );
}
