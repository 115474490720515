import {Box, Divider, Grid, Link, Theme, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import React, {Dispatch, SetStateAction, useContext, useEffect, useMemo, useRef, useState} from "react";
import AppModal from "../modals/AppModal";
import {tt} from "../../../core/Localization";
import AppButton from "../buttons/AppButton";
import {useResettableMutation} from "tomaschyly-apollo-hooks-extended";
import {
    AddressFragment,
    AddressProvider,
    CreateLocationDocument,
    CreateLocationInputPlaceInput,
    CreateLocationMutation,
    CreateLocationMutationVariables
} from "../../../generated/graphql/graphql";
import {AppDataContext, IAppDataContext} from "../../../AppData";
import {useNavigate} from "react-router-dom";
import GoogleMapReact from 'google-map-react';
import {FlavorByEnvironment} from "../../../flavor-config";
import {
    GeocodeAddressToCoordinate,
    GeocodeAddressToCoordinateMapyCZ,
    kDefaultMapProps,
    OnGoogleMapLoaded
} from "../../../service/MapsService";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../form/FormBuilder";
import {SuccessToast} from "../../../service/ToastService";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import DeleteIcon from "../../../icons/DeleteIcon";
import {SetConfirmModal} from "../modals/AppModals";
import {kLocationTypesOptions} from "../../../service/LocationService";
import LocationAddressMarker from "../maps/LocationAddressMarker";
import {locationDetailRoute} from "../../screens/locations/LocationDetailScreen";
import Icons8CloneFigure from "../../../icons/Icons8CloneFigure";
import AppIconButton from "../buttons/AppIconButton";
import Icons8Here from "../../../icons/Icons8-here";
import Icons8Location from "../../../icons/Icons8Location";
import MapyCZ from "../maps/MapyCZ";
import Debouncer from "../../../utils/Debouncer";
import {kUserInputDebounce} from "../../../core/constants";
import {processMutationError} from "../../../service/ErrorService";

export const useStyles = makeStyles()((theme: Theme) => ({
    submitRightContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
    },
    button: {
        "@media (max-width: 767px)": {
            flexGrow: 1,
        }
    },
    map: {
        width: '100%',
        height: 280,
        marginBottom: 20,
        borderRadius: 8,
        overflow: "hidden",
    },
    helperButtonContainer: {
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        height: 38,
        marginBottom: 11,
    },
    helperButton: {
        color: kAppColors.text.primary(theme.palette.mode === "dark") + ' !important',
        textDecoration: "underline",
        cursor: "pointer",
    },
    helperText: {
        color: theme.palette.mode == 'dark' ? '#989898' : '#65676B',
        fontSize: '0.75rem',
        marginTop: -16,
        marginLeft: 12,
    },
    divider: {
        marginLeft: -16,
        marginRight: -16,
        marginBottom: 11,
    },
    deleteIcon: {
        color: kAppColors.red.confirmButton,
        'svg': {height: 24, width: 24}
    },
    placesDivider: {
        display: "none",
        "@media (max-width: 600px)": {
            display: "block"
        },
        marginLeft: -16,
        marginRight: -16,
        marginBottom: 20,
    },
    helperModalText: {
        textAlign: "center",
        color: kAppColors.text.secondaryLighterForDarkMode(theme.palette.mode == "dark"),
        'strong': {
            color: kAppColors.text.primary(theme.palette.mode === "dark"),
        }
    },
    suffixIconsContainer: {
        paddingTop: 5,
    },
    addPlaceButton: {
        "@media (max-width: 600px)": {
            marginBottom: 16,
        }
    }
}));

export interface ICreateLocationModalProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    clientId: number;
    navigation?: boolean;
    onCreate?: (id: number, sendNotifications: boolean) => void;
    modalAboveModals?: boolean;
    askToSendNotifications?: boolean;
}

/**
 * Modal component to create new Location for Client.
 */
export default function CreateLocationModal(props: ICreateLocationModalProps) {
    const {open, setOpen, clientId, navigation, onCreate, modalAboveModals, askToSendNotifications} = props;

    const appDataContext = useContext(AppDataContext);
    const {companyId, company} = appDataContext;

    const navigate = useNavigate();
    const theNavigation = navigation === undefined ? true : navigation;

    const {classes} = useStyles();

    const [mutateCreate, {
        loading,
    }] = useResettableMutation<CreateLocationMutation, CreateLocationMutationVariables>(CreateLocationDocument);

    const [mapInputs, setMapInputs] = useState<IInputsData>({
        type: {
            testId: 'workerTimesheetModalTypeInput',
            value: company?.address?.country === 'cz' ? AddressProvider.MapyCz : AddressProvider.Google,
            label: '',
            type: InputType.ChipSwitch,
            options: [
                {label: tt('common.googleMaps'), value: AddressProvider.Google},
                {label: tt('common.mapyCZ'), value: AddressProvider.MapyCz},
            ]
        },
    });
    const [placesCount, setPlacesCount] = useState<number>(1);
    const [removePlaceIndex, setRemovePlaceIndex] = useState<number>();
    const [duplicatePlaceIndex, setDuplicatePlaceIndex] = useState<number>();
    const [inputs, setInputs] = useState<IInputsData>({});
    const [invalidateForm, setInvalidateForm] = useState<boolean>(false);
    const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true);

    const addPlaces = inputs.addPlaces?.value || false;

    useEffect(() => {
        FormData(
            {
                appDataContext,
                mapInputs,
                inputs,
                setInputs,
                setInvalidateForm,
                placesCount,
                setPlacesCount,
                removePlaceIndex,
                setRemovePlaceIndex,
                duplicatePlaceIndex,
                setDuplicatePlaceIndex,
                classes,
            },
        );
    }, [placesCount, addPlaces, invalidateForm, mapInputs.type.value]);

    const [notificationsInputs, setNotificationsInputs] = useState<IInputsData>({
        notifications: {
            type: InputType.CheckBox,
            label: tt('common.visit.checkbox.sendNotificationsToWorkers'),
            value: true,
        },
    });

    /**
     * Reset validation, values
     */
    useEffect(() => {
        if (open) {
            setPlacesCount(0);
            setInputs(
                prev => {
                    return {
                        ...prev,
                        address: {
                            ...prev.address,
                            value: '',
                        },
                        mapyczAddress: {
                            ...prev.mapyczAddress,
                            value: '',
                        },
                        type: {
                            ...prev.type,
                            value: '',
                            userData: '',
                        },
                        name: {
                            ...prev.name,
                            value: '',
                        },
                        specification: {
                            ...prev.specification,
                            value: '',
                        },
                        addPlaces: {
                            ...prev.addPlaces,
                            value: false,
                        }
                    }
                }
            );

            setNotificationsInputs(prev => {
                return {
                    ...prev,
                    notifications: {
                        ...prev.notifications,
                        value: true,
                    },
                };
            });
        }
    }, [open]);

    const [service, setService] = useState<any>();
    const currentAddress = inputs.address?.value || '';
    const mapyczAddress = inputs.mapyczAddress?.value || '';

    const [mapCenter, setMapCenter] = useState<GoogleMapReact.Coords>(kDefaultMapProps.center);
    const [markerLocation, setMarkerLocation] = useState<GoogleMapReact.Coords>(kDefaultMapProps.center);
    const [mapZoom, setMapZoom] = useState<number>(kDefaultMapProps.zoom);
    const [addressFragment, setAddressFragment] = useState<AddressFragment>();

    const [mapyCZMapCenter, setMapyCZMapCenter] = useState<GoogleMapReact.Coords>(kDefaultMapProps.center);
    const [mapyCZMarkerLocation, setMapyCZMarkerLocation] = useState<GoogleMapReact.Coords>(kDefaultMapProps.center);
    const [mapyCZMapZoom, setMapyCZMapZoom] = useState<number>(kDefaultMapProps.zoom);
    const [mapyCZAddressFragment, setMapyCZAddressFragment] = useState<AddressFragment>();

    useEffect(() => {
        if (mapInputs.type.value == AddressProvider.Google) {
            if (inputs.address?.value && inputs.type?.value != '' && addressFragment) {
                if (disabledSubmit) {
                    setDisabledSubmit(false);
                }
            } else if (!inputs.address?.value || inputs.type?.value == '' || !addressFragment) {
                if (!disabledSubmit) {
                    setDisabledSubmit(true);
                }
            }
        }
        if (mapInputs.type.value == AddressProvider.MapyCz) {
            if (inputs.mapyczAddress?.value && inputs.type?.value != '' && mapyCZAddressFragment) {
                if (disabledSubmit) {
                    setDisabledSubmit(false);
                }
            } else if (!inputs.mapyczAddress?.value || inputs.type?.value == '' || !mapyCZAddressFragment) {
                if (!disabledSubmit) {
                    setDisabledSubmit(true);
                }
            }
        }
    }, [inputs, addressFragment, mapyCZAddressFragment, mapInputs.type.value]);

    const addressOnMapTimeout = useRef(new Debouncer(kUserInputDebounce));

    useEffect(() => {
        addressOnMapTimeout.current!.run(() => {
            AddressOnMap({currentAddress, placeId: inputs.address?.placeId})
                .catch(e => console.error(e));
        });

        return () => {
            addressOnMapTimeout.current?.dispose();
        };
    }, [currentAddress]);

    useEffect(() => {
        AddressOnMap({mapyczAddress})
            .catch(e => console.error(e));
    }, [mapyczAddress]);

    /**
     * Geocode address to determine location displayed on map.
     */
    const AddressOnMap = async (params: {
        currentAddress?: string,
        placeId?: string,
        mapyczAddress?: string,
    }) => {
        const theService = service || new window.google.maps.Geocoder();
        if (!service) {
            setService(theService);
        }

        if (params.currentAddress && params.currentAddress.trim().length > 0) {
            const result = await GeocodeAddressToCoordinate({
                service: theService,
                address: params.currentAddress,
                placeId: params.placeId,
            });

            if (result) {
                setMapCenter(result.coordinate);
                setMarkerLocation(result.coordinate);
                setMapZoom(kDefaultMapProps.closeInMapZoom);
                setAddressFragment(result.addressFragment);
            } else {
                setMapCenter(kDefaultMapProps.center);
                setMarkerLocation(kDefaultMapProps.center);
                setMapZoom(kDefaultMapProps.zoom);
                setAddressFragment(undefined);
            }
        }
        if (params.mapyczAddress) {
            const result = await GeocodeAddressToCoordinateMapyCZ(params.mapyczAddress);

            if (result) {
                setMapyCZMapCenter(result.coordinate);
                setMapyCZMarkerLocation(result.coordinate);
                setMapyCZMapZoom(17);
                setMapyCZAddressFragment(result.addressFragment);
            } else {
                setMapyCZMapCenter(kDefaultMapProps.center);
                setMapyCZMarkerLocation(kDefaultMapProps.center);
                setMapyCZMapZoom(kDefaultMapProps.zoom);
                setMapyCZAddressFragment(undefined);
            }
        }
    };

    /**
     * Mutate Location to BE and close modal if success.
     */
    const CreateLocation = async () => {
        if (ValidateForm(inputs, setInputs)) {
            try {
                const places: CreateLocationInputPlaceInput[] = [];
                if (inputs.addPlaces.value) {
                    for (let i = 1; i <= placesCount; i++) {
                        places.push({
                            name: inputs[`place_${i}_name`].value,
                            type: inputs[`place_${i}_type`].value.newValue || inputs[`place_${i}_type`].value.value,
                            icon: '',
                        });
                    }
                }

                const theFragment = mapInputs.type.value == AddressProvider.Google ? addressFragment : mapyCZAddressFragment;

                const variables: CreateLocationMutationVariables = {
                    input: {
                        companyId: companyId!!,
                        clientId: clientId,
                        address: theFragment!,
                        type: inputs.type.value.newValue || inputs.type.value.value,
                        icon: '',
                        name: inputs.name.value || undefined,
                        specification: inputs.specification.value || undefined,
                        places: places,
                    },
                };

                const result = await mutateCreate({variables});

                if (!result.errors) {
                    setOpen(false);

                    if (theNavigation) {
                        navigate(locationDetailRoute(clientId, result.data!.createLocation.id));
                    }

                    SuccessToast(tt('createLocation.modal.success'));

                    if (onCreate) {
                        if (askToSendNotifications) {
                            if (ValidateForm(notificationsInputs, setNotificationsInputs)) {
                                onCreate(result.data!.createLocation.id, notificationsInputs.notifications.value);
                            }
                        } else {
                            onCreate(result.data!.createLocation.id, false);
                        }
                    }
                }
            } catch (e) {
                processMutationError(e);
            }
        }
    };

    const apiKey = FlavorByEnvironment()!.firebase.apiKey;
    const markerJSX = addressFragment ? (
        <LocationAddressMarker lat={markerLocation.lat} lng={markerLocation.lng}/>
    ) : undefined;
    const mapyCZMarker = useMemo(() => {
        return mapyCZAddressFragment ? [{
            lat: mapyCZMarkerLocation.lat,
            lng: mapyCZMarkerLocation.lng,
        }] : undefined;
    }, [mapyCZAddressFragment]);

    const mapsJSX = mapInputs.type.value === AddressProvider.Google ? (
        <GoogleMapReact
            yesIWantToUseGoogleMapApiInternals={true}
            onGoogleApiLoaded={(maps) => OnGoogleMapLoaded(appDataContext, maps)}
            bootstrapURLKeys={{
                key: apiKey,
                libraries: ['places']
            }}
            center={mapCenter}
            zoom={mapZoom}>

            {markerJSX}
        </GoogleMapReact>
    ) : mapInputs.type.value === AddressProvider.MapyCz ? (
        <MapyCZ center={mapyCZMapCenter} zoom={mapyCZMapZoom} markers={mapyCZMarker}/>
    ) : undefined;

    return (
        <AppModal
            open={open}
            setOpen={setOpen}
            title={tt('common.newLocation')}
            fullScreenOnMobile={true}
            blurBackdrop={true}
            modalAboveModals={modalAboveModals}
        >

            <FormBuilder inputs={mapInputs} setInputs={setMapInputs}/>

            <div className={classes.map}>
                {mapsJSX}
            </div>

            <FormBuilder inputs={inputs} setInputs={setInputs}/>

            {askToSendNotifications ? (
                <FormBuilder inputs={notificationsInputs} setInputs={setNotificationsInputs}/>
            ) : null}

            <div className={classes.submitRightContainer}>
                <AppButton
                    className={classes.button}
                    variant={"contained"}
                    fullWidth={false}
                    onClick={CreateLocation}
                    isLoading={loading}
                    disabled={disabledSubmit}>
                    {tt('common.create')}
                </AppButton>
            </div>
        </AppModal>
    );
}

interface IFormDataProps {
    appDataContext: IAppDataContext;
    mapInputs: IInputsData;
    inputs: IInputsData;
    setInputs: Dispatch<SetStateAction<IInputsData>>;
    setInvalidateForm: Dispatch<SetStateAction<boolean>>;
    placesCount: number;
    setPlacesCount: Dispatch<SetStateAction<number>>;
    removePlaceIndex?: number;
    setRemovePlaceIndex: Dispatch<SetStateAction<number | undefined>>;
    duplicatePlaceIndex?: number;
    setDuplicatePlaceIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
    classes: Record<string, string>;
}

/**
 * Form data based on state.
 */
function FormData(props: IFormDataProps) {
    const {
        appDataContext,
        mapInputs,
        inputs,
        setInputs,
        setInvalidateForm,
        placesCount,
        setPlacesCount,
        removePlaceIndex,
        setRemovePlaceIndex,
        duplicatePlaceIndex,
        setDuplicatePlaceIndex,
        classes,
    } = props;

    /**
     * Add another Place to form.
     */
    const AddPlace = () => setPlacesCount(prev => prev + 1);

    /**
     * Remove Place from form and make sure data for others is not lost.
     */
    const RemovePlace = (i: number) => {
        setRemovePlaceIndex(i);

        setPlacesCount(prev => {
            if (prev - 1 === 0) {
                setTimeout(() => {
                    setInputs(prev => {
                        return {
                            ...prev,
                            addPlaces: {
                                ...prev.addPlaces,
                                value: false,
                            },
                        };
                    });
                }, 1);
            }

            return prev - 1;
        });
    };

    /**
     * Duplicate existing Place.
     */
    const DuplicatePlace = (i: number) => {
        setDuplicatePlaceIndex(i);

        AddPlace();
    };

    const newInputs: IInputsData = {
        address: {
            type: InputType.GooglePlacesAutocomplete,
            label: `${tt('common.address')}*`,
            value: inputs.address?.value || '',
            required: mapInputs.type.value === AddressProvider.Google,
            placeholder: tt('common.addAddress.placeholder'),
            isClearable: true,
            grid: {
                sm: 8,
                xs: 12
            },
            hidden: mapInputs.type.value === AddressProvider.MapyCz,
        },
        mapyczAddress: {
            type: InputType.MapyCZAutocomplete,
            label: `${tt('common.address')}*`,
            value: inputs.mapyczAddress?.value || '',
            required: mapInputs.type.value === AddressProvider.MapyCz,
            grid: {
                sm: 8,
                xs: 12
            },
            hidden: mapInputs.type.value === AddressProvider.Google,
        },
        type: {
            ...inputs.type,
            type: InputType.TextAutocomplete,
            label: `${tt('common.type')}*`,
            value: inputs.type?.value || '',
            autocompleteOptions: kLocationTypesOptions(true),
            required: true,
            overlayEndSuffixJSX: () => <Box marginTop={1.75} marginRight={1}><Icons8Location/></Box>,
            grid: {
                sm: 4,
                xs: 12
            },
            helperText: <Typography className={classes.helperText} paragraph={true}>{tt('common.type.helperText')}</Typography>,
        },
        name: {
            ...inputs.name,
            type: InputType.ButtonTextField,
            label: tt('common.locationName'),
            toggleButtonText: tt('common.addLocationName'),
            value: inputs.name?.value || '',
        },
        specification: {
            ...inputs.specification,
            type: InputType.ButtonTextField,
            label: tt('common.specification'),
            minRows: 4,
            toggleButtonText: tt('common.addLocationSpecification'),
            value: inputs.specification?.value || '',
        },
        divider: {
            type: InputType.CustomRenderer,
            label: '',
            value: '',
            render: () => (
                <Divider className={classes.divider}/>
            ),
        },
        addPlaces: {
            type: InputType.Switch,
            label: tt('createLocationModal.addPlaces'),
            value: inputs.addPlaces?.value || false,
            grid: {
                xs: 6
            },
            onSwitch: (value: boolean) => {
                if (value && placesCount < 1) {
                    setPlacesCount(1);
                }
            },
        },
        link: {
            type: InputType.CustomRenderer,
            label: '',
            value: '',
            grid: {
                xs: 6
            },
            render: () => (
                <Box className={classes.helperButtonContainer}>
                    <Link className={classes.helperButton}
                          onClick={() => {
                              SetConfirmModal(
                                  appDataContext,
                                  {
                                      blurBackdrop: true,
                                      open: true,
                                      title: tt('placesHelperModal.title'),
                                      subtitle: undefined,
                                      children:
                                          <Typography className={classes.helperModalText}
                                                      dangerouslySetInnerHTML={{__html: `<p>${tt('placesHelperModal.text').replace('&&bold', `<Strong>`).replace('&&boldEnd', `</Strong>`)}</p>`}}></Typography>,
                                      cancelButtonText: tt('common.close'),
                                      onConfirm: undefined,
                                  }
                              );
                          }}
                    >
                        {tt('createLocation.modal.helperText')}
                    </Link>
                </Box>
            ),
        }
    };

    if (newInputs.addPlaces.value) {
        const iterateOver = removePlaceIndex !== undefined ? placesCount + 1 : placesCount;
        let inputIndex = 1;
        let hadDuplicate = false;

        for (let i = 1; i <= iterateOver; i++) {
            if (i === removePlaceIndex) {
                continue;
            }

            let valueIndex = i;

            const prevIndex = i - 1;
            const isDuplicateOfPrev = duplicatePlaceIndex !== undefined && duplicatePlaceIndex === prevIndex;

            if (isDuplicateOfPrev) {
                valueIndex = prevIndex;
                hadDuplicate = true;
            }
            if (hadDuplicate) {
                valueIndex = i - 1;
            }

            newInputs[`place_${inputIndex}_name`] = {
                type: InputType.Text,
                label: `${tt('common.placeName')}*`,
                value: inputs[`place_${valueIndex}_name`]?.value || '',
                required: true,
                grid: {
                    sm: 6,
                    xs: 12
                },
            };

            ((inputIndex) => {
                newInputs[`place_${inputIndex}_type`] = {
                    ...inputs[`place_${valueIndex}_type`],
                    type: InputType.TextAutocomplete,
                    label: `${tt('common.type')}*`,
                    value: inputs[`place_${valueIndex}_type`]?.value || '',
                    autocompleteOptions: kLocationTypesOptions(),
                    required: true,
                    overlayEndSuffixJSX: () => <Box marginTop={1.75} marginRight={1}><Icons8Here/></Box>,
                    suffixJSX: () => <Grid className={classes.suffixIconsContainer}>
                        <AppIconButton
                            tooltip={tt('common.duplicate')}
                            onClick={() => DuplicatePlace(i)}
                        >
                            <Icons8CloneFigure/>
                        </AppIconButton>
                        <AppIconButton
                            tooltip={tt('common.delete')}
                            onClick={() => RemovePlace(i)}
                        >
                            <DeleteIcon className={classes.deleteIcon}/>
                        </AppIconButton>
                    </Grid>,
                    grid: {
                        sm: 6,
                        xs: 12
                    },
                };
            })(inputIndex);

            if (i < placesCount) {
                newInputs[`place_${inputIndex}_divider`] = {
                    type: InputType.CustomRenderer,
                    label: '',
                    value: '',
                    render: () => (
                        <Divider className={classes.placesDivider}/>
                    ),
                };
            }

            inputIndex++;
        }

        newInputs.addPlace = {
            type: InputType.CustomRenderer,
            label: '',
            value: '',
            render: () => (
                <Box className={classes.addPlaceButton}>
                    <AppButton
                        variant={"textThin"}
                        fullWidth={false}
                        onClick={AddPlace}>
                        {tt('common.addPlace')}
                    </AppButton>
                </Box>
            ),
        };
    }

    if (removePlaceIndex !== undefined) {
        setRemovePlaceIndex(undefined);
    }
    if (duplicatePlaceIndex !== undefined) {
        setDuplicatePlaceIndex(undefined);
    }

    setInputs(newInputs);
}
