import React, {useContext, useEffect, useState} from "react";
import AppModal from "./AppModal";
import {tt} from "../../../core/Localization";
import AppButton from "../buttons/AppButton";
import {getFlowStepAsString} from "../../../utils/Utils";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../form/FormBuilder";
import {IndustryResponsePage} from "../../../generated/graphql/graphql";
import {makeStyles} from "tss-react/mui";
import {Box, Theme} from "@mui/material";
import {kContentWidthNarrow} from "../../../styles/AppThemeProcessor";
import {kAvailableCountries, kAvailableCurrencies} from "../../../service/CompanyService";
import {RestApiClientContext} from "../../../core/RestApiProvider";
import {processQueryError} from "../../../service/ErrorService";
import {AppDataContext} from "../../../AppData";

export const useStyles = makeStyles()((theme: Theme) => ({
    modalPaper: {
        maxWidth: kContentWidthNarrow,
    },
    select: {
        '.MuiAutocomplete-popper': {
            background: "black"
        }
    },
    autocompletePaper: {
        "@media (max-width: 767px)": {
            maxHeight: 150 + 'px !important',
        }
    }
}));

export interface INewCompanyModalProps {
    open: boolean;
    onClose: Function;
    onContinue: (inputs: IInputsData) => void;
    blurBackdrop?: boolean;
    singleStep?: boolean;
    invalidateForm?: boolean;
    cleanFormOnOpen?: boolean;
}

export default function NewCompanyModal(props: INewCompanyModalProps) {
    const {open, onClose, onContinue, blurBackdrop, singleStep, invalidateForm, cleanFormOnOpen} = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);

    const {classes} = useStyles();

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<IndustryResponsePage | NullOrUndefined>();
    useEffect(() => {
        if (open) {
            restApiGet({
                uri: '/industry/search',
                params: {},
                setLoading,
                onData: setData,
                onError: (error) => {
                    processQueryError(appDataContext, error);
                },
            });
        }
    }, [open]);

    const [showForm, setShowForm] = useState<boolean>(true);
    const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);

    useEffect(() => {
        if (data) {
            setInputs((prev: IInputsData) => {
                return {
                    ...prev,
                    industries: {
                        ...prev.industries,
                        options: data.content
                            .map((industry) => ({...industry}))
                            .sort((a, b) => {
                                const aTranslated = tt(a.translationKey);
                                const bTranslated = tt(b.translationKey);

                                if (a.name === 'other') {
                                    return 1;
                                }

                                return aTranslated.localeCompare(bTranslated);
                            })
                            .map((industry) => ({
                                value: industry.id,
                                label: tt(industry.translationKey),
                            })),
                    },
                };
            });
        }
    }, [data]);

    function resetForm() {
        setInputs((prev) => {
            return {
                ...prev,
                companyName: {
                    ...prev.companyName,
                    value: '',
                    error: false,
                },
                country: {
                    ...prev.country,
                    value: '',
                    error: false,
                },
                currency: {
                    ...prev.currency,
                    value: '',
                    hidden: true,
                    error: false,
                },
                industries: {
                    ...prev.industries,
                    value: [],
                    error: false,
                },
                vat: {
                    ...prev.vat,
                    value: false,
                    error: false,
                    hidden: true,
                },
            };
        });
    }

    useEffect(() => {
        resetForm();
    }, [invalidateForm]);

    useEffect(() => {
        setLoadingUpdate(false);
        if (open) {
            setShowForm(false);
            setShowForm(true);
        } else {
            setShowForm(false);
        }
        if (open && cleanFormOnOpen) {
            resetForm();
        }
    }, [open]);

    const [inputs, setInputs] = useState<IInputsData>({
        companyName: {
            type: InputType.Text,
            label: `${tt('common.companyName')}*`,
            value: '',
            required: true,
        },
        industries: {
            className: classes.select,
            required: true,
            type: InputType.ChipAutocomplete,
            value: [],
            options: [],
            label: `${tt('common.industries')}*`,
            placeholder: tt('editCompany.screen.chooseIndustryPlaceholder'),
            paperClass: classes.autocompletePaper,
        },
        country: {
            type: InputType.Select,
            label: `${tt('common.country')}*`,
            options: kAvailableCountries(),
            value: '',
            required: true,
        },
        currency: {
            type: InputType.Select,
            label: `${tt('common.currency')}*`,
            options: kAvailableCurrencies,
            value: kAvailableCurrencies[1].value,
            required: true,
            hidden: true,
        },
        vat: {
            type: InputType.Switch,
            label: tt('vat.label.isVat'),
            value: false,
            hidden: true,
        },
    });

    useEffect(() => {
        if (inputs.country.value) {
            let currencyValue = '';

            if (inputs.country.value === 'us') {
                currencyValue = kAvailableCurrencies[2].value;
            } else if (inputs.country.value === 'cz') {
                currencyValue = kAvailableCurrencies[0].value;
            } else if (inputs.country.value === 'sk') {
                currencyValue = kAvailableCurrencies[1].value;
            } else {
                currencyValue = kAvailableCurrencies[1].value;
            }

            setInputs((prev: IInputsData) => {
                return {
                    ...prev,
                    currency: {
                        ...prev.currency,
                        hidden: false,
                        value: currencyValue,
                    },
                    vat: {
                        ...prev.vat,
                        hidden: false,
                    },
                };
            });
        }
    }, [inputs.country.value]);

    return (
        <AppModal
            blurBackdrop={blurBackdrop}
            paperClassName={classes.modalPaper} onClose={onClose}
            title={tt('onboarding.modal.newCompany')}
            subtitle={singleStep ? undefined : getFlowStepAsString(1, 2)}
            actions={
                <AppButton
                    fullWidth={true}
                    variant={"contained"}
                    onClick={() => {
                        if (ValidateForm(inputs, setInputs)) {
                            if (singleStep) {
                                setShowForm(false);
                                setLoadingUpdate(true);
                                setTimeout(function () {
                                    onContinue(inputs);
                                }, 400);
                            } else {
                                onContinue(inputs);
                            }
                        }
                    }
                    }
                    isLoading={loadingUpdate}
                    disabled={loading || !showForm}>
                    {singleStep ? tt('newCompanyModal.create') : tt('common.continue')}
                </AppButton>}
            open={open}>

            {/*{singleStep ? <FormBuilder inputs={inputs} setInputs={setInputs}/> :
                <TransitionAnimationWidget show={showForm}>
                    <FormBuilder inputs={inputs} setInputs={setInputs}/>
                </TransitionAnimationWidget>
            }*/}
            <FormBuilder inputs={inputs} setInputs={setInputs}/>

            <Box pb={2}/>
        </AppModal>
    );
}
