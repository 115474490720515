import React from "react";
import Icons8ClipBoardList from "../icons/Icons8ClipBoardList";
import Icons8Product from "../icons/Icons8Product";
import ClockIcon from "../icons/ClockIcon";
import Icons8Company from "../icons/Icons8Company";
import {tt} from "../core/Localization";
import {EmployeeRole} from "../generated/graphql/graphql";
import HammerIcon from "../icons/HammerIcon";
import WorkersIcon from "../icons/WorkersIcon";
import PeopleIcon from "../icons/PeopleIcon";
import Icons8Layers from "../icons/Icons8Layers";
import {
    kActionCreate,
    kActionDelete, kActionUpdate, kActionView,
    kPermissionsClients,
    kPermissionsCompany, kPermissionsForms,
    kPermissionsJobs, kPermissionsMaterials, kPermissionsProducts,
    kPermissionsTimesheets, kPermissionsVisitAttachments, kPermissionsVisitProtocols,
    kPermissionsWorkers
} from "../core/constants";
import Icons8Attachment from "../icons/Icons8Attachment";
import Icons8Document from "../icons/Icons8Document";
import Icons8InvisibleIcon from "../icons/Icons8Invisible";
import Icons8EyeOutlinedIcon from "../icons/Icons8EyeOutlined";
import Icons8EditOutlined from "../icons/Icons8EditOutlined";
import DeleteIcon from "../icons/DeleteIcon";
import MediaIcon from "../icons/MediaIcon";

export const kAllPermissions = [
    kPermissionsCompany,
    kPermissionsJobs,
    kPermissionsClients,
    kPermissionsWorkers,
    kPermissionsTimesheets,
    kPermissionsForms,
    kPermissionsProducts,
    kPermissionsMaterials,
    kPermissionsVisitAttachments,
    kPermissionsVisitProtocols,
];

export const kAdminPermissions = [
    kPermissionsForms,
    kPermissionsProducts,
    kPermissionsMaterials,
    kPermissionsVisitAttachments,
    kPermissionsVisitProtocols,
];

export function getPermissionsSectionIcon(type: string) {
    switch (type) {
        case kPermissionsCompany:
            return <Icons8Company/>;
        case kPermissionsJobs:
            return <HammerIcon/>;
        case kPermissionsClients:
            return <PeopleIcon/>;
        case kPermissionsWorkers:
            return <WorkersIcon/>;
        case kPermissionsTimesheets:
            return <ClockIcon/>;
        case kPermissionsProducts:
            return <Icons8Product/>;
        case kPermissionsMaterials:
            return <Icons8Layers/>;
        case kPermissionsForms:
            return <Icons8ClipBoardList/>;
        case kPermissionsVisitAttachments:
            return <Icons8Attachment/>;
        case kPermissionsVisitProtocols:
            return <MediaIcon/>;
    }
}

export function getPermissionTitle(type: string) {
    switch (type) {
        case kPermissionsCompany:
            return tt('permission.company');
        case kPermissionsJobs:
            return tt('permission.jobsVisits');
        case kPermissionsClients:
            return tt('permission.clients');
        case kPermissionsWorkers:
            return tt('permission.workers');
        case kPermissionsTimesheets:
            return tt('permission.timesheets');
        case kPermissionsProducts:
            return tt('permission.servicesAndProducts');
        case kPermissionsMaterials:
            return tt('permission.materials');
        case kPermissionsForms:
            return tt('permission.forms');
        case kPermissionsVisitAttachments:
            return tt('permission.visitAttachments');
        case kPermissionsVisitProtocols:
            return tt('permission.visitProtocols');
    }
}

export function getApplyPermissionsForAllLabel(role: EmployeeRole | NullOrUndefined) {
    switch (role) {
        case EmployeeRole.Worker:
            return tt('permissionsModal.checkbox.label.applyForAllWorkers');
        case EmployeeRole.Manager:
            return tt('permissionsModal.checkbox.label.applyForAllManagers');
        case EmployeeRole.HeadOfWorkers:
            return tt('permissionsModal.checkbox.label.applyForAllHeadOfWorkers');
        case EmployeeRole.Admin:
            return tt('permissionsModal.checkbox.label.applyForAllOwners');
        default:
            return '';
    }
}

/**
 * Convert permissions to index for select.
 */
export function getPermissionIndex(permissions: string[]) {
    if (permissions.includes(kActionDelete)) {
        return 3;
    } else if (permissions.includes(kActionCreate) && permissions.includes(kActionUpdate)) {
        return 2;
    } else if (permissions.includes(kActionView)) {
        return 1;
    }

    return 0;
}

/**
 * Convert index from select to permissions.
 */
export function getPermissionFromIndex(index: number): string[] {
    switch (index) {
        case 3:
            return [kActionView, kActionCreate, kActionUpdate, kActionDelete];
        case 2:
            return [kActionView, kActionCreate, kActionUpdate];
        case 1:
            return [kActionView];
        default:
            return [];
    }
}

/**
 * Edit permissions.permissionsMapJSON for update of permission.
 */
export function updatePermissionsMapJSONForOption(permission: string, option: number, permissionsMapJSON: string): string {
    const permissions = JSON.parse(permissionsMapJSON);

    permissions[permission] = getPermissionFromIndex(option);

    return JSON.stringify(permissions);
}

/**
 * Edit permissions.permissionsMapJSON for update of permission.
 */
export function updatePermissionsMapJSON(permission: string, values: string[], permissionsMapJSON: string): string {
    const permissions = JSON.parse(permissionsMapJSON);

    permissions[permission] = values;

    return JSON.stringify(permissions);
}

/**
 * Display message for permissions.
 */
export function getPermissionMessage(permissions: string[]) {
    if (permissions.includes(kActionDelete)) {
        return tt('permissionType.viewEditDelete');
    } else if (permissions.includes(kActionCreate) && permissions.includes(kActionUpdate)) {
        return tt('permissionType.viewEdit');
    } else if (permissions.includes(kActionView)) {
        return tt('permissionType.viewOnly');
    }

    return tt('permissionType.none');
}


export function getPermissionTypeIcon(permissions: string[]) {
    if (permissions.includes(kActionDelete)) {
        return <DeleteIcon/>;
    } else if (permissions.includes(kActionCreate) && permissions.includes(kActionUpdate)) {
        return <Icons8EditOutlined/>;
    } else if (permissions.includes(kActionView)) {
        return <Icons8EyeOutlinedIcon/>;
    }

    return <Icons8InvisibleIcon/>;
}

export function getPermissionTypeAllIcons(permissions: string[]) {
    const icons = [];
    if (permissions.includes(kActionDelete)) {
        icons.push(<Icons8EyeOutlinedIcon/>);
        icons.push(<Icons8EditOutlined/>);
        icons.push(<DeleteIcon/>);
        return icons;
    } else if (permissions.includes(kActionCreate) && permissions.includes(kActionUpdate)) {
        icons.push(<Icons8EyeOutlinedIcon/>);
        icons.push(<Icons8EditOutlined/>);
        return icons;
    } else if (permissions.includes(kActionView)) {
        icons.push(<Icons8EyeOutlinedIcon/>);
        return icons;
    }

    return [<Icons8InvisibleIcon/>];
}