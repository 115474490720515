import NewCompanyModal from "./NewCompanyModal";
import PersonalInfoModal from "./PersonalInfoModal";
import React, {useContext, useEffect} from "react";
import {AppDataContext} from "../../../AppData";
import {SetCreateCompanyModal} from "./AppModals";
import {useResettableMutation} from "tomaschyly-apollo-hooks-extended";
import {
    AddressProvider,
    CreateCompanyDocument,
    CreateCompanyInput,
    CreateCompanyMutation,
    CreateCompanyMutationVariables
} from "../../../generated/graphql/graphql";
import {IInputsData} from "../form/FormBuilder";
import {Option} from "../form/InputProps";
import {tt} from "../../../core/Localization";
import {UserStartSwitchCompany} from "../../../service/UserService";
import {ErrorToast, SuccessToast} from "../../../service/ToastService";
import {useNavigate} from "react-router-dom";
import {kDashboardRoute} from "../../screens/dashboard/DashboardScreen";
import {AuthUserUpdateCompanyIds} from "../../../service/AuthUserService";
import {AppContext} from "../../../App";
import {trackDataLayerEvent} from "../../../service/AnalyticsService";

export interface ICreateCompanyModalProps {
    newCompanyOpen?: boolean;
    personalInfoOpen?: boolean;
    input?: CreateCompanyInput;
    singleStep?: boolean;
    invalidateForm?: boolean;
    cleanFormOnOpen?: boolean;
}

/**
 * Modal component for Company creation process.
 */
export default function CreateCompanyModal(props: ICreateCompanyModalProps) {
    const {newCompanyOpen, personalInfoOpen, singleStep, invalidateForm, cleanFormOnOpen} = props;

    const appContext = useContext(AppContext);

    const appDataContext = useContext(AppDataContext);
    const {appModals} = appDataContext;
    const createCompany = appModals?.createCompany;

    const navigate = useNavigate();

    const [mutateCreateCompany, {
        loading: createCompanyLoading,
        error: createCompanyError,
        data: createCompanyData,
    }] = useResettableMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument);

    useEffect(() => {
        if (createCompanyError) {
            console.error(createCompanyError);
            ErrorToast(tt('common.mutation.error'));
        }
    }, [createCompanyError]);

    const theNewCompanyOpen = newCompanyOpen || false;
    const thePersonalInfoOpen = personalInfoOpen || false;

    useEffect(() => {
        if (theNewCompanyOpen) {
            if (singleStep) {
                trackDataLayerEvent('next_company_form_modal_view');
            } else {
                trackDataLayerEvent('new_company_form_modal_step1_view');
            }
        }

        if (thePersonalInfoOpen) {
            trackDataLayerEvent('new_company_form_modal_step2_view');
        }
    }, [theNewCompanyOpen, thePersonalInfoOpen]);

    if (!createCompany) {
        return null;
    }

    /**
     * Previous step actions by state.
     */
    const PrevStep = () => {
        const props: ICreateCompanyModalProps = {...createCompany};

        if (createCompany.personalInfoOpen) {
            props.newCompanyOpen = true;
            props.personalInfoOpen = false;
        } else if (createCompany.newCompanyOpen) {
            props.newCompanyOpen = false;
        }

        SetCreateCompanyModal(appDataContext, props);
    };

    /**
     * Mutate to BE creation of new Company for this User.
     */
    const CreateCompany = async (inputs: IInputsData) => {
        const variables: CreateCompanyMutationVariables = {
            input: {
                name: inputs.companyName.value,
                industryIds: inputs.industries.value.map((option: Option) => option.value),
                address: {
                    street: '',
                    city: '',
                    zipcode: '',
                    state: '',
                    country: inputs.country.value,
                    provider: AddressProvider.None,
                },
                currency: inputs.currency.value,
                timeZone: 'Europe/Brussels',
                hasVat: inputs.vat.value,
            },
        };

        if (singleStep) {
            trackDataLayerEvent('next_company_form_modal_click');

            try {
                const result = await mutateCreateCompany({variables});

                if (!result.errors) {
                    trackDataLayerEvent('next_company_form_modal_reg_success');

                    const theData = result.data!.createCompany;

                    UserStartSwitchCompany(
                        appDataContext,
                        theData.id,
                        tt('switching.to.company.creating'),
                    );

                    AuthUserUpdateCompanyIds(appContext, appDataContext, {add: theData.id});

                    const props: ICreateCompanyModalProps = {...createCompany};
                    props.newCompanyOpen = false;
                    props.personalInfoOpen = false;
                    props.input = undefined;
                    props.invalidateForm = !props.invalidateForm;
                    SetCreateCompanyModal(appDataContext, props);

                    SuccessToast(tt('createCompanyModal.success'));

                    setTimeout(() => {
                        navigate(kDashboardRoute);
                    }, 100);
                }
            } catch (e) {
                console.error(e);
                ErrorToast(tt('common.mutation.error'));
            }
        } else {
            trackDataLayerEvent('new_company_form_modal_step1_click');

            const props: ICreateCompanyModalProps = {...createCompany};
            props.newCompanyOpen = false;
            props.personalInfoOpen = true;
            props.input = variables.input;
            SetCreateCompanyModal(appDataContext, props);
        }
    };

    /**
     * Mutate to BE personal details of User.
     */
    const PersonalDetails = async (inputs: IInputsData) => {
        try {
            trackDataLayerEvent('new_company_form_modal_step2_click');

            const variables: CreateCompanyMutationVariables = {
                input: {
                    name: createCompany!.input!.name,
                    industryIds: createCompany!.input!.industryIds,
                    address: createCompany!.input!.address,
                    personalDetailsInput: {
                        name: inputs.firstName.value,
                        surname: inputs.lastName.value,
                        phoneNumber: inputs.phone.value,
                        newsletter: inputs.newsletter.value,
                    },
                    currency: createCompany!.input!.currency,
                    timeZone: 'Europe/Brussels',
                    hasVat: createCompany!.input!.hasVat,
                },
            };

            const result = await mutateCreateCompany({variables});

            if (!result.errors) {
                trackDataLayerEvent('new_company_form_modal_reg_success');

                const theData = result.data!.createCompany;

                UserStartSwitchCompany(
                    appDataContext,
                    theData.id,
                    tt('switching.to.company.creating'),
                );

                AuthUserUpdateCompanyIds(appContext, appDataContext, {add: theData.id});

                const props: ICreateCompanyModalProps = {...createCompany};
                props.newCompanyOpen = false;
                props.personalInfoOpen = false;
                props.input = undefined;
                props.invalidateForm = !props.invalidateForm;
                SetCreateCompanyModal(appDataContext, props);

                SuccessToast(tt('createCompanyModal.success'));

                setTimeout(() => {
                    navigate(kDashboardRoute);
                }, 100);
            }
        } catch (e) {
            console.error(e);
            ErrorToast(tt('common.mutation.error'));
        }
    };

    return (
        <>
            <NewCompanyModal
                singleStep={singleStep}
                blurBackdrop={true}
                onClose={PrevStep}
                open={newCompanyOpen || false}
                onContinue={CreateCompany}
                invalidateForm={invalidateForm}
                cleanFormOnOpen={cleanFormOnOpen}
            />

            <PersonalInfoModal
                blurBackdrop={true}
                onClose={PrevStep}
                open={personalInfoOpen || false}
                onContinue={PersonalDetails}
                loading={createCompanyLoading}/>
        </>
    );
}
