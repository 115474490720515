import {Box, Checkbox, Theme, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import React, {Dispatch, SetStateAction, useContext, useEffect, useMemo, useState} from "react";
import {tt} from "../../../../../core/Localization";
import {UserAddressSingle, UserFullName, UserPhotoUrl, UserRoleTitle} from "../../../../../service/UserService";
import ModalBottomSheet from "../../../ModalBottomSheet";
import BottomSheetModalAppbar from "../../BottomSheetModalAppbar";
import AppButton from "../../../buttons/AppButton";
import AppListItem from "../../../listItems/AppListItem";
import {
    NotifyEmployeesOfVisitInput,
    SendNotificationToUsersInput,
    VisitDetailResponse
} from "../../../../../generated/graphql/graphql";
import FormBuilder, {IInputsData, InputType} from "../../../form/FormBuilder";
import {SuccessToast} from "../../../../../service/ToastService";
import EmptyListText from "../../../textComponents/EmptyListText";
import {SortEmployeesByName} from "../../../../../service/EmployeeService";
import {visitDateTimes, VisitNameOrSequenceId} from "../../../../../service/VisitService";
import {processMutationError} from "../../../../../service/ErrorService";
import {FetchPolicy, RestApiClientContext} from "../../../../../core/RestApiProvider";
import {AppDataContext} from "../../../../../AppData";


export const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        maxHeight: '80vh',
        height: '100vh',
        overflow: "auto",
    },
    buttonContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: 16,
    },
    text: {
        fontWeight: 500,
    },
    textBold: {
        fontWeight: 700,
    }
}));

export interface IEditVisitNotificationsModalBottomSheetProps {
    visitId: number;
    repeatingDay?: number;
    data?: VisitDetailResponse | NullOrUndefined;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    modalAboveModals?: boolean;
}

export default function EditVisitNotificationsModalBottomSheet(props: IEditVisitNotificationsModalBottomSheetProps) {
    const {
        visitId,
        repeatingDay,
        data,
        open,
        setOpen,
        modalAboveModals,
    } = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiPost} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {storage} = appDataContext;

    const {classes} = useStyles();

    const [selected, setSelected] = useState<number[]>([]);
    const [notificationInput, setNotificationInput] = useState<SendNotificationToUsersInput>(
        {
            userIds: [],
            title: `${tt('common.reminder')}: 🗓️ date`,
            body: 'Job name 📍 address',
        },
    );

    useEffect(() => {
        if (open) {
            setSelected([]);
        }
    }, [open]);

    /**
     * Toggle Employee is selected list.
     */
    const Toggle = (id: number) => {
        if (selected.includes(id)) {
            setSelected(prev => {
                const newSelected: number[] = [];

                for (const idOf of prev) {
                    if (idOf !== id) {
                        newSelected.push(idOf);
                    }
                }

                return newSelected;
            });
        } else {
            setSelected(prev => {
                return [
                    ...prev,
                    id,
                ];
            });
        }
    };

    const [inputs, setInputs] = useState<IInputsData>({
        type: {
            testId: 'editJobNotificationTypeInput',
            value: 1,
            label: '',
            type: InputType.ChipSwitch,
            options: [
                {label: tt('editNotification.type.reminder'), value: 1},
                {label: tt('editNotification.type.newVisit'), value: 2},
            ]
        },
    });

    useEffect(() => {
        if (data && open) {
            const dateTimes = visitDateTimes(data.visit, data.visit.repeatingDayData, repeatingDay);
            const location = data.location;

            const description: string[] = [];
            const visitName = VisitNameOrSequenceId(data.visit, data.visit.repeatingDayData);
            const address = UserAddressSingle(location?.address);
            if (visitName) {
                description.push(visitName);
            }
            if (address) {
                description.push(address);
            }

            if (inputs.type.value === 1) {
                setNotificationInput({
                    userIds: [],
                    title: `${tt('common.reminder')}: 🗓️ ${dateTimes.start.toFormat('d.M.yyyy')}`,
                    body: `${description.join(' 📍 ')}`,
                });
            }
            if (inputs.type.value === 2) {
                setNotificationInput({
                    userIds: [],
                    title: `${tt('common.newVisit')}: 🗓️ ${dateTimes.start.toFormat('d.M.yyyy')}`,
                    body: `${description.join(' 📍 ')}`,
                });
            }
        }
    }, [inputs.type.value, data, open]);

    const [sendLoading, setSendLoading] = useState<boolean>(false);
    /**
     * Mutate to BE to send notification.
     */
    const SendNotification = () => {
        restApiPost({
            uri: '/job/visit/notify-employees',
            params: {
                type: inputs.type.value,
                visitId: visitId,
                repeatingDay: repeatingDay,
                selectedEmployeeIds: selected,
            } as NotifyEmployeesOfVisitInput,
            fetchPolicy: FetchPolicy.NetworkOnly,
            setLoading: setSendLoading,
            onData: (data) => {
                if (data) {
                    SuccessToast(tt('jobNotifications.success'));

                    setOpen(false);
                }
            },
            onError: (error) => processMutationError(error),
        });
    };

    const content = useMemo(
        () => {
            const visitData = data?.visit;
            const repeatingDayData = data?.visit.repeatingDayData;
            const assignedEmployeeIds = repeatingDayData ? repeatingDayData.employeeIds : visitData?.employeeIds;

            return data && data.employees.length > 0 ?
                SortEmployeesByName(
                    data.employees.filter(worker => assignedEmployeeIds?.includes(worker.id)),
                )
                    .map(worker => {
                        return (
                            <AppListItem
                                key={worker.id}
                                profileImage={
                                    UserPhotoUrl(worker?.user, data?.files, storage.publicUrlsForFiles)
                                }
                                variant={"smaller-title"}
                                onClick={worker.id ? () => Toggle(worker.id) : undefined}
                                title={UserFullName(worker?.name || worker?.user?.name, worker?.surname || worker?.user?.surname)}
                                description={UserRoleTitle(worker.role)}
                                actionWidget={worker.id && selected.includes(worker.id) ?
                                    <Checkbox checked={true}/> :
                                    <Checkbox checked={false}/>}
                            />
                        );
                    })
                : <EmptyListText text={tt('jobNotifications.workers.emptyListMessage')}/>;
        },
        [selected, data, storage.publicUrlsForFiles]
    );

    return (
        <ModalBottomSheet
            blurBackdrop={true}
            open={open}
            setOpen={setOpen}
            hideHeader={true}
            modalAboveModals={modalAboveModals}
            children={
                <Box className={classes.container}>
                    <BottomSheetModalAppbar
                        title={tt('visitDetail.sendNotificationsBottomsheet.title')}
                        onClose={() => setOpen(false)}
                        bottomContent={
                            <>
                                <FormBuilder inputs={inputs} setInputs={setInputs}/>

                                <Typography className={classes.textBold}>{notificationInput.title}</Typography>
                                <Typography className={classes.text}>{notificationInput.body}</Typography>
                                <Box pb={2.5}/>
                            </>
                        }
                    />

                    {content}

                    <Box sx={{pb: 10}}/>

                    <Box className={classes.buttonContainer}>
                        <AppButton
                            variant={"contained"}
                            disabled={!data}
                            isLoading={sendLoading}
                            fullWidth={true}
                            onClick={SendNotification}>
                            {tt('common.send')}
                        </AppButton>
                    </Box>
                </Box>
            }
        />
    );
}
